import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, TextInput, StyleSheet, Text, Image, Easing } from 'react-native';
// import Svg, { G, Circle, SvgXml, SvgCss, ClipPath, Rect } from 'react-native-svg';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
const actualHeight = Dimensions.get('screen').height;
const actualWidth = Dimensions.get('screen').width;
const height = 107.5 //107.5; and 120 in 500
const width = 37.5 //37.5;
// const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const AnimatedInput = Animated.createAnimatedComponent(TextInput)
export default function Donut({
    percentage = 50,
    strokeWidth = 10,
    radius = (strokeWidth * 2) * 1.3,//40,
    duration = 900,
    color = 'tomato',
    delay = 500,
    textColor,
    max = 100,
    data,
    lData
}) {
    const animatedValue = useRef(new Animated.Value(0)).current;
    const lossAnimatedValue = useRef(new Animated.Value(0)).current;
    const winAnimatedValue = useRef(new Animated.Value(0)).current;
    const circleRef = useRef(null);
    const inputRef = useRef(null);
    const circleCircumference = 2 * Math.PI * radius;
    const [sdOffset, setSdOffset] = useState(circleCircumference);
    const halfCircle = radius + strokeWidth;
    const [degree, setDegree] = useState('0deg');
    const [lossValue, setLossValue] = useState(0);
    const [gainValue, setGainValue] = useState(0);

    let { lossratio, winamount, lossamount } = data;
    let lossPercent = 90 //lossratio * 100;
    let strwin = winamount.slice(1, winamount.length);
    let strloss = lossamount.slice(1, lossamount.length);
    var intWin = parseInt(strwin)
    var intLoss = parseInt(strloss)


    const animation = (toValue) => {
        return Animated.timing(animatedValue, {
            toValue,
            duration,
            delay,
            useNativeDriver: true,
        }).start(() => {
            // animation(toValue === 0 ? -lossPercent : 0)
        });
    }
    const lossValueAnimation = (toValue) => {
        return Animated.timing(lossAnimatedValue, {
            toValue: 100,
            duration,
            delay,
            // useNativeDriver: true,
        }).start(() => {
            lossAnimatedValue.setValue(toValue)
        });
    }
    const winValueAnimation = (toValue) => {
        return Animated.timing(winAnimatedValue, {
            toValue,
            duration,
            delay,
            easing: Easing.linear
            // useNativeDriver: true,
        }).start(() => {
            // animation(toValue === 0 ? percentage : 0)
        });
    }

    useEffect(() => {
        if (circleRef?.current) {
            animation(-lossPercent);
            animatedValue.addListener(v => {
                const maxPercentage = 100 * (v.value) / max;
                const strokeDashoffset = circleCircumference - (circleCircumference * maxPercentage) / 100;
                setSdOffset(strokeDashoffset);
            })
            // circleRef.current.setNativeProps({
            //     strokeDashoffset
            // })
            // if (inputRef?.current) {
            //     setLossValue(Math.round(v.value))
            //     // inputRef.current.setNativeProps({
            //     //     text: `${Math.round(-v.value)}`
            //     // })
            // }
        }
        lossValueAnimation(intLoss);
        // countAnimation(lossamount);
        lossAnimatedValue.addListener(v => {

            if (inputRef?.current) {
                setLossValue(Math.round(v.value))
            }
        })
        winValueAnimation(intWin);
        // countAnimation(lossamount);
        winAnimatedValue.addListener(v => {

            if (inputRef?.current) {
                setGainValue(Math.round(v.value))
            }
        })

        // return animatedValue.removeAllListeners();
    }, [max, percentage]);

    const winScale = winAnimatedValue.interpolate({
        inputRange: [0, 100 / 2, 100],
        outputRange: [1, 1.4, 1],
    });

    const lossScale = lossAnimatedValue.interpolate({
        inputRange: [0, 100 / 2, 100],
        outputRange: [1, 1.4, 1],
    });

    const LabelLeft = () => {
        return (
            <View
                style={{
                    position: 'absolute',
                    alignSelf: 'flex-start',
                    left: 70,
                }}>
                <Text
                    style={{
                        color: 'white',
                        fontSize: 28,
                        fontFamily: 'JosefinSans-Light',
                        fontWeight: 700,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        letterSpacing: 0.5,
                    }}>
                    You <Text style={{ color: 'black' }}>Win</Text>
                </Text>
                <Animated.View
                    style={{
                        transform: [{ scale: lossScale }],
                        top: 5
                    }}>
                    <Text
                        ref={inputRef}
                        style={[
                            // StyleSheet.absoluteFillObject,
                            {
                                color: 'white',
                                fontSize: 28,
                                textAlign: 'center',
                                fontFamily: 'JosefinSans-Bold',
                                fontWeight: 500,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                letterSpacing: 0.5,
                            }]} >
                        ${lossValue}
                    </Text>
                </Animated.View>

            </View>
        );
    }
    const LabelRight = () => {
        return (
            <View
                style={{
                    position: 'absolute',
                    alignSelf: 'flex-end',
                    right: 62,
                }}>
                <Text
                    style={{
                        color: 'white',
                        fontSize: 28,
                        fontFamily: 'JosefinSans-Light',
                        fontWeight: 700,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        letterSpacing: 0.5,
                    }}>
                    You <Text style={{ color: 'black' }}>Lose</Text>
                </Text>
                <Animated.View
                    style={{
                        transform: [{ scale: winScale }],
                        top: 5
                    }}>
                    <Text
                        ref={inputRef}
                        style={[
                            // StyleSheet.absoluteFillObject,
                            {
                                color: 'white',
                                fontSize: 28,
                                textAlign: 'center',
                                fontFamily: 'JosefinSans-Bold',
                                fontWeight: 500,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                letterSpacing: 0.5,
                            }]} >
                        ${gainValue}
                    </Text>
                </Animated.View>
            </View>
        );
    }

    const Arrow = () => {
        return (
            <>
                <View style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: [{ translateX: -width / 2 }, { translateY: -height + 13 }],
                    zIndex: 99,
                    width: width,
                    height: height
                }}>

                    {/* <Image
                        style={{
                            width: width,
                            height: height,
                            resizeMode: 'stretch',
                            transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
                        }}
                        source={require('../../assets/images/arrow.png')} /> */}
                    <View style={{
                        transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
                    }} >
                        <View style={{
                            position: 'absolute',
                            width: 3, height: 25,
                            backgroundColor: 'black', transform: [{ rotate: '30deg' }],
                            left: 13,
                            top: -33
                        }} />
                        <View style={{
                            position: 'absolute',
                            width: 3, height: 25,
                            backgroundColor: 'black', transform: [{ rotate: '-30deg' }],
                            left: 27,
                            top: -33
                        }} />
                        <View style={{
                            width: 3, height: height + 20,
                            left: 20,
                            bottom: 32,
                            backgroundColor: 'black',
                        }} />
                        <View style={{
                            height: 15, width: 15, borderRadius: 15 / 2,
                            borderColor: 'black', borderWidth: 5,
                            left: 13.5, bottom: 32,
                        }} />
                    </View>
                </View>
            </>
        );
    };

    return (
        <View style={{ width: 400, height: 400, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginVertical: actualHeight > 980 ? hp('20%') : hp('9%') }}>
            <Arrow />
            <svg height={500} width={500} transform="rotate(-90) translate(10,-39)">
                <clipPath id="prefix__a">
                    {/* <path fill="red" d="M0 0h400v140H0z" /> */}
                    <rect width={280} height={140} rx={8} ry={8} />
                </clipPath>
                <circle
                    cx={140}
                    cy={140}
                    r={140}
                    fill="#ee7998"
                    clipPath="url(#prefix__a)"
                />
            </svg>
            <svg height={500} width={500} transform="rotate(90) translate(10,-44)">
                <clipPath id="prefix__a">
                    {/* <path d="M0 0h200v140H0z" /> */}
                    <rect width={400} height={140} rx={8} ry={8} />
                </clipPath>
                <circle
                    cx={140}
                    cy={140}
                    r={140}
                    fill="#bb6ef0"
                    clipPath="url(#prefix__a)"
                />
            </svg>
            <LabelLeft />
            <LabelRight />
        </View>
    );
}
//working code
                    //<AnimatedCircle
                    //     cx='-50%'
                    //     cy='50%'
                    //     stroke={'#bb6ef0'}
                    //     strokeWidth={strokeWidth * 2}
                    //     r={radius}
                    //     fill="transparent"
                    // // strokeOpacity={0.2}
                    // />
                    // {/* <SvgXml xml={xml} width="100%" height="100%" /> */}

                    // <AnimatedCircle
                    //     ref={circleRef}
                    //     cx='-50%'
                    //     cy='50%'
                    //     stroke={'#ee7998'}
                    //     strokeWidth={strokeWidth * 2}
                    //     r={radius}
                    //     fill="transparent"
                    //     strokeDasharray={circleCircumference}
                    //     strokeDashoffset={sdOffset}
                    // // strokeLinecap='round'
                    // />
                    // ------------------------------
                //     <svg height={500} width={500} transform="rotate(-90) translate(10,-39)">
                //     <clipPath id="prefix__a">
                //         {/* <path fill="red" d="M0 0h400v140H0z" /> */}
                //         <rect width={280} height={140} rx={8} ry={8} />
                //     </clipPath>
                //     <circle
                //         cx={140}
                //         cy={140}
                //         r={140}
                //         fill="#ee7998"
                //         clipPath="url(#prefix__a)"
                //     />
                // </svg>
                // <svg height={500} width={500} transform="rotate(90) translate(10,-44)">
                //     <clipPath id="prefix__a">
                //         {/* <path d="M0 0h200v140H0z" /> */}
                //         <rect width={400} height={140} rx={8} ry={8} />
                //     </clipPath>
                //     <circle
                //         cx={140}
                //         cy={140}
                //         r={140}
                //         fill="#bb6ef0"
                //         clipPath="url(#prefix__a)"
                //     />
                // </svg>
                // <LabelLeft />
                // <LabelRight />