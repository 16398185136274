import React, {useLayoutEffect, useState, useRef, useContext, useEffect} from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Image} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../App.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {ActivityContext} from '../../../Activities';

const Index = (props) => {
  const animation = useRef(new Animated.Value(0)).current;
  const resultContext = useContext(ActivityContext);
  const [buttonSelect, setButtonSelect] = useState(true);
  const [hide, setHide] = useState(true);
  const [reRender, setReRender] = useState('0');
  const [data, setData] = useState([]);
  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  };

  useLayoutEffect(() => {
    let check;
    let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
    if (props.moduleObject.questions[props.moduleIndex].askif) {
      if (props.moduleObject.questions[props.moduleIndex].askif.op == 'OR') {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
          moduleIdExep[0].responses.forEach((data) => {
            if (val.qid == data.qid) {
              check = props.moduleObject.questions[props.moduleIndex].askif.answers.find((cc) => cc.respid == data.respid);
            }
          })
          if (check) {
          } else {
            props.selectAnswerHandler(
                props.moduleObject.moduleid,
                props.moduleObject.questions[props.moduleIndex].qid,
                props.moduleObject.questions[props.moduleIndex].answers[0].respid,
            );
            props.setSkippedQuestion(true);
            props.Refresh();
          }

        })
      }
      if (props.moduleObject.questions[props.moduleIndex].askif.op == 'AND') {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
          moduleIdExep[0].responses.forEach((data) => {
            if (val.qid == data.qid) {
              if (val.respid == data.respid) {

              } else {
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.moduleObject.questions[props.moduleIndex].qid,
                    props.moduleObject.questions[props.moduleIndex].answers[0].respid,
                );
                props.Refresh();
              }
            }
          })
        })
      }
    }
    Animated.timing(animation, {
      toValue: 1,
      duration: 2000
    }).start(() => {
      setHide(false)});
    if (isSafari()) {
      setTimeout(() => {
        setReRender('3')
      }, 101)
    }
  }, [])


  const chage = (index) => {
    setButtonSelect(false);
    if (props.moduleObject.questions[props.moduleIndex].multichoice) {
      let tmp = data;
      tmp[index].isActive = !tmp[index].isActive;
      setData(tmp => [...tmp])
    } else {
      let tmp = data.map((item, _index) => {
        if (index === _index) {
          item.isActive = true;
          return item;
        } else {
          item.isActive = false;
          return item;
        }
      });
      setData(tmp)
    }

  }
  useEffect(() => {
    let select = data.find((Value) => Value.isActive == true);
    if (select == undefined) {
      setButtonSelect(true);
    }
  }, [data])

  const [selectedGroups, setSelectedGroups] = useState([]);

  const toggleGroup = (groupId) => {
    //chage(groupId)
    // If "None of these" is selected, deselect everything else.
    if (groupId === 0) {
      setSelectedGroups([0]);
    } else {
      setSelectedGroups(prevSelectedGroups => {
        if (prevSelectedGroups.includes(groupId)) {
          return prevSelectedGroups.filter(id => id !== groupId);
        } else {
          // If any other group is selected, ensure "None of these" is not selected.
          const filteredGroups = prevSelectedGroups.filter(id => id !== 0);
          return [...filteredGroups, groupId];
        }
      });
    }
  };

  const isSelected = (groupId) => selectedGroups.includes(groupId);

  const flipAnim = useRef(new Animated.Value(0)).current; // For flip

  const flipAnimation = Animated.timing(flipAnim, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });
  const nextPage = () => {
    setHide(true);
    let cc = selectedGroups;
    if (!props.moduleObject) return false
    if (buttonSelect && cc[0] === 0) {
      props.selectAnswerHandler(
          props.moduleObject.moduleid,
          props.moduleObject.questions[props.moduleIndex].qid,
          props.moduleObject.questions[props.moduleIndex].answers[9].respid,
      );
    } else {
      cc.forEach((val) => {
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[val-1].respid,
        );
      })
    }
    Animated.parallel([flipAnimation]).start(() => props.Refresh());
  }

  const flipInterpolate = flipAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'], // Flip by 360 degrees
  });

  const animatedStyle = {
    transform: [{rotateY: flipInterpolate}],
  };

  return (
      <div style={{height: '100%'}}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}>
          <div className={`experience-circle-1`} style={{
            width: '100%', display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column', backgroundColor: '#ffce0b'
          }}>
            <Animated.View style={[{}, animatedStyle, {
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }]}>

              <div className={`button-container flip-element`}
                   style={{maxWidth: '600px', width: '100%', display: 'block !important'}}>
                <span style={{visibility: "hidden"}}>{reRender}</span>

                <svg width="100%" height="600" viewBox="0 0 800 800" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <circle id="path-1" cx="400" cy="400" r="400"></circle>
                  </defs>
                  <g id="experience-10segment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"/>
                    </mask>
                    <g id="Oval"></g>
                    <g className={`segment-group none ${isSelected(9) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(9)} id="Group-not" mask="url(#mask-2)">
                      <g transform="translate(76.298, 19.5774)">
                        <path
                            d="M200.095192,0 L323.529868,379.892868 L0,145.391684 C47.9290773,79.2664785 116.47913,27.1685055 200.095192,0 Z"
                            className="wheel-segment" id="not" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="not-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="67.5548336" y="112.8310609" fill="#A6C3FF">Hedge funds,</tspan>
                          <tspan x="67.5548336" y="142.8310609" fill="#A6C3FF">Private equity</tspan>
                          <tspan x="67.5548336" y="172.8310609" fill="#A6C3FF">funds</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(8) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(8)} id="Group-hfpef" mask="url(#mask-2)">
                      <g transform="translate(-4.4926, 164.8859)">
                        <path
                            d="M80.885796,0 L404.042067,234.786933 L4.4642182,235.237329 C4.37249371,153.568914 29.2082313,71.1280657 80.885796,0 Z"
                            className="wheel-segment" id="hfpef" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="hfpef-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="36.484375" y="147.704191" fill="#A6C3FF">Venture</tspan>
                          <tspan x="32.8857422" y="174.704191" fill="#A6C3FF">capital trusts</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(7) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(7)} id="Group-vct" mask="url(#mask-2)">
                      <g transform="translate(-4.1836, 399.9989)">
                        <path
                            d="M403.627008,0 L80.6268477,235.231918 C32.5487105,169.214752 4.18326632,87.9204232 4.18326632,0 L403.627008,0 Z"
                            className="wheel-segment" id="vct" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="vct-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="61.3183594" y="90.3620291" fill="#A6C3FF">Futures /</tspan>
                          <tspan x="61.8554688" y="117.3620291" fill="#A6C3FF">Options</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(6) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(6)} id="Group-fo" mask="url(#mask-2)">
                      <g transform="translate(76.3932, 400.3251)">
                        <path
                            d="M323.156222,0 L200.1087,380.162344 C122.408924,355.012912 51.6776076,305.917076 0,234.788951 L323.156222,0 Z"
                            className="wheel-segment" id="fo" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="fo-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="92.5313536" y="249.854181" fill="#A6C3FF">Structured</tspan>
                          <tspan x="92.7754943" y="276.854181" fill="#A6C3FF">products</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(5) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(5)} id="Group-sp" mask="url(#mask-2)">
                      <g transform="translate(276.3932, 400.5278)">
                        <path
                            d="M123.434078,0 L247.339791,379.883607 C169.696798,405.207841 83.6161388,407.063305 0,379.894775 L123.434078,0 Z"
                            className="wheel-segment" id="sp" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="sp-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="72.485704" y="310.472168" fill="#A6C3FF">Fixed interest</tspan>
                          <tspan x="72.1282821" y="337.472168" fill="#A6C3FF">bonds</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(4) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(4)} id="Group-fib" mask="url(#mask-2)">
                      <g transform="translate(400.1721, 400.5297)">
                        <path
                            d="M0,0 L323.530591,234.500185 C275.60148,300.625865 207.051158,352.724226 123.434675,379.892868 L0,0 Z"
                            className="wheel-segment" id="fib" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="fib-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="122.415464" y="249.061807" fill="#A6C3FF">Investment</tspan>
                          <tspan x="122.538511" y="276.061807" fill="#A6C3FF">trusts</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(3) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(3)} id="Group-it" mask="url(#mask-2)">
                      <g transform="translate(400.4496, 399.8768)">
                        <path
                            d="M0,0.449913422 L399.578796,0 C399.67052,81.6684142 374.834782,164.109263 323.157218,235.237329 L0,0.449913422 Z"
                            className="wheel-segment" id="it" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="it-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="249.130904" y="89.5331376" fill="#A6C3FF">Exchange</tspan>
                          <tspan x="249.786178" y="116.5331376" fill="#A6C3FF">traded funds</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(2) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(2)} id="Group-etf" mask="url(#mask-2)">
                      <g transform="translate(400.556, 164.7691)">
                        <path
                            d="M323.000778,0 C371.078915,66.0171664 399.444049,147.311495 399.444049,235.230856 L1.13686838e-13,235.230856 L323.000778,0 Z"
                            className="wheel-segment" id="etf" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="etf-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="257.768251" y="126.8698892" fill="#A6C3FF">Equities /</tspan>
                          <tspan x="257.342469" y="153.869889" fill="#A6C3FF">Shares</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(1) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(1)} id="Group-eqs" mask="url(#mask-2)">
                      <g transform="translate(400.4496, 19.5125)">
                        <path
                            d="M123.048448,0 C200.748225,25.1494317 271.479541,74.2452676 323.157148,145.373392 L0,380.159491 L123.048448,0 Z"
                            className="wheel-segment" id="eqs" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="eqs-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="132.442201" y="112.3081625" fill="#A6C3FF">Mutual funds /</tspan>
                          <tspan x="132.649232" y="139.3081625" fill="#A6C3FF">Unit trusts</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(0) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(0)} id="Group-mfut" mask="url(#mask-2)">
                      <g transform="translate(276.2661, -0.108)">
                        <path
                            d="M0,19.6968986 C77.6432416,-5.62762949 163.724262,-7.48321927 247.340742,19.685422 L123.905718,399.579715 L0,19.6968986 Z"
                            className="wheel-segment" id="mfut" strokeOpacity="0.495641322"
                            stroke="#0066FF" strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="mfut-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20"
                              fontWeight="500">
                          <tspan x="52.2720301" y="71.1080285" fill="#A6C3FF">None of these</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="Center-10" mask="url(#mask-2)">
                      <g transform="translate(160, 160)">
                        <circle id="Oval" fillOpacity="0.202866348" fill="#000000" fillRule="evenodd"
                                cx="240" cy="240" r="240"></circle>
                        <circle className="segment-center" id="Oval" fill="#ffce0b" fillRule="evenodd"
                                cx="240" cy="240" r="222"></circle>
                        <text id="Which-of-the-followi" fill="none"
                              fontFamily="OpenSans-Regular, Open Sans" fontSize="29"
                              fontWeight="normal">
                          <tspan x="68.1169434" y="209" fill="#000000b0">I have direct experience of
                          </tspan>
                          <tspan x="72.3322754" y="259" fill="#000000b0">investing in the following:
                          </tspan>
                        </text>
                        <text id="(select-all-that-app" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="16"
                              fontWeight="500">
                          <tspan x="160.007812" y="336" fill="#000000b0">(select all that apply)</tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </svg>
                <div className="button experience"
                     style={{display: 'block', opacity: selectedGroups.length ? 1 : 0}} onClick={() => nextPage()}>
                  Continue
                </div>
              </div>
            </Animated.View>
          </div>
        </div>
      </div>
  )
}
export default Index
const styles = StyleSheet.create({
  content: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  customText: {
    position: "relative",
    width: Dimensions.get('screen').width > 1300 ? wp('34%') : Dimensions.get('screen').width > 700 ? wp('34%') : wp('95%'),
    Height: hp('24.3%'),
    fontSize: Dimensions.get('screen').width > 1300 ? 24 : 22,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#575f69',
    marginBottom: hp("3%"),
    fontFamily: 'JosefinSans-Light',
    lineHeight: 26.5,
    letterSpacing: 2
  },
  customText2: {
    position: "relative",
    width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
    Height: hp('24.3%'),
    fontSize: Dimensions.get('screen').width > 1300 ? 16 : 14,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#575f69',
    marginBottom: hp("3%"),
    fontFamily: 'JosefinSans-Light',
  },
  button: {
    width: Dimensions.get('screen').width > 1300 ? 112 : 100,
    height: Dimensions.get('screen').width > 1300 ? 112 : 100,
    borderWidth: 3,
    borderRadius: Dimensions.get('screen').width > 1300 ? 60 : 55,
    borderStyle: "solid",
    borderColor: "#ff8d53",
    backgroundColor: "#ff8d53",
    alignItems: "center",
    justifyContent: "center",
  },
  buttontext: {
    // width: 85,
    // height: 50,
    fontFamily: 'JosefinSans-Bold',
    fontSize: Dimensions.get('screen').width > 320 ? 12 : 10,
    textAlign: 'center',
    letterSpacing: 1,
    color: '#ffffff',
  },
  buttongroup: {
    flexDirection: 'row'
  },
  lastButton: {
    backgroundColor: '#575f69',
    // width:'auto',
    // justifyContent: 'center',
    width: 199,
    minHeight: 35,
    textAlignVertical: "center",
    flexDirection: 'row'
  },
  lastButtonText: {
    color: '#ffffff',
    fontFamily: 'JosefinSans-Bold',
    fontSize: Dimensions.get('screen').width > 500 ? 16 : 12,
    paddingTop: Dimensions.get('screen').width > 500 ? 10 : Dimensions.get('screen').width > 320 ? 12 : 13,
    width: "100%",
    textAlign: "center",
    height: "100%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
  },
  arrow: {
    width: Dimensions.get('screen').width > 1300 ? 34 : 28,
    height: Dimensions.get('screen').width > 1300 ? 34 : 28,
    borderWidth: 3,
    borderRadius: Dimensions.get('screen').width > 1300 ? 18 : 15,
    borderStyle: "solid",
    borderColor: "#575f69",
    backgroundColor: "#575f69",
    alignItems: "center",
    justifyContent: "center",
  },
  continueButton: {
    // width: Dimensions.get('screen').width > 850 ? 196 : 178,
    height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#575f69",
    backgroundColor: "#575f69",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'row',
    textTransform: "uppercase"
    // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
  },
  customArrow: {
    alignSelf: 'center',
    width: Dimensions.get('screen').width > 850 ? 22 : 16,
    height: Dimensions.get('screen').width > 850 ? 22 : 16,
    resizeMode: "contain",
    marginRight: wp('1.4%'),
    marginLeft: wp('0.5%')

  },
  continueText: {
    fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'JosefinSans-Bold',
    lineHeight: 'normal',
    letterSpacing: 1.6,
    marginRight: wp("0.5%"),
    marginLeft: wp('1.4%')


  },

})
