import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import {
  View,
  StyleSheet,
  Text,
  Dimensions,
  Animated,
  Easing,
} from "react-native";
import { EXCLUDE_FROM_INVESTMENTS } from "../../../../Utils/static-strings";
import DragGames from "./dragGameBalls";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "../../../../Utils/responsive";

const actualWidth = Dimensions.get("screen").width;
const actualHeight = Dimensions.get("screen").height;

let deviceWidth = Dimensions.get("window").width;
let deviceHeigth = Dimensions.get("window").height;

const isIpad =
  navigator.userAgent.match(/Mac/) &&
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2;

export const DragBallsFour = (props) => {
  const [PETS, setPets] = useState(
    props.moduleObject.questions[props.moduleIndex].answers
  );
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(deviceHeigth);
  const [width, setWidth] = useState(deviceWidth);
  const introAnimation = useRef(new Animated.Value(actualWidth)).current;

  useLayoutEffect(() => {
    Animated.timing(introAnimation, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start();
  });

  Dimensions.addEventListener("change", (e) => {
    deviceHeigth = e.window.height;
    deviceWidth = e.window.width;
    setHeight(deviceHeigth);
    setWidth(deviceWidth);
  });

  useEffect(() => {
    let moduleIdEsg = props.resultContext.result.modules.filter(
      (mod) => mod.moduleid === props.moduleObject.moduleid
    );
    if (props.moduleObject.questions[props.moduleIndex].askif) {
      if (props.moduleObject.questions[props.moduleIndex].askif.op == "AND") {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach(
          (val) => {
            let ifExist = moduleIdEsg[0].responses.filter(
              (mod) => mod.qid == val.qid && mod.respid == val.respid
            );
            if (ifExist.length > 0) {
              setShow(true);
              setPets(props.moduleObject.questions[props.moduleIndex].answers);
            } else {
              if (props.moduleIndex < props.numberOfQuestions - 1) {
                props.setModuleIndex((moduleIndex) => moduleIndex + 1);
              } else {
                if (moduleIdEsg[0].completedat == null) {
                  props.resultContext.setModuleCompletedAtTime(
                    props.moduleObject.moduleid
                  );
                  props.history.push("/");
                }
              }
            }
          }
        );
      }
    }
  }, [props.moduleIndex]);

  return (
    show && (
      <Animated.View
        style={[
          {
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: "5%",
            transform: [{ translateX: introAnimation }],
          },
        ]}
      >
        <Text
          style={[
            styles.customText,
            isIpad && {
              width:
                actualWidth > 1200
                  ? wp("75%")
                  : actualWidth > 1000
                  ? "916px"
                  : actualWidth > 400
                  ? wp("85%")
                  : wp("90%"),
            },
          ]}
        >
          {props.moduleObject.questions[props.moduleIndex].text ||
            EXCLUDE_FROM_INVESTMENTS}
        </Text>
        {actualWidth < 520 && <View style={styles.Line} />}
        <View
          style={{
            flexDirection: actualWidth > 500 ? "row" : "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: actualWidth > 1000 ? "75%" : "90%",
            marginBottom: "10%",
          }}
        >
          {PETS.map((pet, index) => {
            if (index >= 2) {
              return (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: actualWidth > 700 && "100%",
                    alignItems: "center",
                    width:
                      isIpad && width > 1000
                        ? "408px"
                        : width > 1200
                        ? "30%"
                        : width > 1000
                        ? "408px"
                        : width > 500
                        ? "45%"
                        : null,
                  }}
                  key={index}
                >
                  <Text style={styles.headingText}>{pet.text}</Text>
                  <View style={{ height: actualWidth > 700 && "50%" }}>
                    <DragGames onClick={props.onItemClick} iconKey={pet.metadata.iconkey} {...pet} />
                  </View>
                </View>
              );
            }
          })}
        </View>
        {/* {actualWidth > 500 || actualWidth < 400 ?
                <View style={{marginBottom:'10%',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: actualWidth > 1000 ? '75%' : '90%', marginBottom: actualWidth < 500 ? '10%' : actualHeight > 1000 && '10%' }}>
                        {PETS.map((pet, index) => {
                            if (index < 2) {
                                return (
                                    <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%',height:actualWidth < 500 ?'100%':'50%' }} key={index}>
                                        <Text style={[styles.subHeading,{height:'100%'}]}>{pet.text}</Text>
                                        <View style={{height:'50%'}}>
                                        <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                        </View>
                                    </View>
                                )
                            }
                        })}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: actualWidth > 1000 ? '75%' : '90%' }}>
                        {PETS.map((pet, index) => {
                            if (index >= 2 && index < 4) {
                                return (
                                    <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%',height:actualWidth < 500 ?'100%':'50%' }} key={index}>
                                        <Text style={[styles.subHeading,{height:'100%'}]}>{pet.text}</Text>
                                        <View style={{height:'50%'}}>
                                        <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                        </View>
                                    </View>
                                )
                            }
                        })}
                    </View>
                </View>
                :
                <View style={{ flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: actualWidth > 1000 ? '75%' : '90%' }}>
                    {PETS.map((pet, index) => {
                        if (index < 4) {
                            return (
                                <View style={{
                                    flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center', width: '50%',
                                    alignSelf: index == 0 || index == 2 ? 'flex-end' : 'flex-start',
                                    top: index == 0 ? '20%' : index == 1 ? '4%' : index == 2 ? '-5%' : index == 3 && '-20%',
                                    // marginLeft: index == 1 ? '10%' : index == 3 && '10%',
                                    // marginRight: index == 0 ? '10%' : index == 2 && '10%'
                                }} key={index}>
                                    <Text style={styles.subHeading}>{pet.text}</Text>
                                    <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                </View>
                            )
                        }
                    })}
                </View>
            } */}
      </Animated.View>
    )
  );
};
const styles = StyleSheet.create({
  headingText: {
    marginBottom: 10,
    fontSize: actualWidth > 1000 ? 24 : actualWidth > 400 ? 20 : 12,
    fontWeight: 300,
    color: "#575f69",
    letterSpacing: actualWidth > 1000 ? 2.4 : actualWidth > 400 ? 1.6 : 1.2,
    fontFamily: "JosefinSans-Light",
    textAlign: "center",
  },
  subHeading: {
    fontSize: actualWidth > 1000 ? 20 : actualWidth > 400 ? 20 : 12,
    fontWeight: 300,
    color: "#575f69",
    letterSpacing: actualWidth > 1000 ? 2 : actualWidth > 400 ? 2 : 1.2,
    fontFamily: "JosefinSans-Light",
    textAlign: "center",
  },
  customText: {
    width:
      actualWidth > 1200
        ? wp("75%")
        : actualWidth > 1000
        ? "916px"
        : actualWidth > 400
        ? wp("85%")
        : wp("90%"),
    fontSize: actualWidth > 600 ? 40 : actualWidth > 400 ? 24 : 16,
    fontWeight: 300,
    textAlign: "center",
    color: "#575f69",
    marginBottom: actualWidth > 700 ? hp("4%") : hp("2%"),
    fontFamily: "JosefinSans-Light",
    letterSpacing: actualWidth > 600 ? 4 : actualWidth > 400 ? 2.4 : 1.6,
  },
  Line: {
    width: actualWidth > 400 ? wp("35%") : wp("30%"),
    height: 1,
    backgroundColor: "#d4d4d4",
    marginBottom: actualWidth > 700 ? hp("4%") : hp("2%"),
  },
});
