import React, { useLayoutEffect, useState, useRef, useContext } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import { ActivityContext } from '../../../Activities';
const Index = (props) => {
    const actualwidth = Dimensions.get('screen').width;
    const animation = useRef(new Animated.Value(0)).current;
    const [buttonColor, setButtonColor] = useState("#ff8d53");
    const [colorset, setColorset] = useState(0);
    const [hide, setHide] = useState(true);
    const resultContext = useContext(ActivityContext);
    const Color = (index) => {
        setHide(true);
        setColorset(index);
        setButtonColor("#A65B36");
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[index - 1].respid,
        );
    }
    useLayoutEffect(() => {
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        if (props.moduleObject.questions[props.moduleIndex].askif) {
            if (props.moduleObject.questions[props.moduleIndex].askif.op == 'OR') {
                props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            if (val.respid == data.respid) {
                            } else {
                                props.selectAnswerHandler(
                                    props.moduleObject.moduleid,
                                    props.moduleObject.questions[props.moduleIndex].qid,
                                    props.moduleObject.questions[props.moduleIndex].answers[0].respid,
                                );
                                props.setSkippedQuestion(true);
                                props.Refresh();
                            }
                        }
                    })

                })
            }
        }
        if (buttonColor == '#A65B36') {
            nextPage();
        }
    }, [buttonColor])

    useLayoutEffect(() => {
        Animated.timing(animation, {
            toValue: 1,
            duration: 500
        }).start(() => setHide(false));
    }, [])

    const nextPage = () => {
        Animated.timing(animation, {
            toValue: 0,
            duration: 500
        }).start(() => props.Refresh());
    }

    return (
        <Animated.View style={[styles.content, { opacity: animation }]}>
            <Text style={styles.customText}>
                {props.moduleObject.questions[1].text}
            </Text>
            {actualwidth > 400 ?
                <View style={styles.buttongroup}>
                    <View>
                        <TouchableOpacity
                            onPress={() => { Color(1) }}
                            disabled={hide}
                            style={[styles.button, { borderColor: (colorset == 1 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 1 ? buttonColor : "#ff8d53") }]}>
                            <Text style={actualwidth > 1300 ? styles.buttontext2 : styles.buttontext}>{props.moduleObject.questions[1].answers[0].text}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ margin: 5 }}></View>
                    <View>
                        <TouchableOpacity
                            onPress={() => { Color(2) }}
                            disabled={hide}
                            style={[styles.button, { borderColor: (colorset == 2 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 2 ? buttonColor : "#ff8d53") }]}>
                            <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[1].text}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ margin: 5 }}></View>
                    <View>
                        <TouchableOpacity
                            onPress={() => { Color(3) }}
                            disabled={hide}
                            style={[styles.button, { borderColor: (colorset == 3 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 3 ? buttonColor : "#ff8d53") }]}>
                            <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[2].text}</Text>
                        </TouchableOpacity>
                    </View>
                </View>

                :

                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View>
                            <TouchableOpacity
                                onPress={() => { Color(1) }}
                                disabled={hide}
                                style={[styles.button, { borderColor: (colorset == 1 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 1 ? buttonColor : "#ff8d53") }]}>
                                <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[0].text}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ margin: 5 }}></View>
                        <View>
                            <TouchableOpacity
                                onPress={() => { Color(2) }}
                                disabled={hide}
                                style={[styles.button, { borderColor: (colorset == 2 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 2 ? buttonColor : "#ff8d53") }]}>
                                <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[1].text}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {/* <View style={{ margin: 5 }}></View> */}
                    <View style={{ alignSelf: 'center', top: -9 }}>
                        <TouchableOpacity
                            onPress={() => { Color(3) }}
                            disabled={hide}
                            style={[styles.button, { borderColor: (colorset == 3 ? buttonColor : "#ff8d53"), backgroundColor: (colorset == 3 ? buttonColor : "#ff8d53") }]}>
                            <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[2].text}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            }
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1300 ? 24 : 20,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        lineHeight: 26.5
    },
    button: {
        width: Dimensions.get('screen').width > 1300 ? 136 : 124,
        height: Dimensions.get('screen').width > 1300 ? 136 : 124,
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1300 ? 92 : 88,
        borderStyle: "solid",
        borderColor: "#ff8d53",
        backgroundColor: "#ff8d53",
        alignItems: "center",
        justifyContent: "center",
    },
    buttontext: {
        width: 110,
        height: Dimensions.get('screen').width < 1025 ? 'auto' : 47,
        fontFamily: 'JosefinSans-Bold',
        fontSize: Dimensions.get('screen').width > 1300 ? 17 : Dimensions.get('screen').width < 500 ? 12 : 16,
        textAlign: 'center',
        // lineHeight: 26.5,
        letterSpacing: 1,
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#ffffff',
    },
    buttontext2: {
        width: 110,
        height: Dimensions.get('screen').width < 1025 ? 'auto' : 67,
        fontFamily: 'JosefinSans-Bold',
        fontSize: Dimensions.get('screen').width > 1300 ? 17 : Dimensions.get('screen').width < 500 ? 12 : 16,
        textAlign: 'center',
        // lineHeight: 26.5,
        letterSpacing: 1,
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#ffffff',
    },
    buttongroup: {
        flexDirection: 'row'
    },

})
