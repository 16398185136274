import React, {useEffect, useState, useRef} from 'react'
import {useDrop} from 'react-dnd';
import {DragBalls} from './dragBalls';
import {DragBallsSecond} from './dragBallsSecond';
import {DragBallsThird} from './dragBallsThird';
import {DragBallsFour} from './dragBallsFour';
import {DropBox} from './dropBox';
import BarContainer from './barConatiner'
import {Modal} from './modal';
import {ConfirmationText} from './confirmationText';
import {StyleSheet, Text, View, TouchableOpacity, Dimensions} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../App.css';
import InfoIcon from '@material-ui/icons/Info';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const actualWidth = Dimensions.get('screen').width;
const actualHeigth = Dimensions.get('screen').height;

let deviceWidth = Dimensions.get("window").width;
let deviceHeigth = Dimensions.get("window").height;

const isIpad =
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2;

export default function Main(props) {
  const [basket, setBasket] = useState([]);
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [infoDesc, setInfoDesc] = useState('');
  const [dragOpacity, setDragOpacity] = useState(false);
  const [completeTask, setCompeleteTask] = useState(false);
  const [back, setBack] = useState(false);
  const [height, setHeight] = useState(deviceHeigth);
  const [width, setWidth] = useState(deviceWidth);

  Dimensions.addEventListener("change", (e) => {
    deviceHeigth = e.window.height;
    deviceWidth = e.window.width;
    setHeight(deviceHeigth);
    setWidth(deviceWidth);
  });


  const [{isOver}, dropRef] = useDrop({
    accept: 'balls',
    drop: (item) => {
      setBasket((basket) =>
          !basket.includes(item) ? [...basket, item] : basket)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  })
  const ref = useRef();
  const removeAnswer = () => {
    // props.setModuleIndex(moduleIndex => moduleIndex - 1);
    // props.resultContext.resetPreviousModuleResult(props.moduleObject.moduleid);
    if (basket.length > 0) {
      setBack(true)
      ref.current = basket[basket.length - 1].metadata;
    }
    basket.pop();
  }

  const backButton = () => {
    let moduleIdEsg = props.resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
    let lastresponse = moduleIdEsg[0].responses[moduleIdEsg[0].responses.length - 1]
    let qidlength = lastresponse.qid.split('.').length;
    let checkValue = lastresponse.qid.split('.')[qidlength - 1];
    if (checkValue == '01') {
      props.setModuleIndex(0);
      // props.pageOne()
      props.pageTwo()
      props.pageThree()
    } else if (checkValue == '02') {
      props.setModuleIndex(1);
    } else if (checkValue == '03') {
      props.setModuleIndex(2);
    } else if (checkValue == '04') {
      props.setModuleIndex(3);
    } else if (checkValue == '05') {
      props.setModuleIndex(4);
    } else if (checkValue == '06') {
      props.setModuleIndex(5);
    } else if (checkValue == '07') {
      props.setModuleIndex(6);
    } else if (checkValue == '08') {
      props.setModuleIndex(7);
    } else if (checkValue == '09') {
      props.setModuleIndex(8);
    } else if (checkValue == '10') {
      props.setModuleIndex(9);
    } else if (checkValue == '11') {
      props.setModuleIndex(10);
    }
    if (basket.length > 0) {
      setBack(true)
      ref.current = basket[basket.length - 1].metadata;
    }
    basket.pop();
    props.resultContext.resetPreviousModuleResult(props.moduleObject.moduleid);
  }


  useEffect(() => {
    if (basket.length > 0) {
      setBack(false);
      if (basket[basket.length - 1].metadata.foodforthought) {
        setModal(true);
        // if(basket[basket.length-1].metadata.confirmationtext){
        //     setCompeleteTask(true);
        // }else{
        //     setModal(true);
        // }
      } else {
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            basket[basket.length - 1].respid,
        );
      }
    }
  }, [basket])

  const handleItemClick = (item) => {
    setBasket((basket) => !basket.includes(item) ? [...basket, item] : basket)
  }

  const ChangeInfoText = (val1, val2) => {
    setInfo(prev => !prev)
    setInfoText(val1)
    setInfoDesc(val2)

  }

  const getDeviceType = () => {
    const ua = navigator.userAgent;

    // Patterns for mobile and tablet detection
    const isMobile = /iPhone|iPad|iPod|Android/i.test(ua);
    const isTablet = /iPad|Android/i.test(ua) && !/Mobile/i.test(ua);
    if (isMobile) {
      if (isTablet) {
        return 'tablet';
      }
      return 'mobile';
    }
    return 'desktop';
  }

  return (
      <View style={[styles.content, actualWidth < 500 && {flex: 1}]}>
        {(!modal && !completeTask) &&
            <TouchableOpacity style={styles.backButton} onPress={backButton}>
              <ArrowBackIcon style={{height: 24, width: 24}} htmlColor="#575f69"/>
            </TouchableOpacity>
        }
        {completeTask ?
            <ConfirmationText setDragOpacity={setDragOpacity} {...props} metadata={basket[basket.length - 1].metadata}
                              answerId={basket[basket.length - 1].respid} setCompeleteTask={setCompeleteTask}/> :
            <View style={[styles.dragBallConatiner, {
              opacity: dragOpacity ? 0 : 1,
              height: props.moduleIndex == 1 ? (actualWidth > 400 && ((actualWidth > 1000 && actualWidth < 1200) && actualHeigth < 900) ? '50%' : isIpad && (width > height) ? '55%' : '60%') : isIpad && (width > height) ? '55%' : (actualWidth > 330 ? ((actualWidth > 1000 && actualWidth < 1200) && actualHeigth < 900) ? '50%' : '60%' : '50%')
            }]}>
              {props.moduleIndex == 1 ?
                  <DragBalls onItemClick={handleItemClick} {...props} basket={basket} setModal={setModal}/> :
                  <>
                    {props.moduleObject.questions[props.moduleIndex].answers.length == 2 &&
                        <DragBallsSecond onItemClick={handleItemClick} {...props}/>}
                    {props.moduleObject.questions[props.moduleIndex].answers.length == 3 &&
                        <DragBallsThird onItemClick={handleItemClick} {...props}/>}
                    {props.moduleObject.questions[props.moduleIndex].answers.length == 4 &&
                        <DragBallsFour onItemClick={handleItemClick} {...props}/>}
                  </>
              }
            </View>
        }


        {modal && <Modal setModal={setModal} setDragOpacity={setDragOpacity} {...props}
                         metadata={basket[basket.length - 1].metadata} removeAnswer={removeAnswer}
                         answerId={basket[basket.length - 1].respid} setCompeleteTask={setCompeleteTask}/>}

        <View
            style={{height: completeTask ? (actualWidth > 400 && actualWidth < 500) ? '30%' : isIpad && (width > height) ? '24%' : ((actualWidth > 1000 && actualWidth < 1200) && actualHeigth < 900) ? '30%' : '16%' : (actualWidth > 400 && actualWidth < 500) ? '35%' : isIpad && (width > height) ? '48%' : '40%'}}>
          <View style={[styles.dropBoxContainer, {
            height: !completeTask && isIpad && (width > height) ? 220 : actualWidth > 700 ? completeTask ? isIpad ? undefined : 137 : 240 : actualWidth > 400 ? completeTask ? 112 : 198 : completeTask ? 100 : 198,
            marginTop: (actualWidth > 1300 && completeTask) && 30
          }]}>
            {!completeTask && <View style={[styles.dropBox, {
              height: isIpad && (width > height) ? 80 : actualWidth > 700 ? 95 : actualWidth > 400 ? 78 : 78,
              backgroundColor: isOver ? 'rgba(87, 95, 105, 0.3)' : 'rgba(87, 95, 105, 0.1)',
              justifyContent: basket.length > 0 ? 'flex-start' : 'center'
            }]} ref={dropRef}>
              {(basket.length == 0 && !isOver) && <Text style={styles.dropBoxText}>{getDeviceType() === 'desktop' ? 'Click' : 'Tap'} the option that reflects{"\n"}
                your investment values.</Text>}
              {basket.map((pet, index) => <DropBox  {...pet} key={index}/>)}
            </View>
            }
            <View style={styles.valueBarContainer}>
              <BarContainer basketValue={basket.length > 0 ? basket[basket.length - 1].metadata.values : {score: 0}}
                            value={'values'} visible={basket.length > 0 ? false : true} setInfo={ChangeInfoText}
                            previousbasketValue={back ? ref.current == undefined ? {score: 0} : ref.current.values : basket.length > 1 ? basket[basket.length - 2].metadata.values : {score: 0}}/>
              <BarContainer basketValue={basket.length > 0 ? basket[basket.length - 1].metadata.risk : {score: 0}}
                            value={'risk'} visible={basket.length > 0 ? false : true} setInfo={ChangeInfoText}
                            previousbasketValue={back ? ref.current == undefined ? {score: 0} : ref.current.risk : basket.length > 1 ? basket[basket.length - 2].metadata.risk : {score: 0}}/>
              <BarContainer basketValue={basket.length > 0 ? basket[basket.length - 1].metadata.choice : {score: 0}}
                            value={'choice'} visible={basket.length > 0 ? false : true} setInfo={ChangeInfoText}
                            previousbasketValue={back ? ref.current == undefined ? {score: 0} : ref.current.choice : basket.length > 1 ? basket[basket.length - 2].metadata.choice : {score: 0}}/>
              {basket.length == 0 &&
                  <Text style={styles.barText}>View the impact your decisions have on {"\n"} supporting your values,
                    risk and choice. {"\n"}{"\n"}<Text style={{fontFamily: 'JosefinSans-Bold', marginTop: 20}}>Tap to
                      learn more</Text></Text>
              }
            </View>
            {info && <View
                style={[styles.infoContainer, {height: actualWidth > 700 ? completeTask ? 137 : 240 : actualWidth > 400 ? completeTask ? 112 : 198 : completeTask ? 112 : 198,}]}>
              <Text style={[completeTask ? styles.infoTextHeadingComplete : styles.infoTextHeading]}>{infoText}</Text>
              <Text style={[completeTask ? styles.infoTextComplete : styles.infoText]}>
                {basket[basket.length - 1].metadata.scoresinfo}
              </Text>
              <TouchableOpacity style={{position: 'absolute', alignSelf: 'flex-end', height: 30, width: 30, bottom: 0}}
                                onPress={() => setInfo(prev => !prev)}>
                <ClearIcon fontSize='default' htmlColor="#ffffff"/>
              </TouchableOpacity>
            </View>
            }
          </View>
        </View>
      </View>
  )
}
const styles = StyleSheet.create({
  content: {
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropBoxContainer: {
    height: actualWidth > 700 ? 240 : actualWidth > 400 ? 198 : 198,
    backgroundColor: '#ffffff',
    elevation: 4,
    borderRadius: actualWidth > 700 ? 9.7 : 8,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: actualWidth > 700 ? 640 : actualWidth > 400 ? 366 : 304
  },
  dropBox: {
    height: actualWidth > 700 ? 95 : actualWidth > 400 ? 78 : 78,
    backgroundColor: 'rgba(87, 95, 105, 0.1)',
    elevation: 4,
    borderRadius: actualWidth > 700 ? 9.7 : 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: actualWidth > 700 ? 621 : actualWidth > 400 ? 350 : 288
  },
  dropBoxText: {
    fontSize: actualWidth > 700 ? 17 : actualWidth > 400 ? 14 : 14,
    lineHeight: actualWidth > 700 ? 25.5 : actualWidth > 400 ? 21 : 21,
    color: '#575f69',
    fontFamily: 'JosefinSans-Regular',
    textAlign: 'center',
  },
  valueBarContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  barText: {
    fontSize: actualWidth > 700 ? 17 : actualWidth > 400 ? 14 : 14,
    lineHeight: actualWidth > 700 ? 25.5 : actualWidth > 400 ? 21 : 21,
    position: 'absolute',
    color: '#575f69',
    fontFamily: 'JosefinSans-Regular',
    textAlign: 'center',
  },
  dragBallConatiner: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  infoContainer: {
    height: actualWidth > 700 ? 240 : actualWidth > 400 ? 198 : 198,
    borderRadius: actualWidth > 700 ? 9.7 : 8,
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(87, 95, 105, 0.9)',
    width: actualWidth > 700 ? 640 : actualWidth > 400 ? 366 : 304
  },
  infoText: {
    margin: 20,
    textAlign: 'justify',
    fontSize: actualWidth > 700 ? 20 : actualWidth > 400 ? 14 : 14,
    lineHeight: actualWidth > 700 ? 26.5 : actualWidth > 400 ? 21 : 21,
    color: '#fff',
    fontFamily: 'JosefinSans-Regular'
  },
  infoTextHeading: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: actualWidth > 700 ? 19.4 : actualWidth > 400 ? 16 : 16,
    letterSpacing: actualWidth > 700 ? 1.94 : actualWidth > 400 ? 1.6 : 1.6,
    color: '#fff',
    fontFamily: 'JosefinSans-Bold',
    textTransform: "uppercase"
  },
  backButton: {
    zIndex: 999,
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: actualWidth > 500 ? -20 : -15,
    left: 10
  },
  infoTextComplete: {
    margin: actualWidth > 700 ? 20 : actualWidth > 400 ? 10 : 10,
    textAlign: 'justify',
    fontSize: actualWidth > 700 ? 16 : actualWidth > 400 ? 12 : 12,
    lineHeight: actualWidth > 700 ? 20 : actualWidth > 400 ? 16 : 16,
    color: '#fff',
    fontFamily: 'JosefinSans-Regular'
  },
  infoTextHeadingComplete: {
    marginTop: actualWidth > 700 ? 10 : actualWidth > 400 ? 5 : 5,
    textAlign: 'center',
    fontSize: actualWidth > 700 ? 19.4 : actualWidth > 400 ? 16 : 16,
    letterSpacing: actualWidth > 700 ? 1.94 : actualWidth > 400 ? 1.6 : 1.6,
    color: '#fff',
    fontFamily: 'JosefinSans-Bold',
    textTransform: "uppercase"
  },
})
