import React, { useLayoutEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '../../../App.css';
import Box from './Box';
import Slider from './Slider'
import Rotate from '../Loss/Skin1/Rotate1';
const Index = (props) => {
    const [cc, setcc] = useState(0);
    const [dd, setdd] = useState(0);
    const [divide, setdivide] = useState(0);
    const [chooseValue, setChooseValue] = useState(null);
    const [answerIndex, setAnswerIndex] = useState(1);
    useLayoutEffect(() => {
        setChooseValue(props.module.questions[0].answers[props.firstPageA].metadata.value);
        let value = props.module.questions[0].answers[props.firstPageA].metadata.value;
        if (value == 500) {
            setdivide(1)
        } else if (value == 1000) {
            setdivide(2)
        } else if (value == 5000) {
            setdivide(10)
        } else if (value == 10000) {
            setdivide(20)
        } else {
            setdivide(50)
        }
        let gain = value * props.module.questions[1].answers[0].metadata.gain;
        let loss = value * props.module.questions[1].answers[0].metadata.loss;
        setcc((Math.round(gain * 100) / 100).toFixed(2));
        setdd((Math.round(JSON.stringify(loss).split('-')[1] * 100) / 100).toFixed(2));
    }, [])

    const handleChange = (event, newValue) => {
        setAnswerIndex(newValue);
        let gain = chooseValue * props.module.questions[1].answers[newValue - 1].metadata.gain;
        let loss = chooseValue * props.module.questions[1].answers[newValue - 1].metadata.loss;
        setcc((Math.round(gain * 100) / 100).toFixed(2));
        setdd((Math.round(JSON.stringify(loss).split('-')[1] * 100) / 100).toFixed(2));
    };
    const handle = () => {
        props.resultContext.setAnswer(
            props.module.moduleid,
            props.module.questions[1].qid,
            props.module.questions[1].answers[answerIndex - 1].respid);
        props.pageTwo()
    }
    return (
        <View style={[styles.content]}>
            <Text style={styles.customText}>
                Using the slider below, what represents the right balance
       {"\n"}for you between how much your investment might drop in a {"\n"}
month compared to how much it could rise?
        </Text>
            <View style={{ width: 260, height: Dimensions.get('screen').width > 760 ? 200 : 190, justifyContent: 'flex-end' }}>
                <View style={styles.greenText}>
                    <Text style={{ color: '#86d969', fontWeight: 'bold', fontFamily: 'JosefinSans-Regular' }}>I could gain</Text>
                    <Text style={{ color: '#86d969', fontWeight: 'bold', fontFamily: 'JosefinSans-Regular', fontSize: 20 }}> - £{cc} ({(Math.round(((cc * 100) / chooseValue) * 100) / 100).toFixed(2)}%)</Text>
                </View>
                <Box height={cc / divide + `%`} color='#86d969' />
            </View>
            <View style={{ alignSelf: 'stretch', justifyContent: 'center' }}>
                <View style={styles.Line2} />
                <Text style={styles.LineText}> £{chooseValue}   </Text>
                <View style={styles.Line1} />
            </View>
            <View style={{ width: 260, height: Dimensions.get('screen').width > 760 ? 200 : 190 }}>
                <Box height={dd / divide + `%`} color='#ff5f5f' />
                <View style={[styles.redText, { bottom: -4 }]}>
                    <Text style={{ color: '#ff5f5f', fontWeight: 'bold', fontFamily: 'JosefinSans-Regular' }}>I could lose</Text>
                    <Text style={{ color: '#ff5f5f', fontWeight: 'bold', fontFamily: 'JosefinSans-Regular', fontSize: 20 }}> - £{dd} ({(Math.round(((dd * 100) / chooseValue) * 100) / 100).toFixed(2)}%)</Text>
                </View>
            </View>
            <View style={styles.bottomView}>
                <View style={{ width: Dimensions.get('screen').width > 760 ? 464 : 330, flexDirection: 'row' }}>
                    <Text style={{ color: "#575f69", textAlign: 'center', fontSize: 8, top: 10, width: 70, position: 'relative', textTransform: 'uppercase' }}>Less Risk</Text>
                    <View style={{ left: 6, bottom: 1 }}>
                        <ArrowBackIcon fontSize='large' htmlColor="#575f69" />
                    </View>
                    <Slider aria-label="ios slider" min={1} max={10} onChange={handleChange} valueLabelDisplay='off' />
                    <View style={{ right: 6, bottom: 1, zIndex: -99999 }}>
                        <ArrowForwardIcon fontSize='large' htmlColor="#575f69" />
                    </View>
                    <Text style={{ color: "#575f69", textAlign: 'center', fontSize: 8, top: 10, width: 70, position: 'relative', textTransform: 'uppercase' }}>More Risk</Text>
                </View>
                <TouchableOpacity onPress={() => { handle() }} style={styles.lastButton}>
                    <Text style={styles.lastButtonText}> This feels about right </Text>
                </TouchableOpacity>
            </View>
        </View>

    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: hp('5%')
    },
    Line1: {
        position: 'absolute',
        width: Dimensions.get('screen').width > 760 ? '90%' : '75%',
        height: 1,
        backgroundColor: "rgba(87, 95, 105, 0.5)",
        alignSelf: 'flex-end',

    },
    Line2: {
        position: 'absolute',
        width: '5%',
        height: 1,
        backgroundColor: "rgba(87, 95, 105, 0.5)",
        alignSelf: 'flex-start',
    },
    LineText: {
        position: "absolute",
        left: '5%',
        width: 'auto',
        color: "rgba(87, 95, 105, 0.5)",
        textAlign: 'center',
        alignSelf: 'flex-start',
    },
    bottomView: {
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        // top: Dimensions.get('screen').width > 760 ? hp('9%') : Dimensions.get('screen').width > 320 ? hp('10%') : hp('3%')
    },
    lastButton: {
        backgroundColor: '#575f69',
        top: 15,
        // width:'auto',
        // justifyContent: 'center',
        width: 278,
        minHeight: 48,
        textAlignVertical: "center",
        flexDirection: 'row'
    },
    lastButtonText: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: 16,
        paddingTop: 18,
        width: "100%",
        textAlign: "center",
        height: "100%",
        textAlignVertical: "center",
        alignSelf: "center",
        letterSpacing: 2,
        textTransform: 'uppercase'
    },
    greenText: {
        position: 'relative',
        alignSelf: Dimensions.get('screen').width > 760 ? 'flex-end' : 'flex-start',
        left: Dimensions.get('screen').width > 760 ? 116 : -10,
    },
    redText: {
        position: 'relative',
        alignSelf: Dimensions.get('screen').width > 760 ? 'flex-end' : 'flex-start',
        left: Dimensions.get('screen').width > 760 ? 116 : -10,
    },
    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('60%') : Dimensions.get('screen').width > 700 ? wp('80%') : wp('99%'),
        Height: 'auto',
        fontSize: Dimensions.get('screen').width > 400 ? 24 : 11,
        fontStyle: 'normal',
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.5)',
        marginBottom: Dimensions.get('screen').width > 400 ? hp("4%") : null,
        fontFamily: 'JosefinSans-Light',
        lineHeight: Dimensions.get('screen').width > 400 ? 26.5 : null
    },
})