import React, { useLayoutEffect, useRef ,useState} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Easing } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
import Thirdpage from './thirdPage'
const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
    const [infopage,setInfopage]= useState(false);
    const [metaindex,setMetaIndex]= useState(null);
    const introAnimation = useRef(new Animated.Value(actualWidth)).current;
    useLayoutEffect(() => {
        Animated.timing(introAnimation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: false,
            easing: Easing.linear
        }).start()
    }, [])

    const nextFunction=(index)=>{
        setMetaIndex(index);
        if(props.moduleObject.questions[props.moduleIndex].answers[index].metadata){
            setInfopage(true)
        }else{
            props.pageTwo()
        }
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[index].respid,
        );
    }

    return (
        <>
        {infopage ?
        <Thirdpage {...props} setInfopage={setInfopage} metaindex={metaindex}/>
        :
        <Animated.View style={[styles.content, { transform: [{ translateX: introAnimation }] }]}>
            <Text style={styles.customText}>
                    {props.moduleObject.questions[props.moduleIndex].text}
            </Text>
            <View style={styles.Line} />
            {
               props.moduleObject.questions[props.moduleIndex].answers.map((data,index)=>{
                    return(
                        <TouchableOpacity style={styles.button} onPress={()=>nextFunction(index)} key={index}>
                            <Text style={styles.buttontext}>{data.text}</Text>
                        </TouchableOpacity>
                    )
                })
            }
        </Animated.View>
    }
    </>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        flex:1
    },

    customText: {
        position: "relative",
        width: actualWidth > 1000 ? wp('75%') : actualWidth > 400 ? wp('85%') : wp('90%'),
        Height: hp('24.3%'),
        fontSize: actualWidth > 1000 ? 32 : actualWidth > 400 ? 24 : 20,
        fontWeight: 300,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("6%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: actualWidth > 1000 ? 3.2 : actualWidth > 400 ? 2.4 : 2
    },
    button: {
        backgroundColor: '#575f69',
        width: actualWidth > 1000 ? 366 : actualWidth > 400 ? 366 : 288,
        height: 48,
        marginBottom: hp("3%"),
        justifyContent: 'center',
        borderRadius:2,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        padding:5
    },
    Line: {
        width: actualWidth > 1000 ? wp('40%') : actualWidth > 400 ? wp('50%') : wp('60%'),
        height: 1,
        backgroundColor: "#d4d4d4",
        marginBottom: hp("4%"),
    },
})