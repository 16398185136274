export const getSurveyQuestions = (userId) => `users/${userId}/games`;
export const getSurveryQuestionsWithSessionKey = (userRef,sessionref)=>`users/${userRef}/sessions/${sessionref}/games`
export const saveResults = () => 'surveyresults';
export const getReportForSession = (userRef, sessionref) =>  `users/${userRef}/sessions/${sessionref}/report`; 
export const getSession = (userRef) => `users/${userRef}/surveyresults`;
export const getSessionWithSessionRef = (userRef,sessionref)=>`users/${userRef}/sessions/${sessionref}/surveyresults`
export const getSkin = (userRef) => `users/${userRef}/orgref`  
export const getLinkstub = (linkstub) => `linkstubs/${linkstub}`;
export const checkProgression = () => 'progression'; 
export const consumeSuKey = (suKey) => `sukeys/${suKey}`;
export const consumeSessionKey = (sessionKey) => `sessions/${sessionKey}`;  
export const completedUserref = (userref,sessionref) => `completion/users/${userref}/sessions/${sessionref}`;  