import React, { useState } from 'react'
import FuturePrePage from '../../../../Components/FuturePrePage2/index'
import FuturePage from '../../../../Components/FutureSecondSkin/index'

const FutureSkin2 = (props) => {
    const [prePage, setPrePage] = useState(true)

    return (
        <React.Fragment>
            {
                prePage ? <FuturePrePage setPrePage={setPrePage} moduleObject={props.moduleObject} /> : <FuturePage {...props} />
            }
        </React.Fragment>
    )
}

export default FutureSkin2
