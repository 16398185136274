import React, {useEffect, useRef, useState} from 'react';
import {View, Text, Animated, Easing, Dimensions} from 'react-native';
import {PIELABELS, YOU} from '../../Utils/static-strings';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../Utils/responsive';

const LabelRight = ({
                      loss,
                      percent,
                      setDisable,
                      circularPieForSkin3,
                      showAnimation = true,
                      showAmount,
                      showPercentage,
                      setTriangle,
                      isGain,
                      isBlueOverDark,
                      hideBracket,
                      useBiggerFont,
                      isMandg
                    }) => {
  const lossAnimatedValue = useRef(new Animated.Value(0)).current;
  const scaleValue = useState(new Animated.Value(1))[0];
  const translateValue = useState(new Animated.Value(0))[0];
  const inputRef = useRef(null);
  const [lossValue, setLossValue] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [marginRight, setMarginRight] = useState(60);
  const [scale, setScale] = useState(null);

  const runAnimation = (toValue) => {
    setDisable(true);
    Animated.sequence([
      Animated.parallel([
        Animated.timing(scaleValue, {toValue: 1.4, duration: 600}),
        Animated.timing(translateValue, {toValue: -10, duration: 600, easing: Easing.ease})
      ]),
      Animated.timing(lossAnimatedValue, {toValue: loss, duration: 900,}),
      Animated.parallel([
        Animated.timing(scaleValue, {toValue: 1, duration: 600}),
        Animated.timing(translateValue, {toValue: 0, duration: 600})])
    ]).start(() => setDisable(false));
  }

  const setTextPosition = (percent) => {
    switch (true) {
      case (percent < 56):
        setMarginBottom(0);
        setMarginRight(60);
        setScale(1);
        break;
      case (percent < 61):
        setMarginBottom(28);
        setMarginRight(60);
        setScale(0.8);
        break;
      case (percent < 66):
        setMarginBottom(42);
        setMarginRight(60);
        setScale(0.8);
        break;
      case (percent < 71):
        setMarginBottom(45);
        setMarginRight(58);
        setScale(0.75);
        break;
      case (percent < 76):
        setMarginBottom(45);
        setMarginRight(56);
        setScale(0.7);
        break;
      case (percent < 81):
        setMarginBottom(55);
        setMarginRight(52);
        setScale(0.65);
        break;
      case (percent < 86):
        setMarginBottom(67);
        setMarginRight(45);
        setScale(0.7);
        break;
      case (percent < 91):
        setMarginBottom(73);
        setMarginRight(28);
        setScale(0.4);
        break;
      case (percent < 96):
        setMarginBottom(90);
        setMarginRight(15);
        setScale(0.3);
        break;
      default:
        setMarginBottom(0);
        setMarginRight(60);
        setScale(1);
    }
  }

  useEffect(() => {
    setTextPosition(percent);
    runAnimation(loss);
    if (showAnimation) {
      lossAnimatedValue.addListener(v => {
        if (inputRef?.current) setLossValue(Math.round(v.value))
      })
    } else {
      setLossValue(loss)
    }
  }, [])

  useEffect(() => {
    setTextPosition(percent);
    runAnimation(loss);
    if (showAnimation) {
      lossAnimatedValue.addListener(v => {
        if (inputRef?.current) setLossValue(Math.round(v.value))
      })
    } else {
      setLossValue(loss)
    }
  }, [loss])


  const screenHeight = Dimensions.get('window').height;

  const getResponsiveFontSize = (size) => {
    const baseScreenHeight = 680;
    let scaleRatio = screenHeight / baseScreenHeight;
    if (scaleRatio > 1.69) scaleRatio = 1.69
    let fontSize = size * scaleRatio
    if (fontSize > 32) {
      fontSize = 32
    }
    return fontSize;
  };

  return (
      <View style={[circularPieForSkin3 ? {
            position: 'absolute',
            alignSelf: 'center',
            justifyContent: "center",
            alignItems: 'center',
            zIndex: 55,
            elevation: 5,
            transform: [{translateX: setTriangle ? isMandg ? '-70%' :'-45%' : '-90%'}, {scaleX: -1}, {scale: scale}],
            top: isBlueOverDark ? 35 : 'inherit'
          } :
          {
            position: 'absolute',
            alignSelf: 'center',
            justifyContent: "center",
            alignItems: 'center',
            zIndex: 55,
            elevation: 5,
            transform: [{translateX: setTriangle ? isMandg ? '-70%' :'-45%' : '-60%'}, {scaleX: -1}, {scale: scale}],
            top: isBlueOverDark ? 35 : 'inherit'
          }]}>
        <Animated.View style={{transform: [{translateY: !showAnimation ? 0 : translateValue}],}}>
          {circularPieForSkin3 ? <Text style={{
                color: '#2f2f2f',
                fontSize: getResponsiveFontSize(22),
                fontFamily: 'Manrope-ExtraLight',
                fontWeight: 700,
                letterSpacing: 0.5,
                lineHeight: 12
              }}>Lose</Text> :
              <Text style={{
                color: 'white',
                fontSize: getResponsiveFontSize(22),
                fontFamily: 'JosefinSans-Light',
                fontWeight: 500,
                letterSpacing: 0.5
              }}>{YOU} <Text style={{color: 'white', fontWeight: 900}}>{PIELABELS.lose}</Text></Text>}
        </Animated.View>
        <Animated.View style={{transform: [{scale: !showAnimation ? 1 : scaleValue}], top: 5, alignItems: 'center'}}>
          <View style={{alignItems: 'center'}}>
            {circularPieForSkin3 ? (<Text ref={inputRef} style={{
                  color: '#2f2f2f',
                  fontSize: getResponsiveFontSize(21),
                  textAlign: 'center',
                  fontFamily: 'Manrope-Medium',
                  fontWeight: '500',
                  letterSpacing: 0.5
                }}>{showAmount ? '£' + lossValue : ''}</Text>) :
                (<Text ref={inputRef} style={{
                  color: 'white',
                  fontSize: getResponsiveFontSize(21),
                  textAlign: 'center',
                  fontFamily: 'JosefinSans-Bold',
                  fontWeight: '500',
                  letterSpacing: 0.5
                }}>{showAmount ? '£' + lossValue : ''}</Text>)}
            {showPercentage && (<Text style={{
              color: 'white',
              fontSize: getResponsiveFontSize(35),
              textAlign: 'center',
              fontFamily: circularPieForSkin3 ? 'Manrope-Medium' : 'JosefinSans-Bold',
              fontWeight: '500',
              letterSpacing: 0.5
            }}>{hideBracket ? '' : '('}{Math.round(Number(lossValue) * 0.1) + '%'}{hideBracket ? '' : ')'}</Text>)}
          </View>
        </Animated.View>
      </View>
  );
}

export default LabelRight;
