import React, {useLayoutEffect, useState, useRef} from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../App.css';

const Index = (props) => {
  const handleLeftClick = () => {
    setSelectedPath('left')
    Color(0);
    // Additional logic for top segment click
  };

  const handleRightClick = () => {
    setSelectedPath('right')
    Color(1);    // Additional logic for bottom segment click
  };
  const animation = useRef(new Animated.Value(0)).current;
  const [buttonColor, setButtonColor] = useState("#ff8d53")
  const [colorset, setColorset] = useState(0)
  const [hide, setHide] = useState(true);
  const [selectedPath, setSelectedPath] = useState(null);

  const Color = (index) => {
    setHide(true)
    setColorset(index)
    setButtonColor("#A65B36");
    props.selectAnswerHandler(
        props.moduleObject.moduleid,
        props.moduleObject.questions[props.moduleIndex].qid,
        props.moduleObject.questions[props.moduleIndex].answers[index].respid,
    );
  }
  useLayoutEffect(() => {
    if (buttonColor == '#A65B36') {
      nextPage();
    }
  }, [buttonColor])

  useLayoutEffect(() => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 2000
    }).start(() => setHide(false));
  }, [])

  const flipAnim = useRef(new Animated.Value(0)).current; // For flip

  const flipAnimation = Animated.timing(flipAnim, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });
  const nextPage = () => {
    Animated.parallel([flipAnimation]).start(() => props.Refresh());
  }

  const flipInterpolate = flipAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'], // Flip by 360 degrees
  });

  const animatedStyle = {
    transform: [{rotateY: flipInterpolate}],
  };


  return (
      <div style={{height: '100%'}}>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}>
          <div className="experience-circle-1" style={{
            width: '100%', display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column', backgroundColor: '#ffce0b'
          }}>
            <Animated.View style={[{}, animatedStyle, {
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }]}>
              <div style={{height: '600px', width: '600px'}}>
                <div className="tiger-first-page-component-circle"
                     style={{
                       borderColor: 'rgb(0, 0, 0)',
                       borderRadius: '175.75px',
                       borderStyle: 'solid',
                       borderWidth: '12px',
                       height: '250px',
                       left: '50%',
                       opacity: '0.3',
                       top: '50%',
                       transform: 'translateX(-125.75px) translateY(-125.75px)',
                       width: '250px',
                       zIndex: '99'
                     }}>
                </div>
                <div className="VictoryContainer"
                     style={{
                       height: '100%',
                       width: '100%',
                       pointerEvents: 'none',
                       touchAction: 'none',
                       position: 'relative'
                     }}>
                  <svg width="685.5" height="685.5" role="img" viewBox="0 0 685.5 685.5"
                       style={{pointerEvents: 'all', width: '100%', height: '100%'}}>
                    <g>
                      <path
                          d="M1.7925767522519383e-14,-292.75A292.75,292.75,0,1,1,1.7925767522519383e-14,292.75L7.83773951454306e-15,128A128,128,0,1,0,7.83773951454306e-15,-128Z"
                          transform="translate(342.75, 342.75)"
                          role="presentation"
                          shapeRendering="auto"
                          className={`hoverable-path ${selectedPath === 'right' ? 'selected' : ''}`}
                          onClick={handleRightClick}
                      />
                      <path
                          d="M1.7925767522519383e-14,292.75A292.75,292.75,0,1,1,-5.3777302567558145e-14,-292.75L-2.351321854362918e-14,-128A128,128,0,1,0,7.83773951454306e-15,128Z"
                          transform="translate(340.75, 342.75)"
                          role="presentation"
                          shapeRendering="auto"
                          className={`hoverable-path ${selectedPath === 'left' ? 'selected' : ''}`}
                          onClick={handleLeftClick}
                      />
                      <text
                          direction="inherit"
                          dx="0"
                          x="524.75"
                          y="316.74875"
                          id="pie-labels-0"
                          style={{
                            fontFamily: 'OpenSans-Light',
                            fontWeight: 300,
                            fontSize: '33px',
                            fill: 'white',
                            stroke: 'transparent',
                            cursor: 'pointer',
                            pointerEvents: 'none'
                          }}
                          onClick={handleRightClick}>
                        <tspan x="524.75" dx="0" dy="38.557500000000005" textAnchor="start" style={{
                          fontSize: '39.5px',
                          fontFamily: 'OpenSans-Bold',
                          fontWeight: 'bolder',
                          fill: 'white'
                        }}>No
                        </tspan>
                      </text>
                      <text
                          direction="inherit"
                          dx="0"
                          x="161.75"
                          y="316.74875"
                          id="pie-labels-1"
                          style={{
                            fontFamily: 'OpenSans-Light',
                            fontWeight: 300,
                            fontSize: '33px',
                            fill: 'white',
                            stroke: 'transparent',
                            cursor: 'pointer',
                            pointerEvents: 'none'
                          }}
                          onClick={handleLeftClick}>
                        <tspan x="161.75" dx="0" dy="38.557500000000005" textAnchor="end" style={{
                          fontSize: '39.5px',
                          fontFamily: 'OpenSans-Bold',
                          fontWeight: 'bolder',
                          fill: 'white'
                        }}>Yes
                        </tspan>
                      </text>
                    </g>

                  </svg>
                  <div style={{zIndex: 99, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                    <svg width="685.5" height="685.5" viewBox="0 0 685.5 685.5"
                         style={{overflow: 'visible', width: '100%', height: '100%'}}>
                    </svg>
                  </div>
                  <div className="center-text-for-chart"
                       style={{display: 'block'}}>
                    I've invested in Financial Products such as shares  bonds and funds
                  </div>
                </div>
              </div>
            </Animated.View>
          </div>
        </div>
      </div>
  );
}
export default Index
