import React from 'react';
import { StyleSheet, View } from 'react-native';

export const dotColor = '#495049';
export const Elevation = 23;

const Index = ({ leftStyle, thread }) => {
  return (
    <React.Fragment>
      <View style={thread === 'first' ? [styles.dot1] : [styles.dot3]} />
      <View style={thread === 'first' ? [styles.dot2] : [styles.dot4]} />
    </React.Fragment>
  );
};

export default Index;

const styles = StyleSheet.create({
  dot1: {
    position: 'absolute',
    backgroundColor: dotColor,
    height: 3,
    width: 3,
    top: 8,
    left: "7.3%",
    borderRadius: 1.5
  },
  dot2: {
    position: 'absolute',
    backgroundColor: dotColor,
    height: 3,
    width: 3,
    top: 8,
    right: "7.1%",
    borderRadius: 1.5
  },
  dot3: {
    position: 'absolute',
    backgroundColor: dotColor,
    height: 3,
    width: 3,
    top: 8,
    left: "7.3%",
    borderRadius: 1.5
  },
  dot4: {
    position: 'absolute',
    backgroundColor: dotColor,
    height: 3,
    width: 3,
    top: 8,
    right: "7.1%",
    borderRadius: 1.5
  },
});
