import React,{useState,memo, useEffect} from 'react'
import { View ,StyleSheet,Text,Dimensions} from 'react-native'
import { TouchableOpacity } from 'react-native-web';
const actualWidth = Dimensions.get('screen').width;
const BarContainer = (props) => {
  const [bgcolor1,setBgColor1]=useState(false);
  const [barcolor,setBarColor]=useState('rgba(87, 95, 105, 0.1)');
  const [opacity2,setOpacity2]=useState(false);
  const [opacity3,setOpacity3]=useState(false);
  const [opacity4,setOpacity4]=useState(false);
  const [opacity5,setOpacity5]=useState(false);
  useEffect(()=>{
      if(props.basketValue.level == 1){
          setBarColor('#4bd5ad')
      }else if(props.basketValue.level == 2){
        setBarColor('#f7ad01')
    }else if(props.basketValue.level == 3){
        setBarColor('#ff5f5f')
    }else{
        setBarColor('rgba(87, 95, 105, 0.1)')
    }
  },[props.basketValue])

useEffect(()=>{
    const timer2 = setTimeout(() => {
        setOpacity2(true)
      }, 100);
      const timer3 = setTimeout(() => {
        setOpacity3(true)
      }, 200);
      const timer4 = setTimeout(() => {
        setOpacity4(true)
      }, 300);
      const timer5 = setTimeout(() => {
        setOpacity5(true)
      }, 400);
      return () => {
        clearTimeout(timer2)
        clearTimeout(timer3)
        clearTimeout(timer4)
        clearTimeout(timer5)
        setOpacity2(false)
        setOpacity3(false)
        setOpacity4(false)
        setOpacity5(false)
};
},[props.basketValue])

    return (
                <>
                    <TouchableOpacity style={[styles.valueBarConatiner,{opacity:props.visible?0.45:1,backgroundColor:bgcolor1 && 'rgba(87, 95, 105, 0.1)',borderRadius:bgcolor1 && 8}]}
                        onMouseEnter={() => setBgColor1(props.visible?false:true)}
                        onMouseLeave={() => setBgColor1(false)}
                        onPress={()=>props.setInfo(props.value,'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget ligula fringilla, facilisis lacus rutrum, condimentum enim. Proin a dui quis nibh pulvinar commodo at.')}
                        disabled={props.visible && true}
                        >
                       {props.basketValue.score == 0 && <>
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1,}]} />
                       </>
                      }
                      {props.basketValue.score == 0.2 && 
                       <>
                       {props.previousbasketValue.score == 1 &&
                       <>
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                       {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                         {
                        opacity4 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                      {
                        opacity5 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:'rgba(87, 95, 105, 0.1)'}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                          <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       {props.previousbasketValue.score == 0.8 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                         {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                      {
                        opacity4 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:'rgba(87, 95, 105, 0.1)'}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                        {props.previousbasketValue.score == 0.6 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                         {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                      {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:'rgba(87, 95, 105, 0.1)'}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                      {props.previousbasketValue.score == 0.4 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:'rgba(87, 95, 105, 0.1)'}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor }]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       {
                       (props.previousbasketValue.score == 0.2 || props.previousbasketValue.score == 0) &&
                       <>
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       </>
                      }


                      { props.basketValue.score == 0.4 &&
                       <>
                        { props.previousbasketValue.score == 1 &&
                       <>
                      {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                       {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                       {
                        opacity4 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.8 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                       {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                        { props.previousbasketValue.score == 0.6 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.4 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { 
                       (props.previousbasketValue.score == 0.2 || props.previousbasketValue.score == 0 ) &&
                        <>
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       {opacity2 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                       }
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                      }
                       </>
                      }
                      { props.basketValue.score == 0.6 &&
                       <>

                      { props.previousbasketValue.score == 1 &&
                       <>
                      {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                       {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:'rgba(87, 95, 105, 0.1)' }]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                        { props.previousbasketValue.score == 0.8 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                      { props.previousbasketValue.score == 0.6 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1 }]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                      { props.previousbasketValue.score == 0.4 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       {  (props.previousbasketValue.score == 0.2 || props.previousbasketValue.score == 0) &&
                        <>
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, }]} />
                       {opacity3 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity2 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                      }
                       </>
                      }
                     { props.basketValue.score == 0.8 &&
                       <>
                     { props.previousbasketValue.score == 1 &&
                       <>
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.8 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                        { props.previousbasketValue.score == 0.6 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        {opacity2 ? 
                         <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                          :
                          <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.4 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        {opacity3 ? 
                         <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                          :
                          <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        }
                        {opacity2 ? 
                         <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                          :
                          <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                        }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { (props.previousbasketValue.score == 0.2 || props.previousbasketValue.score == 0 ) &&
                        <>
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       {opacity4 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity3 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity2 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       </>
                      }
                      {props.basketValue.score == 1 &&
                      <>
                        { props.previousbasketValue.score == 1 &&
                       <>
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.8 &&
                       <>
                       {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       { props.previousbasketValue.score == 0.6 &&
                       <>
                       {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                        { props.previousbasketValue.score == 0.4 &&
                       <>
                       {
                        opacity4 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        {
                        opacity3 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        {
                        opacity2 ?
                        <View style={[styles.bar,{opacity:props.visible?0.45:1,backgroundColor:barcolor}]} />
                        :
                        <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                        <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       {(props.previousbasketValue.score == 0.2  || props.previousbasketValue.score == 0) &&
                        <>
                        {opacity5 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity4 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity3 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       {opacity2 ? 
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       :
                       <View style={[styles.bar,{opacity:props.visible?0.45:1}]} />
                       }
                       <View style={[styles.bar,{opacity:props.visible?0.45:1, backgroundColor:barcolor}]} />
                       </>
                       }
                       </>
                      }
                    <Text style={[styles.valueBoxText,{opacity:props.visible?0.45:1}]}>{props.value}</Text>
                    </TouchableOpacity>
                </>
    )
}
export default memo(BarContainer);
const styles = StyleSheet.create({
    valueBarConatiner:{
        height:actualWidth>700?116:actualWidth>400?96:96,
        width:actualWidth>700?116:actualWidth>400?96:96,
        opacity:0.45,
        marginHorizontal:5,
        justifyContent:'center',
        alignItems:'center'
    },
    valueBoxText:{
        fontSize:13,
        marginTop:actualWidth>400?16:8,
        letterSpacing:1.3,
        fontWeight:600,
        color: '#575f69',
        opacity:0.45,
        fontFamily:'JosefinSans-SemiBold',
        textAlign:'center',
        textTransform: 'uppercase'
    },
    bar:{
        height:6,
        width:60,
        opacity:0.45,
        backgroundColor:'rgba(87, 95, 105, 0.1)',
        borderRadius:4,
        marginVertical:3
    },
})