import React, { useState, useRef, useLayoutEffect, Fragment } from 'react';
import { View, Animated, Dimensions } from 'react-native';
import {
  Container,
  TopSection,
  Text,
  Question,
  BottomSection,
  Rectangle,
  BottomSectionWrapper
} from './style';

const LastPageA = (props) => {
  const [hide, setHide] = useState(true);
  const animation = useRef(new Animated.Value(0)).current;
  const animation2 = useRef(new Animated.Value(0)).current;
  const animation3 = useRef(new Animated.Value(110)).current;
  useLayoutEffect(() => {
    Animated.timing(animation3, {
      toValue: 10,
      duration: 400
    }).start(() => {
      Animated.timing(animation, {
        toValue: 1,
        duration: 400
      }).start();
      Animated.timing(animation2, {
        toValue: 1,
        duration: 400
      }).start(() => setHide(false));
    }
    );
  }, [])

  const handleClick = (value) => {
    Animated.timing(animation2, {
      toValue: 0,
      duration: 1000
    }).start();
    props.selectAnswerHandler(
      props.moduleObject.moduleid,
      props.Questions[props.indexOfset].qid,
      props.Questions[props.indexOfset].answers[value].respid,
      undefined,
      undefined,
      props.questionSkipArray
    );
    props.setIndexOfset(prev => prev + 1);
    setHide(true);
    props.refresh(props.questionAnswer);
  }
  const staticText = (s1, s2) => (
    <Fragment>
      <div>{s1}</div>
      <div>{s2}</div>
    </Fragment>
  )
  const createBottomSection = (n) => {
    const elements = [];
    let fontSize = 60;
    const lineHeight = 68;
    for (let i = 0; i < n; i++) {
      // fontSize = fontSize + 5;
      elements.push(
        <Rectangle
          key={i}
          questionAnswer={props.questionAnswer}
          disabled={hide}
          onPress={() => handleClick(i)}
        >
          <BottomSectionWrapper justifyContent={'flex-end'} flexDirection={'column'}>
            <Text
              fontSize={ Dimensions.get('window').width > 500 ? fontSize : 40}
              questionAnswer={props.questionAnswer}
              lineHeight={Dimensions.get('window').width > 500 ? lineHeight : 48}
            >
              {i}
            </Text>
          </BottomSectionWrapper>
          <BottomSectionWrapper justifyContent={'center'}>
            {(i === 0 || i === n - 1) &&
              <Text
                fontSize={Dimensions.get('window').width > 600 ? 16 : Dimensions.get('window').width > 400 ? 12 : 11}
                marginTop={10}
                questionAnswer={props.questionAnswer}
                letterSpacing={0}
                lineHeight={Dimensions.get('window').width > 600 ? 24 : Dimensions.get('window').width > 400 ? 16 : 15}
                fontFamily={"Manrope-Regular"}
              >
                {(i === 0) ? staticText('Complete', 'Guess') : staticText('Totally', 'Certain')}
              </Text>
            }
          </BottomSectionWrapper>
        </Rectangle>
      );
    }
    return elements;
  }

  return (
    <Container questionAnswer={props.questionAnswer}>
      <TopSection>
        <Animated.View style={{ transform: [{ translateY: animation3 }] }}> <Text fontSize={60} letterSpacing={0} questionAnswer={props.questionAnswer}>{props.questionAnswer && props.questionAnswer.toUpperCase()}</Text></Animated.View>
        <Animated.View style={{ opacity: animation }}><Question questionAnswer={props.questionAnswer}>{props.question}</Question></Animated.View>
      </TopSection>
      <Animated.View style={{ opacity: animation2, flex: 1 }}>
        <BottomSection>
          {createBottomSection(props.questionScale)}
        </BottomSection>
      </Animated.View>
    </Container>
  )
}

export default LastPageA;

LastPageA.defaultProps = {
  question: 'How sure are you?',
  questionAnswer: '',
  questionScale: 6
}