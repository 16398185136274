import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Dimensions} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../../src/App.css';

const actualWidth = Dimensions.get('screen').width;

const set1 = (props, isDisable) => {
  return (
      <>
        <Text style={[styles.customText]}>
          {props.isVariantC ?
              'Now let’s see how you feel when the chances of gaining and losing are changed.'
              : 'Now let’s see how you feel when the amounts you stand to gain or lose are changed.'}
        </Text>
        {props.isVariantC && <Text style={[styles.customText]}>
          In the following scenarios the chances of a{`\n`}
          gain are higher than that of a loss.
        </Text>}
        <TouchableOpacity disabled={isDisable} onPress={() => {
          props.setDisable(true);
          props.fun()
        }} style={[styles.button]}>
          <Text style={styles.buttontext}>OK</Text>
        </TouchableOpacity>
      </>
  )
}
const set2 = (props, isDisable) => {
  return (
      <>
        <View style={styles.headingContainer}>
          <View style={styles.Line1}/>
          <Text style={styles.header}>GREAT</Text>
          <View style={styles.Line2}/>
        </View>
        <Text style={[styles.customText]}>
          Now what about when the odds{`\n`}
          change?
        </Text>

        <TouchableOpacity disabled={isDisable} onPress={() => {
          props.setDisable(true);
          props.fun()
        }} style={[styles.button]}>
          <Text style={styles.buttontext}>OK</Text>
        </TouchableOpacity>
      </>
  )
}
const set3 = (props, isDisable) => {
  return (
      <>
        <View style={styles.headingContainer}>
          <View style={styles.Line1}/>
          <Text style={styles.header}>FINALLY</Text>
          <View style={styles.Line2}/>
        </View>
        <Text style={[styles.customText]}>
          We're going to change things{`\n`}
          more.
        </Text>
        <TouchableOpacity disabled={isDisable} onPress={() => {
          props.setDisable(true);
          props.fun()
        }} style={[styles.button]}>
          <Text style={styles.buttontext}>OK</Text>
        </TouchableOpacity>
      </>
  )
}



const Index = (props) => {
  const [disable, setDisable] = useState(true);
  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));

  setTimeout(()=> {setDisable(false)}, 1000)
// Handle dimension changes
  useEffect(() => {
    const onChange = ({ window }) => {
      setScreenDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);
  const dynamicStyles = StyleSheet.create({
    customText: {
      letterSpacing: screenDimensions.height <= 479 ? 1.6 : screenDimensions.width <= 767 ? 2 : screenDimensions.width <= 991 ? 2.4 : 3.2,
      fontSize: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 16 : 24) : screenDimensions.width <= 767 ? 20 : screenDimensions.width <= 991 ? 24 : 32,
      lineHeight: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 21 : 29) : screenDimensions.width <= 767 ? 24 : screenDimensions.width <= 991 ? 29 : 38,
      marginTop: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      marginBottom: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      // Other styles that do not depend on dimensions can remain static
      position: "relative",
      height: 'auto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      color: 'rgba(87, 95, 105, 0.75)',
      width: '100%',
      maxWidth: 680,
      fontFamily: 'JosefinSans-Light',
    },
  });
  return (
      <View style={styles.content}>
        {props.currentSet === 0 ? <>
              <View style={styles.headingContainer}>
                <View style={styles.Line1}/>
                <Text style={styles.header}>{props.moduleObject.name} OR GAIN</Text>
                <View style={styles.Line2}/>
              </View>

              <Text style={dynamicStyles.customText}>
                Investing is all about accepting a degree of risk for the potential of a larger return.{`\n`}{`\n`}This
                exercise measures when the tradeoff between gains and losses feels right and when it feels
                wrong.{`\n`}
              </Text>

              <TouchableOpacity disabled={disable} onPress={() => {
                props.showDescriptionPage();
                props.fun();
                setDisable(true)
              }} style={styles.button}>
                <Text style={styles.buttontext}>OK</Text>
              </TouchableOpacity></> :
            (props.currentSet === 1 ? set1(props, disable) : (props.currentSet === 2 ? set2(props, disable) : (props.currentSet === 3 && set3(props, disable))))
        }
      </View>
  )
}

export default Index;
export const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '99vh',
    width: '100vw',
    marginBottom: '15vh'
  },
  heading: {
    position: "relative",
    width: wp("60%"),
    resizeMode: "contain",
    height: hp("10%"),
    marginBottom: hp("4%")
  },
  customText: {
    position: "relative",
    // width: actualWidth > 500 ? wp('100%') : actualWidth < 320 ? wp('90%') : wp('95%'),
    height: 'auto',
    // lineHeight: 22,
    // fontSize: 20,
    fontWeight: 'normal',
    fontStyle: 'normal',
    // letterSpacing: 0.5,
    textAlign: 'center',
    color: 'rgba(87, 95, 105, 0.75)',
    // fontFamily: "JosefinSans-Regular",
    width: '100%', maxWidth: 680,
    // height: 212,
    fontSize: Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400 ? 20 : 16,
    fontFamily: 'JosefinSans-Light',
    letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400 ? 2 : 1.6,
    padding: '17px'
  },
  buttontext: {
    fontFamily: 'JosefinSans-Bold',
    fontSize: 20,
    paddingTop: 15,
    width: "100%",
    textAlign: "center",
    height: "100%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
    color: '#ffffff',
  },
  button: {
    // backgroundColor: '#ee7998',
    backgroundColor: '#575f69',
    position: "relative",
    width: 78,
    minHeight: 48,
    textAlignVertical: "center",
    borderRadius: 2
  },

  headingContainer: {
    width: Dimensions.get('screen').width > 750 ? 420 : 320,
    height: Dimensions.get('screen').width > 550 ? 70 : 74,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: hp("2%")
  },

  Line1: {
    width: 250,
    height: 1,
    backgroundColor: "#d4d4d4",
    alignSelf: 'center',
  },
  Line2: {
    width: 250,
    height: 1,
    backgroundColor: "#d4d4d4",
    alignSelf: 'center',
  },


  header: {
    position: "relative",
    width: "100%",
    height: "50%",
    alignSelf: 'center',
    fontWeight: '300',
    transform: actualWidth <= 320 ? [{scale: .9}] : [{scale: 1}],
    textAlignVertical: "center",
    textAlign: 'center',
    color: '#575f69',
    textTransform: "uppercase",
    fontFamily: 'JosefinSans-Light',
    fontSize: Dimensions.get('screen').width > 400 ? 48 : 32,
    letterSpacing: Dimensions.get('screen').width > 400 ? 4.8 : 3.2,
  },
  customText2: {
    fontFamily: 'JosefinSans-SemiBold',
  }

});
