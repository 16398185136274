import React, {useEffect, useRef, useState} from 'react';
import {View, Text, Animated, Easing, Dimensions} from 'react-native';
import {PIELABELS, YOU} from '../../Utils/static-strings';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../Utils/responsive';
const LabelLeft = ({gain, setDisable,setTriangle, circularPieForSkin3, showAnimation = true, showAmount, showPercentage, isGain, isBlueOverDark, hideBracket, useBiggerFont}) => {
  const winAnimatedValue = useRef(new Animated.Value(0)).current;
  const scaleValue = useState(new Animated.Value(1))[0];
  const translateValue = useState(new Animated.Value(0))[0];
  const inputRef = useRef(null);
  const [gainValue, setGainValue] = useState(0);

  const runAnimation = (toValue) => {
    setDisable(true);
    Animated.sequence([
      Animated.parallel([
        Animated.timing(scaleValue, {toValue: 1.4, duration: 600}),
        Animated.timing(translateValue, {toValue: -10, duration: 600, easing: Easing.ease})
      ]),
      Animated.timing(winAnimatedValue, {toValue, duration: 900,}),
      Animated.parallel([
        Animated.timing(scaleValue, {toValue: 1, duration: 600}),
        Animated.timing(translateValue, {toValue: 0, duration: 600})
      ])
    ]).start(() => setDisable(false))
  }

  useEffect(() => {
    runAnimation(gain);
    if (showAnimation) {
      winAnimatedValue.addListener(v => { if (inputRef?.current) setGainValue(Math.round(v.value)) })
    } else { setGainValue(gain) }
  }, [])

  useEffect(() => {
    runAnimation(gain);
    if (showAnimation) {
      winAnimatedValue.addListener(v => { if (inputRef?.current) setGainValue(Math.round(v.value)) })
    } else { setGainValue(gain) }
  }, [gain])

  const screenHeight = Dimensions.get('window').height;
  const getResponsiveFontSize = (size) => {
    const baseScreenHeight = 680;
    let scaleRatio = screenHeight / baseScreenHeight;
    if(scaleRatio > 1.69) scaleRatio = 1.69
    let fontSize = size * scaleRatio
    if(fontSize > 32){
      fontSize = 32
    }
    return fontSize;
  };

  return (
      <View style={[circularPieForSkin3 ? {position: 'absolute', alignSelf: 'center', justifyContent: "center", alignItems: 'center', zIndex: 55, elevation: 5, transform: [{translateX: setTriangle ? '90%' : '90%'}, {scaleX: -1}], bottom: isBlueOverDark ? 'positionLeftBottom' : 'initial'} :
          {marginRight: 0,position: 'absolute', alignSelf: 'center', justifyContent: "center", alignItems: 'center', zIndex: 55, elevation: 5, transform: [{translateX: setTriangle ? '60%' : '60%'}, {scaleX: -1}], bottom: isBlueOverDark ? 'positionLeftBottom' : 'initial'}]}>

        <Animated.View style={{transform: [{translateY: !showAnimation ? 0 : translateValue}],}}>
          {circularPieForSkin3 ? <Text style={{color: 'white', fontSize: getResponsiveFontSize(22), fontFamily: 'Manrope-ExtraLight', fontWeight: 700, letterSpacing: 0.5, lineHeight: 12}}>Gain</Text> : <Text
              style={{color: 'white', fontSize: getResponsiveFontSize(22), fontFamily: 'JosefinSans-Light', fontWeight: 500, letterSpacing: 0.5,}}>{YOU} <Text
              style={{color: 'white', fontWeight:900}}>{isGain ? 'Gain' : PIELABELS.win}</Text></Text>}
        </Animated.View>

        <Animated.View style={{transform: [{scale: !showAnimation ? 1 : scaleValue}], top: 5, alignItems: 'center',}}>
          <View style={{alignItems: 'center'}}>
            {circularPieForSkin3 ? (
                <Text ref={inputRef} style={{color: 'white', fontSize: getResponsiveFontSize(21), textAlign: 'center', fontFamily: 'Manrope-Medium', fontWeight: '500', letterSpacing: 0.5,}}>{showAmount ? '£' + gainValue : ''}</Text>
            ) : (
                <Text ref={inputRef} style={{color: 'white', fontSize: getResponsiveFontSize(21), textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontWeight: '500', letterSpacing: 0.5,}}>{showAmount ? '£' + gainValue : ''}</Text>
            )}
            {showPercentage && (
                <Text style={{color: 'white', fontSize: getResponsiveFontSize(35), textAlign: 'center', fontFamily: circularPieForSkin3 ? 'Manrope-Medium' : 'JosefinSans-Bold', fontWeight: '500', letterSpacing: 0.5,}}>{hideBracket ? '' : '('}{Math.round(Number(gainValue) * 0.1) + '%'}{hideBracket ? '' : ')'}</Text>
            )}
          </View>
        </Animated.View>
      </View>
  );
}

export default LabelLeft;
