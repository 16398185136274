import React, { useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    Dimensions,
    Animated,
    TouchableOpacity,
    Easing,
} from 'react-native';
import '../../../../../App.css'
import CustomCard from './CustomCard';
import { withRouter } from 'react-router-dom';


export const THREAD_COLOR = 'transparent' // '#e8e8e8';
export const CARD_BACKGROUND_COLOR = '#7b76b6';
export const CARD_BACKGROUND_COLOR2 = '#d00301';
export const dotColor = '#495049';
export const customDimesion = Dimensions.get('window').width;

const Index = (props) => {

    const firstCardAnimation = useRef(new Animated.Value(-Dimensions.get('window').height / 2)).current;

    useEffect(() => {
        if (props.flow === 'dow') {
            firstCardAnimation.setValue(-Dimensions.get('window').height / 2);
            Animated.timing(firstCardAnimation, {
                toValue: 0,
                duration: 400,
                useNativeDriver: false,
                easing: Easing.linear,
            }).start(() => {
                setTimeout(() => props.setRestrict(false), 200)
            });
        }
        if (props.flow === 'up') {
            firstCardAnimation.setValue(0);
            Animated.timing(firstCardAnimation, {
                toValue: -Dimensions.get('window').height / 2,
                duration: 400,
                useNativeDriver: false,
                easing: Easing.linear,
            }).start(() => {
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.Questions[props.indexOfset].qid,
                    props.Questions[props.indexOfset].answers[props.answerId].respid,
                );
                props.setIndexOfset(prev => prev + 1);
                props.navigation(props.answerId);
                setTimeout(() => props.setRestrict(false), 200)

            });
        }
    }, []);



    return (
        <Animated.View style={[styles.firstcard, {transform : [{translateY:firstCardAnimation}] }]}>
            {<CustomCard
                style={styles.customStyle}
                data={props.Questions[props.indexOfset].text}//allValue[currentSet][questionIndex].answers[0].text}
                innerStyle={styles.innerStyleFirst}
                valueStyle={styles.valueStyleFirst}
                thread="first"
            />}
        </Animated.View>
    );
}

export default withRouter(Index);

const styles = StyleSheet.create({
    firstcard: {
        position: 'absolute',
        alignSelf: "center",
        minHeight:50,
        top :customDimesion > 800 ? "36%" : customDimesion > 700 ? "34.5%" : customDimesion > 400 ? "33%" : "28%",
        width:customDimesion > 950 ? "60%": customDimesion > 800 ? "65%" : customDimesion > 700 ? "74%": customDimesion > 550 ? '80%' : '90%', 
    },
    customStyle: {
        position: 'relative',
        backgroundColor: CARD_BACKGROUND_COLOR,
        justifyContent: "center",
        alignItems: "center",
    },
    innerStyleFirst: {
        position: 'relative',
        textAlign: "center",
        color: '#ffffff',
        fontFamily: "Manrope-Light",
        fontSize: customDimesion < 500 ? customDimesion < 390 ? 18 : 18 : 24,
        lineHeight: customDimesion < 500 ? customDimesion < 390 ? 20 : 24 : 32,
        marginVertical: customDimesion < 500 ? customDimesion < 390 ? 12 : 16 : 48,
        marginHorizontal:12
    },
});