import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';

const height = hp('12.5%') //107.5; and 120 in 500
const width = 37.5 //37.5;
// const height = '1vh';
const pos = '-18vh';
const ArrowForSkin3 = () => {
  const [degree, setDegree] = useState('0deg');

  return (
    <>
      <View style={{
        position: 'absolute',
        bottom: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99,
        height: '65px'
      }}>

        {/* <Image
                    style={{
                        width: width,
                        height: height,
                        resizeMode: 'stretch',
                        transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
                    }}
                    source={require('../../assets/images/arrow.png')} /> */}
        <View style={{}} >
          {/* <View style={{
            position: 'absolute',
            width: 2, height: 25,
            backgroundColor: 'black', transform: [{ rotate: '30deg' }],
            left: 10,
            top: -21
          }} />
          <View style={{
            position: 'absolute',
            width: 2, height: 25,
            backgroundColor: 'black', transform: [{ rotate: '-30deg' }],
            left: 25,
            top: -21
          }} /> */}
          <View style={styles.triangleShapeCSS} />
          <View style={{
            width: '14px', height: '80px',
            // left: 13,
            // bottom: 64,
            backgroundColor: '#2f2f2f',
            alignSelf: 'center',
            bottom: -20
          }} />
          <View style={{
            backgroundColor: '#2f2f2f',
            height: '26px', width: '26px', borderRadius: '180px',
            borderColor: '#2f2f2f', borderWidth: 5,
            alignSelf: 'center',
            bottom: -5
            // left: 7.5, bottom: 66.5,
          }} />
        </View>
      </View>
    </>
  );
};

export default ArrowForSkin3;

const styles = StyleSheet.create({
  triangleShapeCSS: {

    width: 0,
    height: 0,
    borderLeftWidth: '20px',
    borderRightWidth: '20px',
    borderBottomWidth: '30px',
    borderStyle: 'solid',
    // top: -55,
    // right: -1.5,
    backgroundColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#2f2f2f',
    alignSelf: 'center',
    position: 'absolute'
  }
})
