import { StyleSheet, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../../src/App.css';

const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;

const getSheet = (show) => {
  return StyleSheet.create({
    body: {
      flex: 1,
      backgroundColor: "#f5d3e4",
      height: '100vh',
      width: '100vw',
      overflow: 'hidden'
    },

    topborder: {
      position: "relative",
      alignSelf: 'center',
      borderColor: "#ee7998",
      borderTopWidth: 3,
      marginBottom: hp('1.2%')
    },
    Image: {
      position: "relative",
      alignSelf: "flex-end",
      width: 48,
      height: 12,
      marginRight: 7,
      color: '#ee7998',
      fontSize: 17,
      fontFamily: 'JosefinSans-Bold'
    },

    headingContainer: {
      width: actualWidth < 600 ? "321px" : "534px",
      height: 'auto',
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "center",
      marginBottom: "2%"
    },

    Line1: {
      position: "relative",
      width: actualWidth < 600 ? 250 : 400,
      height: 1,
      backgroundColor: "#d4d4d4",
      alignSelf: 'center',

    },

    Line2: {
      position: "relative",
      width: actualWidth < 600 ? 250 : 400,
      height: 1,
      backgroundColor: "#d4d4d4",
      alignSelf: 'center',
    },

    header: {
      position: "relative",
      width: "100%",
      alignSelf: 'center',
      fontFamily: 'JosefinSans-Light',
      fontSize: 48,
      letterSpacing: 2.8,
      textAlignVertical: "center",
      textAlign: 'center',
      color: '#575f69',
      textTransform: "capitalize",
      marginTop: 15,
      marginBottom: 8
    },

    headerSkin3: {
      position: "relative",
      width: "100%",
      alignSelf: 'baseline',
      justifyContent: 'center',
      fontFamily: 'Manrope-SemiBold',
      fontSize: '2.7vh',
      fontWeight: 900,
      letterSpacing: 'normal',
      textAlignVertical: "center",
      textAlign: 'center',
      color: '#2f2f2f',

    },

    headingTxtPos: {
      position: 'relative',
      top: (actualHeight < 768) ? 20 : (actualHeight <= 1024 ? 20 : 30)
    },
    headingTxt: {
      position: 'relative',
      fontFamily: 'JosefinSans-Light',
      fontWeight: '100',
      fontSize: '250%',
      alignSelf: 'center',
      top: 30,
      height: 'auto',
      color: '#575f69'
    },

    topView: {
      bottom: (actualHeight < 768) ? '7%' : (actualHeight <= 1024 ? '7%' : '0%'),
    },

    bottomView: {
      justifyContent: 'space-between',
      // height: hp('15%'),
      // bottom: actualWidth > 500 ? (actualHeight < 768 ? '17%' : (actualHeight <= 1024 ? '0%' : '17%')) : '-22%',
    },

    nextTextStyle: {

      color: '#575f69',
      fontSize: 32,
      letterSpacing: 0.5,
      fontFamily: 'JosefinSans-Light',
      fontWeight: '300',
      fontStyle: 'normal',
      position: 'relative',
      alignSelf: 'center',
      letterSpacing: 3.2,
      top: actualHeight < 768 ? '-120%' : (actualHeight <= 1024 ? '-20%' : '90%'),
    },

    button: {
      height: 48,
      width: 68,
      backgroundColor: '#51c4a5',
      borderRadius: (48 + 68) / 2, //wp('0.1%'),
      paddingVertical: actualWidth === 1600 ? 17 :
        (actualWidth === 1920 ? 18 :
          (actualWidth === 2560 ? 16 :
            (actualWidth === 3840 ? 17 :
              (actualWidth === 4096 ? 17 :
                (actualWidth === 4500 ? 17 :
                  (actualWidth === 5120 ? 16 :
                    (actualWidth === 6400 ? 16 :
                      (actualWidth === 7680 ? 16 :
                        (actualWidth === 1024 ? 16 : 16))))))))),//2.1 / 16
      position: "relative",
      alignSelf: 'center',
      marginHorizontal: wp('1%')
    },
    buttonText: {

      color: '#ffffff',
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'JosefinSans-Bold',
      letterSpacing: 2

    },
    backButtonText: {

      color: '#575f69',
      fontSize: hp('2.9%'),
      textAlign: 'center',
      fontFamily: 'JosefinSans-Bold'
    },
    backButton: {
      height: hp('6%'),
      width: wp('23%'),
      borderWidth: 1,
      borderRadius: wp('0.7%'),
      borderColor: '#575f69',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
      paddingVertical: actualHeight > 640 ? (actualHeight > 980 ? hp('1%') : hp('1.3%')) : hp('0.8%'),
      position: "relative",
      alignSelf: 'center'
    },
    rowBox: {
      flexDirection: "row",
      justifyContent: 'center',
      alignSelf: 'center',
      position: 'relative',
      height: hp('7%'),
      width: wp('100%'),
      top: actualHeight < 768 ? '-100%' : (actualHeight <= 1024 ? '0%' : '100%')//13
    },

    button5: {
      marginTop: 5,
      backgroundColor: '#2f2f2f',
      width: '8vh',
      borderRadius: 2,
      height: '5vh'

    },
    button5text: {
      alignSelf: 'center',
      paddingTop: '1vh',
      paddingBottom: '0.5vh',
      color: '#ffffff',
      fontFamily: 'Manrope-SemiBold',
      fontSize: '2vh',
      textAlign: 'center',
      fontWeight: '600',
      // textTransform: 'capitalize'
    },

    button5Container: {
      flexDirection: "row",
      justifyContent: 'space-between',
      position: 'relative',
      alignItems: 'flex-start',
      // width: wp('11%'),
      margin: 'auto',
      marginBottom: '5%',
      // width: wp('12%'),
      // top: actualHeight < 768 ? '-100%' : (actualHeight <= 1024 ? '0%' : '100%')//13
    },
    // spinnerText:{
    //   width: ""
    // }


  });
}

export default getSheet;
