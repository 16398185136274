import React from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  StyleSheet,
  ImageBackground,
} from 'react-native';

const Index = (props) => {
  return (
    <TouchableOpacity
      style={props.disabled ? styles.disabledButton : styles.button}
      disabled={props.disabled}
      onPress={props.onPress}>
      {/* <View> */}
      <ImageBackground
        style={styles.backgroundStyle}
        source={
          props.disabled
            ? require('../../assets/images/signRed.png')
            : require('../../assets/images/signWhite.png')
        }>
        <Text style={props.disabled ? styles.disabledTitle : styles.title}>
          {props.title}
        </Text>
        <View style={props.disabled ? styles.disableopacity : styles.opacity} />
      </ImageBackground>
      {/* </View> */}
    </TouchableOpacity>
  );
};

export default Index;

const styles = StyleSheet.create({
  button: {
    margin: 40, //20,
    height: 40, //40,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  opacity: {
    width: 260,
    height: 1, //40,
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: '2px',
    },
    shadowOpacity: 0.4,
    shadowRadius: 7,
  },
  disableopacity: {
    width: 260,
    height: 1, //40,
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    shadowColor: '#b00102',
    shadowOffset: {
      width: 0,
      height: '2px',
    },
    shadowOpacity: 0.4,
    shadowRadius: 7,
  },
  disabledButton: {
    margin: 40, //20,
    height: 40, //40,
    color: 'white',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundStyle: {
    width: 295, //200,
    minHeight: 100, //60,
    resizeMode: 'cover',
  },
  disabledTitle: {
    marginTop: 16,
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    color: '#b00102',
    fontSize: 30, //30,
    lineHeight: 60, //60,
    fontWeight: 'bold',
  },
  title: {
    marginTop: 16,
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000000',
    fontSize: 30, //30,
    lineHeight: 60, //60,
    fontWeight: 'bold',
  },
});
