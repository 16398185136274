import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native';
import { heightPercentageToDP as hp } from './responsive';
import { withRouter } from 'react-router-dom';
import CustomText from './customText';
import '../App.css';
import './style.css';
import { ActivityContext } from '.././Containers/Activities/index';
import FrontPageTabBar from '../Components/FrontPageTabBar';
import MobileViewModules from '../Components/MobileViewModules';
import { moduleid, discriptorText, GAME_COMPLETED_MESSAGE, GAME_COMPLETED_DESCRIPTION } from '../Utils/static-strings';
import Resultpage from './newResultPage';
import MobileResultPage from './mobileResultPage';
import { ReportContext } from '../App';

const deviceWidth = Dimensions.get('screen').width;
const buttonGroupHeight = deviceWidth > 1300 ? 396 : 326;
const buttonGroupWidth = deviceWidth > 1300 ? 140 : 110;
const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;



const Index = (props) => {
    const [details, setDetails] = useState(null);
    const [allCompleted, setAllCompleted] = useState(false);
    const [displayContainer, setDisplayContainer] = useState(true);
    const [currentModule, setCurrentModule] = useState(null);
    const [rightArrow, rightArrowClicked] = useState(false);
    const [leftArrow, leftArrowClicked] = useState(false);
    const [leftArrowVisibility, setLeftArrowVisibility] = useState(false);
    const [rightArrowVisibility, setRightArrowVisibility] = useState(true);
    const [currentSelectedTab, setCurrentSelectedTab] = useState(1);
    const [defaultScreen, setDefaultscreen] = useState(1);
    const userSession = useContext(ActivityContext);
    const countdisable = useState(props.modules.modules.length)[0];
    const [filterArray, setFilterArray] = useState(null)
    const [report, setReport] = useState(null);
    const [reportbutton, setReportButton] = useState(false)
    const [discriptor, setDiscriptor] = useState(null);
    const [riskUI, setRiskUi] = useState(null);
    const [understandingUI, setUnderstandingUi] = useState(null);
    const [valueUI, setValueUi] = useState(null);
    const [modal, setmodal] = useState(false);
    const [isGameCompleted, setIsGameCompleted] = useState(false);
    const resultButton = useContext(ReportContext);

    useEffect(() => {
        if ((userSession.riskCalculation !== undefined && userSession.riskCalculation !== '')) {
            if (userSession.riskCalculation.modules.length === props.modules.modules.length) {
                setIsGameCompleted(true);
                if (window.parent) {
                    if (window.parent.postMessage) {
                        window.parent.postMessage({ message: "surveycomplete", userref: props.userref }, window['runConfig'].REACT_APP_RESEARCH_APP_URL);
                    }
                }
            }
        }


        if (userSession.riskCalculation !== undefined && userSession.riskCalculation !== '') {
            let brs = userSession.riskCalculation.calcresults.filter((item) => {
                if (item.moduleid === moduleid.brs()) {
                    setDiscriptor(item.score);
                }
                // item.moduleid === moduleid.brs() ? setDiscriptor(item.score) : console.log("")
            })
        }
        if (userSession !== null) {
            setDetails(userSession.userSession);
            if (userSession.userSession !== '') {
                const completed = props.modules.modules.length === userSession.userSession.modules.length;
                setAllCompleted(completed);
                setIsGameCompleted(completed);
            }
        }
    }, [])

    useEffect(() => {

        let mm = false;
        if (userSession.riskCalculation !== undefined && userSession.riskCalculation !== '') {
            if( userSession.riskCalculation.modules.length === props.modules.modules.length ){
                setIsGameCompleted(true)
            }


            mm = (userSession.riskCalculation !== undefined && userSession.riskCalculation !== '') && userSession.riskCalculation.modules.map((item) => {
                if (item.moduleid == moduleid.risk() || item.moduleid == moduleid.loss() || item.moduleid == moduleid.future()) {
                    return (item)
                }
            })
        }

        let ll = mm !== false && mm.filter((item) => item !== undefined)
        if (ll.length === 3) {
            setReportButton(true);
        }
    }, [])

    useEffect(() => {
        const newarray = props.modules.modules.filter((module) => {
            return module.tags?.includes('ui.risk')
        });
        if (newarray !== null) setRiskUi(newarray);

        const newarray2 = props.modules.modules.filter((module) => {
            return module.tags?.includes('ui.understanding')
        });
        if (newarray2 !== null) setUnderstandingUi(newarray2);
        const newarray3 = props.modules.modules.filter((module) => {
            return module.tags?.includes('ui.values')
        });
        if (newarray3 !== null) setValueUi(newarray3);
        let emptyArray = [];
        newarray.forEach((item) => { emptyArray.push(item) })
        newarray2.forEach((item) => { emptyArray.push(item) })
        newarray3.forEach((item) => { emptyArray.push(item) })
        setFilterArray(emptyArray);
    }, [])

    const showResultPage = () => {
        // props.history.push('/userresult/result');
    }
    let array1 = [];
    const disabledfuctio = (id) => {
        if (array1.indexOf(id) === -1) {
            array1.push(id)
        }
        if (array1.length === countdisable) {
            showResultPage()
        }


    }
    useEffect(() => {
        let yy = -1;
        if (userSession.riskCalculation !== undefined && userSession.riskCalculation !== '') {
            yy = userSession.riskCalculation.modules.length;
            if (userSession.riskCalculation.modules[yy > 0 ? yy - 1 : 0].moduleid == moduleid.knowledge() || userSession.riskCalculation.modules[yy > 0 ? yy - 1 : 0].moduleid == moduleid.experience()) {
                setDefaultscreen(2);
            }else if(userSession.riskCalculation.modules[yy > 0 ? yy - 1 : 0].moduleid == moduleid.esg()){
                setDefaultscreen(3);
            }else {
                setDefaultscreen(1);
            }
        }
    }, [])

    return (
        <>
            {details !== null &&
                <div className={modal ? "blurm" : "check"}>
                    <View style={styles.container}>
                        {/* <Text style={styles.logoImage}>Be-IQ</Text> */}
                        <img src={require("../assets/svg/logo.svg")} style={{
                            position: "relative",
                            alignSelf: "flex-end",
                            width: deviceWidth > 400 ? 54 : 40,
                            objectFit: 'contain',
                            height: deviceWidth > 400 ? 16 : 12,
                            marginRight: 7,
                            top: '5px',
                        }} />
                        <View style={styles.mainContainer}>
                            <View style={[styles.headingContainer, { opacity: displayContainer ? 1 : 0, top: actualWidth < 322 && 5 }]}>
                                <View style={styles.Line1} />
                                <Text style={styles.header}>YOU AND...</Text>
                                <View style={styles.Line2} />
                            </View>
                            <View style={{ flexDirection: "column", justifyContent: deviceWidth > 500 ? 'space-between' : 'space-evenly', height: deviceWidth > 500 ? '48%' : deviceWidth < 330 ? '90%' : actualHeight < 700 ? '69%' : '65%' }} >
                                {deviceWidth > 500 ? <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <FrontPageTabBar {...props}
                                        rightArrow={rightArrow}
                                        rightArrowClicked={rightArrowClicked}
                                        leftArrow={leftArrow}
                                        leftArrowClicked={leftArrowClicked}
                                        setLeftArrowVisibility={setLeftArrowVisibility}
                                        setRightArrowVisibility={setRightArrowVisibility}
                                        currentSelectedTab={currentSelectedTab}
                                        setCurrentSelectedTab={setCurrentSelectedTab}
                                        defaultScreen={defaultScreen}
                                        valueUI={valueUI}
                                        understandingUI={understandingUI}
                                    />
                                </View> :
                                    <FrontPageTabBar {...props}
                                        rightArrow={rightArrow}
                                        rightArrowClicked={rightArrowClicked}
                                        leftArrow={leftArrow}
                                        leftArrowClicked={leftArrowClicked}
                                        setLeftArrowVisibility={setLeftArrowVisibility}
                                        setRightArrowVisibility={setRightArrowVisibility}
                                        currentSelectedTab={currentSelectedTab}
                                        setCurrentSelectedTab={setCurrentSelectedTab}
                                        defaultScreen={defaultScreen}
                                        valueUI={valueUI}
                                        understandingUI={understandingUI}
                                    />}

                                <View style={{ height: deviceWidth > 500 ? '10%' : '5%' }} />
                                <View style={[{ flexDirection: "row", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, deviceWidth < 500 && { height: '50%' }]}>
                                    <View style={[{ width: 50, padding: 10, zIndex: 100, }, deviceWidth > 550 ? { left: -5, position: "absolute" } : { marginLeft: 40 }]}>
                                        {leftArrowVisibility && <TouchableOpacity onPress={() => {
                                            leftArrowClicked(true)
                                            setDefaultscreen(1)
                                        }}>
                                            <img style={{ transform: 'rotate(180deg)' }} src={require("../assets/images/right.png")} />
                                        </TouchableOpacity>}
                                    </View>
                                    {/* <View style={{ position: 'relative', width: `${currentSelectedTab === 1 ? 402 : 268}`, height: 'fit-content', borderRadius: 88, padding: 10, justifyContent: 'space-between', flexDirection: deviceWidth < 500 ? "column" : "row", backgroundColor: displayContainer ? 'rgba(87, 95, 105, 0.1)' : "transparent", top: actualHeight > 812 ? 0 : hp('5%') }}> */}

                                    {deviceWidth > 500 ? <View style={[styles.buttonGroup,
                                    {
                                        marginLeft: deviceWidth > 500 && (deviceWidth > 1024 ?
                                            (defaultScreen === 2 ? 75 : (currentSelectedTab === 2 ? (63) : currentSelectedTab === 1 ? (0) : (120)))
                                            : (defaultScreen === 2 ? 100 : (currentSelectedTab === 2 ? (85) : currentSelectedTab === 1 ? (30) : (135)))
                                        ),
                                        width: deviceWidth > 500 ? (currentSelectedTab === 1 ? (deviceWidth > 1300 ? 395 : 332) :currentSelectedTab === 2 ? (deviceWidth > 1300 ? 261 : 220):(deviceWidth > 1300 ? 140 : 120)) : 122,
                                        height: deviceWidth < 500 ? (currentSelectedTab === 1 ? 326 : 224) : (deviceWidth > 1300 ? 144 : 122),
                                        backgroundColor: displayContainer ? 'rgba(87, 95, 105, 0.1)' : "transparent",
                                        // backgroundColor:"red",
                                        // justifyContent:"space-between",
                                        // alignItems:"center"
                                    }]}>

                                        {riskUI !== null &&
                                            riskUI.sort(function (a, b) {
                                                var nameA = a.moduleid.toUpperCase(); // ignore upper and lowercase
                                                var nameB = b.moduleid.toUpperCase(); // ignore upper and lowercase
                                                if (nameA < nameB) {
                                                    return -1;
                                                }
                                                if (nameA > nameB) {
                                                    return 1;
                                                }
                                                // names must be equal
                                                return 0;
                                            }).map((module, index) => {
                                                return (
                                                    <CustomText
                                                        key={module.moduleid}
                                                        module={module}
                                                        index={index}
                                                        details={details}
                                                        displayContainer={displayContainer}
                                                        setDisplayContainer={setDisplayContainer}
                                                        setCurrentModule={setCurrentModule}
                                                        currentModule={currentModule}
                                                        disabledfuctio={disabledfuctio}
                                                        currentSelectedTab={currentSelectedTab}
                                                        {...props}
                                                        report={report}

                                                        valueUI={valueUI}
                                                        understandingUI={understandingUI}
                                                    />
                                                )
                                            }
                                            )}
                                        {understandingUI !== null &&
                                            understandingUI.sort(function (a, b) {
                                                var nameA = a.moduleid.toUpperCase(); // ignore upper and lowercase
                                                var nameB = b.moduleid.toUpperCase(); // ignore upper and lowercase
                                                if (nameA < nameB) {
                                                    return -1;
                                                }
                                                if (nameA > nameB) {
                                                    return 1;
                                                }
                                                // names must be equal
                                                return 0;
                                            }).map((module, index) => {
                                                return (
                                                    <CustomText
                                                        key={module.moduleid}
                                                        module={module}
                                                        index={index}
                                                        details={details}
                                                        displayContainer={displayContainer}
                                                        setDisplayContainer={setDisplayContainer}
                                                        setCurrentModule={setCurrentModule}
                                                        currentModule={currentModule}
                                                        disabledfuctio={disabledfuctio}
                                                        currentSelectedTab={currentSelectedTab}
                                                        {...props}
                                                        report={report}

                                                        valueUI={valueUI}
                                                        understandingUI={understandingUI}
                                                    />
                                                );

                                            })}
                                        {valueUI !== null &&
                                            valueUI.sort(function (a, b) {
                                                var nameA = a.moduleid.toUpperCase(); // ignore upper and lowercase
                                                var nameB = b.moduleid.toUpperCase(); // ignore upper and lowercase
                                                if (nameA < nameB) {
                                                    return -1;
                                                }
                                                if (nameA > nameB) {
                                                    return 1;
                                                }
                                                // names must be equal
                                                return 0;
                                            }).map((module, index) => {
                                                return (
                                                    <CustomText
                                                        key={module.moduleid}
                                                        module={module}
                                                        index={index}
                                                        details={details}
                                                        displayContainer={displayContainer}
                                                        setDisplayContainer={setDisplayContainer}
                                                        setCurrentModule={setCurrentModule}
                                                        currentModule={currentModule}
                                                        disabledfuctio={disabledfuctio}
                                                        currentSelectedTab={currentSelectedTab}
                                                        {...props}
                                                        report={report}

                                                        valueUI={valueUI}
                                                        understandingUI={understandingUI}
                                                    />
                                                );

                                            })}

                                    </View> :
                                        <View style={[styles.buttonGroup, { width: 122, height: currentSelectedTab === 1 ? 326 : 224 }]}>
                                            <MobileViewModules
                                                details={details}
                                                displayContainer={displayContainer}
                                                setDisplayContainer={setDisplayContainer}
                                                setCurrentModule={setCurrentModule}
                                                currentModule={currentModule}
                                                disabledfuctio={disabledfuctio}
                                                currentSelectedTab={currentSelectedTab}
                                                defaultScreen={defaultScreen}
                                                {...props}
                                            />
                                        </View>}


                                    <View style={[{ width: 50, padding: 10, zIndex: 100, }, deviceWidth > 550 ? { right: (defaultScreen === 2 ? -10 : (currentSelectedTab === 2 ? (10) : -50)), position: "absolute" } : { marginRight: 40 }]}>
                                        {rightArrowVisibility && <TouchableOpacity onPress={() => {
                                            rightArrowClicked(true)
                                            setDefaultscreen(1)
                                        }}>
                                            <img src={require("../assets/images/right.png")} />
                                        </TouchableOpacity>}
                                    </View>
                                </View>
                                <View style={{ height: deviceWidth > 500 ? '10%' : actualWidth < 322 ? '9%' : '10%' }} />

                                    <View>
                                    {
                                        (
                                            !resultButton.showResultButon ?
                                            <>
                                            {


                                                !isGameCompleted ?
                                                    <Text style={[styles.bottomText, { opacity: displayContainer ? 1 : 0 }]} >
                                                            Tap an activity to get started.{`\n`}
                                                            Each activity takes about two minutes
                                                    </Text> :
                                                <TouchableOpacity style={ modal ? styles.reportbuttonpressed : styles.reportbutton}
                                                onPress={() => {
                                                    setmodal(true);
                                                    // props.history.push('/userresult/result')
                                                }}>

                                                <Text style={styles.reportbuttonText1}
                                                >{discriptor !== null ?
                                                    (discriptor >= discriptorText.level1.min && discriptor < discriptorText.level1.max ? discriptorText.level1.name :
                                                        (discriptor >= discriptorText.level2.min && discriptor < discriptorText.level2.max ? discriptorText.level2.name :
                                                            (discriptor >= discriptorText.level3.min && discriptor < discriptorText.level3.max ? discriptorText.level3.name :
                                                                (discriptor >= discriptorText.level4.min && discriptor < discriptorText.level4.max ? discriptorText.level4.name :
                                                                    (discriptor >= discriptorText.level5.min && discriptor <= discriptorText.level5.max ? discriptorText.level5.name : 'Out Of Limit'))))) :
                                                    'Null Text'}</Text>
                                                <Text style={styles.reportbuttonText2}
                                                >View Report</Text>
                                            </TouchableOpacity>
                                            }

                                            </>

                                                :
                                                <>
                                                {
                                                    !isGameCompleted &&
                                                    <Text style={[styles.bottomText, { opacity: displayContainer ? 1 : 0 }]} >
                                                            Tap an activity to get started.{`\n`}
                                                            Each activity takes about two minutes
                                                    </Text>
                                                }
                                                </>
                                        )}
                                </View>
                                {actualWidth < 322 && <View style={{ height: '10%' }} />}
                            </View>

                            {
                                resultButton.showResultButon && isGameCompleted &&
                                <View style={styles.gameCompletedContainer} >
                                    <Text style={styles.gameCompleteMessage} >
                                        {GAME_COMPLETED_MESSAGE}
                                    </Text>
                                    <Text style={styles.gameCompleteDescription} >
                                        {GAME_COMPLETED_DESCRIPTION}
                                    </Text>
                                </View>
                            }

                        </View>
                    </View>
                </div>
            }
            {modal && (
                actualWidth > 768 ?
                    <Resultpage setmodal={setmodal} riskCalculation={userSession.riskCalculation} {...props} />
                    :
                    <MobileResultPage setmodal={setmodal} riskCalculation={userSession.riskCalculation} {...props} />
            )}
        </>
    )
}

export default withRouter(Index)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'whitesmoke',
        overflow: 'hidden',
        height: '100vh',
        width: '100wh',
        // opacity:0
    },
    logoImage: {
        position: "relative",
        alignSelf: "flex-end",
        width: 48,
        //resizeMode:"contain",
        height: 10,
        marginRight: 7,
        top: '5px',
        color: '#575f69',
        fontSize: 15,
        fontFamily: 'JosefinSans-Bold'
    },
    mainContainer: {
        //flex:1,
        height: deviceWidth > 500 ? '70vh' : '100vh',
        justifyContent: "space-evenly",
        alignItems: "center",
        top: Dimensions.get('screen').width > 1300 ? 70 : Dimensions.get('screen').width > 500 ? 50 : 0,
    },
    firstItem: {
        position: "relative",
        width: 153,
        height: 48,
        opacity: 0,
        borderRadius: 24,
        // backgroundColor: 'green',
    },
    thirdItem: {
        position: "relative",
        width: 153,
        height: 48,
        borderRadius: 24,
        opacity: 1,
    },
    heading: {
        width: 247,
        height: 94,
        resizeMode: 'contain',
    },
    heading3: {
        width: 500,
        height: 94,
        resizeMode: 'contain',
    },
    rectangle: {
        width: 'auto',
        height: 'auto',
        borderRadius: 24,
        alignSelf: "center",
        backgroundColor: 'rgba(87, 95, 105, 0.1)',
        position: 'relative',
        top: actualHeight > 812 ? 0 : hp('2%')
    },
    rectangleText: {
        padding: 12,
        //paddingVertical:hp('2.5%'),
        height: "100%",
        fontFamily: 'JosefinSans-Regular',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 1.6,
        textAlign: 'center',
        textAlignVertical: 'center',
        color: '#575f69',
    },
    rowStyling: {
        // flex:1,
        width: "100%",
        justifyContent: 'space-evenly',
        alignItems: "center",
        // backgroundColor:"blue",
        flexDirection: "row",
        position: 'relative',
        top: actualHeight > 812 ? 0 : hp('2%')

    },
    // firstItem: {
    //     position: "relative",
    //     width: 153,
    //     height: 48,
    //     opacity: 0,
    //     borderRadius: 24,
    //     // backgroundColor: 'green',
    // },
    // thirdItem: {
    //     position: "relative",
    //     width: 153,
    //     height: 48,
    //     borderRadius: 24,
    //     opacity: 0.35,
    // },
    forthItem: {
        position: "relative",
        width: 153,
        height: 48,
        opacity: 0.35,
        // opacity: 0,
        borderRadius: 24,
        marginRight: 200,
    },
    buttonGroup: {
        borderRadius: 88,
        top: 0,
        paddingTop: 10,
        paddingBottom: 10,
        // paddingLeft: deviceWidth < 500 ? 5 : 0,
        // paddingRight: deviceWidth < 500 ? 5 : 0,
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        flexDirection: deviceWidth < 500 ? "column" : "row",
        // backgroundColor:"blue"

        // alignSelf:"center"

    },
    oval1: {
        width: deviceWidth > 1300 ? 124 : 120,
        height: deviceWidth > 1300 ? 124 : 120,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#6fd5da",
        backgroundColor: "#6fd5da",
        alignItems: "center",
        justifyContent: "center"
    },
    oval2: {
        width: deviceWidth > 1300 ? 124 : 120,
        height: deviceWidth > 1300 ? 124 : 120,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#79a2d9",
        backgroundColor: "#79a2d9",
        alignItems: "center",
        justifyContent: "center"
    },
    oval3: {
        width: deviceWidth > 1300 ? 124 : 120,
        height: deviceWidth > 1300 ? 124 : 120,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#fda435",
        backgroundColor: "#fda435",
        alignItems: "center",
        justifyContent: "center"
    },
    value: {
        width: 105,
        height: 27,
        fontFamily: 'JosefinSans-Regular',
        fontSize: 20,
        textAlign: 'center',
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#575f69',
    },
    label: {
        width: 105,
        height: 13,
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: 10,
        fontWeight: '600',
        textAlign: 'center',
        color: '#575f69',
    },
    bottomImage: {
        width: 246,
        height: 48,
        resizeMode: "contain"
    },
    cardContainer: {
        justifyContent: 'space-evenly',
        alignItems: 'center',
        // alignSelf:'center',
        width: 371,
        height: 567,
        borderRadius: 2,
        // box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15),
        backgroundColor: '#ffffff',
    },
    cardText: {
        width: 323,
        height: 405,
        fontFamily: 'JosefinSans-Regular',
        fontSize: 20,
        // line-height: 1.33,
        color: 'rgba(87, 95, 105, 0.75)',
    },
    heading2: {
        alignSelf: 'center',
        width: 413,
        height: 29,
        fontFamily: 'JosefinSans-Light',
        fontSize: 24,
        fontWeight: '300',
        letterSpacing: 2.4,
        textAlign: 'center',
        color: '#575f69',
    },
    label2: {
        alignSelf: 'center',
        width: 413,
        height: 44,
        fontFamily: 'JosefinSans-Light',
        fontSize: 32,
        fontWeight: '300',
        letterSpacing: 3.2,
        textAlign: 'center',
        color: '#575f69',
        textTransform: 'uppercase'
    },
    riskContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 246,
        height: 48,
        justifyContent: 'space-evenly',
        borderRadius: 31.5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(87, 95, 105, 0.05)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        // elevation:15
    },
    riskValue: {
        width: 182,
        height: 24,
        fontFamily: 'JosefinSans-Light',
        fontSize: 20,
        fontWeight: '300',
        letterSpacing: 2,
        textAlign: 'center',
        color: '#575f69',
    },
    arrow: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
        opacity: 0.75,
    },
    bottomText: {
        width: '100%',
        height: 53,
        fontFamily: 'JosefinSans-Regular',
        fontSize: actualWidth < 325 ? 16 : 20,
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.75)',
        position: 'relative',
        alignSelf: 'center',
        lineHeight: 26.5,
        //paddingHorizontal:wp('2%'),
        // top: actualHeight > 812 ? 0 : hp('7%')
    },

    headingContainer: {
        width: actualWidth < 600 ? "321px" : "534px",
        // height: 76,
        height: 'auto',
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor:'orange',
        // alignSelf:"center",
        // marginBottom: hp("2%")
    },

    Line1: {
        position: "relative",
        width:  deviceWidth > 600 ? 292 :deviceWidth > 370 ? 195:148,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',

    },

    Line2: {
        position: "relative",
        width:  deviceWidth > 600 ? 292 :deviceWidth > 370 ? 195:148,
        height: 1,
        // display: WidthDimension<600
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },

    header: {
        position: "relative",
        width: "100%",
        height: "auto",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize: deviceWidth > 600 ? 48 : deviceWidth > 370 ? 32 : 20,
        letterSpacing: deviceWidth > 500 ? 2.8 : deviceWidth > 330 ? 2.8 : 2,
        // fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        marginTop: deviceWidth < 500 ? deviceWidth > 320 ? 8 : 9 : 12,
        marginBottom: deviceWidth < 500 ? deviceWidth > 320 ? 2 : 3 : 4,
        color: '#575f69',
        textTransform: "capitalize",
        // top:actualWidth>2599 ?hp('0.7%') : hp('1%')
    },
    reportbutton: {
        alignSelf: 'center',
        backgroundColor: '#575F69',
        height: 53, width: 165, borderRadius: 25,
        elevation: 10,
        borderColor: 'rgba(87, 95, 105, 0.05)',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: "solid",
        shadowColor: "rgba(87, 95, 105, 0.15)",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 25,
        marginTop: 20
    },
	reportbuttonpressed: {
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        height: 53, width: 165, borderRadius: 25,
        elevation: 10,
        borderColor: 'rgba(87, 95, 105, 0.05)',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: "solid",
        shadowColor: "rgba(87, 95, 105, 0.15)",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 25,
        marginTop: 20
    },
    reportbuttonText1: {
        fontFamily: 'JosefinSans-Light',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: '300',
        color: '#ffffff',
        letterSpacing: 1.6,
        paddingBottom: 5,
        textTransform: 'capitalize'
    },
    reportbuttonText2: {
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: 10,
        textAlign: 'center',
        fontWeight: '600',
        color: '#ffffff',
        letterSpacing: 1,
        textTransform: 'uppercase',
    },
    gameCompletedContainer:{
        marginTop:deviceWidth > 1250 && actualHeight > 850 ? -40 :  850 < actualHeight < 650 && deviceWidth > 600 ? 0 :  deviceWidth > 400 ? -10 : deviceWidth > 340 && actualHeight > 720 ? -10 : -5
    },
    gameCompleteMessage:{
        fontFamily: 'JosefinSans-Light',
        fontSize: deviceWidth > 1250 ? 32 : deviceWidth > 380 ? 20 : 16,
        textAlign: 'center',
        fontWeight: '300',
        letterSpacing: 2,
        marginBottom: 10,
        color: '#575f69',
    },
    gameCompleteDescription:{
        fontFamily: 'JosefinSans-Regular',
        width: deviceWidth > 1250 ? 640 : deviceWidth > 400 ? 360 : deviceWidth > 340 ? 315 : 260,
        fontSize: deviceWidth > 1250 ? 20 : 16,
        textAlign: 'center',
        fontWeight: 'normal',
        letterSpacing: deviceWidth > 1200 ? 1.2 : 'normal',
        color: '#575f69',
    }
})
