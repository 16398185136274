import React from 'react'
import { StyleSheet, Image } from 'react-native'
import '../App.css'

const reportLoader = () => {
    return (
        <>
            <Image source={require('../assets/images/ResultPage2/logoResult.png')} style={styles.logo} />
            <div className="loaderContainer">
                <p className="lining1"></p>
                <p className="loaderText">Building your report...</p>
                <p className="lining2"></p>
            </div>
            <div className="spineerOne">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </>
    )
}

export default reportLoader

const styles = StyleSheet.create({
    logo: {
        width: "40px",
        height: "14px",
        resizeMode: 'contain',
        marginTop: "5px",
        alignSelf: "flex-end",
        marginRight: 5
    }
})
