import React from 'react'
import { heightPercentageToDP as hp } from '../../Utils/responsive'

const Index = ({ text, data }) => {
    const amountData = data.split(' ')[0];
    const value = data.replace(amountData, '');

    return (
        <>
            <p className={text === "First" ? "amount" : "amount2"} style={text === "First" ? { fontSize: hp("3.8%") } : { fontSize: hp("5.5%") }} >{amountData} </p>
            <p className={text === "First" ? "label" : "label2"} style={text === "First" ? { fontSize: hp("2.3%") } : { fontSize: hp("3.2%") }} >{value}</p>
        </>
    )
}

export default Index

