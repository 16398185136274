import React, {useContext, useEffect, useRef, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, Dimensions, Animated, Easing, View} from 'react-native';
import {withRouter} from 'react-router-dom';
import {ActivityContext} from '../Containers/Activities';
import {moduleid} from '../Utils/static-strings'
import axios from 'axios';

const deviceWidth = Dimensions.get('screen').width;
const deviceHeight = Dimensions.get('window').height;
const buttonGroupHeight = deviceWidth > 1300 ? 396 : 326;
const buttonGroupWidth = deviceWidth > 1300 ? 140 : 110;
const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;


const Index = (props) => {
  const {valueUI, understandingUI} = props;
  const [report, setReport] = useState(null);
  const resultContext = useContext(ActivityContext);
  const animated = useRef(new Animated.Value(0)).current;

  const animate = () => {

    Animated.parallel([Animated.timing(animated, {
      toValue: deviceWidth < 500 ? props.currentModule !== null && props.currentModule === 0 ? -deviceHeight / 2 + 60 : props.currentModule === 1 ? -deviceHeight / 2 - 102 + 60 : -deviceHeight / 2 - 204 + 20 : -deviceHeight / 2 + 5,
      duration: 600,
      easing: Easing.in(Easing.ease),
      useNativeDriver: false,
    }),

    ]).start(() => {
      props.history.push(`/activityType/${props.module.moduleid}`)
    });
  };

  const scaling = animated.interpolate({
    inputRange: [deviceWidth < 500 ? props.currentModule !== null && props.currentModule === 0 ? -deviceHeight / 2 + 60 : props.currentModule === 1 ? -deviceHeight / 2 - 102 + 60 : -deviceHeight / 2 - 204 + 20 : -deviceHeight / 2 + 5, 0],
    outputRange: [0, 1]
  })

  const startAnimation = (currentIndex) => {
    props.setCurrentModule(currentIndex);
    props.setDisplayContainer(false);
    animate();
  }

  const selectStyle = (currentTab, index, disabled) => {
    if (currentTab === 1) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return styles.disableOval1
          } else return styles.oval1;

        case moduleid.loss():
          if (disabled) {
            return styles.disableOval2
          } else return styles.oval2;

        case moduleid.future():
          if (disabled) {
            return styles.disableOval3
          } else return styles.oval3;

        case moduleid.experience():
          if (disabled) {
            return styles.disableOvalExperiance
          } else return styles.ovalExperience;

        case moduleid.knowledge():
          if (disabled) {
            return styles.disableOvalKnowledge
          } else return styles.ovalKnowledge;

        case moduleid.esg():
          if (disabled) {
            return styles.disableOvalEsg
          } else return styles.ovalEsg;

      }
    } else if (currentTab === 2) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return styles.disableOval1
          } else return styles.oval1;

        case moduleid.loss():
          if (disabled) {
            return styles.disableOval2
          } else return styles.oval2;

        case moduleid.future():
          if (disabled) {
            return styles.disableOval3
          } else return styles.oval3;

        case moduleid.experience():
          if (disabled) {
            return styles.disableOvalExperiance
          } else return styles.ovalExperience;

        case moduleid.knowledge():
          if (disabled) {
            return styles.disableOvalKnowledge
          } else return styles.ovalKnowledge;

        case moduleid.esg():
          if (disabled) {
            return styles.disableOvalEsg
          } else return styles.ovalEsg;

      }
    } else if (currentTab === 3) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return styles.disableOval1
          } else return styles.oval1;

        case moduleid.loss():
          if (disabled) {
            return styles.disableOval2
          } else return styles.oval2;

        case moduleid.future():
          if (disabled) {
            return styles.disableOval3
          } else return styles.oval3;

        case moduleid.experience():
          if (disabled) {
            return styles.disableOvalExperiance
          } else return styles.ovalExperience;

        case moduleid.knowledge():
          if (disabled) {
            return styles.disableOvalKnowledge
          } else return styles.ovalKnowledge;

        case moduleid.esg():
          if (disabled) {
            return styles.disableOvalEsg;
          } else return styles.ovalEsg;

      }
    }
  }

  const setModuleOpacity = (currentTab, index, disabled) => {
    if (currentTab === 1) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

        case moduleid.loss():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

        case moduleid.future():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

        case moduleid.experience():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.knowledge():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.esg():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

      }
    } else if (currentTab === 2) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.loss():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.future():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.experience():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

        case moduleid.knowledge():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

        case moduleid.esg():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

      }
    } else if (currentTab === 3) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.loss():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.future():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.knowledge():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.experience():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }

        case moduleid.esg():
          if (disabled) {
            return 1
          } else {
            return 1;
          }

      }
    }
  }


  const checkDisability = (index, disabled, currentTab) => {
    if (currentTab === 1) {
      switch (index) {
        case moduleid.risk():
          if (disabled) {
            return true
          } else return false;

        case moduleid.loss():
          if (disabled) {
            return true
          } else return false;

        case moduleid.future():
          if (disabled) {
            return true
          } else return false;

        case moduleid.experience():
          return true

        case moduleid.knowledge():
          return true;

        case moduleid.esg():
          return true;
      }
    } else if (currentTab === 2) {
      switch (index) {
        case moduleid.risk():
          return true

        case moduleid.loss():
          return true;

        case moduleid.future():
          return true;

        case moduleid.experience():
          if (disabled) {
            return true
          } else return false;

        case moduleid.knowledge():
          if (disabled) {
            return true
          } else return false;

        case moduleid.esg():
          return true;
      }
    } else if (currentTab === 3) {
      switch (index) {
        case moduleid.risk():
          return true

        case moduleid.loss():
          return true;

        case moduleid.future():
          return true;

        case moduleid.experience():
          return true

        case moduleid.knowledge():
          return true;

        case moduleid.esg():
          if (disabled) {
            return true
          } else return false;

      }
    }
  }

  const [scrollViewPos, setScrollViewPos] = useState(new Animated.ValueXY(0, 0));

  useEffect(() => {
    if (props.currentSelectedTab === 2) {
      deviceWidth < 500 ? Animated.spring(scrollViewPos, {
        toValue: {x: 0, y: scrollViewPos.x._value - 306}
      }).start() : Animated.spring(scrollViewPos, {
        toValue: {x: (deviceWidth > 1300 ? (-382) : (-316)), y: 0}
      }).start();
    } else if (props.currentSelectedTab === 1) {
      Animated.spring(scrollViewPos, {
        toValue: {x: 0, y: 0}
      }).start();
    } else if (props.currentSelectedTab === 3) {
      const temp = props.understandingUI.length === 0 ? -378 : -634
      deviceWidth < 500
          ? Animated.spring(scrollViewPos, {
            toValue: {x: 0, y: scrollViewPos.x._value - 306}
          }).start()
          : Animated.spring(scrollViewPos, {
            toValue: {x: (deviceWidth > 1300 ? temp : -522), y: 0}
          }).start();
    }
  }, [props.currentSelectedTab])

  const Text1 = (props) => {
    return (
        <>{(props.index === 0 ? (props.disabled && props.disabledfuctio(props.index)) :
            (props.index === 1 ? (props.disabled && props.disabledfuctio(props.index)) :
                (props.index === 2 ? (props.disabled && props.disabledfuctio(props.index)) :
                    (props.index === 3 ? (props.disabled && props.disabledfuctio(props.index)) :
                            (props.index === 4 ? (props.disabled && props.disabledfuctio(props.index)) :
                                (props.index === 5 ? (props.disabled && props.disabledfuctio(props.index)) :
                                    (props.disabled && props.disabledfuctio(props.index))))
                    ))))}

          <TouchableOpacity onPress={props.onPress}
                            disabled={checkDisability(props.moduleid, props.disabled, props.currentSelectedTab)}
                            style={{
                              opacity: props.currentModule !== null ? (props.currentModule === props.index ? 1 : 0) : 1,
                              marginLeft: props.currentSelectedTab === 1 ? 6.3 : 0.5,
                              paddingLeft: props.currentSelectedTab === 1 ? null : 3,
                            }}>

            <Animated.View
                style={[scrollViewPos.getLayout(), selectStyle(props.currentSelectedTab, props.moduleid, props.disabled), {opacity: setModuleOpacity(props.currentSelectedTab, props.moduleid, props.disabled)}, {transform: [{translateY: animated}, {scale: scaling}]}]}>
              {props.disabled ?
                  resultContext.riskCalculation !== undefined && resultContext.riskCalculation.calcresults.filter((value) =>
                      value.moduleid == props.moduleid).map((item) => {
                    return (
                        <>
                          <Text style={[styles.valueTextLarge, {opacity: 1}]}>{item.measure}</Text>
                          <Text style={[styles.valueTextSmall, {opacity: 1}]}>
                            {item.chartvalues && item.chartvalues.length > 0 && item.chartvalues[0].label}
                          </Text>
                        </>
                    )
                  })
                  :
                  <Text style={[styles.value, {opacity: props.currentModule !== null ? 0 : 1}]}>{props.title}</Text>
              }
            </Animated.View>

          </TouchableOpacity>
        </>
    )
  }

  return (
      <Text1 disabled={
          ((resultContext.result &&
              resultContext.result.modules.filter(
                  (module) => module.moduleid === props.module.moduleid,
              )[0].completedat !== null) || (props.details !== '' && props.details.modules.some((item) => item.moduleid === props.module.moduleid))) &&
          (resultContext.riskCalculation !== undefined && resultContext.riskCalculation.modules.some((item) => item.moduleid === props.module.moduleid))
      }
             displayContainer={props.displayContainer}
             index={props.index}
             setDisplayContainer={props.setDisplayContainer}
             onPress={() => startAnimation(props.index)}
             currentModule={props.currentModule}
             title={props.module.name}
             disabledfuctio={props.disabledfuctio}
             moduleid={props.module.moduleid}
             {...props}
      />
  )
}

export default withRouter(Index);

const styles = StyleSheet.create({
  oval1: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#6fd5da",
    backgroundColor: "#6fd5da",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: deviceWidth > 500 ? 250 : 0,
    // marginTop: deviceWidth > 500 ? 0 : 205

  },
  oval2: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#79a2d9",
    backgroundColor: "#79a2d9",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  oval3: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#fda435",
    backgroundColor: "#fda435",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  ovalExperience: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#FF8D53",
    backgroundColor: "#FF8D53",
    alignItems: "center",
    justifyContent: "center",
  },
  ovalKnowledge: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#bb6fda",
    backgroundColor: "#bb6fda",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  ovalEsg: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#4bd5ad",
    backgroundColor: "#4bd5ad",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  disableOval1: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#6fd5da",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#6fd5da",
    // backgroundColor: "#6fd5da",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
    // marginLeft: deviceWidth > 500 ? 250 : 0,
    // marginTop: deviceWidth > 500 ? 0 : 205
  },
  disableOval2: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#79a2d9",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#79a2d9",
    // backgroundColor: "#79a2d9",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
    // marginLeft: 5
  },
  disableOval3: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#fda435",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#fda435",
    // backgroundColor: "#fda435",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOvalExperiance: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#FF8D53",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#fda435",
    // backgroundColor: "#fda435",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOvalEsg: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#4bd5ad",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#fda435",
    // backgroundColor: "#fda435",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOvalKnowledge: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#bb6fda",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // borderStyle: "solid",
    // borderColor: "#bb6fda",
    // backgroundColor: "#bb6fda",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
    // marginLeft: 5
  },
  value: {
    width: 105,
    height: 27,
    fontFamily: 'JosefinSans-Regular',
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 26.5,
    // zIndex:99999,
    // backgroundColor:"blue",
    color: '#ffffff',
  },
  label: {
    width: 105,
    height: 13,
    fontFamily: 'JosefinSans-SemiBold',
    fontSize: 10,
    fontWeight: '600',
    textAlign: 'center',
    color: '#575f69',
  },
  valueTextLarge: {
    width: 105,
    height: 27,
    fontFamily: 'JosefinSans-Regular',
    fontSize: 18,
    textAlign: 'center',
    // zIndex:99999,
    // backgroundColor:"blue",
    color: '#575f69',
  },
  valueTextSmall: {
    width: actualWidth > 760 ? 105 : 55,
    height: 13,
    fontFamily: 'JosefinSans-SemiBold',
    fontSize: actualWidth > 760 ? 10.5 : 9,
    fontWeight: '600',
    textAlign: 'center',
    color: '#575f69',
    textTransform: 'uppercase',
    alignSelf: 'center'
  }
})
