import React, {FunctionComponent, useEffect, useState} from "react";
import {StyleSheet, View, Animated, Easing, Platform, Dimensions} from "react-native";
import Arrow from "./Arrow";
import LabelLeft from './LabelLeft';
import LabelRight from "./LabelRight";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../Utils/responsive';
import ArrowForSkin3 from "./ArrowForSkin3";

const actualHeight = Dimensions.get('screen').height;
const actualWidth = Dimensions.get('screen').width;

const CircularPie = ({
                       activeColor,
                       passiveColor,
                       baseColor,
                       radius,
                       done,
                       setDisable,
                       gain,
                       loss,
                       width,
                       duration,
                       children,
                       circularPieForSkin3,
                       showAnimation = true,
                       hideIndicator,
                       showPercentage,
                       showAmount,
                       isGain,
                       isBlueOverDark,
                       hideBracket,
                       useBiggerFont,
                       setTriangle,
                       ratio,
                       bigRadius,
                       smallRadius
                     }) => {
  const [currentVal, setCurrentVal] = useState(0);
  const initialValueHalfCircle = done >= 50 ? 0 : 180;
  const initialValueInnerCircle = 0;
  const animatedValue1 = new Animated.Value(initialValueHalfCircle);
  const animatedValue2 = new Animated.Value(initialValueHalfCircle);
  const animatedValue3 = new Animated.Value(initialValueInnerCircle);
  const timePerDegree = duration / 360;
  const color1 = activeColor;
  const color2 = done >= 50 ? activeColor : passiveColor;
  const [radiusVal, setRadius] = useState(radius);
  const [animation, setAnimation] = useState(showAnimation);
  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));
  const firstAnimation = (isAnim) => {
    animatedValue1.setValue(initialValueHalfCircle);
    animatedValue2.setValue(initialValueHalfCircle);
    animatedValue3.setValue(initialValueInnerCircle);
    Animated.parallel([
      Animated.timing(animatedValue1, {toValue: 180, duration: isAnim ? 0 : 180, easing: Easing.linear}),
      Animated.timing(animatedValue2, {
        toValue: 180 + (done - 50) * 3.6, duration: (isAnim ? 0 : 180 + (done - 50) * 3.6) * timePerDegree,
        easing: Easing.linear
      }),
      Animated.timing(animatedValue3, {
        toValue: (done - 50) * 3.6, delay: 180 * timePerDegree,
        duration: isAnim ? 0 : timePerDegree * ((done - 50) * 3.6),
        easing: Easing.linear
      })
    ]).start();
  };
  const secondAnimation = () => {
    animatedValue1.setValue(initialValueHalfCircle);
    animatedValue2.setValue(initialValueHalfCircle);
    animatedValue3.setValue(initialValueInnerCircle);
    Animated.timing(animatedValue2, {
      toValue: 180 + done * 3.6,
      duration: done * 3.6 * timePerDegree,
      useNativeDriver: true,
      easing: Easing.linear
    }).start();
  };
  useEffect(() => {
    if (currentVal === 1) {
      if (done >= 50) {
        firstAnimation();
      } else {
        secondAnimation();
      }
    }
  }, [done, currentVal]);
  useEffect(() => {
    if (currentVal === 1) {
      if (done >= 50) {
        firstAnimation(true);
      } else {
        secondAnimation(true);
      }
    }
  }, [screenDimensions.width, screenDimensions.height]);
  useEffect(() => {
    setCurrentVal(prev => prev + 1);
  }, [])
  const renderHalf = (color, transforms = [], otherStyles = {}, isOverride, pos) => {
    const trans = transforms[0]
    return (
        isOverride ?
            <Animated.View style={[
              styles.half,
              {backgroundColor: color},
              {
                width: radius,
                height: radius * 2,
                borderRadius: isOverride ? 0 : radius,
                zIndex: isOverride ? 10 : 'initial'
              },
              showAnimation ? {
                transform: [{translateX: useBiggerFont ? -25 : 15}, {rotate: useBiggerFont ? '130deg' : '120deg'}, {translateX: 50},
                  {scale: 1.204}]
              } : {}, otherStyles]}/>
            :
            <Animated.View style={[
              styles.half,
              {backgroundColor: color},
              {
                width: radius,
                height: radius * 2,
                borderRadius: isOverride ? 0 : radius,
                zIndex: isOverride ? 10 : 'initial'
              },
              showAnimation ? {transform: [{translateX: radiusVal / 2}, ...transforms, {translateX: -radiusVal / 2}, {scale: setTriangle ? trans.scale ? trans.scale : 1.004 : 1.004}]} : {},
              otherStyles]}></Animated.View>
    )
  };
  const rotate1 = animatedValue1.interpolate({inputRange: [0, 180], outputRange: ["0deg", "180deg"]});
  const rotate2 = animatedValue2.interpolate({inputRange: [0, 180], outputRange: ["0deg", "180deg"]});
  const rotate3 = animatedValue3.interpolate({inputRange: [0, 45], outputRange: ["0deg", "45deg"]});
  const elevation3 = animatedValue3.interpolate({inputRange: [0, 1], outputRange: [0, -1]});
  useEffect(() => {
    const onChange = ({window}) => {
      setScreenDimensions(window);
      setRadius(window.height <= 700 || window.width <= 600 ? smallRadius : bigRadius);
    };
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);
  return (
      done && <View style={circularPieForSkin3 ? {margin: 30} : styles.container} key={done}>
        <View style={[styles.overlay]}>
          {hideIndicator ? null : circularPieForSkin3 ? null : <Arrow/>}
          <View style={[styles.outer, {backgroundColor: !showAnimation ? activeColor : passiveColor},
            {borderRadius: radius, height: radius * 2, width: radius * 2, margin: '0 auto', overflow: 'hidden'}]}>
            {hideIndicator ? null : circularPieForSkin3 ? <ArrowForSkin3/> : null}
            <LabelLeft showPercentage={showPercentage} showAmount={showAmount} isGain={isGain} showAnimation={showAnimation}
                       gain={gain} setDisable={setDisable} circularPieForSkin3={circularPieForSkin3}
                       isBlueOverDark={isBlueOverDark} hideBracket={hideBracket} useBiggerFont={useBiggerFont}
                       setTriangle={setTriangle} isMandg={circularPieForSkin3}/>
            <LabelRight showAmount={showAmount} showPercentage={showPercentage} isGain={isGain}
                        showAnimation={showAnimation} loss={loss} percent={done} setDisable={setDisable}
                        circularPieForSkin3={circularPieForSkin3} isBlueOverDark={isBlueOverDark} hideBracket={hideBracket}
                        useBiggerFont={useBiggerFont} setTriangle={setTriangle} isMandg={circularPieForSkin3}/>
            {
              circularPieForSkin3
                  ?
                  (renderHalf(color1, [{rotate: rotate1}], {}, isBlueOverDark, 'left'))
                  :
                  (renderHalf(color1, [showAnimation ? {rotate: rotate1} : {}], {
                    borderRightWidth: hideIndicator ? 0 : 2.5,
                  }, isBlueOverDark, 'left'))
            }
            {renderHalf(color2, [showAnimation ? setTriangle ? {
              translateX: '104%',
              rotate: rotate2,
              scale: -1.096
            }: {rotate: rotate2} : {borderRightColor: ''}], {},false,'middle')}

            {circularPieForSkin3 ?
                (renderHalf(passiveColor, [showAnimation ? setTriangle ? {
                  translateX: 5,
                  rotate: rotate3,
                  elevation: elevation3,

                } : {rotate: rotate3} : {}], {
                  elevation: elevation3,
                  zIndex: elevation3,
                },false, 'right')) :
                (renderHalf(passiveColor, [showAnimation ? setTriangle ? {
                  translateX: 5,
                  rotate: rotate3,
                  elevation: elevation3,

                } : {rotate: rotate3} : {}], {
                  elevation: elevation3,
                  zIndex: elevation3,
                  borderRightWidth: hideIndicator ? 0 : 2.5,
                },false, 'right'))}
            <View style={[
              {
                backgroundColor: baseColor,
                borderRadius: radius,
                elevation: 1000, display: "flex", justifyContent: "center", alignItems: "center"
              }]}>{children}
            </View>
          </View>
        </View>
      </View>);
};

const styles = StyleSheet.create({
  container: {},
  overlay: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "relative",
    margin: '0 auto'
  },
  outer: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden !important",
    transform: [{scaleX: -1}]
  },
  half: {position: "absolute", left: 0, top: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0}
});

export default CircularPie;
