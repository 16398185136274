import React from 'react'
import '../App.css';
import { Text, View, StyleSheet, Dimensions } from 'react-native'
import Loadingscreen from '../Utils/skin2Loader';
const actualWidth = Dimensions.get('screen').width;
const Index = () => {
  return (
    <View style={{height:'100vh',justifyContent:'center',alignItems:'center',backgroundColor:'#91d5d3'}}>
      <View style={{justifyContent:'center',alignItems:'center',height:'10%'}}>
          <Text style={styles.text}>Please wait, submitting your answers</Text>
      </View>
      <View  style={{justifyContent:'center',alignItems:'center',height:'10%'}}>
      <Loadingscreen />
      </View>
    </View>
  )
}

export default Index;

const styles = StyleSheet.create({
  Container: {
  },
  text: {
      textAlign:'center',
        fontFamily: 'Manrope-SemiBold',
        fontSize: actualWidth>600? 34:actualWidth>380?28:24,
        color: '#2f2f2f',
        lineHeight: 40,
        fontWeight:'bold'
  },
})