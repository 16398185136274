import React, { useLayoutEffect, useState, useRef, useContext, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Image } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ActivityContext } from '../../../Activities';
const Index = (props) => {
    const animation = useRef(new Animated.Value(0)).current;
    const resultContext = useContext(ActivityContext);
    const [buttonSelect, setButtonSelect] = useState(true);
    const [hide, setHide] = useState(true);
    const [data, setData] = useState([
        { name: props.moduleObject.questions[3].answers[0].text, isActive: false, id: 0 },
        { name: props.moduleObject.questions[3].answers[1].text, isActive: false, id: 1 },
        { name: props.moduleObject.questions[3].answers[2].text, isActive: false, id: 2 },
        { name: props.moduleObject.questions[3].answers[3].text, isActive: false, id: 3 },
        { name: props.moduleObject.questions[3].answers[4].text, isActive: false, id: 4 },
        { name: props.moduleObject.questions[3].answers[5].text, isActive: false, id: 5 },
        { name: props.moduleObject.questions[3].answers[6].text, isActive: false, id: 6 },
        { name: props.moduleObject.questions[3].answers[7].text, isActive: false, id: 7 },
        { name: props.moduleObject.questions[3].answers[8].text, isActive: false, id: 8 },
    ]);

    useLayoutEffect(() => {
        let check;
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        if (props.moduleObject.questions[3].askif) {
            if (props.moduleObject.questions[3].askif.op == 'OR') {
                props.moduleObject.questions[3].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            check = props.moduleObject.questions[props.moduleIndex].askif.answers.find((cc) => cc.respid == data.respid);
                        }
                    })
                    if (check) { }
                    else {
                        props.selectAnswerHandler(
                            props.moduleObject.moduleid,
                            props.moduleObject.questions[3].qid,
                            props.moduleObject.questions[3].answers[0].respid,
                        );
                        props.setSkippedQuestion(true);
                        props.Refresh();
                    }

                })
            }
            if (props.moduleObject.questions[3].askif.op == 'AND') {
                props.moduleObject.questions[3].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            if (val.respid == data.respid) {

                            }
                            else {
                                props.selectAnswerHandler(
                                    props.moduleObject.moduleid,
                                    props.moduleObject.questions[3].qid,
                                    props.moduleObject.questions[3].answers[0].respid,
                                );
                                props.Refresh();
                            }
                        }
                    })
                })
            }
        }
        Animated.timing(animation, {
            toValue: 1,
            duration: 500
        }).start(() => setHide(false));
    }, [])

    const nextPage = () => {
        setHide(true);
        let cc = data.filter(val => val.isActive == true);
        if (buttonSelect) {
            props.selectAnswerHandler(
                props.moduleObject.moduleid,
                props.moduleObject.questions[3].qid,
                props.moduleObject.questions[3].answers[9].respid,
            );
        } else {
            cc.forEach((val) => {
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.moduleObject.questions[3].qid,
                    props.moduleObject.questions[3].answers[val.id].respid,
                );
            })
        }

        Animated.timing(animation, {
            toValue: 0,
            duration: 500
        }).start(() => props.Refresh());
    }

    const chage = (index) => {
        setButtonSelect(false);
        if(props.moduleObject.questions[3].multichoice){
            let tmp = data;
            tmp[index].isActive = !tmp[index].isActive;
            setData(tmp => [...tmp])
        }else{
            let tmp = data.map((item, _index)=>{
                if(index === _index){
                    item.isActive = true;
                    return item;
                }else{
                    item.isActive = false;
                    return item;
                }
            });
            setData(tmp)
        }

    }
    useEffect(() => {
        let select = data.find((Value) => Value.isActive == true);
        if (select == undefined) {
            setButtonSelect(true);
        }
    }, [data])

    return (
        <Animated.View style={[styles.content, { opacity: animation }]}>
            <Text style={styles.customText}>
                {props.moduleObject.questions[3].text.split('(')[0]}
            </Text>
            <Text style={styles.customText2}>
                {props.moduleObject.questions[3].text.split('?')[1]}
            </Text>
            <View style={styles.buttongroup}>
                {
                    data && data.map((el, index) => {
                        if (index < 3) {
                            return (
                                <View key={index} style={{ margin: Dimensions.get('screen').width > 320 ? 4 : 1 }}>
                                    <View>
                                        <TouchableOpacity
                                            onPress={() => { chage(index) }}
                                            disabled={hide}
                                            style={[styles.button, { borderColor: el.isActive ? "#A65B36" : "#ff8d53", backgroundColor: el.isActive ? "#A65B36" : "#ff8d53" }]}>
                                            <Text style={styles.buttontext}> {el.name.toUpperCase()}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        }
                    })
                }
            </View>
            <View style={styles.buttongroup}>
                {
                    data && data.map((el, index) => {
                        if (index >= 3 && index < 6) {
                            return (
                                <View key={index} style={{ margin: Dimensions.get('screen').width > 320 ? 4 : 1 }}>
                                    <View>
                                        <TouchableOpacity
                                            onPress={() => { chage(index) }}
                                            disabled={hide}
                                            style={[styles.button, { borderColor: el.isActive ? "#A65B36" : "#ff8d53", backgroundColor: el.isActive ? "#A65B36" : "#ff8d53" }]}>
                                            <Text style={styles.buttontext}> {el.name.toUpperCase()}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        }
                    })
                }
            </View>
            <View style={styles.buttongroup}>
                {
                    data && data.map((el, index) => {
                        if (index >= 6) {
                            return (
                                <View key={index} style={{ margin: Dimensions.get('screen').width > 320 ? 4 : 1 }}>
                                    <View>
                                        <TouchableOpacity
                                            onPress={() => { chage(index) }}
                                            disabled={hide}
                                            style={[styles.button, { borderColor: el.isActive ? "#A65B36" : "#ff8d53", backgroundColor: el.isActive ? "#A65B36" : "#ff8d53" }]}>
                                            <Text style={styles.buttontext}> {el.name.toUpperCase()}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        }
                    })
                }
            </View>
            <View style={{ margin: 5 }}></View>
            <View>
                {buttonSelect ?
                    <TouchableOpacity
                        onPress={() => { nextPage() }}
                        disabled={hide}
                        style={[styles.continueButton]}>

                    <Text style={styles.continueText}>
                        {props.moduleObject.questions[3].answers[9].text.toUpperCase()}
                    </Text>

                    <Image style={styles.customArrow}
                        source={require('../../../../assets/images/knowledgearrow.png')} ></Image>
                    </TouchableOpacity> :
                    <TouchableOpacity
                        onPress={() => { nextPage() }}
                        disabled={hide}
                        style={[styles.arrow]}>
                        <ArrowForwardIcon fontSize='inherit' htmlColor="#ffffff" />
                    </TouchableOpacity>
                }
            </View>
            <View style={{ height: Dimensions.get('screen').width > 320 ? 0 : 10 }} />
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('34%') : Dimensions.get('screen').width > 700 ? wp('34%') : wp('95%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1300 ? 24 : 22,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
        lineHeight: 26.5,
        letterSpacing: 2
    },
    customText2: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1300 ? 16 : 14,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
    },
    button: {
        width: Dimensions.get('screen').width > 1300 ? 112 : 100,
        height: Dimensions.get('screen').width > 1300 ? 112 : 100,
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1300 ? 60 : 55,
        borderStyle: "solid",
        borderColor: "#ff8d53",
        backgroundColor: "#ff8d53",
        alignItems: "center",
        justifyContent: "center",
    },
    buttontext: {
        // width: 85,
        // height: 50,
        fontFamily: 'JosefinSans-Bold',
        fontSize: Dimensions.get('screen').width > 320 ? 12 : 10,
        textAlign: 'center',
        letterSpacing: 1,
        color: '#ffffff',
    },
    buttongroup: {
        flexDirection: 'row'
    },
    lastButton: {
        backgroundColor: '#575f69',
        // width:'auto',
        // justifyContent: 'center',
        width: 199,
        minHeight: 35,
        textAlignVertical: "center",
        flexDirection: 'row'
    },
    lastButtonText: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: Dimensions.get('screen').width > 500 ? 16 : 12,
        paddingTop: Dimensions.get('screen').width > 500 ? 10 : Dimensions.get('screen').width > 320 ? 12 : 13,
        width: "100%",
        textAlign: "center",
        height: "100%",
        textAlignVertical: "center",
        alignSelf: "center",
        letterSpacing: 2,
    },
    arrow: {
        width: Dimensions.get('screen').width > 1300 ? 34 : 28,
        height: Dimensions.get('screen').width > 1300 ? 34 : 28,
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1300 ? 18 : 15,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
    },
    continueButton: {
        // width: Dimensions.get('screen').width > 850 ? 196 : 178,
        height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'row',
        textTransform:"uppercase"
        // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
    },
    customArrow: {
        alignSelf: 'center',
        width: Dimensions.get('screen').width > 850 ? 22 : 16,
        height: Dimensions.get('screen').width > 850 ? 22 : 16,
        resizeMode: "contain",
        marginRight:wp('1.4%'),
        marginLeft:wp('0.5%')

    },
    continueText:{
        fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'JosefinSans-Bold',
        lineHeight: 'normal',
        letterSpacing: 1.6,
        marginRight:wp("0.5%"),
        marginLeft:wp('1.4%')


    },

})
