import React from 'react'
import { View, Dimensions, StyleSheet } from 'react-native'
const actualWidth = Dimensions.get('screen').width;
export const DropBox = (props) => {

    return (
        <>{props.iconKey == 'planet' &&
            <View style={styles.balls}>
                <img src={require("../../../../../src/assets/svg/Token-Planet.svg")} style={{
                    width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                    height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                    objectFit: 'contain',
                }} />
            </View>
        }
            {props.iconKey == 'people' &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-People.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == 'planetandpeople' &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-PeoplePlanet.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == 'planetmoney' &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-PlanetOverReturns.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == 'moneyplanet' &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPlanet.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == "positiveonly" &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-PositiveImpact.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == "carbonneutral" &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-CarbonNeutral.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == "screen" &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-NoSins.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
            {props.iconKey == "all" &&
                <View style={styles.balls}>
                    <img src={require("../../../../../src/assets/svg/Token-All.svg")} style={{
                        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                        objectFit: 'contain',
                    }} />
                </View>
            }
               {props.iconKey == "peoplemoney" &&
                 <View style={styles.balls}>
                 <img src={require("../../../../../src/assets/svg/Token-PeopleOverReturns.svg")} style={{
                     width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                     height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
                     objectFit: 'contain',
                 }} />
             </View>
        }
        {props.iconKey == "moneypeople" &&
         <View style={styles.balls}>
         <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPeople.svg")} style={{
             width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
             height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
             objectFit: 'contain',
         }} />
     </View>
        }
           {props.iconKey == "bothmoney" &&
           <View style={styles.balls}>
           <img src={require("../../../../../src/assets/svg/Token-BothOverReturns.svg")} style={{
               width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
               height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
               objectFit: 'contain',
           }} />
       </View>
        }
        {props.iconKey == "moneyboth" &&
         <View style={styles.balls}>
         <img src={require("../../../../../src/assets/svg/Token-ReturnsOverBoth.svg")} style={{
             width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
             height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
             objectFit: 'contain',
         }} />
     </View>
        }
        </>
    )

}
const styles = StyleSheet.create({
    balls: {
        width: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
        height: actualWidth > 700 ? 75 : actualWidth > 400 ? 62 : 62,
        borderRadius: actualWidth > 700 ? 38 : actualWidth > 400 ? 31 : 31,
        elevation: 4,
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 10
    },
})