import React, { useEffect } from 'react'
import { Text, View, StyleSheet, Dimensions, TouchableOpacity,PixelRatio } from 'react-native'
import { 
  // widthPercentageToDP as wp, heightPercentageToDP as hp, 
  
  screenWidth as actualWidth , screenHeight as actualHeight} from '../../../../Utils/responsive'
import '../../../../App.css'
import { inIframe } from '../../../../Utils/helper';
// const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }


  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);
   
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);


  return (
    <View style={[styles.Container,{height: screenWidth > 1300 ? '100vh' : screenWidth > 760 ? '90vh' : '85vh',}]}>
      <View style={{ marginBottom:  inIframe() ? 16.5: hp('2%') }}>
        <Text style={[styles.text,{ marginLeft:inIframe() ? 23.5: wp('2%'),fontSize: inIframe() ? 18: hp("2.5%"), marginBottom: inIframe() ? 16.5 : hp("2%"),}]}>
          The following ten statements aim{`\n`}to gauge your feeling towards risk.{`\n`}{`\n`}There are no right or wrong answers,{`\n`}choose honestly and we'll be able to recommend the{`\n`}investment you'll feel most comfortable with.
        </Text>
        <TouchableOpacity style={styles.button} onPress={() => props.Showing()}>
          <Text style={styles.buttonText}>Let's begin</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default Index;

const styles = StyleSheet.create({
  Container: {
    
    width: '100vw',
    // flex: 1,
    // justifyContent: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    // position: 'relative',
    // alignSelf: 'flex-start',
    alignSelf: 'center',
    width: '150',
    borderRadius: '22px',
    backgroundColor: '#ffffff',
    // marginLeft: wp('2%'),
  },
  buttonText: {
    color: '#a81c1b',
    fontSize: '19px',
    textAlign: 'center',
    fontFamily: 'OpenSans-Regular',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  text: {
    position: "relative",
    alignSelf: "center",
    width: "auto",
    color: "#ffffff",
    fontWeight: "400",
    // marginLeft: wp('2%'),
    fontFamily: "OpenSans-Regular",
  },
})