import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  Dimensions,
  Animated,
  Easing,
} from "react-native";
import DragGames from "./dragGameBalls";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "../../../../Utils/responsive";
import { TARGET_INVESTMENT } from "../../../../Utils/static-strings";

const actualWidth = Dimensions.get("screen").width;
const actualHeigth = Dimensions.get("screen").height;

let deviceWidth = Dimensions.get("window").width;
let deviceHeigth = Dimensions.get("window").height;

const isIpad =
  navigator.userAgent.match(/Mac/) &&
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2;

export const DragBallsThird = (props) => {
  const [PETS, setPets] = useState(
    props.moduleObject.questions[props.moduleIndex].answers
  );
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(deviceHeigth);
  const [width, setWidth] = useState(deviceWidth);
  const introAnimation = useRef(new Animated.Value(actualWidth)).current;

  useLayoutEffect(() => {
    Animated.timing(introAnimation, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start();
  });

  Dimensions.addEventListener("change", (e) => {
    deviceHeigth = e.window.height;
    deviceWidth = e.window.width;
    setHeight(deviceHeigth);
    setWidth(deviceWidth);
  });

  useEffect(() => {
    let moduleIdEsg = props.resultContext.result.modules.filter(
      (mod) => mod.moduleid === props.moduleObject.moduleid
    );
    if (props.moduleObject.questions[props.moduleIndex].askif) {
      if (props.moduleObject.questions[props.moduleIndex].askif.op == "AND") {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach(
          (val) => {
            let ifExist = moduleIdEsg[0].responses.filter(
              (mod) => mod.qid == val.qid && mod.respid == val.respid
            );
            if (ifExist.length > 0) {
              setShow(true);
              setPets(props.moduleObject.questions[props.moduleIndex].answers);
            } else {
              if (props.moduleIndex < props.numberOfQuestions - 1) {
                props.setModuleIndex((moduleIndex) => moduleIndex + 1);
              } else {
                if (moduleIdEsg[0].completedat == null) {
                  props.resultContext.setModuleCompletedAtTime(
                    props.moduleObject.moduleid
                  );
                  props.history.push("/");
                }
              }
            }
          }
        );
      }
    }
  }, [props.moduleIndex]);

  return (
    show && (
      <Animated.View
        style={[
          {
            justifyContent: actualWidth > 750 ? "space-evenly" : "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            transform: [{ translateX: introAnimation }],
          },
        ]}
      >
        <Text
          style={[
            styles.customText,
            isIpad && {
              width:
                width > 1000 ? wp("75%") : width > 400 ? wp("85%") : wp("90%"),
            },
          ]}
        >
          {props.moduleObject.questions[props.moduleIndex].text ||
            TARGET_INVESTMENT}
        </Text>
        {actualWidth < 520 && <View style={styles.Line} />}
        <View
          style={{
            flexDirection: "row",
            justifyContent: actualWidth > 750 ? "space-between" : "center",
            alignItems: "center",
            width: actualWidth > 1000 ? "75%" : "90%",
          }}
        >
          {PETS.map((pet, index) => {
            if (actualWidth > 550) {
              return (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: actualWidth > 1250 ? "33%" : "237px",
                  }}
                  key={index}
                >
                  <Text style={styles.headingText}>{pet.text}</Text>
                  <Text style={styles.subHeading}>
                    {pet.metadata.description}
                  </Text>
                  <DragGames onClick={props.onItemClick} iconKey={pet.metadata.iconkey} {...pet} />
                </View>
              );
            } else if (index === 0) {
              return (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "98%",
                  }}
                  key={index}
                >
                  <Text style={styles.headingText}>{pet.text}</Text>
                  <Text style={styles.subHeading}>
                    {pet.metadata.description}
                  </Text>
                  <DragGames onClick={props.onItemClick} iconKey={pet.metadata.iconkey} {...pet} />
                </View>
              );
            }
          })}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: actualWidth > 750 ? "space-between" : "center",
            alignItems: "center",
            width: actualWidth > 1000 ? "75%" : "90%",
          }}
        >
          {PETS.map((pet, index) => {
            if (actualWidth < 550 && index !== 0) {
              return (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:
                      actualWidth > 1000 &&
                      actualWidth < 1200 &&
                      actualHeigth < 900
                        ? "-5%"
                        : actualWidth < 330 && -20,
                    width: "50%",
                  }}
                  key={index}
                >
                  <Text
                    style={[
                      styles.headingText,
                      { marginBottom: 0, width: "99%" },
                    ]}
                  >
                    {pet.text}
                  </Text>
                  <DragGames onClick={props.onItemClick} iconKey={pet.metadata.iconkey} {...pet} />
                </View>
              );
            }
          })}
        </View>

        {/* {actualWidth > 400 ?
                <View style={{ flexDirection: actualWidth > 500 ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center', width: actualWidth > 1000 ? '75%' : '90%', marginBottom: actualWidth > 500 && '10%' }}>
                    {PETS.map((pet, index) => {
                        if (index < 3) {
                            return (
                                actualWidth > 500 ?
                                    <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '33%',height:actualWidth>700 && '100%' }} key={index}>
                                        <Text style={styles.headingText}>{pet.text}</Text>
                                        <View style={{height:actualWidth>700 &&'50%'}}>
                                        <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                        </View>
                                    </View> :
                                    <View style={{
                                        flexDirection: 'column', justifyContent: 'center',
                                        alignItems: 'center', width: '50%',
                                        alignSelf: index == 0 ? 'flex-end' : index == 1 ? 'flex-start' : 'flex-end',
                                        top:index == 0? '10%' : index == 1 ? '0%' : index == 2 && '-15%',
                                        // marginLeft: index == 1 && '10%',
                                        // marginRight: index !== 1 && '10%'
                                    }} key={index}>
                                        <Text style={styles.headingText}>{pet.text}</Text>
                                        <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                    </View>
                            )
                        }
                    })}
                </View> :
                <>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: actualWidth > 1000 ? '75%' : '90%',height:'50%' }}>
                        {PETS.map((pet, index) => {
                            if (index < 2) {
                                return (
                                    <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%' }} key={index}>
                                        <Text style={styles.headingText}>{pet.text}</Text>
                                        <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                    </View>
                                )
                            }
                        })}
                    </View>
                    {PETS.map((pet, index) => {
                        if (index == 2) {
                            return (
                                <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%' }} key={index}>
                                    <Text style={styles.headingText}>{pet.text}</Text>
                                    <DragGames iconKey={pet.metadata.iconkey} {...pet} />
                                </View>
                            )
                        }
                    })}
                </>
            } */}
      </Animated.View>
    )
  );
};
const styles = StyleSheet.create({
  headingText: {
    height: actualWidth > 700 && "100%",
    marginBottom: 10,
    fontSize: actualWidth > 1000 ? 16 : actualWidth > 400 ? 16 : 12,
    fontWeight: 300,
    color: "#575f69",
    letterSpacing: actualWidth > 1000 ? 1.6 : actualWidth > 400 ? 1.6 : 1.2,
    fontFamily: "JosefinSans-Light",
    textAlign: "center",
  },
  subHeading: {
    fontSize: actualWidth > 1000 ? 16 : actualWidth > 400 ? 16 : 12,
    fontWeight: 300,
    color: "#575f69",
    letterSpacing: actualWidth > 1000 ? 1.6 : actualWidth > 400 ? 2 : 1.2,
    fontFamily: "JosefinSans-Light",
    textAlign: "center",
  },
  customText: {
    width:
      actualWidth > 1000
        ? wp("75%")
        : actualWidth > 400
        ? wp("85%")
        : wp("90%"),
    fontSize: actualWidth > 600 ? 40 : actualWidth > 400 ? 24 : 16,
    fontWeight: 300,
    textAlign: "center",
    color: "#575f69",
    marginBottom: actualWidth > 700 ? hp("4%") : hp("2%"),
    fontFamily: "JosefinSans-Light",
    letterSpacing: actualWidth > 600 ? 4 : actualWidth > 400 ? 2.4 : 1.6,
  },
  Line: {
    width: actualWidth > 400 ? wp("35%") : wp("30%"),
    height: 1,
    backgroundColor: "#d4d4d4",
    marginBottom: actualWidth > 700 ? hp("4%") : hp("2%"),
  },
});
