import React, {useRef, useLayoutEffect, useContext, useState} from 'react';
import {StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Animated} from 'react-native';
import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import {ActivityContext} from '../../../Activities';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import {buttonText, DRAG_TEXT} from '../../../../Utils/static-strings';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
  root: {
    color: '#575f69', height: 2, padding: '15px 0',
  }, thumb: {
    height: 50,
    width: 50,
    backgroundColor: '#ff8d53',
    boxShadow: iOSBoxShadow,
    marginTop: -28,
    marginLeft: -18,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)', // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  }, active: {}, valueLabel: {
    left: 'calc(-17% + 17px)', top: 17, fontSize: 20, fontWeight: '900', '& *': {
      background: 'transparent', color: '#ffffff',
    },
  }, track: {
    height: 2,
  },

  rail: {
    height: 2, opacity: 0.5, backgroundColor: '#575f69',
  },
})(Slider);

export default function CustomizedSlider(props) {
  const animation = useRef(new Animated.Value(0)).current;
  const [hide, setHide] = useState(true);
  const [sliderValue, setSliderValue] = useState(0);
  const resultContext = useContext(ActivityContext);
  const [resindex, setResIdex] = useState(0);
  const [reRender, setReRender] = useState('0');
  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  };
  useLayoutEffect(() => {
    let check;
    let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
    if (props.moduleObject.questions[props.moduleIndex].askif) {
      if (props.moduleObject.questions[props.moduleIndex].askif.op == 'OR') {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
          moduleIdExep[0].responses.forEach((data) => {
            if (val.qid == data.qid) {
              check = props.moduleObject.questions[props.moduleIndex].askif.answers.find((cc) => cc.respid == data.respid);
            }
          })
          if (check) {
          } else {
            props.selectAnswerHandler(props.moduleObject.moduleid, props.moduleObject.questions[props.moduleIndex].qid, props.moduleObject.questions[props.moduleIndex].answers[0].respid,);
            props.setSkippedQuestion(true);
            props.Refresh();
          }
        })
      }
      if (props.moduleObject.questions[props.moduleIndex].askif.op == 'AND') {
        props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
          moduleIdExep[0].responses.forEach((data) => {
            if (val.qid == data.qid) {
              if (val.respid == data.respid) {

              } else {
                props.selectAnswerHandler(props.moduleObject.moduleid, props.moduleObject.questions[2].qid, props.moduleObject.questions[2].answers[0].respid,);
                props.Refresh();
              }
            }
          })

        })
      }
    }
    Animated.timing(animation, {
      toValue: 1, duration: 2000
    }).start(() => setHide(false));
    if (isSafari()) {
      setTimeout(() => {
        setReRender('3')
      }, 101)
    }
  }, [])

  const nextPage = (val) => {
    setHide(true);
    props.selectAnswerHandler(props.moduleObject.moduleid, props.moduleObject.questions[props.moduleIndex].qid, props.moduleObject.questions[props.moduleIndex].answers[val].respid,);
    Animated.parallel([flipAnimation]).start(() => props.Refresh());
  }

  const [selectedGroups, setSelectedGroups] = useState([]);

  const toggleGroup = (groupId) => {
    setSelectedGroups(prevSelectedGroups => {
      if (prevSelectedGroups.includes(groupId)) {
        // If the clicked group is already selected, remove it and any higher groups
        return prevSelectedGroups.filter(id => id < groupId + 1);
      } else {
        // Add all groups up to and including the clicked group
        return Array.from({length: groupId + 1}, (_, i) => i);
      }
    });
  };

  const isSelected = (groupId) => {
    return selectedGroups.includes(groupId);
  }

  const flipAnim = useRef(new Animated.Value(0)).current; // For flip

  const flipAnimation = Animated.timing(flipAnim, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });

  const flipInterpolate = flipAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'], // Flip by 360 degrees
  });

  const animatedStyle = {
    transform: [{rotateY: flipInterpolate}],
  };


  return (
      <div style={{height: '100%'}}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}>
          <div className="experience-circle-1" style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: '#ffce0b'
          }}>
            <Animated.View style={[{}, animatedStyle, {
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }]}>
              <div className="button-container" style={{maxWidth: '600px', width: '100%'}} className={'flip-element'}>
                <span style={{visibility: "hidden"}}>{reRender}</span>
                <svg width="100%" height="600" viewBox="0 0 800 800" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <circle id="path-1" cx="400" cy="400" r="400"></circle>
                  </defs>
                  <g id="experience-11segment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"/>
                    </mask>
                    <g id="Oval"/>
                    {/* Group-10 */}
                    <g onClick={() => toggleGroup(10)}
                       className={`segment-group single ${isSelected(10) ? 'selected' : ''}`}
                       id="Group-10" mask="url(#mask-2)">
                      <g transform="translate(97.5933, 16.2028)">
                        <path
                            d="M189.713681,0 L302.211356,383.130842 L0,121.902202 C48.5261454,65.7631104 113.221352,22.4601744 189.713681,0 Z"
                            className="wheel-segment" id="10" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="10-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="87.029853" y="129.6867583" fill="#A6C3FF">10+</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-9 */}
                    <g onClick={() => toggleGroup(9)}
                       className={`segment-group single ${isSelected(9) ? 'selected' : ''}`}
                       id="Group-9" mask="url(#mask-2)">
                      <g transform="translate(4.0083, 138.0557)">
                        <path
                            d="M93.6918832,2.84217094e-14 L395.467423,261.490003 L0,205.116811 C10.4718189,131.654671 41.485519,60.249356 93.6918832,2.84217094e-14 Z"
                            className="wheel-segment" id="9" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="9-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="90.6356912" y="144.159604" fill="#A6C3FF">9</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-8 */}
                    <g onClick={() => toggleGroup(8)}
                       className={`segment-group single ${isSelected(8) ? 'selected' : ''}`} id="Group-8"
                       mask="url(#mask-2)">
                      <g transform="translate(-16.8162, 343.0741)">
                        <path
                            d="M20.8876187,1.42108547e-14 L416.127798,56.8265379 L52.963635,223.209168 C22.0563679,155.747325 9.54207636,78.9099802 20.8876187,1.42108547e-14 Z"
                            className="wheel-segment" id="8" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="8-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="84.8737793" y="118.9564617" fill="#A6C3FF">8</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-7 */}
                    <g onClick={() => toggleGroup(7)}
                       className={`segment-group single ${isSelected(7) ? 'selected' : ''}`} id="Group-7"
                       mask="url(#mask-2)">
                      <g transform="translate(36.1472, 400.2885)">
                        <path
                            d="M363.220338,-2.84217094e-14 L147.659975,336.311315 C85.1865344,296.268534 33.1174655,238.3946 0,165.877465 L363.220338,-2.84217094e-14 Z"
                            className="wheel-segment" id="7" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="7-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="107.547541" y="227.027704" fill="#A6C3FF">7</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-6 */}
                    <g onClick={() => toggleGroup(6)}
                       className={`segment-group single ${isSelected(6) ? 'selected' : ''}`} id="Group-6"
                       mask="url(#mask-2)">
                      <g transform="translate(183.7437, 400.5832)">
                        <path
                            d="M215.880553,0 L216.362888,399.465547 C142.15815,399.554801 67.0658823,379.018812 0,335.918187 L215.880553,0 Z"
                            className="wheel-segment" id="6" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="6-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="113.406783" y="324.158335" fill="#A6C3FF">6</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-5 */}
                    <g onClick={() => toggleGroup(5)}
                       className={`segment-group single ${isSelected(5) ? 'selected' : ''}`} id="Group-5"
                       mask="url(#mask-2)">
                      <g transform="translate(388.6846, 400.696)">
                        <path
                            d="M11.3154153,0 L227.68702,335.78896 C165.310554,375.982172 91.0365369,399.304 11.3154153,399.304 L11.3154153,0 Z"
                            className="wheel-segment" id="5" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="5-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="84.8737793" y="323.769924" fill="#A6C3FF">5</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-4 */}
                    <g onClick={() => toggleGroup(4)}
                       className={`segment-group single ${isSelected(4) ? 'selected' : ''}`} id="Group-4"
                       mask="url(#mask-2)">
                      <g transform="translate(400.3752, 400.5842)">
                        <path
                            d="M0,2.84217094e-14 L363.565416,165.506138 C332.821064,233.041868 282.946602,292.816806 215.881101,335.917187 L0,2.84217094e-14 Z"
                            className="wheel-segment" id="4" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="4-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="226.333648" y="225.977308" fill="#A6C3FF">4</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-3 */}
                    <g onClick={() => toggleGroup(3)}
                       className={`segment-group single ${isSelected(3) ? 'selected' : ''}`} id="Group-3"
                       mask="url(#mask-2)">
                      <g transform="translate(400.6305, 342.9616)">
                        <path
                            d="M395.330847,1.42108547e-14 C405.979937,73.4367251 396.339726,150.687273 363.222258,223.204412 L0,57.3259463 L395.330847,1.42108547e-14 Z"
                            className="wheel-segment" id="3" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="3-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="301.198608" y="118.0786648" fill="#A6C3FF">3</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-2 */}
                    <g onClick={() => toggleGroup(2)}
                       className={`segment-group single ${isSelected(2) ? 'selected' : ''}`} id="Group-2"
                       mask="url(#mask-2)">
                      <g transform="translate(400.6866, 137.9436)">
                        <path
                            d="M301.580228,2.84217094e-14 C350.241593,56.0215321 383.896452,126.220634 395.241974,205.130476 L-5.68434189e-14,261.957014 L301.580228,2.84217094e-14 Z"
                            className="wheel-segment" id="2" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="2-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="276.318355" y="148.443913" fill="#A6C3FF">2</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-1 */}
                    <g onClick={() => toggleGroup(1)}
                       className={`segment-group single ${isSelected(1) ? 'selected' : ''}`} id="Group-1"
                       mask="url(#mask-2)">
                      <g transform="translate(400.5237, 16.1264)">
                        <path
                            d="M112.081177,0 C183.305158,20.8200132 249.569836,61.6800413 301.77612,121.929304 L0,383.419308 L112.081177,0 Z"
                            className="wheel-segment" id="1" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="1-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="158.215226" y="132.2266967" fill="#A6C3FF">1</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Group-0 */}
                    <g onClick={() => toggleGroup(0)}
                       className={`segment-group single ${isSelected(0) ? 'selected' : ''}`} id="Group-0"
                       mask="url(#mask-2)">
                      <g transform="translate(287.1912, -0.1043)">
                        <path
                            d="M0,16.2906321 C71.1736695,-4.70114809 149.009793,-6.15293641 225.50185,16.3071579 L113.004479,399.438 L0,16.2906321 Z"
                            className="wheel-segment" id="0" strokeOpacity="0.495641322" stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"/>
                        <text className="wheel-segment-text" id="0-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-Bold, Open Sans" fontSize="53" fontWeight="bold">
                          <tspan x="97.1826061" y="97.1043474" fill="#A6C3FF">0</tspan>
                        </text>
                      </g>
                    </g>
                    {/* Center-11 */}
                    <g id="Center-11" mask="url(#mask-2)">
                      <g transform="translate(160, 160)">
                        <circle id="Oval" fillOpacity="0.202866348" fill="#000000" cx="240"
                                cy="240" r="240"></circle>
                        <circle id="Oval" fill="#ffce0b" fillRule="evenodd" cx="240" cy="240" r="222"></circle>
                        <text id="How-many-years-have" fill="none" fontFamily="OpenSans-Regular, Open Sans"
                              fontSize="29" fontWeight="normal">
                          <tspan x="109.7714844" y="193" fill="#000000b0">I've been trading or</tspan>
                          <tspan x="105.7714844" y="232" fill="#000000b0">investing in financial</tspan>
                          <tspan x="90.7714844" y="271" fill="#000000b0">products for this many</tspan>
                          <tspan x="205.7714844" y="310" fill="#000000b0">years</tspan>

                        </text>
                      </g>
                    </g>
                  </g>
                </svg>
                {selectedGroups.length > 0 && (

                    <button
                        className="button experience"
                        onClick={() => nextPage(selectedGroups.length - 1)} // Replace with your actual click handler
                    >
                      Continue
                    </button>)}
              </div>
            </Animated.View>
          </div>
        </div>
      </div>);
};
const styles = StyleSheet.create({
  content: {
    height: '100vh', width: '100vw', flex: 1, justifyContent: 'center', alignItems: 'center'
  }, customText: {
    position: "relative",
    width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
    Height: hp('24.3%'),
    fontSize: Dimensions.get('screen').width > 1300 ? 24 : 20,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#575f69',
    fontFamily: 'JosefinSans-Light',
    lineHeight: 26.5,
    letterSpacing: 0.8,
  }, dragText: {
    fontSize: 16,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#575f69',
    fontFamily: 'JosefinSans-Light',
    lineHeight: 'normal',
    letterSpacing: 1.6,
    marginTop: hp("1%"),
    marginBottom: hp("3%"),
  }, continueText: {
    fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'JosefinSans-Bold',
    lineHeight: 'normal',
    letterSpacing: 1.6,
    marginRight: wp("0.5%"),
    marginLeft: wp('1.4%')
  }, slider: {
    width: Dimensions.get('screen').width > 1300 ? wp('30%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('80%')
  }, button: {
    // width: Dimensions.get('screen').width > 850 ? 192 : 141,
    height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#575f69",
    backgroundColor: "#575f69",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'row',
    textTransform: "uppercase",

    // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
  }, arrow: {
    marginRight: wp('1.4%'),
    marginLeft: wp('0.5%'),
    alignSelf: 'center',
    width: Dimensions.get('screen').width > 850 ? 22 : 16,
    height: Dimensions.get('screen').width > 850 ? 22 : 16,
    resizeMode: "contain",
  }
})
