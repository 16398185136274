import React, { useEffect, useState, useRef } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Easing,
    Text,
    TouchableOpacity
} from 'react-native';
import Cards from './Card';

const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {

    const animated1 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animated2 = useRef(new Animated.Value(Dimensions.get('screen').width)).current;
    const animatedValue = useRef(new Animated.Value(0)).current;
    const animatedValue2 = useRef(new Animated.Value(0)).current;
    const [showUpAnimation, setShowUpAnimation] = useState(false);
    const [showDownAnimation, setShowDownAnimation] = useState(false);
    const [showTrueAnimation, setShowTrueAnimation] = useState(true);
    const [showFalseAnimation, setShowFalseAnimation] = useState(true);
    const [answerId, setanswerId] = useState(2);
    const [hide, setHide] = useState(true);
    const [restrict, setRestrict] = useState(true);
    const animateLeft = (i) => {
        setanswerId(i);
        animated1.setValue(-Dimensions.get('screen').width / 4.2);
        animatedValue.setValue(0);
        animated2.setValue(Dimensions.get('screen').width / 2);
        Animated.parallel([
            Animated.timing(animated1, {
                toValue: 0,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(animated2, {
                toValue: Dimensions.get('screen').width,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 400,
                easing: Easing.linear
            }).start()
        ]).start(() => {
            setRestrict(true);
            setShowUpAnimation(false)
            setShowDownAnimation(true)
        });
    };
    const animateRight = (i) => {
        setanswerId(i);
        animated1.setValue(35);
        animated2.setValue(Dimensions.get('screen').width / 4);
        animatedValue2.setValue(0);
        Animated.parallel([
            Animated.timing(animated1, {
                toValue: -Dimensions.get('screen').width,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(animated2, {
                toValue: 0,
                duration: 400,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(animatedValue2, {
                toValue: 1,
                duration: 400,
                easing: Easing.linear
            }).start()
        ]).start(() => {
            setRestrict(true);
            setShowUpAnimation(false)
            setShowDownAnimation(true)
        });
    };

    useEffect(() => {
        if (props.moduleIndex < 1) {
            animated1.setValue(-Dimensions.get('screen').width);
            animated2.setValue(Dimensions.get('screen').width);
            Animated.parallel([
                Animated.timing(animated1, {
                    toValue: 0,
                    duration: 400,
                    easing: Easing.linear,
                    useNativeDriver: true,
                }),
                Animated.timing(animated2, {
                    toValue: 0,
                    duration: 400,
                    easing: Easing.linear,
                    useNativeDriver: true,
                }),
            ]).start(() => {
                setHide(false)
                setShowUpAnimation(true)
            });
        } else {
            if (props.page == 'FALSE') {
                animated1.setValue(0);
                animated2.setValue(-25);
                animatedValue2.setValue(1);
                Animated.parallel([
                    Animated.timing(animated2, {
                        toValue: 0,
                        duration: 500,
                        easing: Easing.linear,
                        useNativeDriver: true,
                    }),
                    Animated.timing(animatedValue2, {
                        toValue: 0,
                        duration: 500,
                        easing: Easing.linear
                    })
                ]).start(() => {
                    setHide(false)
                    setShowUpAnimation(true)
                })
            }
            if (props.page == 'TRUE') {
                animated1.setValue(25);
                animatedValue.setValue(1)
                animated2.setValue(0);
                Animated.parallel([
                    Animated.timing(animated1, {
                        toValue: 0,
                        duration: 400,
                        easing: Easing.linear,
                        useNativeDriver: true,
                    }),
                    Animated.timing(animatedValue, {
                        toValue: 0,
                        duration: 400,
                        easing: Easing.linear
                    })
                ]).start(() => {
                    setHide(false)
                    setShowUpAnimation(true)
                })
            }

        }
    }, []);
    return (
        <View style={styles.container}>

            <View style={styles.subContainer}>


                <Animated.View style={[styles.trueContainer,
                { transform: [{ translateX: animated1 }] }, showTrueAnimation ? null : { width: '100%' },
                showFalseAnimation ? null : { width: '0%' }
                ]}>
                    <TouchableOpacity style={styles.trueTocuh} disabled={hide} onPress={() => {
                        if (restrict === false) {
                            setShowTrueAnimation(false)
                            setHide(true)
                            animateLeft(0)
                        }
                    }}>

                        <Animated.Text style={[styles.trueText, {
                            transform: [
                                {
                                    scale: animatedValue.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [1, 2]
                                    })
                                },
                            ]
                        }]}>{props.Questions[props.indexOfset].answers[0].text}</Animated.Text>

                    </TouchableOpacity>
                </Animated.View>

                <Animated.View style={[styles.falseContainer,
                { transform: [{ translateX: animated2 }] }, showTrueAnimation ? null : { width: '0%' },
                showFalseAnimation ? null : { width: '100%' }
                ]}>
                    <TouchableOpacity style={styles.falseTocuh} disabled={hide} onPress={() => {
                        if (restrict === false) {
                            setShowFalseAnimation(false)
                            setHide(true)
                            animateRight(1)
                        }
                    }}>

                        <Animated.Text style={[styles.falseText, {
                            transform: [
                                {
                                    scale: animatedValue2.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [1, 2]
                                    })
                                },
                            ]
                        }]}>{props.Questions[props.indexOfset].answers[1].text}</Animated.Text>

                    </TouchableOpacity>
                </Animated.View>

            </View>

            {showUpAnimation ? <Cards flow="dow" setRestrict={setRestrict} {...props} answerId={answerId} /> : null}
            {showDownAnimation ? <Cards flow="up" setRestrict={setRestrict} {...props} answerId={answerId} /> : null}

        </View>
    );
}
export default Index;

const styles = StyleSheet.create({

    container: {
        flex: 1,
        justifyContent: 'center',
    },

    subContainer: {
        flexDirection: "row"
    },

    trueContainer: {
        // flex: 1,
        width: '50vw',
        justifyContent: 'center',
        alignItems: 'center',
        height: '99.5vh',
        backgroundColor: "#eeeeee"
    },

    falseContainer: {
        // flex: 1,
        width: '50vw',
        justifyContent: 'center',
        alignItems: 'center',
        height: '99.5vh',
        backgroundColor: "#575f69"
    },

    trueTocuh: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    falseTocuh: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    trueText: {
        position: 'relative',
        top: 100,
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth <= 320 ? 24 : 35,
        color: '#575f69',
        textTransform: 'uppercase'
    },

    falseText: {
        position: 'relative',
        top: 100,
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth <= 320 ? 24 : 35,
        color: '#eeeeee',
        textTransform: 'uppercase'
    },
})