import React from 'react'
import { useDrag } from 'react-dnd'
import { StyleSheet, Dimensions, TouchableOpacity } from 'react-native'
import ImagePreview from './imageSelector';
const actualWidth = Dimensions.get('screen').width;

const DragGames = (props) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'balls',
        item: { ...props },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),

    })
    return (
    <>
        <TouchableOpacity onPress={() => props.onClick(props)} style={[styles.balls, { opacity: isDragging ? 0 : 1 }]}>
             <ImagePreview iconKey={props.iconKey} />
        </TouchableOpacity>
    </>
    )
}
export default DragGames;
const styles = StyleSheet.create({
    balls: {
        width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
        height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
        borderRadius: actualWidth > 600 ? 57 : actualWidth > 400 ? 62 : 31,
        elevation: 4,
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        marginVertical: 10,
    },
})
