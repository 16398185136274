import React, { useLayoutEffect, useState, useRef, useContext } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import { ActivityContext } from '../../../Activities';
const Index = (props) => {
    const animation = useRef(new Animated.Value(0)).current;
    const [buttonColor, setButtonColor] = useState("#ffb91d");
    const [colorset, setColorset] = useState(0);
    const [hide, setHide] = useState(true);
    const resultContext = useContext(ActivityContext);
    const Color = (index) => {
        setHide(true);
        setColorset(index);
        setButtonColor("#ea9550");
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[index - 1].respid,
        );
    }
    useLayoutEffect(() => {
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        if (props.moduleObject.questions[props.moduleIndex].askif) {
            if (props.moduleObject.questions[props.moduleIndex].askif.op == 'OR') {
                props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            if (val.respid == data.respid) {
                            } else {
                                props.selectAnswerHandler(
                                    props.moduleObject.moduleid,
                                    props.moduleObject.questions[props.moduleIndex].qid,
                                    props.moduleObject.questions[props.moduleIndex].answers[0].respid,
                                );
                                props.setSkippedQuestion(true);
                                props.Refresh();
                            }
                        }
                    })

                })
            }
        }
        if (buttonColor == '#ea9550') {
            nextPage();
        }
    }, [buttonColor])

    useLayoutEffect(() => {
        Animated.timing(animation, {
            toValue: 1,
            duration: 1000
        }).start(() => setHide(false));
    }, [])

    const nextPage = () => {
        Animated.timing(animation, {
            toValue: 0,
            duration: 1000
        }).start(() => props.Refresh());
    }

    return (
        <Animated.View style={[styles.content, { opacity: animation }]}>
            <Text style={styles.customText}>
                {props.moduleObject.questions[1].text}
            </Text>
            <View style={styles.buttongroup}>
                <View>
                    <TouchableOpacity
                        onPress={() => { Color(1) }}
                        disabled={hide}
                        style={[styles.button, { borderColor: (colorset == 1 ? buttonColor : "#ffb91d"), backgroundColor: (colorset == 1 ? buttonColor : "#ffb91d") }]}>
                        <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[0].text}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ margin: 5 }}></View>
                <View>
                    <TouchableOpacity
                        onPress={() => { Color(2) }}
                        disabled={hide}
                        style={[styles.button, { borderColor: (colorset == 2 ? buttonColor : "#ffb91d"), backgroundColor: (colorset == 2 ? buttonColor : "#ffb91d") }]}>
                        <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[1].text}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ margin: 5 }}></View>
                <View>
                    <TouchableOpacity
                        onPress={() => { Color(3) }}
                        disabled={hide}
                        style={[styles.button, { borderColor: (colorset == 3 ? buttonColor : "#ffb91d"), backgroundColor: (colorset == 3 ? buttonColor : "#ffb91d") }]}>
                        <Text style={styles.buttontext}>{props.moduleObject.questions[1].answers[2].text}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('42%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1000 ? 24 : (Dimensions.get('screen').width < 350 ? 16 : 22),
        textAlign: 'center',
        color: '#2f2f2f',
        marginBottom: hp("4%"),
        fontFamily: 'Manrope-SemiBold',
        lineHeight: Dimensions.get('screen').width > 1000 ? 32 : (Dimensions.get('screen').width < 350 ? 24 : 26),
        fontWeight: "600"
    },
    button: {
        width: Dimensions.get('screen').width > 1000 ? 144 : (Dimensions.get('screen').width < 350 ? 88 : 114),
        height: Dimensions.get('screen').width > 1000 ? 144 : (Dimensions.get('screen').width < 350 ? 88 : 114),
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1000 ? 72 : (Dimensions.get('screen').width < 350 ? 44 : 57),
        borderStyle: "solid",
        borderColor: "#ffb91d",
        backgroundColor: "#ffb91d",
        alignItems: "center",
        justifyContent: "center",
    },
    buttontext: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: Dimensions.get('screen').width > 1000 ? 20 : (Dimensions.get('screen').width < 350 ? 11.3 : 12),
        textAlign: 'center',
        color: '#2f2f2f',
        fontWeight: '600',
        lineHeight: Dimensions.get('screen').width > 1000 ? 24 : (Dimensions.get('screen').width < 350 ? 14 : 20.5),
        padding: Dimensions.get('screen').width > 1000 ? 17 : (Dimensions.get('screen').width < 350 ? 10 : 10)
    },
    buttontext2: {
        // width: 110,
        // height: 67,
        fontFamily: 'Manrope-SemiBold',
        fontSize: Dimensions.get('screen').width > 1300 ? 17 : 16,
        textAlign: 'center',
        // lineHeight: 26.5,
        letterSpacing: 1,
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#2f2f2f',
    },
    buttongroup: {
        flexDirection: 'row'
    },

})