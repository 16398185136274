import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import DotDesign from '../../../../Components/DotDesign/index';

const Index = ({ style, innerStyle, data, text, thread, valueStyle }) => {
    const amount = data.split(' ')[0];
    const value = data.replace(amount, '');
    return (

        <View style={style}>
            {/* <Text style={valueStyle}>{amount}</Text> */}
            <Text style={innerStyle}>{data}</Text>
            <DotDesign thread={thread} />
        </View>

    );
};

export default Index;

const styles = StyleSheet.create({
})
