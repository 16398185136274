import React, {useEffect, useState} from "react";
import {View, Text, TouchableOpacity, Dimensions, StyleSheet} from "react-native";
import CircularPie from "../../../../Components/CircularPie";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "../../../../Utils/responsive";
import '../../../../../src/App.css';

const actualWidth = Dimensions.get('screen').width;

const LossSecondPage = (props) => {
  const [disable, setDisable] = useState(true);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [height, setHeight] = useState(Dimensions.get('window').height);
  const testingGroup = props.testingGroup
  // Set initial dimensions
  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));

  // Handle dimension changes
  useEffect(() => {
    const onChange = ({ window }) => {
      setScreenDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);
  const dynamicStyles = StyleSheet.create({
    customText: {
      letterSpacing: screenDimensions.height <= 479 ? 1.6 : screenDimensions.width <= 767 ? 2 : screenDimensions.width <= 991 ? 2.4 : 3.2,
      fontSize: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 16 : 24) : screenDimensions.width <= 767 ? 20 : screenDimensions.width <= 991 ? 24 : 32,
      lineHeight: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 21 : 29) : screenDimensions.width <= 767 ? 24 : screenDimensions.width <= 991 ? 29 : 38,
      marginTop: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      marginBottom: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      // Other styles that do not depend on dimensions can remain static
      position: "relative",
      height: 'auto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      color: 'rgba(87, 95, 105, 0.75)',
      width: '100%',
      maxWidth: 680,
      fontFamily: 'JosefinSans-Light',
    },
  });
  const header = testingGroup === 'A' ? 'Imagine your investment for the next 6 months, here the probability of a gain or a loss are equal.' : testingGroup === 'B' ? 'Imagine your investment for the next 6 months, where the probability of a gain or a loss are equal.' : 'Imagine your investment for the next 6 months, where the probability of a gain or a loss are equal.'
  const footer = testingGroup === 'A' ? 'In this example, there’s a 50% chance of losing £10 (1%), but also a 50% chance of gaining £80 (8%).' : testingGroup === 'B' ? 'In this example,\n' +
      'there\'s a 50% chance of losing 1%, but also a 50% chance of gaining 8%.' : `In this example\n there’s a 50% chance of losing 1%,\n but also a 50% chance of gaining 8%.`
  return (
      <View style={styles.content}>
        <Text style={dynamicStyles.customText}>
          {header}
        </Text>
        <CircularPie
            activeColor="#79a2d9"
            passiveColor="#4B73A8"
            baseColor="white"
            width={70}
            done={40}
            setDisable={setDisable}
            gain={80}
            showAnimation={false}
            loss={10}
            radius={screenDimensions.height < 700 || screenDimensions.width < 600 ? 110 : 155}
            duration={600}
            hideIndicator={testingGroup !== 'A'}
            showPercentage={true}
            showAmount={false}
            hideBracket={true}
            isGain={testingGroup !== 'A'}
            useBiggerFont={!(width < 992 || height < 1080)}
        />

        <Text style={dynamicStyles.customText}>
          {footer}
        </Text>

        <TouchableOpacity
            onPress={props.showDescriptionPage}
            style={styles.button}
        >
          <Text style={styles.buttontext}>OK</Text>
        </TouchableOpacity>
      </View>
  );
};

export default LossSecondPage;

export const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '99vh',
    width: '100vw',
    //marginBottom: '15vh'
  },
  heading: {
    position: "relative",
    width: wp("60%"),
    resizeMode: "contain",
    height: hp("10%"),
  },
  customText: {
    position: "relative",
    // width: actualWidth > 500 ? wp('100%') : actualWidth < 320 ? wp('90%') : wp('95%'),
    height: 'auto',
    // lineHeight: 22,
    // fontSize: 20,
    fontWeight: 'normal',
    fontStyle: 'normal',
    // letterSpacing: 0.5,
    textAlign: 'center',
    color: 'rgba(87, 95, 105, 0.75)',
    // fontFamily: "JosefinSans-Regular",
    width: '100%',
    maxWidth: 680,
    // height: 212,
    fontSize: Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400 ? 20 : 16,
    fontFamily: 'JosefinSans-Light',
    letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400 ? 2 : 1.6,
    padding: '17px'
  },
  buttontext: {
    fontFamily: 'JosefinSans-Bold',
    fontSize: 20,
    paddingTop: 15,
    width: "100%",
    textAlign: "center",
    height: "100%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
    color: '#ffffff',
  },
  button: {
    // backgroundColor: '#ee7998',
    backgroundColor: '#575f69',
    position: "relative",
    width: 68,
    minHeight: 48,
    textAlignVertical: "center",
    borderRadius: 2
  },

});
