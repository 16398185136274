import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Image,
    Easing,
    Text,
    TouchableHighlight
} from 'react-native';

const Index = (props) => {

    return (
        <View style={styles.container}>

            <Text style={styles.content}>Now, answer the following questions with <Text style={{ fontFamily: "JosefinSans-Bold" }}>True</Text> or <Text style={{ fontFamily: "JosefinSans-Bold" }}>False</Text>,{`\n`}
             and let us know how sure you are.{`\n`}{`\n`}If you don’t know, take a guess!</Text>

            <View style={styles.buttonContainer}>
                <TouchableHighlight style={styles.button}
                    onPress={() => { props.navigation() }}>

                    <Text style={styles.buttonText}>OK, GOT IT!</Text>

                </TouchableHighlight>
            </View>

        </View>
    );
}
export default Index;

const styles = StyleSheet.create({

    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    content: {
        textAlign: 'center',
        fontFamily: 'JosefinSans-Thin',
        fontSize: 32,
        fontWeight: '300',
        letterSpacing: 3.2,
    },

    button: {
        height: 'auto',
        width: 'auto',
        backgroundColor: '#575f69',
        borderRadius: 3,
        justifyContent: 'center',
        paddingHorizontal: 15,
        paddingVertical: 10
    },

    buttonText: {
        fontFamily: 'JosefinSans-Regular',
        fontSize: 16,
        textAlign: 'center',
        letterSpacing: 1.5,
        color: '#ffffff',
    },

    buttonContainer: {
        top: 25
    }
})