import React, { useEffect } from 'react';
import { 
  // widthPercentageToDP as wp, heightPercentageToDP as hp, screenWidth as windowWidth 
  screenWidth as actualWidth , screenHeight as actualHeight
} from '../../Utils/responsive';
import '../../App.css'
import { inIframe } from '../../Utils/helper';
const {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  PixelRatio
} = require('react-native');

export const TriangleCorner = (props) => {
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);
   
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  return (
    <View>
      <View style={[styles.rectangle,{
         marginLeft:inIframe() ? 23: wp('2%'),
         width: inIframe() ? 708:  screenWidth > 700 ? screenWidth > 1200 ? screenWidth <= 1125 ? wp('40%') : wp('30%') : wp('60%') : wp('95%'),}]}>
        <Text style={[styles.triangleCornerText,{
           fontSize:   inIframe() ? 22: hp('2.7%'),
           lineHeight:   inIframe() ? 28: hp('3.4%'),
        }]}>{props.test}</Text>
      </View>
      <View style={[styles.triangleCorner,{
        marginLeft:inIframe() ? 47: screenWidth > 760 ? wp('4%') : wp('6%'),
        width:inIframe() ?0: hp('0%'),
        height:inIframe() ? 0 : hp('0%'),
        borderRightWidth:inIframe() ? 71: screenWidth > 1200 ? wp('2%') : wp('6%'),
        borderTopWidth:inIframe() ? 71: screenWidth > 1200 ? wp('2%') : wp('6%'),
        marginBottom:inIframe() ? 16.5: hp('2%'),
        }]} />
    </View>
  );
};

export const Button = (props) => {
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);
   
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  return (
    <TouchableOpacity
      style={[styles.buttonQuestion,{ 
        marginTop:   inIframe() ? 8: hp('1%'),
         marginLeft:   inIframe() ? 23.5: wp('2%'),
    }]}
      onPress={() => {
        props.selectAnswerHandler(
          props.moduleObject.moduleid,
          props.moduleObject.questions[props.moduleIndex].qid,
          props.moduleObject.questions[props.moduleIndex].answers[props.i]
            .respid,
        );
      }}>
      <Text style={styles.buttonTextQuestion}>
        {props.moduleObject.questions[props.moduleIndex].answers[props.i].text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  triangleCorner: {
    
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightColor: 'transparent',
    borderTopColor: '#ffffff',
    
  },
  rectangle: {
   
    backgroundColor: '#ffffff',
    borderRadius: '9px',
  },
  buttonQuestion: {
    alignSelf: 'flex-start',
    width: 'auto',
    borderRadius: '22px',
    backgroundColor: '#ffffff',
   
  },
  buttonTextQuestion: {
    paddingHorizontal: 20,
    paddingVertical: 9,
    color: '#a81c1b',
    fontSize: '19px',
    textAlign: 'center',
    fontFamily: 'OpenSans-Regular',
  },
  triangleCornerText: {
    padding: 25,
    fontFamily: 'OpenSans-Bold',
    color: '#000000',
    fontWeight: 'bold',
    fontStyle: 'normal',
   
  },
});
