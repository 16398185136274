import { StyleSheet, Dimensions } from 'react-native';
import '../../../../../../App.css'

const actualWidth = Dimensions.get('window').width;
const backgroundColor = '#cccbe3';

export const styles = StyleSheet.create({
    container: {
        height: '100vh',
        width: '100vw',
        display: "flex",
        justifyContent: "space-between",
        backgroundColor,
        transform: [{ scale: 1 }],
        // borderRadius:"50%"
    },
    body: {
        height: "80%",
        width: "100%",
        justifyContent: "center",
        marginTop: actualWidth < 350 ? 10 : 0
        // alignItems: "center",
    },
    bodyHeader: {
        // justifyContent: "center",
        // alignItems: 'center',
        marginLeft: "7%"
    },
    headerText: {
        // textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: actualWidth > 600 ? 60 : 40,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: actualWidth > 600 ? 68 : 48,
        textTransform: "uppercase"
    },
    bodyTextContainer: {
        justifyContent: "center",
        // alignItems: 'center',
        width: actualWidth > 900 ? actualWidth > 700 ? "50%" : "40%" : actualWidth > 600 ? "72%" : "90%",
        // alignSelf: 'center',
        marginVertical: actualWidth > 1000 ? "1.5%" : actualWidth > 800 ? "3.5%" : '4%',
        // marginBottom: '3%',
        marginLeft: "7%"
    },
    bodyText: {
        // textAlign: "center",
        fontFamily: 'Manrope-Regulars',
        fontSize: 16,
        color: '#2f2f2f',
        lineHeight: 24,
        fontStyle: "normal",
    },
    button: {
        height: 40,
        width: 115,
        backgroundColor: "#2f2f2f",
        justifyContent: "center",
        alignItems: 'center',
        marginLeft: "7%",
        // marginTop: actualWidth <550 ? 20 : 0
    },
    buttonText: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        fontWeight: "600",
        color: '#ffffff',
        lineHeight: 24
    },
    footer: {
        height: "15%",
        width: "100%",
        bottom: actualWidth < 1000 ? actualWidth < 800 ? -24 : -12 : 0,
        right: actualWidth < 1000 ? actualWidth < 800 ? -150 : -70 : 0
        // justifyContent: "space-between",
        // alignItems: "center",
        // marginTop: actualWidth < 600 ? 14 : 0,
    },
    footerCircle: {
        height: 300,
        width: 300,
        borderTopLeftRadius: 300,
        borderTopRightRadius: 300,
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        marginBottom: 50
    },
    footerAnimatedContainer: {
        position: 'absolute',
        bottom: 0
    }

})
