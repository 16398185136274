import React, { useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  PixelRatio,
} from "react-native";
import {
  // heightPercentageToDP as hp,
  screenWidth as actualWidth,
  screenHeight as actualHeight,
} from "../../../../Utils/responsive";
import "../../../../App.css";
import { inIframe } from "../../../../Utils/helper";
// const actualWidth = Dimensions.get("window").width;
const Index = (props) => {
  const [screenWidth, setScreenWidth] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight] = React.useState(actualHeight);

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth(t.window.width);
    setScreenHeight(t.window.height);
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  return (
    <View
      style={[
        styles.Container,
        {
          height:
            screenWidth > 1300 ? "100vh" : screenWidth > 760 ? "90vh" : "85vh",
        },
      ]}
    >
      <View style={{ marginBottom: inIframe() ? 16.5 : hp("2%") }}>
        <Text
          style={[
            styles.text,
            {
              marginLeft:inIframe() ? 23.5: wp('2%'),
              fontSize: inIframe() ? 18 : hp("2.5%"),
              marginBottom: inIframe() ? 16.5 : hp("2%"),
            },
          ]}
        >
          Let's talk about risk.{`\n`}
          {`\n`}We wish we could tell you that investing is a sure{`\n`}fire way
          to make money but, of course, we can't.{`\n`}
          {`\n`}Although investing over the long term may{`\n`}reduce the risk,
          it is always possible you{`\n`}will lose some money on your
          investments.{" "}
        </Text>
        <TouchableOpacity style={styles.button} onPress={() => props.Refresh()}>
          <Text style={styles.buttonText}>OK</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Index;

const styles = StyleSheet.create({
  Container: {
    width: "100vw",
    // flex: 1,
    // justifyContent: 'flex-end',
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    // position: 'relative',
    // alignSelf: 'flex-start',
    alignSelf: "center",
    width: 191,
    borderRadius: "22px",
    backgroundColor: "#ffffff",
    // marginLeft: wp('2%'),
  },
  buttonText: {
    color: "#a81c1b",
    fontSize: "19px",
    textAlign: "center",
    fontFamily: "OpenSans-Regular",
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  text: {
    position: "relative",
    alignSelf: "center",
    width: "auto",
    color: "#ffffff",
    fontWeight: "400",

    // marginLeft: wp('2%'),
    fontFamily: "OpenSans-Regular",
  },
});
