import { StyleSheet, Dimensions } from 'react-native';
import '../Styles.css'

const actualWidth = Dimensions.get('window').width;
const backgroundColor = '#bbddf2';

export const styles = StyleSheet.create({
    container: {
        height: '100vh',
        width: '100vw',
        display: "flex",
        justifyContent: "space-between",
        backgroundColor,
        transform: [{ scale: 1 }],
        // borderRadius:"50%"
    },
    body: {
        height: "80%",
        width: "100%",
        justifyContent: "center",
    },
    bodyHeader: {
        // justifyContent: "center",
        // alignItems: 'center',
        marginLeft: actualWidth > 850 ? "8.5%" : "7%"
    },
    headerText: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: actualWidth > 600 ? 60 : actualWidth > 600 ? 48 : 40,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: actualWidth > 600 ? 68 : actualWidth > 600 ? 52 : 48,
        textTransform: "uppercase"
    },
    bodyTextContainer: {
        justifyContent: "center",
        // alignItems: 'center',
        width: actualWidth > 600 ? "60%" : "90%",
        marginVertical: actualWidth > 550 ? "2.2%" : "4%",
        marginLeft: actualWidth > 850 ? "8.5%" : "7%",
        // marginTop : actualWidth>550 ? "2.2%": actualWidth>380 ? "5%" : "4%",
    },
    bodyText: {
        // textAlign: "center",
        fontFamily: 'Manrope-Regulars',
        fontSize: 16,
        color: '#2f2f2f',
        lineHeight: 24,
        fontStyle: "normal",
        width: "100%"
    },
    button: {
        height: 40,
        width: 115,
        backgroundColor: "#2f2f2f",
        justifyContent: "center",
        alignItems: 'center',
        marginLeft: actualWidth > 850 ? "8.5%" : "7%",
        marginTop: actualWidth > 600 ? 0 : 10
    },
    buttonText: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        fontWeight: "600",
        color: '#ffffff',
        lineHeight: 24,
    },
    footer: {
        height: "15%",
        width: "100%",
        bottom: actualWidth < 1000 ? actualWidth < 800 ? -24 : -12 : 0,
        right: actualWidth < 1000 ? actualWidth < 800 ? -150 : -70 : 0
    },
    footerCircle: {
        height: 300,
        width: 300,
        borderTopLeftRadius: 300,
        borderTopRightRadius: 300,
        // borderRadius: 300,
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        marginBottom: 50
    }

})
