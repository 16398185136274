import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, ScrollView, Animated } from 'react-native';
import ClearIcon from '@material-ui/icons/Clear';
import ScoreView from './scoreView';
import axios from 'axios';
import { discriptorText, moduleid } from './static-strings'
import { getReport, getReportForSession, saveResults } from './path-config';
import { addAnalytics } from '../helper';
const actualWidth = Dimensions.get('screen').width;
const deviceWidth = Dimensions.get('screen').width;


const Index = (props) => {

    const [discriptor, setDiscriptor] = useState(null);
    const [lossValue, setlossValue] = useState(0);
    const [riskValue, setriskValue] = useState(0);
    const [brsValue, setbrsValue] = useState(0);
    const [score, setscore] = useState(null);
    const [button1, setbutton1] = useState(true);
    const [button2, setbutton2] = useState(false);
    const [button3, setbutton3] = useState(false);
    const [button4, setbutton4] = useState(false);
    const [report, setReport] = useState(null);
    const [reportText, setReportText] = useState(null);
    const animation = useRef(new Animated.Value(Dimensions.get('screen').height)).current;
    useEffect(() => {
        if (report !== null) {
            Animated.timing(animation, {
                toValue: 0,
                duration: 500
            }).start();
        }
    }, [report])

    useEffect(() => {
		const url = window['runConfig'].REACT_APP_BASE_URL + getReportForSession(props.userref, props.sessionref);

        const config = {
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios(config)
            .then(function (response) {
                setReport(response.data);

            })
            .catch(function (error) {
                addAnalytics(error, config);
            });
    }, [])

    useEffect(() => {
        if (props.riskCalculation !== undefined) {
            setscore(props.riskCalculation);
            let brs = props.riskCalculation.calcresults.filter((item) => item.moduleid === moduleid.brs());
            let loss = props.riskCalculation.calcresults.filter((item) => item.moduleid === moduleid.loss());
            let risk = props.riskCalculation.calcresults.filter((item) => item.moduleid === moduleid.risk());
            setlossValue((Math.round((1 - loss[0].score) * 100)));
            setriskValue((Math.round((risk[0].score * 100))));
            setbrsValue((Math.round(brs[0].score * 100)))
            props.riskCalculation.calcresults.filter((item) => {
                if(item.moduleid === moduleid.brs()){
                    setDiscriptor(item.score);
                }
                // item.moduleid === moduleid.brs() ? setDiscriptor(item.score) : console.log("")
            })
        }
    }, [])

    const reportText1 = (id = 'none') => {
        if (id !== 'none') {
            const reportArray = report.filter((item) => {
                if (item.hasOwnProperty('moduleids')) {
                    return item.moduleids[0] === id
                }
            })
            setReportText(reportArray);
        }
    }

    const cancelAnimation = () => {
        Animated.timing(animation, {
            toValue: Dimensions.get('screen').height,
            duration: 500
        }).start(() => props.setmodal(false));
    }

    return (
        <>
            {report !== null &&
                <Animated.View style={{ height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center', flex: 1, transform: [{ translateY: animation }] }}>
                    <View style={{ height: 150, width: 700, justifyContent: 'center' }}>
                        <View style={{ alignSelf: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                cancelAnimation()
                            }}
                                style={{ position: 'absolute', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                {/* <ClearIcon color="disabled" style={{ fontSize: 51 }} /> */}
                                <View style={{
                                    borderBottomWidth: 1, borderBottomColor: 'grey', top: 1, width: 45, transform: [{ rotate: '46deg' }]
                                }} />
                                <View style={{
                                    borderBottomWidth: 1, borderBottomColor: 'grey', width: 45, transform: [{ rotate: '-46deg' }]
                                }} />
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <View >
                                <Text style={{ fontFamily: 'JosefinSans-Light', fontSize: 24, letterSpacing: 2.4, fontWeight: '300', textAlign: 'center', color: '#575f69' }}>Your investment style is</Text>
                            </View>
                            <View style={[styles.headingContainer]}>
                                <View style={styles.Line1} />
                                <Text style={styles.header}>
                                    {discriptor !== null ?
                                        (discriptor >= discriptorText.level1.min && discriptor < discriptorText.level1.max ? discriptorText.level1.name :
                                            (discriptor >= discriptorText.level2.min && discriptor < discriptorText.level2.max ? discriptorText.level2.name :
                                                (discriptor >= discriptorText.level3.min && discriptor < discriptorText.level3.max ? discriptorText.level3.name :
                                                    (discriptor >= discriptorText.level4.min && discriptor < discriptorText.level4.max ? discriptorText.level4.name :
                                                        (discriptor >= discriptorText.level5.min && discriptor <= discriptorText.level5.max ? discriptorText.level5.name : 'Out Of Limit'))))) :
                                        'Null Text'}
                                </Text>
                                <View style={styles.Line2} />
                            </View>
                        </View>
                    </View>
                    <View style={{ height: 105, width: 800, justifyContent: 'flex-end', alignItems: 'center', marginBottom: 10 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={[styles.slidernumber, { opacity: 1, textTransform: 'uppercase', marginRight: 10 }]}>Low Risk</Text>
                            <View style={{
                                width: 670, borderRadius: 88,
                                height: 32, backgroundColor: 'rgba(87, 95, 105, 0.1)', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                <Text style={[styles.slidernumber, { marginLeft: 10 }]}>0</Text>
                                <Text style={styles.slidernumber}>20</Text>
                                <Text style={styles.slidernumber}>40</Text>
                                {brsValue && <ScoreView riskvalue={riskValue} lossvalue={lossValue} score={brsValue} />}
                                <Text style={styles.slidernumber}>{(riskValue == 60 || lossValue == 60) ? '' : 60}</Text>
                                <Text style={styles.slidernumber}>{(riskValue == 80 || lossValue == 80) ? '' : 80}</Text>
                                <Text style={[styles.slidernumber, { marginRight: 10 }]}>{(riskValue == 100 || lossValue == 100) ? '' : 100}</Text>
                            </View>
                            <Text style={[styles.slidernumber, { opacity: 1, textTransform: 'uppercase', marginLeft: 10 }]}>High Risk</Text>
                        </View>
                    </View>
                    <View style={{ height: 400, width: 700, justifyContent: 'center', alignItems: 'center' }}>
                        <View style={{ height: '20%', width: 670, justifyContent: 'flex-end', borderBottomColor: 'rgba(105,105,105,0.3)', borderBottomWidth: 1 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                                <TouchableOpacity style={{ height: 48, width: 114, borderBottomWidth: button1 ? 3 : 0, justifyContent: 'center', alignItems: 'center', borderColor: '#575f69', }}
                                    onPress={() => {
                                        setbutton4(false)
                                        setbutton1(true)
                                        setbutton2(false)
                                        setbutton3(false)
                                    }}>
                                    <Text style={[{ textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontSize: 14, letterSpacing: 1.4, fontWeight: 'bold', color: '#575f69', textTransform: 'uppercase', opacity: button1 ? 1 : 0.7 }, button1 ? styles.testShadow : {}]}>Overview</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ height: 48, width: 164, borderBottomWidth: button2 ? 3 : 0, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', borderColor: '#575f69' }}
                                    onPress={() => {
                                        reportText1(moduleid.risk());
                                        setbutton2(true)
                                        setbutton4(false)
                                        setbutton1(false)
                                        setbutton3(false)
                                    }}>
                                    <View style={{ height: 8, width: 8, borderRadius: 4, backgroundColor: '#6fd5da', marginBottom: 3 }} />
                                    <Text style={[{ textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontSize: 14, letterSpacing: 1.4, fontWeight: 'bold', color: '#575f69', textTransform: 'uppercase', opacity: button2 ? 1 : 0.7 }, button2 ? styles.testShadow : {}]}>Risk Attitude</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ height: 48, width: 190, flexDirection: 'row', borderBottomWidth: button3 ? 3 : 0, justifyContent: 'space-evenly', alignItems: 'center', borderColor: '#575f69' }}
                                    onPress={() => {
                                        reportText1(moduleid.loss())
                                        setbutton3(true)
                                        setbutton4(false)
                                        setbutton1(false)
                                        setbutton2(false)
                                    }}>
                                    <View style={{ height: 8, width: 8, borderRadius: 4, backgroundColor: '#ee7998', marginBottom: 3 }} />
                                    <Text style={[{ textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontSize: 14, letterSpacing: 1.4, fontWeight: 'bold', color: '#575f69', textTransform: 'uppercase', opacity: button3 ? 1 : 0.7 }, button3 ? styles.testShadow : {}]}>Loss Tolerance</Text>
                                </TouchableOpacity>


                                <TouchableOpacity style={{ height: 48, width: 143, borderBottomWidth: button4 ? 3 : 0, justifyContent: 'center', alignItems: 'center', borderColor: '#575f69' }}
                                    onPress={() => {
                                        reportText1(moduleid.future())
                                        setbutton4(true)
                                        setbutton1(false)
                                        setbutton2(false)
                                        setbutton3(false)
                                    }}>
                                    <Text style={[{ textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontSize: 14, letterSpacing: 1.4, fontWeight: 'bold', color: '#575f69', textTransform: 'uppercase', opacity: button4 ? 1 : 0.7 }, button4 ? styles.testShadow : {}]}>Present Bias</Text>
                                </TouchableOpacity>

                            </View>
                        </View>
                        <ScrollView>
                            <View style={{ height: '80%', width: 670, justifyContent: 'flex-start', alignItems: 'center' }}>
                                {button1 &&
                                    <Text style={{ fontFamily: 'JosefinSans-Regular', fontSize: 20, lineHeight: 26.5, color: '#575f69', textAlign: 'left', marginTop: 30 }}>
                                        {
											(report !== null) && report.length > 0 && report.map((items, index) => (
												items.section >= 100 && items.section < 200 ? items.text.replace(/\r\n/g, "\n\n") : ""
                                            ))
										}
                                    </Text>
                                }
                                {button2 &&
                                    <Text style={{ fontFamily: 'JosefinSans-Regular', fontSize: 20, lineHeight: 26.5, color: '#575f69', textAlign: 'left', marginTop: 30 }}>
                                        {
                                            (report !== null) && report.length > 0 && report.map((items, index) => (
												items.section >= 200 && items.section < 300 ? items.text.replace(/\r\n/g, "\n\n") : ""
                                            ))
                                        }
                                    </Text>
                                }
                                {button3 &&
                                    <Text style={{ fontFamily: 'JosefinSans-Regular', fontSize: 20, lineHeight: 26.5, color: '#575f69', textAlign: 'left', marginTop: 30 }}>
                                        {
                                            (report !== null) && report.length > 0 && report.map((items, index) => (
												items.section >= 300 && items.section < 400 ? items.text.replace(/\r\n/g, "\n\n") : ""
                                            ))
                                        }
                                    </Text>
                                }
                                {button4 &&
                                    <Text style={{ fontFamily: 'JosefinSans-Regular', fontSize: 20, lineHeight: 26.5, color: '#575f69', textAlign: 'left', marginTop: 30 }}>
                                        {
                                            (report !== null) && report.length > 0 && report.map((items, index) => (
												items.section >= 400 && items.section < 500 ? items.text.replace(/\r\n/g, "\n\n") : ""
                                            ))
                                        }
                                    </Text>
                                }
                            </View>
                        </ScrollView>
                    </View>
                </Animated.View >
            }
        </>
    );
};

export default Index;

const styles = StyleSheet.create({
    headingContainer: {
        width: actualWidth < 600 ? "321px" : "534px",
        height: 'auto',
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 5
    },

    Line1: {
        position: "relative",
        width: actualWidth < 600 ? 224 : 224,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',

    },

    Line2: {
        position: "relative",
        width: actualWidth < 600 ? 224 : 224,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },

    header: {
        position: "relative",
        width: "100%",
        height: "auto",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: 32,
        letterSpacing: 3.2,
        fontWeight: '600',
        textAlignVertical: "center",
        textAlign: 'center',
        marginTop: deviceWidth < 500 ? 8 : 12,
        marginBottom: deviceWidth < 500 ? 2 : 4,
        color: '#575f69',
        textTransform: 'uppercase'
    },
    slidernumber: {
        fontSize: 10,
        fontWeight: '600',
        color: '#575f69',
        fontFamily: 'JosefinSans-SemiBold',
        textAlign: 'center',
        opacity: 0.5
    },
    testShadow: {
        textShadowColor: 'rgba(0, 0, 0, 0.15)',
        textShadowOffset: { width: 1, height: 0 },
    }
})