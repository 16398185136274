import React  from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Main from './main';
import { usePreview } from 'react-dnd-preview';
import ImagePreview from './imageSelector';


function is_touch_enabled() {
  return ( 'ontouchstart' in window ) ||
         ( navigator.maxTouchPoints > 0 ) ||
         ( navigator.msMaxTouchPoints > 0 );
 }

  const MyPreview = () => {
    const {display, itemType, item, style} = usePreview();
    if (!display) {
      return null;
    }
    return  <div className="item-list__item" style={style}>
           <ImagePreview iconKey={item.iconKey}/>
           </div>;;
  };


export const Drag = (props) => {
  return (
    <DndProvider backend={is_touch_enabled() ? TouchBackend :HTML5Backend} >
      {/* Here, render a component that uses DND inside it */}
      <Main {...props}/>
      {is_touch_enabled() && <MyPreview/>} 
    </DndProvider>
  )
}

export default Drag