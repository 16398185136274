import React, {useState, useRef, useLayoutEffect } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Easing
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
import Firstpage from './firstPage';
import Secondpage from './secondPage';
import Fourthpage from './fourthPage';
import Fifthpage from './dragComponent/index';
const deviceWidth = Dimensions.get('screen').width;
export default function Index(props) {
    const tBar = useRef(new Animated.Value(0)).current;
    const [display, setDisplay] = useState(false);
    const [pageFirst, setPageFirst] = useState(true);
    const [pageSecond, setPageSecond] = useState(true);
    const [pageThird, setPageThird] = useState(true);
    const [pageFourth, setPageFourth] = useState(true);
    const [pageFifth, setPageFifth] = useState(true);
    const [skippedQuestion, setSkippedQuestion] = useState(false);

    useLayoutEffect(() => {
        Animated.timing(tBar, {
            toValue: 1,
            duration: 800,
            useNativeDriver: false,
            easing: Easing.linear
        }).start(() => setDisplay(true));
    }, [])

    const topBar = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%']
    });

    const topBar2 = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: [deviceWidth > 1300 ? -126 : -102, 0]
    });
    const pageOne = () => {
        setPageFirst(prev=>!prev)
    };
    const pageTwo = () => {
        setPageSecond(prev=>!prev)
    };
    const pageThree = () => {
        setPageThird(prev=>!prev)
    };
    const pageFour = () => {
        setPageFourth(prev=>!prev)
    };
    const pageFive = () => {
        setPageFifth(prev=>!prev)
    };
    return (
        <View style={[styles.container, { height: '100vh' }]}>
            <Animated.View style={[styles.topborder, { transform: deviceWidth < 500 ? [{ translateX: 0 }] : [{ translateX: topBar2 }], width: topBar, }]}></Animated.View>
            <img src={require("../../../assets/svg/ESG-logo.svg")} style={{
                position: "relative",
                alignSelf: "flex-end",
                width: deviceWidth > 400 ? 54 : 40,
                objectFit: 'contain',
                height: deviceWidth > 400 ? 16 : 12,
                marginRight: 7
            }} />
            { 
                display &&(
                     pageFirst?<Firstpage pageOne={pageOne} {...props} />:
                     pageSecond?<Secondpage pageTwo={pageTwo} pageThree={pageThree}  {...props} />:
                     pageThird?<Fourthpage pageThree={pageThree} pageTwo={pageTwo} {...props}/> :
                        <Fifthpage {...props} pageOne={pageOne} pageTwo={pageTwo} pageThree={pageThree}/>
                )                    
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100vw",
        backgroundColor: '#eeeeee',
        overflow: 'hidden'
    },
    topborder: {
        position: "relative",
        alignSelf: 'center',
        borderTopColor: "#4bd5ad",
        borderTopWidth: 3,
        marginBottom: hp("1.2%")
    },
    Image: {
        position: "relative",
        alignSelf: "flex-end",
        marginLeft: '90vw',
        width: 48,
        resizeMode: "contain",
        marginRight: 8,
        height: 12
    },
});
