import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
} from 'react-native';

const Index = ({ prevQuestion }) => {
  return (
    <TouchableOpacity style={styles.button} onPress={prevQuestion}>
      <Text style={styles.backButton}>Back</Text>
    </TouchableOpacity>
  );
};

export default Index;

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    borderColor: '#ffffff',
    borderWidth: 1,
    width: 90,
    height: 44,
    // marginTop: Dimensions.get("window").height - 80,
    // marginTop:Dimensions.get("window").width>600?"8%":"20%",
    marginLeft: 25,
    borderRadius: 22,
    backgroundColor: 'transparent',
    bottom:50
  },
  backButton: {
    paddingTop: 10,
    backgroundColor: 'transparent',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: "19px",
    height: '100%',
    width: '100%',
  },
});
