import React, { useLayoutEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Easing } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
    const introAnimation = useRef(new Animated.Value(actualWidth)).current;
    useLayoutEffect(() => {
        Animated.timing(introAnimation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: false,
            easing: Easing.linear
        }).start()
    }, [])

    const goBack = () => {
        props.pageTwo()
        props.setModuleIndex(moduleIndex => moduleIndex - 1);
        props.resultContext.resetPreviousModuleResult(props.moduleObject.moduleid)
    }
    const getDeviceType = () => {
        const ua = navigator.userAgent;

        // Patterns for mobile and tablet detection
        const isMobile = /iPhone|iPad|iPod|Android/i.test(ua);
        const isTablet = /iPad|Android/i.test(ua) && !/Mobile/i.test(ua);
        if (isMobile) {
            if (isTablet) {
                return 'tablet';
            }
            return 'mobile';
        }
        return 'desktop';
    }
    return (
        <Animated.View style={[styles.content, { transform: [{ translateX: introAnimation }] }]}>
            <TouchableOpacity style={styles.backButton} onPress={goBack}>
                <ArrowBackIcon  style={{height:24,width:24}} htmlColor="#575f69" />
            </TouchableOpacity>
            <Text style={styles.customText}>
                You will be shown a serious of tokens representing different values.
            </Text>
            <View style={styles.ballContainer}>
                <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-NoSins.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-PeoplePlanet.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-Planet.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-PositiveImpact.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-People.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-CarbonNeutral.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                    <View style={styles.balls}>
                        <img src={require("../../../../src/assets/svg/Token-All.svg")} style={{
                            width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
                            objectFit: 'contain',
                        }} />
                    </View>
                </View>
            </View>
            <Text style={styles.customText}>
                {getDeviceType() === 'desktop' ? 'Click' : 'Tap'} the tokens that reflect your investment values so that we know how best to invest your money to suit your values.
            </Text>
            <View style={styles.Line} />
            <TouchableOpacity style={styles.button} onPress={props.pageThree}>
                <Text style={styles.buttontext}>Continue</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.goBack} onPress={goBack}>
                <Text style={styles.goBackText}>go back</Text>
            </TouchableOpacity>
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        flex:1
    },

    customText: {
        position: "relative",
        width: actualWidth > 1000 ? wp('75%') : actualWidth > 400 ? wp('85%') : wp('90%'),
        Height: hp('24.3%'),
        fontSize: actualWidth > 1000 ? 32 : actualWidth > 400 ? 24 : 16,
        fontWeight: 300,
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: actualWidth > 1000 ? 3.2 : actualWidth > 400 ? 2.4 : 1.6
    },
    button: {
        backgroundColor: '#575f69',
        width: actualWidth > 1000 ? 135 : actualWidth > 400 ? 135 : 110,
        height: 48,
        marginBottom: hp("3%"),
        justifyContent: 'center',
        borderRadius:2,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 },
    },
    Line: {
        width: actualWidth > 1000 ? wp('35%') : actualWidth > 400 ? wp('45%') : wp('55%'),
        height: 1,
        backgroundColor: "#d4d4d4",
        marginBottom: hp("4%"),
    },
    heading: {
        fontSize: actualWidth > 1000 ? 48 : actualWidth > 400 ? 32 : 16,
        fontWeight: 300,
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: 4.8
    },
    goBack: {
        backgroundColor: 'transparent',
        width: 118,
        height: 48,
        marginBottom: hp("3%"),
        justifyContent: 'center'
    },
    goBackText: {
        color: "#575f69",
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase'
    },
    ballContainer: {
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        marginBottom: hp("3%"),
    },
    balls: {
        width: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
        height: actualWidth > 1000 ? 62 : actualWidth > 400 ? 62 : 31,
        borderRadius: actualWidth > 1000 ? 31 : actualWidth > 400 ? 31 : 15,
        elevation: 4,
        backgroundColor: "transparent",
        alignItems: "center",
        justifyContent: "center",
        marginVertical:actualWidth > 400? 10 : 5,
        marginHorizontal: actualWidth > 400? 20 : 10
    },
    backButton: {
        height: 24,
        width: 24,
        borderRadius: 12,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: actualWidth > 500 ? -20 : -15,
        left: 10
    }

})
