import React, { useContext } from 'react';
import { View } from 'react-native';
import { withRouter } from 'react-router-dom';

import ButtonModule from '../../../Components/ButtonModule';
import { ActivityContext } from '../../Activities';

const Index = (props) => {
  const resultContext = useContext(ActivityContext);
  return (
    <View>
      <ButtonModule
        onPress={() => props.history.push(`/activityType/${props.module.moduleid}`)}
        title={props.module.name}
        disabled={
          (resultContext.result &&
            resultContext.result.modules.filter(
              (module) => module.moduleid === props.module.moduleid,
            )[0].completedat !== null) || (resultContext.userSession !== '' && resultContext.userSession.modules.some((item) => item.moduleid === props.module.moduleid))
        }
        skin={props.skin}
      />
    </View>
  );
};

export default withRouter(Index);
