import React, { useRef, useLayoutEffect, useContext, useState } from 'react';
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Animated } from 'react-native';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { ActivityContext } from '../../../Activities';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { buttonText, DRAG_TEXT } from '../../../../Utils/static-strings';
const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: '#575f69',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 50,
        width: 50,
        backgroundColor: '#ff8d53',
        boxShadow: iOSBoxShadow,
        marginTop: -28,
        marginLeft: -18,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-17% + 17px)',
        top: 17,
        fontSize: 20,
        fontWeight: '900',
        '& *': {
            background: 'transparent',
            color: '#ffffff',
        },
    },
    track: {
        height: 2,
    },

    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#575f69',
    },
})(Slider);

export default function CustomizedSlider(props) {
    const animation = useRef(new Animated.Value(0)).current;
    const [hide, setHide] = useState(true);
    const [sliderValue, setSliderValue] = useState(0);
    const resultContext = useContext(ActivityContext);
    const [resindex, setResIdex] = useState(0);

    const handleChange = (event, newValue) => {
        if (newValue >= 0 && newValue < 1) {
            setSliderValue(newValue);
            setResIdex(0);
        } else if (newValue >= 1 && newValue < 3) {
            setSliderValue(newValue);
            setResIdex(1);
        } else if (newValue >= 3 && newValue < 5) {
            setSliderValue(newValue);
            setResIdex(2);
        } else if (newValue >= 5 && newValue < 10) {
            setSliderValue(newValue);
            setResIdex(3);
        } else if (newValue >= 10) {
            setSliderValue('10+');
            setResIdex(4);
        }
    };
    useLayoutEffect(() => {
        let check;
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        if (props.moduleObject.questions[props.moduleIndex].askif) {
            if (props.moduleObject.questions[props.moduleIndex].askif.op == 'OR') {
                props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            check = props.moduleObject.questions[props.moduleIndex].askif.answers.find((cc) => cc.respid == data.respid);
                        }
                    })
                    if (check) { }
                    else {
                        props.selectAnswerHandler(
                            props.moduleObject.moduleid,
                            props.moduleObject.questions[props.moduleIndex].qid,
                            props.moduleObject.questions[props.moduleIndex].answers[0].respid,
                        );
                        props.setSkippedQuestion(true);
                        props.Refresh();
                    }
                })
            }
            if (props.moduleObject.questions[props.moduleIndex].askif.op == 'AND') {
                props.moduleObject.questions[props.moduleIndex].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            if (val.respid == data.respid) {

                            }
                            else {
                                props.selectAnswerHandler(
                                    props.moduleObject.moduleid,
                                    props.moduleObject.questions[2].qid,
                                    props.moduleObject.questions[2].answers[0].respid,
                                );
                                props.Refresh();
                            }
                        }
                    })

                })
            }
        }
        Animated.timing(animation, {
            toValue: 1,
            duration: 500
        }).start(() => setHide(false));
    }, [])

    const nextPage = () => {
        setHide(true);
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[resindex].respid,
        );
        Animated.timing(animation, {
            toValue: 0,
            duration: 500
        }).start(() => props.Refresh());
    }

    return (
        <Animated.View style={[styles.content, { opacity: animation }]}>
            <Text style={styles.customText}>
                {props.moduleObject.questions[2].text}
            </Text>
            <Text style={styles.dragText}>
                {DRAG_TEXT}
            </Text>
            <View style={styles.slider}>
                <IOSSlider aria-label="ios slider" defaultValue={0} max={10} onChange={handleChange} valueLabelFormat={sliderValue} valueLabelDisplay="on" />
            </View>
            <View style={Dimensions.get('screen').width > 500 ? { margin: 5 } : { height: 20 }}></View>
            <View>
                <TouchableOpacity
                    onPress={() => { nextPage() }}
                    disabled={hide}
                    style={[styles.button]}>

                    <Text style={styles.continueText}>
                        {buttonText}
                    </Text>

                    <Image style={styles.arrow}
                        source={require('../../../../assets/images/knowledgearrow.png')} ></Image>
                </TouchableOpacity>
            </View>
        </Animated.View>
    );
};
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1300 ? 24 : 20,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        fontFamily: 'JosefinSans-Light',
        lineHeight: 26.5,
        letterSpacing: 0.8,
    },
    dragText:{
        fontSize: 16,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        fontFamily: 'JosefinSans-Light',
        lineHeight: 'normal',
        letterSpacing: 1.6,
        marginTop: hp("1%"),
        marginBottom: hp("3%"),
    },
    continueText:{
        fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'JosefinSans-Bold',
        lineHeight: 'normal',
        letterSpacing: 1.6,
        marginRight:wp("0.5%"),
        marginLeft:wp('1.4%')
    },
    slider: {
        width: Dimensions.get('screen').width > 1300 ? wp('30%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('80%')
    },
    button: {
        // width: Dimensions.get('screen').width > 850 ? 192 : 141,
        height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'row',
        textTransform:"uppercase",

        // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
    },
    arrow: {
        marginRight:wp('1.4%'),
        marginLeft:wp('0.5%'),
        alignSelf: 'center',
        width: Dimensions.get('screen').width > 850 ? 22 : 16,
        height: Dimensions.get('screen').width > 850 ? 22 : 16,
        resizeMode: "contain",
    }
})
