import React from 'react';
import { Animated, View, Image, Dimensions } from 'react-native';
import { VictoryPie, VictoryLabel } from 'victory';
import getSheet from './RotateStyle';
import '../../../../../src/App.css';
import { lossLabels } from '../../../../Utils/static-strings'
import { useEffect, useState } from 'react';

const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;
const height = 107.5 //107.5; and 120 in 500
const width = 37.5 //37.5;
const Rotate = (props) => {
  const {
    gain,
    loss,
    primaryColorBlue,
    primaryColorPurple,
    secondaryColorBlue,
    secondaryColorPurple,
    spin,
    transform,
    opacity,
    lossRatio,
  } = props;

  const [degree, setDegree] = useState('0deg');
  const [size, setSize] = useState(30);
  const [Loss, setLoss] = useState(loss)
  const inLoss = 1;


  const styles = getSheet(
    secondaryColorBlue,
    secondaryColorPurple,
  );

  const counter = () => {
    if (inLoss < loss) {
      setLoss(inLoss + 1)
    }
    // do{
    //   setLoss(inLoss+1);
    // }
    // while(inLoss<loss)
  }

  useEffect(() => {
    setTimeout(() => {
      setSize(24);
      counter();
    }, 1500)
    if (lossRatio !== null) {
      const deg = lossRatio * 100 * 3.6;
      setDegree(`${deg}deg`);
    }

  }, [lossRatio]);

  const CustomLabel = () => {
    return (
      <>
        <View style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: [{ translateX: -width / 2 }, { translateY: -height + 13 }],
          zIndex: 99,
          width: width,
          height: height
        }}>

          <Image
            style={{
              width: width,
              height: height,
              resizeMode: 'stretch',
              transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
            }}
            source={require('../../../../assets/images/arrow.png')} />
        </View>
      </>
    );
  };

  return (
    <>

      <Animated.View style={[{ transform: [{ translateX: transform }, { rotate: spin }] }, {
        opacity: opacity, position: 'relative',
        top: actualHeight < 768 ? '-10%' : (actualHeight <= 1024 ? '-5%' : '10%')
      }]}>
        {CustomLabel()}
        <VictoryPie
          colorScale={[primaryColorBlue, primaryColorPurple]}
          width={actualHeight < 768 ? 370 : (actualHeight <= 1024 ? 370 : 500)}
          height={actualHeight < 768 ? 370 : (actualHeight <= 1024 ? 370 : 500)}
          data={[
            { x: 3, y: lossRatio * 100 * 3.6 },
            { x: 1, y: 360 - (lossRatio * 100 * 3.6) },
          ]}

          labels={[() =>
            () => [lossLabels.loss, `£${loss}`],
          () => [lossLabels.gain, `£${gain}`]
          ]}

          labelPosition={'centroid'}

          labelComponent={
            <VictoryLabel
              style={[
                {
                  fontFamily: 'JosefinSans-Light',
                  fontSize:
                    actualHeight < 768 ? 24 : //24
                      (actualHeight <= 1024 ? 22 :
                        (actualWidth === 2560 ? 32 :
                          (actualWidth === 3840 ? 37 :
                            (actualWidth === 4096 ? 40 :
                              (actualWidth === 4500 ? 45 :
                                (actualWidth === 5120 ? 45 :
                                  (actualWidth === 6400 ? 45 :
                                    (actualWidth === 7680 ? 45 : 45)))))))), //35
                  fontWeight: 300,
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  letterSpacing: 'normal',
                  alignSelf: 'center',
                  fill: 'white',
                },
                {
                  fontSize:
                    actualHeight < 768 ? 24 : //24
                      (actualHeight <= 1024 ? 24 :
                        (actualWidth === 2560 ? 32 :
                          (actualWidth === 3840 ? 37 :
                            (actualWidth === 4096 ? 40 :
                              (actualWidth === 4500 ? 45 :
                                (actualWidth === 5120 ? 45 :
                                  (actualWidth === 6400 ? 45 :
                                    (actualWidth === 7680 ? 45 : 45)))))))), //35
                  fill: 'white',
                  fontFamily: 'JosefinSans-Bold',
                  fontWeight: 900,
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 1.39,
                  letterSpacing: 0.5,
                },
              ]} />
          }

          innerRadius={actualHeight < 768 ? 50 : (actualHeight <= 1024 ? 50 : 60)}
          labelRadius={85} />

      </Animated.View>
    </>
  );
};

export default Rotate;
