import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, Text, Animated, Dimensions } from 'react-native';

const deviceWidth = Dimensions.get('window').width;

const Index = (props) => {
    const amountPriceAnimation = useState(new Animated.Value(0))[0];
    const monthChangeFontAnimation = useRef(new Animated.Value(1)).current;
    const dataChangeFontAnimation = useRef(new Animated.Value(1)).current;
    const [prevAmt, setPrevAmt] = useState(0);
    const [prevTime, setPrevTime] = useState("");
    const [animationDone, setAnimationDone] = useState(false);
    const inputRef = useRef(null);
    const valueRef = useRef(null);
    const delayanimation = useState(new Animated.Value(0))[0];
    const [monthFont, setMonthFont] = useState(deviceWidth > 600 ? 19.5 : deviceWidth > 380 ? 15.8 : 10.8);


    const { text, data } = props;
    const amountData = data.split(' ')[0];
    const amountValue = amountData.substring(1, amountData.length);
    const value = data.replace(amountData, '');

    const changeAmountValue = (value) => {
        Animated.timing(amountPriceAnimation, {
            toValue: value,
            duration: 10,
            delay:700
        })
            .start();

    }

    useEffect(() => {

        if (props.isLeftPostClicked) {
            // setAnimationDone(true);
            // setTimeout(()=>{
            //     setAnimationDone(true);
            // },3000)
            Animated.sequence([
                // Animated.timing(monthChangeFontAnimation, {
                //     toValue: 1.36,
                //     duration: 1100,
                //     delay : 1300
                // }),
                Animated.parallel([
                    Animated.timing(monthChangeFontAnimation, {
                        toValue: deviceWidth > 600 ? 0.85 : 0.8,
                        duration: 100,
                        delay: 700
                    }),
                    Animated.timing(dataChangeFontAnimation, {
                        toValue: deviceWidth > 600 ? 0.85 : 0.8,
                        duration: 100,
                        delay: 700
                    })
                ]),

            ]).start(()=>{
                 setAnimationDone(true);
            });
        }
    }, [props.isLeftPostClicked])

    // const monthChangeScaleInterpolate = monthChangeFontAnimation.interpolate({
    //     inputRange: [0, 100],
    //     outputRange: [1, 1]
    // })

    useEffect(() => {
        changeAmountValue(Number(amountValue));
        amountPriceAnimation.addListener(v => {
            if (inputRef?.current) {
                setPrevAmt(Math.round(v.value))
            }
        })
        if (prevTime === "") {
            setPrevTime(value);
        } else {
            Animated.timing(delayanimation, {
                toValue: 1,
                duration: 100,
                delay: 900
            }).start(()=>{
                setPrevTime(value);
            })
            // setTimeout(() => {
            //     setPrevTime(value);
            // }, 400)
        }

    }, [data]);

    // useEffect(()=>{
    //     if(props.isLeftPostClicked){
    //         Animated.timing()
    //     }
    // },[props.isLeftPostClicked])

    return (
        <>
            <Animated.View>
                <Animated.Text ref={inputRef} style={text === "First" ? [styles.amount] : [styles.amount2, { transform: [{ scale: dataChangeFontAnimation }] }]} >£{prevAmt} </Animated.Text>
            </Animated.View>
            <Animated.View>
                <Animated.Text ref={valueRef} style={text === "First" ? [styles.time] : [styles.time2, { transform: [{ scale: monthChangeFontAnimation }] }, animationDone ? { lineHeight: deviceWidth > 600 ? 16.5 : deviceWidth > 380 ? 14 : 9.7 } : {}]} >{prevTime}</Animated.Text>
            </Animated.View>
        </>
    )
}

//props.isLeftPostClicked ? {fontSize : deviceWidth>600 ?25.5 :deviceWidth >380 ? 19.4 : 14.5,} : props.leftPostPressed? {fontSize : monthFont, lineHeight:deviceWidth>600 ?22.5 :deviceWidth >380 ? 19 : 14.7} : {}
//props.leftPostPressed? {fontSize :dataChangeFontAnimation, lineHeight:deviceWidth>600 ?38 :deviceWidth >380 ? 36 : 27.7} : {}

export default Index;

const styles = StyleSheet.create({
    amount: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: deviceWidth > 600 ? 49 : deviceWidth > 380 ? 38.9 : 31.2,
        // fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: deviceWidth > 600 ? 68 : deviceWidth > 380 ? 47.3 : 37.8,
    },
    time: {
        fontFamily: 'Manrope-Medium',
        fontSize: deviceWidth > 600 ? 24.5 : deviceWidth > 380 ? 19.5 : 15.6,
        color: '#2f2f2f',
        lineHeight: deviceWidth > 600 ? 24 : deviceWidth > 380 ? 25 : 20,
    },
    amount2: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: deviceWidth > 600 ? 43.1 : deviceWidth > 380 ? 34.5 : 27.6,
        // fontWeight: "500",
        color: '#2f2f2f',
        lineHeight: deviceWidth > 600 ? 52.3 : deviceWidth > 380 ? 41.8 : 33.5,
    },
    time2: {
        fontFamily: 'Manrope-Medium',
        fontSize: deviceWidth > 600 ? 19.5 : deviceWidth > 380 ? 17.2 : 13.8,
        color: '#2f2f2f',
        lineHeight: deviceWidth > 600 ? 27.7 : deviceWidth > 380 ? 22.2 : 17.7,
    }
})

