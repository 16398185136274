import React from 'react';
import { Switch, Route } from 'react-router';
import { withRouter } from 'react-router-dom'
import { moduleid } from '../../Utils/static-strings'
import ChooseActivity from './chooseActivity';

const Index = (props) => {
  return (
    <Switch>
      <Route exact path={"/activityType/" + moduleid.risk()} render={() => <ChooseActivity {...props} />} />
      <Route exact path={"/activityType/" + moduleid.loss()} render={() => <ChooseActivity {...props} />} />
      <Route exact path={"/activityType/" + moduleid.future()} render={() => <ChooseActivity {...props} />} />
      <Route exact path={"/activityType/" + moduleid.experience()} render={() => <ChooseActivity {...props} />} />
      <Route exact path={"/activityType/" + moduleid.knowledge()} render={() => <ChooseActivity {...props} />} />
      <Route exact path={"/activityType/" + moduleid.esg()} render={() => <ChooseActivity {...props} />} />
    </Switch>
  );
};

export default withRouter(Index);
