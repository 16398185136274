import React, { useLayoutEffect, useState } from 'react'
import ExpSkin2 from './Skin2/index'
import ExpSkin3 from './Skin3/index';
import ExpSkinSantander from './SkinSantander/index';
import {tiger, zebra} from '../../../Utils/static-strings';
const Index = (props) => {
    const [currentSkin, setCurrentSkin] = useState(null);

    useLayoutEffect(() => {
        if (props.skin) {
            setCurrentSkin(props.skin)
        }
    }, [])

    const handleSkin = () => {
        const skin = sessionStorage.getItem("skinName")?.toLowerCase()
        if(skin  === zebra?.toLowerCase()) {
            return <ExpSkin3 {...props} />
        } else if(skin  === tiger?.toLowerCase()) {
            return <ExpSkinSantander {...props} />
        } else {
            return <ExpSkin2 {...props} />
        }
    }
    return (
        <React.Fragment>
            {currentSkin !== null && handleSkin()}
        </React.Fragment>
    )
}
export default Index

