import React, { useState, useLayoutEffect } from 'react';
import KnowledgeSkin2 from './KnowledgeSkin2/index';
import KnowledgeSkin3 from './KnowledgeSkin3/index';
import { zebra } from '../../../Utils/static-strings';
const FutureMain = (props) => {
    const [skin, setSkin] = useState(null);

    useLayoutEffect(() => {
        if (props.skin) {
            setSkin(props.skin)
        }
    }, [])

    const handleSkinSwitch = () => {
        if(sessionStorage.getItem("skinName")?.toLowerCase() === zebra?.toLowerCase()) {
            return <KnowledgeSkin3 {...props} />
        } else {
            return <KnowledgeSkin2 {...props} />
        }
    }

    return (
        <React.Fragment>
            {
                skin !== null && handleSkinSwitch()
            }
        </React.Fragment>
    )
}

export default FutureMain

