import React, { useEffect, useState, useRef } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Easing,
    Text,
    TouchableOpacity
} from 'react-native';
import Cards from './Card';

const screenWidth = Dimensions.get('window').width;

const Index = (props) => {

    const trueContainerAnimation = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const falseContainerAnimation = useRef(new Animated.Value(Dimensions.get('screen').width)).current;
    const trueContainerScaleAnimation = useRef(new Animated.Value(0)).current;
    const falseContainerScaleAnimation = useRef(new Animated.Value(0)).current;
    const [showBottomCardAnimation, setShowBottomCardAnimation] = useState(false);
    const [showUpperCardAnimation, setShowUpperCardAnimation] = useState(false);
    const [ShowTrueContainerWidth, setShowTrueContainerWith] = useState(true);
    const [showFalseContainerWidth, setShowFalseContainerStyle] = useState(true);
    const [answerId, setanswerId] = useState(2);
    const [hide, setHide] = useState(true);
    const [restrict, setRestrict] = useState(true);
    const animateLeft = (i) => {
        setanswerId(i);
        trueContainerAnimation.setValue(-Dimensions.get('screen').width / 4.2);
        trueContainerScaleAnimation.setValue(0);
        falseContainerAnimation.setValue(Dimensions.get('screen').width / 2);
        Animated.parallel([
            Animated.timing(trueContainerAnimation, {
                toValue: 0,
                duration: 800,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(falseContainerAnimation, {
                toValue: Dimensions.get('screen').width,
                duration: 800,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(trueContainerScaleAnimation, {
                toValue: 1,
                duration: 800,
                easing: Easing.linear
            }).start()
        ]).start(() => {
            setRestrict(true);
            setShowBottomCardAnimation(false)
            setShowUpperCardAnimation(true)
        });
    };
    const animateRight = (i) => {
        setanswerId(i);
        trueContainerAnimation.setValue(35);
        falseContainerAnimation.setValue(Dimensions.get('screen').width / 4);
        falseContainerScaleAnimation.setValue(0);
        Animated.parallel([
            Animated.timing(trueContainerAnimation, {
                toValue: -Dimensions.get('screen').width,
                duration: 800,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(falseContainerAnimation, {
                toValue: 0,
                duration: 800,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
            Animated.timing(falseContainerScaleAnimation, {
                toValue: 1,
                duration: 800,
                easing: Easing.linear
            }).start()
        ]).start(() => {
            setRestrict(true);
            setShowBottomCardAnimation(false)
            setShowUpperCardAnimation(true)
        });
    };

    useEffect(() => {
        if (props.moduleIndex < 1) {
            trueContainerAnimation.setValue(-Dimensions.get('screen').width);
            falseContainerAnimation.setValue(Dimensions.get('screen').width);
            Animated.parallel([
                Animated.timing(trueContainerAnimation, {
                    toValue: 0,
                    duration: 800,
                    easing: Easing.linear,
                    useNativeDriver: true,
                }),
                Animated.timing(falseContainerAnimation, {
                    toValue: 0,
                    duration: 800,
                    easing: Easing.linear,
                    useNativeDriver: true,
                }),
            ]).start(() => {
                setHide(false)
                setShowBottomCardAnimation(true)
            });
        } else {
            if (props.page == 'FALSE') {
                trueContainerAnimation.setValue(0);
                falseContainerAnimation.setValue(-25);
                falseContainerScaleAnimation.setValue(1);
                Animated.parallel([
                    Animated.timing(falseContainerAnimation, {
                        toValue: 0,
                        duration: 800,
                        easing: Easing.linear,
                        useNativeDriver: true,
                    }),
                    Animated.timing(falseContainerScaleAnimation, {
                        toValue: 0,
                        duration: 800,
                        easing: Easing.linear
                    })
                ]).start(() => {
                    setHide(false)
                    setShowBottomCardAnimation(true)
                })
            }
            if (props.page == 'TRUE') {
                trueContainerAnimation.setValue(25);
                trueContainerScaleAnimation.setValue(1)
                falseContainerAnimation.setValue(0);
                Animated.parallel([
                    Animated.timing(trueContainerAnimation, {
                        toValue: 0,
                        duration: 800,
                        easing: Easing.linear,
                        useNativeDriver: true,
                    }),
                    Animated.timing(trueContainerScaleAnimation, {
                        toValue: 0,
                        duration: 800,
                        easing: Easing.linear
                    })
                ]).start(() => {
                    setHide(false)
                    setShowBottomCardAnimation(true)
                })
            }

        }
    }, []);
    return (
        <View style={styles.container}>

            <View style={styles.subContainer}>


                <Animated.View style={[styles.trueContainer,
                { transform: [{ translateX: trueContainerAnimation }] }, ShowTrueContainerWidth ? null : { width: '100%' },
                showFalseContainerWidth ? null : { width: '0%' }
                ]}>
                    <TouchableOpacity style={styles.trueTocuh} disabled={hide} onPress={() => {
                        if (restrict === false) {
                            setShowTrueContainerWith(false)
                            setHide(true)
                            animateLeft(0)
                        }
                    }}>

                        <Animated.Text style={[styles.trueText, {
                            transform: [
                                {
                                    scale: 1
                                    // trueContainerScaleAnimation.interpolate({
                                    //     inputRange: [0, 1],
                                    //     outputRange: [1, 2]
                                    // })
                                },
                            ]
                        }]}>{props.Questions[props.indexOfset].answers[0].text}</Animated.Text>

                    </TouchableOpacity>
                </Animated.View>

                <Animated.View style={[styles.falseContainer,
                { transform: [{ translateX: falseContainerAnimation }] }, ShowTrueContainerWidth ? null : { width: '0%' },
                showFalseContainerWidth ? null : { width: '100%' }
                ]}>
                    <TouchableOpacity style={styles.falseTocuh} disabled={hide} onPress={() => {
                        if (restrict === false) {
                            setShowFalseContainerStyle(false)
                            setHide(true)
                            animateRight(1)
                        }
                    }}>

                        <Animated.Text style={[styles.trueText, {
                            transform: [
                                {
                                    scale: 1
                                    // falseContainerScaleAnimation.interpolate({
                                    //     inputRange: [0, 1],
                                    //     outputRange: [1, 2]
                                    // })
                                },
                            ]
                        }]}>{props.Questions[props.indexOfset].answers[1].text}</Animated.Text>

                    </TouchableOpacity>
                </Animated.View>

            </View>

            {showBottomCardAnimation ? <Cards flow="dow" setRestrict={setRestrict} {...props} answerId={answerId} /> : null}
            {showUpperCardAnimation ? <Cards flow="up" setRestrict={setRestrict} {...props} answerId={answerId} /> : null}

        </View>
    );
}
export default Index;

const styles = StyleSheet.create({

    container: {
        flex: 1,
        justifyContent: 'center',
        height: "100%"
    },

    subContainer: {
        flexDirection: "row"
    },

    trueContainer: {
        // flex: 1,
        width: '50vw',
        justifyContent: 'center',
        alignItems: 'center',
        height: '99.5vh',
        backgroundColor: "rgba(255, 255, 255, 0.16)"
    },

    falseContainer: {
        // flex: 1,
        width: '50vw',
        justifyContent: 'center',
        alignItems: 'center',
        height: '99.5vh',
        backgroundColor: "rgba(0, 0, 0, 0.05)"
    },

    trueTocuh: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    falseTocuh: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    trueText: {
        position: 'relative',
        top:  screenWidth > 800 ? 245 :screenWidth > 700 ? 195 :  170,
        fontFamily: 'Manrope-SemiBold',
        fontSize: screenWidth > 350 ? screenWidth > 500 ? 60 : 48 : 40,
        lineHeight: screenWidth > 350 ? screenWidth > 500 ? 68 : 52 : 48,
        color: '#2f2f2f',
        textTransform: 'uppercase'
    },

    falseText: {
        position: 'relative',
        top:  screenWidth > 800 ? 245 :screenWidth > 700 ? 195 :  170,
        fontFamily: 'Manrope-SemiBold',
        fontSize: screenWidth > 350 ? screenWidth > 500 ? 60 : 48 : 40,
        lineHeight: screenWidth > 350 ? screenWidth > 500 ? 68 : 52 : 48,
        color: '#2f2f2f',
        textTransform: 'uppercase'
    },
})