import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { Switch, Route, withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import logo from '../../assets/loading-gif/DIA-loaderAnimation.gif'
import { chooseActivity, summaryPage, tiger, zebra, moduleid } from '../../Utils/static-strings';
import { saveResults, getSession, getSessionWithSessionRef } from '../../Utils/path-config';
import ChooseActivity from '../ChooseActivity';
import ResultPage from '../Result/index';
import Activity from './Activity';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
import FrontPageTwo from '../../Utils/frontPage2';
import Loadingscreen from '../../Utils/skin2Loader';
import FrontPageThree from '../frontPageSkin3/index'
import '../../App.css'
import { addAnalytics } from '../../helper';
import { inIframe } from '../../Utils/helper';
import IntroPage from './IntroPage';
export const ActivityContext = createContext();

const Index = (props) => {
  const [result, setResult] = useState();
  const [testCompleted, setTestCompleted] = useState(false);
  const [riskCalculation, setRiskCalculation] = useState();
  const [loader, setLoader] = useState(false);
  const [showIntroPage, setShowIntroPage] = useState(false);
  const [userSession, setUserSession] = useState(null);
  const [finishAnimate, setfinishAnimate] = useState(null);
  const bgColor = [
    "#f5d3e4",
    "#c3e0b5",
    "#ffeec9",
    "rgb(187, 221, 242)",
    "rgb(204, 203, 227)"]

  useLayoutEffect(() => {
    setLoader(true);
    const url = window['runConfig'].REACT_APP_BASE_URL + getSessionWithSessionRef(props.userref, props.sessionref);

    let config = {
      method: 'get',
      url,
    };
    axios(config)
      .then((response) => {
        setLoader(false);
        setUserSession(response.data);
        setRiskCalculation(response.data);
        if (response.data !== '') {
      if (sessionStorage.getItem("skinName")?.toLowerCase() === tiger?.toLowerCase()) {
            if (props.modules.modules.length === response.data.modules.length) {
              setTestCompleted(true);
            }
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        setUserSession(error);
        addAnalytics(error, config);
      });


  }, [])
  useEffect(() => {
    if (userSession !== '' && userSession !== null) {
      let data = props.modules.modules.map((module) => {
        if (userSession.modules.some((item) => item.moduleid === module.moduleid)) {
          let data2 = userSession.modules.map((item) => {
            if (item.moduleid === module.moduleid) {
              return {
                responses: item.responses,
                moduleid: module.moduleid,
                startedat: item.startedat,
                completedat: item.completedat,
              };
            }
          })
          return data2.filter((item) => item !== undefined)[0];
        } else {
          return {
            responses: [],
            moduleid: module.moduleid,
            startedat: null,
            completedat: null,
          };
        }
      })
      setResult({
        userref: props.userref,
        sessionref: props.sessionref,
        modules: data
      });
    }
  }, [userSession])

  useEffect(() => {
    if (props.modules.modules.length > 0) {
      const newarray = props.modules.modules.filter((module) => {
        return module.tags?.includes('ui.risk')
      })
      if (sessionStorage.getItem("skinName")?.toLowerCase() === tiger?.toLowerCase()) {
        setResult({
          userref: props.userref,
          sessionref: props.sessionref,
          modules: props.modules.modules.map((module) => {
            return {
              responses: [],
              // module.questions.map((question) => {
              //   return {
              //     qid: question.qid,
              //     respid: null,
              //   };
              // }),
              moduleid: module.moduleid,
              startedat: null,
              completedat: null,
            };
          }),
        });
      } else {
        setResult({
          userref: props.userref,
          sessionref: props.sessionref,
          modules: props.modules.modules.map((module) => {
            return {
              responses: [],
              //  module.questions.map((question) => {
              //   return {
              //     qid: question.qid,
              //     respid: null,
              //   };
              // }),
              moduleid: module.moduleid,
              startedat: null,
              completedat: null,
            };
          }),
        });
      }
    }
  }, [props.modules.modules]);

  useEffect(()=>{
    if(sessionStorage.getItem("skinName")?.toLowerCase() !== tiger?.toLowerCase() &&  sessionStorage.getItem("skinName")?.toLowerCase() !== zebra?.toLowerCase()){
      setShowIntroPage(true);
    }
  }, [])

  const handleBgColor = () => {
    if (finishAnimate === moduleid.future()) {
      return bgColor[3]
    }
    if (finishAnimate === moduleid.risk()) {
      return bgColor[1]
    }
    if (finishAnimate === moduleid.loss()) {
      return bgColor[0]
    }
    if (finishAnimate === moduleid.knowledge()) {
      return bgColor[4]
    }
    if (finishAnimate === moduleid.experience()) {
      return bgColor[2]
    }
  }

  const setAnswer = (moduleId, questionId, answerId) => {
    const tempResult = result;
    const moduleIndex = result.modules.findIndex(
      (module) => module.moduleid === moduleId,
    );

    const questionArray = tempResult.modules[moduleIndex].responses;
    const existingQuestionIndex =  questionArray.findIndex((question) => question.qid === questionId);

    if(moduleId === moduleid.experience()){
      questionArray.push({ 'qid': questionId, 'respid': answerId });
    }
    else if(existingQuestionIndex !== -1){
      questionArray[existingQuestionIndex].respid = answerId;
    }else{
      questionArray.push({ 'qid': questionId, 'respid': answerId });
    }


    tempResult.modules[moduleIndex].responses = questionArray;

    setResult(tempResult);
  };
  const resetModuleResult = (moduleId) => {
    const tempResult = { ...result };
    const moduleIndex = tempResult.modules.findIndex(
      (module) => module.moduleid === moduleId,
    );
    tempResult.modules[moduleIndex].responses = [];
  }

  const resetPreviousModuleResult = (moduleId) => {
    const tempResult = { ...result };
    const moduleIndex = tempResult.modules.findIndex(
      (module) => module.moduleid === moduleId,
    );
    tempResult.modules[moduleIndex].responses.pop();
  }

  const setModuleStartedAtTime = (moduleId) => {
    const tempResult = { ...result };
    const moduleIndex = tempResult.modules.findIndex(
      (module) => module.moduleid === moduleId,
    );
    tempResult.modules[moduleIndex].startedat = new Date().toISOString();
    setResult(tempResult);
  };

  const setModuleCompletedAtTime = (moduleId, skipValue, knowledgeSkip, knowLedgeSkipQuestion) => {
    setLoader(true);
    const tempResult = { ...result };
    const moduleIndex = tempResult.modules.findIndex(
      (module) => module.moduleid === moduleId,
    );
    tempResult.modules[moduleIndex].completedat = new Date().toISOString();
    setResult(tempResult);

    let allModuleDone = true;
    tempResult.modules.forEach((module) => {
      if (module.completedat === null) {
        allModuleDone = false;
      }
    });

    saveModuleResults(moduleId, skipValue ? skipValue : undefined, knowledgeSkip ? knowledgeSkip : undefined, knowLedgeSkipQuestion ? knowLedgeSkipQuestion : undefined);
    if (allModuleDone) {
      setTestCompleted(true);
    }
  };

// Assume isSaving is defined outside of the saveModuleResults function
// This flag will be used to prevent the function from executing if it's already running
  let isSaving = false;

  const saveModuleResults = (moduleId, skipQuestion, knowledgeSkip, knowLedgeSkipQuestion) => {
    // Check if the function is already running
    if (isSaving) {
      console.log("Save operation is already in progress.");
      return;
    }

    isSaving = true;

    const tempResult = { ...result };
    let responses = [];
    const moduleObject = tempResult.modules.filter(module => module.moduleid === moduleId);

    // Ensure moduleObject has elements
    if (moduleObject.length > 0) {
      tempResult.modules = [];

      if (skipQuestion) {
        const lastResponse = moduleObject[0].responses[moduleObject[0].responses.length - 1];
        const lastQid = lastResponse ? lastResponse.qid : null;
        moduleObject[0].responses = moduleObject[0].responses.filter((val, index) => index === 0 || val.qid === lastQid);
      } else if (knowledgeSkip) {
        moduleObject[0].responses = moduleObject[0].responses.filter(val => !knowLedgeSkipQuestion.includes(val.qid));
      }

      tempResult.modules.push(...moduleObject);
      tempResult.sessionref = props.sessionref;

      const data = JSON.stringify(tempResult);

      // save the module result
      const config = {
        method: 'post',
        url: `${window['runConfig'].REACT_APP_BASE_URL}${saveResults()}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
          .then(function (response) {
            setRiskCalculation(response.data);
            setLoader(false);
            // Reset the isSaving var
            isSaving = false;
          })
          .catch(function (error) {
            alert('Ooops! Something went wrong. Please play this module again.');
            addAnalytics(error, config);
            const tempResultFailed = { ...result };
            const moduleIndex = tempResultFailed.modules.findIndex(module => module.moduleid === moduleId);
            if (moduleIndex !== -1) {
              tempResultFailed.modules[moduleIndex] = { ...tempResultFailed.modules[moduleIndex], responses: [], completedat: null, startedat: null };
            }
            setLoader(false);
            setResult(tempResultFailed);
            props.history.push('/');
            // Reset the isSaving var
            isSaving = false;
          });
    } else {
      isSaving = false;
    }
  };


  const ResultPageRedirect = () => {
    props.history.push('/userresult/result');
  }

  const FrontPage = (props) => {
    return (
      <React.Fragment>
        <View style={styles.homeContainer}>
          <View style={styles.post}>
            <View style={styles.recatangleLeft} />
            <View style={styles.recatangleRight} />
          </View>
          {(testCompleted) ? (
            <View>
              <Text style={styles.chooseActivityTitle}>
                {summaryPage.allDoneTitle()}
              </Text>
              <Text style={styles.allDoneDescription}>
                {summaryPage.allDoneDescription()}
              </Text>
            </View>
          ) : (
            <View style={{ justifyContent: 'center', height: 90, width: 360 }}>
              <View>
                <View style={[styles.cornertwo, { top: 22, transform: [{ rotate: '180deg' }] }]} />
                <View style={[styles.cornertwo, { top: 7, alignSelf: 'flex-end', transform: [{ rotate: '-90deg' }] }]} />
              </View>
              <Text style={styles.chooseActivityTitle}>
                {chooseActivity.title()}
              </Text>
              <View>
                <View style={[styles.cornertwo, { bottom: 7, alignSelf: 'flex-end' }]} />
                <View style={[styles.cornertwo, { bottom: 22, transform: [{ rotate: '90deg' }] }]} />
              </View>
            </View>
          )}
          <View style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: "3.5%" }}>
            {props.modules.modules.map((module) => {
              return (
                <Activity
                  style={styles.moduleButtons}
                  key={module.moduleid}
                  module={module}
                  // details={details}
                  setTestCompleted={setTestCompleted}
                  {...props}
                  result={result}
                  skin={props.skin}
                />
              );
            })}
          </View>
          {(testCompleted) ? (
            <>
              {ResultPageRedirect()}
            </>
          ) : (
            <View style={styles.intructionContainer}>
              <Text style={styles.instruction}>
                {chooseActivity.instructionOne()}{`\n`}
                {chooseActivity.instructionTwo()}
              </Text>
            </View>
          )}
        </View>
      </React.Fragment>

    )
  }

  const handleSkin = () => {
    switch (sessionStorage.getItem("skinName")) {
      case tiger:
      return <FrontPage {...props} testCompleted={testCompleted} />;
        case zebra:
          return <FrontPageThree {...props} testCompleted={testCompleted} accessToken={""} riskCalculation={riskCalculation} finishAnimate={finishAnimate} />;
      default:
        return <FrontPageTwo {...props} testCompleted={testCompleted} />
    }
  }

  const loaderGif = () => {
    switch (sessionStorage.getItem("skinName")) {
      case tiger:
        return <View style={{ height: '100vh', width: '100vw', backgroundColor: '#ec0000', justifyContent: 'center' }}><img style={{ objectFit: 'contain', alignSelf: 'center', height: inIframe() ? '300px': hp('40%'), width: inIframe() ? '300px': wp('40%'), borderRadius:  inIframe() ? '300px':(hp('40%') + wp('40%')) / 2 }} src={logo} alt="loading..." /></View>
      case zebra:
        return <View style={{ height: '100vh', width: '100vw', backgroundColor: handleBgColor() }} />
        default:
          return <Loadingscreen />
    }
  }

  return (
    <>
    {
      showIntroPage ? <IntroPage setShowIntroPage={setShowIntroPage} /> :
      <>
        {(userSession !== null && riskCalculation !== undefined) ?
          <ActivityContext.Provider
            value={{
              result,
              setAnswer,
              setModuleStartedAtTime,
              setModuleCompletedAtTime,
              riskCalculation,
              userSession,
              userref: props.userref,
              sessionref: props.sessionref,
              resetModuleResult,
              resetPreviousModuleResult
            }}>
            <Switch>
              <Route
                exact path="/"
                render={() => {
                  return (
                    <>
                      {loader ? loaderGif() :
                        handleSkin()
                      }
                    </>
                  );
                }}
              />
              <Route
                exact path="/sukeys/:root"
                render={() => {
                  return (
                    <>
                      {loader ? loaderGif() :
                        handleSkin()
                      }
                    </>

                  );
                }}
              />
              <Route
                exact path="/sessions/:root"
                render={() => {
                  return (
                    <>
                      {loader ? loaderGif() :
                        handleSkin()
                      }
                    </>

                  );
                }}
              />
              <Route
                exact path="/:root"
                render={() => {
                  return (
                    <>
                      {loader ? loaderGif() :
                        handleSkin()
                      }
                    </>

                  );
                }}
              />
              <Route exact path="/userresult/result" render={() => <ResultPage userref={props.userref} sessionref={props.sessionref} skin={props.skin} {...props} />} />
              <Route
                path="/activityType/:activityType"
                render={() => <ChooseActivity {...props} setfinishAnimate={setfinishAnimate} />}
              />
            </Switch>
          </ActivityContext.Provider>
          : loaderGif()
        }
      </>
    }
    </>

  );
};

export default withRouter(Index);

const styles = StyleSheet.create({
  chooseActivityTitle: {
    width: '100%',
    height: 49,
    fontFamily: 'OpenSans-Bold',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff'
  },
  homeContainer: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    backgroundColor: '#ec0000',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  intructionContainer: {
    // top:"7%",
    position: 'relative',
    width: '100%',
    alignSelf: 'flex-end'
  },
  instruction: {
    width: '100%',
    height: 40,
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#ffffff'
  },
  moduleButtons: {
    marginTop: 50,
  },
  recatangleLeft: {
    width: 18,
    height: '100%',
    backgroundColor: '#ff2b2c',
  },
  recatangleRight: {
    width: 18,
    height: '100%',
    backgroundColor: '#d00301',
  },
  post: {
    height: '100%',
    zIndex: -1,
    position: 'absolute',
    top: Dimensions.get('screen').width < 1400 ? Dimensions.get('screen').width < 1000 ? hp('35%') : hp('28%') : Dimensions.get('screen').width < 2600 ? hp('40%') : hp('45%'),
    flexDirection: 'row',
    alignSelf: 'center'
  },
  allDoneDescription: {
    color: 'white',
    fontSize: 22,
    marginBottom: 20,
    marginTop: 10,
    bottom: 10,
    justifyContent: 'center',
    textAlign: 'center',
  },
  cornertwo: {
    width: 17,
    height: 17,
    borderRightWidth: '4px',
    borderRightColor: 'white',
    borderBottomColor: 'white',
    borderBottomWidth: '4px',
  }
});
