import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
const Index = (props) => {
    return (
        <View style={styles.content}>
            <View style={styles.headingContainer}>
                <View style={styles.Line} />
                <Text style={styles.header}>{props.moduleObject.name}</Text>
                <View style={styles.Line2} />
            </View>
            <Text style={styles.customText}>
                The following ten statements aim to gauge your feeling towards risk.
       {"\n"}{"\n"}
There are no right or wrong answers, choose honestly and we’ll be able to pick the investment you’ll feel most comfortable with.
        </Text>
            <TouchableOpacity onPress={props.Showing} style={styles.button}>
                <Text style={styles.buttontext}>GET STARTED</Text>
            </TouchableOpacity>
        </View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        // flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 750 ? 660 : Dimensions.get('screen').width > 400? 366 : 300,
        Height: hp('24.3%'),
        fontSize:Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400? 20 : 16,
        fontStyle: 'normal',
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.75)',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        // lineHeight: 26.5,
        letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400? 2 : 1.6,
    },
    button: {
        backgroundColor: '#6fd5da',
        // width:'auto',
        width: 191,
        minHeight: 48,
        textAlignVertical: "center",
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: 20,
        paddingTop: 17,
        width: "100%",
        // width:"100%",
        textAlign: "center",
        height: "100%",
        // paddingHorizontal:"50%",
        textAlignVertical: "center",
        alignSelf: "center",
        letterSpacing: 2,
    },
    headingContainer: {
        width: 315,
        height: 70,
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: hp("4%")
    },
    Line: {
        width: 183,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    Line2: {
        width: 183,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    header: {
        position: "relative",
        width: "100%",
        height: "50%",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize:Dimensions.get('screen').width > 400 ? 48:  32,
        letterSpacing: Dimensions.get('screen').width > 400 ? 4.8:  3.2,
        fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        color: '#575f69',
        textTransform: "uppercase"
    },
})