import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Image,
    Easing
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
import First from './firstIntropage';
import Second from './secondIntroPage';
import Third from './thirdIntroPage';
const deviceWidth = Dimensions.get('screen').width;
export default function Index(props) {

    const tBar = useRef(new Animated.Value(0)).current;
    const [display, setDisplay] = useState(false);
    const [page1, setPage1] = useState(true);
    const [page2, setPage2] = useState(true);
    const [firstPageA, setFirstPageA] = useState(null);
    useLayoutEffect(() => {
        Animated.timing(tBar, {
            toValue: 1,
            duration: 800,
            useNativeDriver: false,
            easing: Easing.linear
        }).start(() => setDisplay(true));
    }, [])

    const topBar = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%']
    });

    const topBar2 = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: [deviceWidth > 1300 ? -126 : -102, 0]
    });
    const pageOne = (id) => {
        setPage1(false)
        setFirstPageA(id)
    };
    const pageTwo = () => {
        setPage2(false)
    };

    return (
        <View style={[styles.container, { height: '100vh' }]}>
            <Animated.View style={[styles.topborder, { width: '100%' }]}></Animated.View>
            <Image style={styles.Image}
                source={require('../../../assets/images/risklogo.png')} ></Image>
            {
                display && (page1 ? (<First {...props} pageOne={pageOne} />) : (page2 ? (<Second {...props} pageTwo={pageTwo} firstPageA={firstPageA} />) : <Third {...props} />))
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100vw",
        //flex: 1,
        backgroundColor: '#eeeeee',
        overflow: 'hidden'
    },
    topborder: {
        position: "relative",
        alignSelf: 'center',
        borderTopColor: "#6fd5da",
        borderTopWidth: 3,
        marginBottom: hp("1.2%")
    },
    Image: {
        position: "relative",
        alignSelf: "flex-end",
        marginLeft: '90vw',
        width: 48,
        resizeMode: "contain",
        marginRight: 8,
        height: 12
    },
});
