import React, { useState, useRef } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import Intro from './Intro';
import Firstpage from './firstPage';
import Secondpage from './secondPage';
import Thirdpage from './thirdPage';
import Fourthpage from './fourthPage';
import Fifthpage from './fifthPage';
import HeaderSkin3 from '../../../../Components/HeaderSkin3/index';
const deviceWidth = Dimensions.get('screen').width;
export default function Index(props) {

    const [display, setDisplay] = useState(true);
    const [pageSecond, setPageSecond] = useState(true);
    const [pageThird, setPageThird] = useState(true);
    const [pageFourth, setPageFourth] = useState(true);
    const [pageFifth, setPageFifth] = useState(true);
    const [skippedQuestion, setSkippedQuestion] = useState(false);

    const Refresh = () => {
        setPageSecond(false)
    };
    const pageThree = () => {
        setPageThird(false)
    };
    const pageFour = () => {
        setPageFourth(false)
    };
    const pageFive = () => {
        setPageFifth(false)
    };
    return (
        <div style={{
            width: "100%",
            height: '100%',
            position:'absolute',
            backgroundColor: '#ffeec9',
            overflow: 'hidden'
        }}>
            <HeaderSkin3 currentRoute={"Understanding"} />

            {
                display ?
                    (<Intro setDisplay={setDisplay} />)
                    : (pageSecond ?
                        <Firstpage Refresh={Refresh} {...props} />
                        : (pageThird ?
                            <Secondpage Refresh={pageThree} setSkippedQuestion={setSkippedQuestion} skippedQuestion={skippedQuestion} {...props} /> :
                            (pageFourth ?
                                <Thirdpage Refresh={pageFour} {...props} setSkippedQuestion={setSkippedQuestion} skippedQuestion={skippedQuestion} />
                                : (pageFifth ?
                                    <Fourthpage Refresh={pageFive} {...props} setSkippedQuestion={setSkippedQuestion} skippedQuestion={skippedQuestion} /> : <Fifthpage {...props} setSkippedQuestion={setSkippedQuestion} skippedQuestion={skippedQuestion} />))))
            }
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100vw",
        flex: 1,
        backgroundColor: '#ffeec9',
        overflow: 'hidden'
    },
    topborder: {
        position: "relative",
        alignSelf: 'center',
        borderTopColor: "#fda435",
        borderTopWidth: 3,
        marginBottom: hp("1.2%")
    },
    Image: {
        position: "relative",
        alignSelf: "flex-end",
        marginLeft: '90vw',
        width: 48,
        resizeMode: "contain",
        marginRight: 8,
        height: 12
    },
});
