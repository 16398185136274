import React from 'react';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
import '../../App.css';
const {
  View,
  StyleSheet,
  Text,
  Image,
  Dimensions
} = require('react-native');

export const TriangleCorner = (props) => {
  return (
    <>
      <View style={styles.rectangle}>
        <View style={{ paddingVertical: 24 }}>
          <Image style={styles.opequotes} source={require('../../assets/images/open-quotation-mark.png')}></Image>
          <Text style={styles.triangleCornerText}>{props.test}</Text>
          <Image style={styles.closequotes} source={require('../../assets/images/close-quotation-mark.png')}></Image>
        </View>
      </View>
      <View style={[styles.triangleCorner]} />
    </>
  );
};

const styles = StyleSheet.create({
  triangleCorner: {
    marginLeft: wp('2%'),
    width: hp('0%'),
    height: hp('0%'),
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: Dimensions.get('screen').width > 1200 ? wp('3%') : wp('7%'),
    borderTopWidth: Dimensions.get('screen').width > 1200 ? wp('3%') : wp('7%'),
    borderRightColor: 'transparent',
    borderTopColor: 'rgba(111, 213, 218, 0.15)',
    marginBottom: hp('2%'),
    transform: [
      { rotate: '90deg' }
    ]
  },
  rectangle: {
    marginLeft: wp('2%'),
    width: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? wp('30%') : wp('70%') : wp('95%'),
    backgroundColor: 'rgba(111, 213, 218, 0.15);',
  },

  triangleCornerText: {
    padding: hp('3.5%'),
    fontSize: Dimensions.get('screen').width > 1300 ? 24 : hp('3%'),
    fontFamily: 'JosefinSans-Light',
    color: '#575f69',
    fontWeight: "300",
    fontStyle: 'normal',
    letterSpacing: 2.4
  },
  opequotes: {
    position: 'relative',
    top: hp('1%'),
    left: wp('2%'),
    height: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? hp('4.5%') : hp('4.5%') : hp('3.5%'),
    width: Dimensions.get('screen').width > 1200 ? wp('3.5%') : wp('8%')
  },
  closequotes: {
    position: 'relative',
    bottom: hp('1%'),
    left: wp('2%'),
    height: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? hp('4.5%') : hp('4.5%') : hp('3.5%'),
    width: Dimensions.get('screen').width > 1200 ? wp('3.5%') : wp('8%')
  }
});
