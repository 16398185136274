import React, { useState, useLayoutEffect } from 'react';
import FutureSkinOne from './FutureSkin1/index';
import FutureSkinTwo from './FutureSkin2/FutureSkin2';
import FutureSkinThree from './FutureSkin3/index';
import { tiger, zebra } from '../../../Utils/static-strings'
const FutureMain = (props) => {
    const [skin, setSkin] = useState(null);

    useLayoutEffect(() => {
        if (props.skin) {
            setSkin(props.skin)
        }
    }, [])

    const FutureSkinSwitch = () => {
        switch (sessionStorage.getItem("skinName")) {
            case tiger:
                return <FutureSkinOne {...props} />
            case zebra:
                return <FutureSkinThree {...props} />
            default:
                return <FutureSkinTwo {...props} />
        }
    }

    return (
        <React.Fragment>
            {
                skin !== null && FutureSkinSwitch()
            }
        </React.Fragment>
    )
}

export default FutureMain
