import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Animated,
    TouchableOpacity,
    Dimensions,
    Image,
    Easing
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { TriangleCorner } from '../../../../Components/ButtonOption/index3';
import Intro from './intro';
import '../../../../App.css';
import Quant from '../../AtrQuants/index';
import { moduleid } from '../../../../Utils/static-strings';
import HeaderSkin3 from '../../../../Components/HeaderSkin3/index'
const deviceWidth = Dimensions.get('screen').width;
export default function Index({
    moduleIndex,
    selectAnswerHandler,
    moduleObject,
    history,
    allmodule,
    resultContext,
    setfinishAnimate
}) {
    const actualWidth = Dimensions.get('screen').width;
    const animation = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animation1 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animation2 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animation3 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animation4 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const animation5 = useRef(new Animated.Value(-Dimensions.get('screen').width)).current;
    const [show, setShow] = useState(true);
    const [display, setDisplay] = useState(false);
    const [atrQuant, setatrQuant] = useState(true);
    const [atrQuantModule, setAtrQuantModule] = useState(null);
    useLayoutEffect(() => {
        const moduleArray = allmodule.filter(
            (module) => module.moduleid === moduleid.atrquant(),
        );
        setAtrQuantModule(moduleArray[0]);
    }, [])

    useEffect(() => {
        animation.setValue(-Dimensions.get('screen').width);
        animation1.setValue(-Dimensions.get('screen').width);
        animation2.setValue(-Dimensions.get('screen').width);
        animation3.setValue(-Dimensions.get('screen').width);
        animation4.setValue(-Dimensions.get('screen').width);
        animation5.setValue(-Dimensions.get('screen').width);
        Animated.parallel([
            Animated.timing(animation, {
                toValue: 0,
                duration: 600,
                useNativeDriver: false,
            }),
            Animated.timing(animation1, {
                toValue: 0,
                duration: 600,
                delay: 500,
                useNativeDriver: false,
            }),
            Animated.timing(animation2, {
                toValue: 0,
                duration: 600,
                delay: 600,
                useNativeDriver: false,
            }),
            Animated.timing(animation3, {
                toValue: 0,
                duration: 600,
                delay: 700,
                useNativeDriver: false,
            }),
            Animated.timing(animation4, {
                toValue: 0,
                duration: 600,
                delay: 800,
                useNativeDriver: false,
            }),
            Animated.timing(animation5, {
                toValue: 0,
                duration: 600,
                delay: 900,
                useNativeDriver: false,
            }),
        ]).start();
    });


    const setAnswer = (i) => {
        if (moduleIndex < 9) {
            selectAnswerHandler(
                moduleObject.moduleid,
                moduleObject.questions[moduleIndex].qid,
                moduleObject.questions[moduleIndex].answers[i].respid,
            );
        } else {
            resultContext.setAnswer(moduleObject.moduleid,
                moduleObject.questions[moduleIndex].qid,
                moduleObject.questions[moduleIndex].answers[i].respid);
            resultContext.setModuleCompletedAtTime(moduleObject.moduleid);
            setfinishAnimate(moduleObject.moduleid)
            history.push('/');
            // if (atrQuantModule == undefined || atrQuantModule == null) {
            //     history.push('/');
            // } else {
            //     setatrQuant(false)
            // }
        }
    };
    const Showing = () => {
        setShow(false);
    }
    return (
        <>{atrQuant ? (<>
            <div style={{
                 width: "100%",
                 // flex: 1,
                 height: '100%',
                 position:'absolute',
                //  display:'flex',
                //  justifyContent: 'center',
                //  alignItems: 'center',
                 backgroundColor: '#c3e0b5',
                 overflow: 'hidden'
            }}>
                <HeaderSkin3 currentRoute={"Understanding"} />
                {show ? (
                    <>
                        <div
                            style={{
                                height:"100%",
                                width:'100%'
                                // transform: [{ translateX: animation }] 
                            }}
                        >
                            <Intro Showing={Showing} moduleObject={moduleObject} />
                        </div>
                    </>

                ) : (<>
                    {/* <HeaderSkin3 currentRoute={"Understanding"} /> */}
                    <View style={[{height:"100%",width:"100%", justifyContent: 'center', alignItems: 'center' }, actualWidth > 760 ? { marginBottom: hp('2%') } : null]}>
                        <View  >
                            <Animated.View style={{ transform: [{ translateX: animation }] }}>
                                <TriangleCorner test={moduleObject.questions[moduleIndex].text} />
                            </Animated.View>
                        </View>
                        <View>
                            <Animated.View style={{ transform: [{ translateX: animation1 }] }}>
                                <TouchableOpacity style={styles.button1} onPress={() => setAnswer(0)}>
                                    <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[0].text}</Text>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{ transform: [{ translateX: animation2 }] }}>
                                <TouchableOpacity style={styles.button2} onPress={() => setAnswer(1)}>
                                    <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[1].text}</Text>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{ transform: [{ translateX: animation3 }] }}>
                                <TouchableOpacity style={styles.button3} onPress={() => setAnswer(2)}>
                                    <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[2].text}</Text>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{ transform: [{ translateX: animation4 }] }}>
                                <TouchableOpacity style={styles.button4} onPress={() => setAnswer(3)}>
                                    <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[3].text}</Text>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{ transform: [{ translateX: animation5 }] }}>
                                <TouchableOpacity style={styles.button5} onPress={() => setAnswer(4)}>
                                    <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[4].text}</Text>
                                </TouchableOpacity>
                            </Animated.View>
                        </View>
                    </View></>
                )}
            </div></>) :
            <Quant
                resultContext={resultContext}
                history={history}
                module={atrQuantModule}
            />
        }
        </>
    );
}

const styles = StyleSheet.create({
    container1: {
        width: "100vw",
        height:"100vh",
        //flex: 1,
        backgroundColor: '#eeeeee',
        overflow: 'hidden'
    },
    container: {
        width: "100%",
        // flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#c3e0b5',
        overflow: 'hidden'
    },
    topborder: {
        position: "relative",
        alignSelf: 'center',
        borderTopColor: "#6fd5da",
        borderTopWidth: 3,
        marginBottom: hp("1.2%")
    },
    Image: {
        position: "relative",
        alignSelf: "flex-end",
        marginLeft: '90vw',
        width: 48,
        resizeMode: "contain",
        marginRight: 8,
        height: 12
    },
    button1: {
        // marginTop:10,
        backgroundColor: '#2f2f2f',
        width: 'auto',
        alignSelf: 'center',
        borderRadius: 2
    },
    button2: {
        marginTop: 5,
        backgroundColor: '#2f2f2f',
        width: 'auto',
        alignSelf: 'center',
        borderRadius: 2
    },
    button3: {
        marginTop: 5,
        backgroundColor: '#2f2f2f',
        width: 'auto',
        alignSelf: 'center',
        borderRadius: 2
    },
    button4: {
        marginTop: 5,
        backgroundColor: '#2f2f2f',
        width: 'auto',
        alignSelf: 'center',
        borderRadius: 2
    },
    button5: {
        marginTop: 5,
        backgroundColor: '#2f2f2f',
        width: 'auto',
        alignSelf: 'center',
        borderRadius: 2
    },
    button5text: {
        alignSelf: 'center',
        paddingHorizontal: 20,
        paddingTop: 9,
        paddingBottom: 8,
        color: '#ffffff',
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center',
        fontWeight: '600',
        // textTransform: 'capitalize'
    }
});