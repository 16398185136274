import { StyleSheet, Dimensions } from 'react-native';
import '../../../../../../App.css';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../../../Utils/responsive';

const actualWidth = Dimensions.get('screen').width;
const backgroundColor = '#f5d3e4';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '99vh',
        width: '100vw',
        backgroundColor
    },
    body: {
        height: actualWidth > 600 ? "70%" : "73%",
        width: "100%",
        justifyContent: "center",
        // alignItems: "center",
    },
    bodyHeader: {
        // justifyContent: "center",
        // alignItems: 'center',
        marginLeft: "5%"
    },
    headerText: {
        // textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 50,
        fontWeight: "600",
        color: '#2f2f2f',
        textTransform: "uppercase"
    },
    bodyTextContainer: {
        justifyContent: "center",
        // alignItems: 'center',
        width: actualWidth > 900 ? "40%" : actualWidth > 600 ? "78%" : "90%",
        // alignSelf: 'center',
        marginTop: '1%',
        marginBottom: '1.5%',
        marginLeft: "5%",
        paddingRight: '5%'
    },
    bodyText: {
        // textAlign: "center",
        fontFamily: 'Manrope-Regular',
        fontSize: 16,
        color: '#2f2f2f',
        lineHeight: 24,
        fontStyle: "normal",
    },
    button: {
        height: 40,
        width: 115,
        backgroundColor: "#2f2f2f",
        justifyContent: "center",
        alignItems: 'center',
        marginLeft: "5%"
    },
    buttonText: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        fontWeight: "600",
        color: '#ffffff',
        lineHeight: 24
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    footerCircle: {
        height: 300,
        width: 300,
        borderTopLeftRadius: 300,
        borderTopRightRadius: 300,
        // borderRadius: 300,
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        marginBottom: 50
    },

    customText: {
        position: "relative",
        width: wp('100%'),
        height: 'auto',
        fontSize: 20,
        fontWeight: '800',
        fontStyle: 'normal',
        letterSpacing: 0.5,
        textAlign: 'center',
        color: '#2f2f2f',
        fontFamily: "Manrope-ExtraLight",
        // top: 40
    },

})
