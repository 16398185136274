import React ,{useEffect} from 'react'
import { Text, View, StyleSheet, Dimensions, TouchableOpacity ,PixelRatio, } from 'react-native'
import { screenWidth as actualWidth , screenHeight as actualHeight } from '../../../../Utils/responsive'
import '../../../../../src/App.css';
import Rotate1 from './Rotate1';
import { inIframe } from '../../../../Utils/helper';
// const screenWidth = Dimensions.get('window').width;
// const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }


  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);

  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  return (
    <View style={styles.content}>
      <Text style={[styles.textStyle,{    fontSize:inIframe() ? 22.5: screenWidth <= 1125 ? wp('2.4%') : (screenWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
}]}>
        Imagine you’re investing £1,000 over the next 9 months.{`\n`}Where the chances of a gain, or a loss, are both 50%.
        </Text>
      <View style={{ height: inIframe() ? 573.5: screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : (screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : wp(`${2.7 * 12}%`)), width:inIframe() ? 573.5: screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : (screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : wp(`${2.7 * 12}%`)) }}>
        <Rotate1
          gain={80}
          loss={10}
          lossRatio={0.5}
          primaryColorBlue={props.primaryColorBlue}
          primaryColorPurple={props.primaryColorPurple}
          spin={props.spin}
          transform={props.transform}
          show={props.show}
        />
      </View>
      <View style={{ height: '10%', justifyContent: 'flex-end', position: 'absolute', bottom: 5 }}>
        <Text style={[styles.textStyle,{    fontSize:inIframe() ? 22.5: screenWidth <= 1125 ? wp('2.4%') : (screenWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
        }]}>
          There’s a 50% chance of losing £10,{`\n`}but also a 50% chance of gaining £80 over the same period.
            </Text>
        <TouchableOpacity style={[styles.introButton,{
    marginBottom:inIframe() ? '1%': screenWidth > 1300 ? '4%' : '1%'}]} onPress={() => props.Showing()}>
          <Text style={styles.buttonText}>OK</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default Index;

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '99vh',
    width: '100vw'
  },
  textStyle: {
    color: 'white',
    letterSpacing: 0.5,
    fontFamily: 'OpenSans-Light',
    fontWeight: 'normal',
    fontStyle: 'normal',
    position: 'relative',
  },
  introButton: {
    minHeight: 48,
    width: 170,
    borderRadius: 50,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    paddingVertical: 15,
    color: '#a81c1b',
    position: "relative",
    marginTop: '1%',
  },
  buttonText: {

    color: '#a81c1b',
    fontSize: 22,
    textAlign: 'center',

  },
})
