import React from 'react'
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import '../../../App.css'

const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;
const backgroundColor = '#91d5d3';

export const styles = StyleSheet.create({
    container: {
        height: '100vh',
        width: '100vw',
        display: "flex",
        justifyContent: "space-between",
        backgroundColor
    },
    body: {
        height: actualWidth > 600 ? "70%" : "73%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    bodyHeader: {
        justifyContent: "center",
        alignItems: 'center',
        marginBottom: actualWidth < 600 ? 10 : 0
    },
    headerText: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 28,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: 32
    },
    bodyTextContainer: {
        justifyContent: "center",
        alignItems: 'center',
        width: actualWidth > 900 ? "40%" : actualWidth > 600 ? "78%" : "90%",
        alignSelf: 'center',
        marginVertical: '2%'
    },
    bodyText: {
        textAlign: "center",
        fontFamily: 'Manrope-Light',
        fontSize: 20,
        fontWeight: "300",
        color: '#2f2f2f',
        lineHeight: 28,
        fontStyle: "normal",
    },
    button: {
        height: 40,
        width: 115,
        backgroundColor: "#2f2f2f",
        justifyContent: "center",
        alignItems: 'center',
    },
    buttonText: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        fontWeight: "600",
        color: '#ffffff',
        lineHeight: 24
    },
    footer: {
        height: "15%",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: actualWidth < 600 ? 14 : 0
    },
    footerCircle: {
        height: 300,
        width: 300,
        borderTopLeftRadius: 300,
        borderTopRightRadius: 300,
        // borderRadius: 300,
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        marginBottom: 50
    },

    animatedCircleStyle: {
        backgroundColor: '#FFFDD0',
        width: 10,
        height: 10,
        borderRadius: 5,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: [{ translateX: -5 }, { translateY: -5 }],
        zIndex: 99,

    }


})
