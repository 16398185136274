import React, {useEffect, useState} from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp, widthPercentageToDP, heightPercentageToDP,
} from "../../Utils/responsive";
import { RISK_SKIN_2_HEADER } from "../../Utils/static-strings";
import smallDevice from "../../assets/svg/small.svg";
import mediumDevice from "../../assets/svg/medium.svg";
import largeDevice from "../../assets/svg/large.svg";

import "../../App.css";

const IntroPage = (props) => {

  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));

// Handle dimension changes
  useEffect(() => {
    const onChange = ({window}) => {
      setScreenDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);




  const dynamicStyles = StyleSheet.create({
    customText: {
      position: "relative",
      width: screenDimensions.width > 750 ? 660 : screenDimensions.width > 400? 366 : 300,
      Height: hp("24.3%"),
      fontSize:
          Dimensions.get("screen").width > 600
              ? 28
              : Dimensions.get("screen").width > 400
                  ? 20
                  : 16,
      fontStyle: "normal",
      textAlign: "center",
      color: "rgba(87, 95, 105, 0.75)",
      marginBottom: hp("4%"),
      fontFamily: "JosefinSans-Light",
      letterSpacing:
          Dimensions.get("screen").width > 600
              ? 2.8
              : Dimensions.get("screen").width > 400
                  ? 2
                  : 1.6,
      // lineHeight: 26.5,
    },
  });

  return (
    <View style={styles.content}>
      <View style={styles.headingContainer}>
        {/* <View style={styles.Line} /> */}
        <Text style={styles.header}>{RISK_SKIN_2_HEADER}</Text>
        {/* <View style={styles.Line2} /> */}
      </View>
      <Text style={dynamicStyles.customText}>
        How you feel about risk, your attitudes and your understanding.
        {"\n"}
        {"\n"}
        The next few screens take the form of interactive exercises.
        {"\n"}
        {"\n"}
        Please respond honestly without over thinking.
      </Text>
      <TouchableOpacity
        onPress={() => props.setShowIntroPage((prev) => !prev)}
        style={styles.button}
      >
        <Text style={styles.buttonText}>GET STARTED</Text>
      </TouchableOpacity>

      <View style={styles.bottomLogo}>
        <img
          src={
            Dimensions.get("screen").width > 600
              ? largeDevice
              : Dimensions.get("screen").width > 400
              ? mediumDevice
              : smallDevice
          }
          alt="logo"
        />
      </View>
    </View>
  );
};

const screenHeight = Dimensions.get('window').height;

const getResponsiveFontSize = (size) => {
  const baseScreenHeight = 680; // Based on standard screen height
  let scaleRatio = screenHeight / baseScreenHeight;
  if(scaleRatio > 1.69){
    scaleRatio = 1.69
  }
  return size * scaleRatio;
};

export default IntroPage;
const styles = StyleSheet.create({
  content: {
    height: "100vh",
    width: "100vw",
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  customText: {
    position: "relative",
    Height: hp("24.3%"),
    fontSize:
      Dimensions.get("screen").width > 600
        ? 28
        : Dimensions.get("screen").width > 400
        ? 20
        : 16,
    fontStyle: "normal",
    textAlign: "center",
    color: "rgba(87, 95, 105, 0.75)",
    marginBottom: hp("4%"),
    fontFamily: "JosefinSans-Light",
    letterSpacing:
      Dimensions.get("screen").width > 600
        ? 2.8
        : Dimensions.get("screen").width > 400
        ? 2
        : 1.6,
    // lineHeight: 26.5,
  },
  button: {
    // backgroundColor: '#575f69',
    backgroundColor: "#6fd5da",
    // width:'auto',
    // width: 191,
    minHeight: 48,
    textAlignVertical: "center",
  },
  buttonText: {
    color: "#ffffff",
    fontFamily: "JosefinSans-Bold",
    fontSize: 20,
    // paddingTop: 17,
    // padding: '15px 10px 10px 10px',
    width: "100%",
    // width:"100%",
    textAlign: "center",
    height: "100%",
    // paddingHorizontal:"50%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
    padding: "10px",
    paddingTop: 15,
  },
  headingContainer: {
    width: Dimensions.get("screen").width > 750 ? "auto" : 270,
    // height: 70,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: hp("4%"),
  },
  Line: {
    width: 183,
    height: 1,
    backgroundColor: "#d4d4d4",
    alignSelf: "center",
  },
  Line2: {
    width: 183,
    height: 1,
    backgroundColor: "#d4d4d4",
    alignSelf: "center",
  },
  header: {
    position: "relative",
    width: "100%",
    // height: "50%",
    alignSelf: "center",
    fontFamily: "JosefinSans-Light",
    fontSize:
        getResponsiveFontSize(36),
    letterSpacing: Dimensions.get("screen").width > 450 ? 4.8 : 2.8,
    fontWeight: "300",
    textAlignVertical: "center",
    textAlign: "center",
    color: "#575f69",
    textTransform: "uppercase",
    // paddingVertical:hp("2%"),
    paddingTop: 16,
    paddingBottom: 12,
    borderTopColor: "rgb(212, 212, 212)",
    borderBottomColor: "rgb(212, 212, 212)",
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  bottomLogo: {
    position: "absolute",
    bottom:
      Dimensions.get("screen").width > 600
        ? -99
        : Dimensions.get("screen").width > 400
        ? -49
        : -46,
  },
});
