import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Animated,
  PixelRatio
} from 'react-native';
import {screenWidth as actualWidth, screenHeight as actualHeight} from '../../Utils/responsive'
import '../../App.css'
import {inIframe} from '../../Utils/helper';

const Index = (props) => {
  const [screenWidth, setScreenWidth] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth(t.window.width);
    setScreenHeight(t.window.height);

  });


  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  const customAnimation = useState(
      new Animated.Value(Dimensions.get('screen').width),
  )[0];
  useEffect(() => {
    customAnimation.setValue(Dimensions.get('screen').width);
    Animated.timing(customAnimation, {
      toValue: 20,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  }, []);

  return (
      <View style={styles.container}>
        <Animated.View
            style={[{transform: [{translateX: customAnimation}]}, styles.custom]}>
          <View style={styles.firstText}>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>Imagine you're holding an investment which<br/>offers two exits.</Text>
          </View>
          <View style={styles.secondText}>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>Your choice of investment</Text>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>'today' will impact the money</Text>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>you could receive 'tomorrow'.</Text>
          </View>
          <View style={styles.thirdText}>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>This activity looks at how</Text>
            <Text style={[styles.textStyle, {
              fontSize: inIframe() ? 18 : hp("2.5%"),
            }]}>time affects your choice.</Text>
          </View>
        </Animated.View>
        <Animated.View style={[{transform: [{translateX: customAnimation}]}]}>
          <TouchableOpacity
              onPress={() => props.handleStart()}
              style={styles.button}>
            <Text style={styles.textStyle2}>Get started</Text>
          </TouchableOpacity>
        </Animated.View>
      </View>
  );
};

export default Index;

const styles = StyleSheet.create({
  container: {
    color: '#a81c1b',
    flex: 1,
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    backgroundColor: '#9f3469',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    position: "relative",
    width: "150px",
    // height: "40px",
    height: "auto",
    // paddingVertical: 8,
    fontFamily: 'OpenSans-Regular',
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 24,
    // left: 3,
    zIndex: 99999999999,
    marginTop: '10%',
    backgroundColor: '#ffffff',
    alignSelf: 'center'
  },
  textStyle: {
    fontWeight: '400',
    color: '#ffffff',
    fontFamily: 'OpenSans-Regular',
  },
  textStyle2: {
    fontSize: "19px",
    height: "100%",
    width: "100%",
    textAlign: 'center',
    paddingVertical: "8px",
    fontFamily: 'OpenSans-Regular',
    // textAlignVertical:"center",

    color: '#a81c1b',
  },
  custom: {
    // alignSelf: 'center',
    position: 'relative',
    // top: hp("62.5%")
  },
  firstText: {
    marginBottom: 8,
  },
  secondText: {
    marginTop: 10,
    marginBottom: 15,
  },
});
