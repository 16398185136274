import React, { useEffect, useState, useRef, useContext } from 'react';
import { StyleSheet, View, Dimensions, Text, Animated, Easing, TouchableOpacity } from 'react-native';
import { ActivityContext } from '../../Containers/Activities';
import { moduleid } from '../../Utils/static-strings';
const deviceWidth = Dimensions.get('screen').width;
const deviceHeight = Dimensions.get('window').height;

const MobileViewModules = (props) => {
  const { modules, currentSelectedTab } = props;
  const resultContext = useContext(ActivityContext);
  const [riskPart, setRiskPart] = useState([]);
  const [understandingPart, setUnderstandingPart] = useState([]);
  const [valuePart, setValuePart] = useState(null);
  const animated = useRef(new Animated.Value(0)).current;
  const [scrollViewPos, setScrollViewPos] = useState(new Animated.ValueXY(0, 0));
  const [secondTabPos, setSecondTabPos] = useState(0);

  useEffect(() => {
    if (!!modules && !!modules.modules && modules.modules.length > 0) {
      const risks = modules.modules.filter((module) => {
        return module.tags?.includes('ui.risk')
      });
      const understanding = modules.modules.filter((module) => {
        return module.tags?.includes('ui.understanding')
      });
      const values = modules.modules.filter((module) => {
        return module.tags?.includes('ui.values')
      });
      setRiskPart(risks);
      setUnderstandingPart(understanding);
      setValuePart(values);
    }
  }, [modules]);

  const animate = (module) => {

    Animated.parallel([Animated.timing(animated, {
      toValue: deviceWidth < 500 ? props.currentModule !== null && props.currentModule === 0 ? -deviceHeight / 2 + 60 : props.currentModule === 1 ? -deviceHeight / 2 - 102 + 60 : -deviceHeight / 2 - 204 + 20 : -deviceHeight / 2 + 5,
      duration: 600,
      easing: Easing.in(Easing.ease),
      useNativeDriver: false,
    }),

    ]).start(() => { props.history.push(`/activityType/${module.moduleid}`) });
  };

  const scaling = animated.interpolate({
    inputRange: [deviceWidth < 500 ? props.currentModule !== null && props.currentModule === 0 ? -deviceHeight / 2 + 60 : props.currentModule === 1 ? -deviceHeight / 2 - 102 + 60 : -deviceHeight / 2 - 204 + 20 : -deviceHeight / 2 + 5, 0],
    outputRange: [0, 1]
  })

  const startAnimation = (currentIndex, module) => {
    props.setCurrentModule(currentIndex);
    props.setDisplayContainer(false);
    animate(module);
  }

  const selectStyle = (module, disabled) => {
    switch (module.moduleid) {
      case moduleid.risk():
        if (disabled) {
          return styles.disableOval1
        }
        else return styles.oval1;
      case moduleid.loss():
        if (disabled) {
          return styles.disableOval2
        }
        else return styles.oval2;
      case moduleid.future():
        if (disabled) {
          return styles.disableOval3
        }
        else return styles.oval3;
      case moduleid.experience():
        if (disabled) {
          return styles.disableOval3
        }
        else return styles.oval3;
      case moduleid.knowledge():
        if (disabled) {
          return styles.disableOvalKnowledge
        }
        else return styles.ovalKnowledge;
      case moduleid.esg():
        if (disabled) {
          return styles.disableOvalEsg
        }
          else return styles.ovalEsg;
    }
  }

  const checkDisabledModule = (currentModule) => {
    return (resultContext.result &&
      resultContext.result.modules.filter(
        (module) => module.moduleid === currentModule.moduleid,
      )[0].completedat !== null) || (props.details !== '' && props.details.modules.some((item) => item.moduleid === currentModule.moduleid))
  }

  const getIndex = (module) => {
    switch (module) {
      case moduleid.risk():
        return 0;
      case moduleid.loss():
        return 1;
      case moduleid.future():
        return 2;
      case moduleid.experience():
        return 3;
      case moduleid.knowledge():
        return 4;
      case moduleid.esg():
        return 5;
    }
  }

  useEffect(() => {
    if (currentSelectedTab === 2) {
      Animated.spring(scrollViewPos, {
        toValue: { x: - 146, y: 0 }
      }).start()
    }
    else if (currentSelectedTab === 1) {
      Animated.spring(scrollViewPos, {
        toValue: { x: 0, y: 0 }
      }).start();
    }
    else if (props.currentSelectedTab === 3){
      Animated.spring(scrollViewPos, {
        toValue: { x: -272, y: 0 }
      }).start();
    }

  }, [currentSelectedTab])

  const setModuleOpacity = (module, disabled) => {
    if (currentSelectedTab === 1) {
      switch (module.moduleid) {
        case moduleid.risk():
          if (disabled) {
            return 1
          } else {
            return 1;
          }
        case moduleid.loss():
          if (disabled) {
            return 1
          } else {
            return 1;
          }
        case moduleid.future():
          if (disabled) {
            return 1
          } else {
            return 1;
          }
        case moduleid.experience():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.knowledge():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.esg():
            if (disabled) {
              return 0.15
            } else {
              return 0.2;
            }
      }
    }
    else if (currentSelectedTab === 2) {
      switch (module.moduleid) {
        case moduleid.risk():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.loss():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.future():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.experience():
          if (disabled) {
            return 1
          } else {
            return 1;
          }
        case moduleid.knowledge():
          if (disabled) {
            return 1
          } else {
            return 1;
          }
        case moduleid.esg():
           if (disabled) {
           return 0.15
           } else {
           return 0.2;
           }

      }

    }
    else {
      switch (module.moduleid) {
        case moduleid.risk():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.loss():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.future():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.experience():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.knowledge():
          if (disabled) {
            return 0.15
          } else {
            return 0.2;
          }
        case moduleid.knowledge():
            if (disabled) {
              return 1
            } else {
              return 1;
            }  

      }
    }
  }

  const setDisability = (index, disabled, currentTab) => {
    if (currentTab === 1) {
      switch (index) {
        case 0:
          if (disabled) {
            return true
          }
          else return false;

        case 1:
          if (disabled) {
            return true
          }
          else return false;

        case 2:
          if (disabled) {
            return true
          }
          else return false;

        case 3:
          return true

        case 4:
          return true;

        case 5:
          return true;  
      }
    }
    else if (currentTab === 2) {
      switch (index) {
        case 0:
          return true

        case 1:
          return true;

        case 2:
          return true;

        case 3:
          if (disabled) {
            return true
          }
          else return false;

        case 4:
          if (disabled) {
            return true
          }
          else return false;
        case 5:
          return true;   
      }
    }else if(currentTab === 3){
      switch (index) {
        case 0:
          return true

        case 1:
          return true;

        case 2:
          return true;

        case 3:
          return true;

        case 4:
          return true; 

        case 5:
          if (disabled) {
            return true
          }
          else return false;
          
      }
    }
  }
  return (
    <>
      <Animated.View style={[scrollViewPos.getLayout(), { flexDirection: 'row', justifyContent: 'space-between' }]}>
        <View style={{ flexDirection: 'column', height: 'fit-content', backgroundColor: (currentSelectedTab === 1 ? 'rgba(87, 95, 105, 0.1)' : 'trannsparent'), borderRadius: 88, margin: 10, padding: 5, marginTop: currentSelectedTab == 2 && -40 }}>
          {!!riskPart && riskPart.map((risk, index) => {
            return (
              <TouchableOpacity key={index} onPress={() => { startAnimation(getIndex(risk.moduleid), risk) }} disabled={setDisability(getIndex(risk.moduleid), checkDisabledModule(risk), currentSelectedTab)} style={{ opacity: props.currentModule !== null ? (props.currentModule === props.index ? 1 : 0) : 1, marginVertical: 2 }}>
                <View style={[selectStyle(risk, checkDisabledModule(risk)), { opacity: setModuleOpacity(risk, checkDisabledModule(risk)) }, { transform: [{ translateY: animated }, { scale: scaling }] }, {}]}>
                  {
                    setDisability(getIndex(risk.moduleid), checkDisabledModule(risk), currentSelectedTab) ?
                      (resultContext.riskCalculation !== undefined && resultContext.riskCalculation.calcresults !== undefined) && resultContext.riskCalculation.calcresults.filter((value) =>
                        value.moduleid == risk.moduleid).map((item) => {
                          return (
                            <>
                              <Text style={[styles.valueTextLarge, { opacity: props.currentModule !== null ? 0 : 1 }]}>{item.measure}</Text>
                              <Text style={[styles.valueTextSmall, { opacity: props.currentModule !== null ? 0 : 1 }]}>
                                {/* {props.report !== null && props.report[props.report.findIndex((values, index) => {
                                if (index > 0) {
                                  return values.moduleids[0] === item.moduleid
                                }
                              })].text} */}
                                {item.chartvalues && item.chartvalues.length > 0 && item.chartvalues[0].label}
                              </Text>
                            </>
                          )
                        })
                      :
                      <Text style={[styles.value, { opacity: props.currentModule !== null ? 0 : 1 }]}>{risk.name}</Text>
                  }
                </View>
              </TouchableOpacity>
            )
          })}
        </View>
        <View onLayout={event => { setSecondTabPos(event.nativeEvent.layout.x) }} style={{ flexDirection: 'column', height: 'fit-content', backgroundColor: (currentSelectedTab === 2 ? 'rgba(87, 95, 105, 0.1)' : 'trannsparent'), borderRadius: 88, margin: 10, padding: 5, marginLeft: currentSelectedTab == 2 ? 24 : currentSelectedTab == 1 && 20 }}>
          {!!understandingPart && understandingPart.map((understanding, index) => {
            return (
              <TouchableOpacity key={index} onPress={() => { startAnimation(getIndex(understanding.moduleid), understanding) }} disabled={setDisability(getIndex(understanding.moduleid), checkDisabledModule(understanding), currentSelectedTab)} style={{ opacity: props.currentModule !== null ? (props.currentModule === props.index ? 1 : 0) : 1, marginVertical: 2 }}>
                <View style={[selectStyle(understanding, checkDisabledModule(understanding)), { opacity: setModuleOpacity(understanding, checkDisabledModule(understanding)) }, { transform: [{ translateY: animated }, { scale: scaling }] }, {}]}>
                  {setDisability(getIndex(understanding.moduleid), checkDisabledModule(understanding), currentSelectedTab) ?
                    (resultContext.riskCalculation !== undefined && resultContext.riskCalculation.calcresults !== undefined) && resultContext.riskCalculation.calcresults.filter((value) =>
                      value.moduleid == understanding.moduleid).map((item) => {
                        return (
                          <>
                            <Text style={[styles.valueTextLarge, { opacity: props.currentModule !== null ? 0 : 1 }]}>{item.measure}</Text>
                            <Text style={[styles.valueTextSmall, { opacity: props.currentModule !== null ? 0 : 1 }]}>
                              {/* {props.report !== null && props.report[props.report.findIndex((values, index) => {
                                if (index > 0) {
                                  return values.moduleids[0] === item.moduleid
                                }
                              })].text} */}
                              {item.chartvalues && item.chartvalues.length > 0 && item.chartvalues[0].label}
                            </Text>
                          </>
                        )
                      })
                    :
                    <Text style={[styles.value, { opacity: props.currentModule !== null ? 0 : 1 }]}>{understanding.name}</Text>
                  }
                </View>
              </TouchableOpacity>
            )
          })}
        </View>
        <View onLayout={event => { setSecondTabPos(event.nativeEvent.layout.x) }} style={{ flexDirection: 'column', height: 'fit-content', backgroundColor: (currentSelectedTab === 3 ? 'rgba(87, 95, 105, 0.1)' : 'trannsparent'), borderRadius: 88, margin: 10, padding: 5, marginLeft: currentSelectedTab == 3 ? 24 : currentSelectedTab == 2 && 20 }}>
          {!!valuePart && valuePart.map((understanding, index) => {
            return (
              <TouchableOpacity key={index} onPress={() => { startAnimation(getIndex(understanding.moduleid), understanding) }} disabled={setDisability(getIndex(understanding.moduleid), checkDisabledModule(understanding), currentSelectedTab)} style={{ opacity: props.currentModule !== null ? (props.currentModule === props.index ? 1 : 0) : 1, marginVertical: 2 }}>
                <View style={[selectStyle(understanding, checkDisabledModule(understanding)), { opacity: setModuleOpacity(understanding, checkDisabledModule(understanding)) }, { transform: [{ translateY: animated }, { scale: scaling }] }, {}]}>
                  {setDisability(getIndex(understanding.moduleid), checkDisabledModule(understanding), currentSelectedTab) ?
                    (resultContext.riskCalculation !== undefined && resultContext.riskCalculation.calcresults !== undefined) && resultContext.riskCalculation.calcresults.filter((value) =>
                      value.moduleid == understanding.moduleid).map((item) => {
                        return (
                          <>
                            <Text style={[styles.valueTextLarge, { opacity: props.currentModule !== null ? 0 : 1 }]}>{item.measure}</Text>
                            <Text style={[styles.valueTextSmall, { opacity: props.currentModule !== null ? 0 : 1 }]}>
                              {/* {props.report !== null && props.report[props.report.findIndex((values, index) => {
                                if (index > 0) {
                                  return values.moduleids[0] === item.moduleid
                                }
                              })].text} */}
                              {item.chartvalues && item.chartvalues.length > 0 && item.chartvalues[0].label}
                            </Text>
                          </>
                        )
                      })
                    :
                    <Text style={[styles.value, { opacity: props.currentModule !== null ? 0 : 1 }]}>{understanding.name}</Text>
                  }
                </View>
              </TouchableOpacity>
            )
          })}
        </View>
        {/* {modules.modules.map((module, index) => {
          for (let i = 0; i < 3; i++) {
            return (
              <View style={{ flexDirection: 'column' }}>
                <Text>Ram</Text>
                <Text>Sita</Text>
                <Text>Gita</Text>
              </View>
            )
          }
        })} */}

      </Animated.View>
    </>
  )
}

const styles = StyleSheet.create({
  oval1: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#6fd5da",
    backgroundColor: "#6fd5da",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: deviceWidth > 500 ? 250 : 0,
    // marginTop: deviceWidth > 500 ? 0 : 205

  },
  oval2: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#79a2d9",
    backgroundColor: "#79a2d9",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  oval3: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#fda435",
    backgroundColor: "#fda435",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  ovalEsg: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#4bd5ad",
    backgroundColor: "#4bd5ad",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  ovalKnowledge: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#bb6fda",
    backgroundColor: "#bb6fda",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: 5
  },
  disableOval1: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#6fd5da",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // width: deviceWidth > 1300 ? 124 : 102,
    // height: deviceWidth > 1300 ? 124 : 102,
    // borderWidth: 3,
    // borderRadius: 88,
    // borderStyle: "solid",
    // borderColor: "#6fd5da",
    // backgroundColor: "#6fd5da",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOval2: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#79a2d9",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // width: deviceWidth > 1300 ? 124 : 102,
    // height: deviceWidth > 1300 ? 124 : 102,
    // borderWidth: 3,
    // borderRadius: 88,
    // borderStyle: "solid",
    // borderColor: "#79a2d9",
    // backgroundColor: "#79a2d9",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOval3: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#fda435",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // width: deviceWidth > 1300 ? 124 : 102,
    // height: deviceWidth > 1300 ? 124 : 102,
    // borderWidth: 3,
    // borderRadius: 88,
    // borderStyle: "solid",
    // borderColor: "#fda435",
    // backgroundColor: "#fda435",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOvalKnowledge: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#bb6fda",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // width: deviceWidth > 1300 ? 124 : 102,
    // height: deviceWidth > 1300 ? 124 : 102,
    // borderWidth: 3,
    // borderRadius: 88,
    // borderStyle: "solid",
    // borderColor: "#bb6fda",
    // backgroundColor: "#bb6fda",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  disableOvalEsg: {
    width: deviceWidth > 1300 ? 124 : 102,
    height: deviceWidth > 1300 ? 124 : 102,
    borderWidth: 3,
    borderRadius: 88,
    borderStyle: "solid",
    borderColor: "#4bd5ad",
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center"
    // width: deviceWidth > 1300 ? 124 : 102,
    // height: deviceWidth > 1300 ? 124 : 102,
    // borderWidth: 3,
    // borderRadius: 88,
    // borderStyle: "solid",
    // borderColor: "#79a2d9",
    // backgroundColor: "#79a2d9",
    // alignItems: "center",
    // opacity: 0.15,
    // justifyContent: "center",
  },
  value: {
    width: 105,
    height: 27,
    fontFamily: 'JosefinSans-Regular',
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 26.5,
    // zIndex:99999,
    // backgroundColor:"blue",
    color: '#ffffff',
  },
  label: {
    width: 105,
    height: 13,
    fontFamily: 'JosefinSans-SemiBold',
    fontSize: 10,
    fontWeight: '600',
    textAlign: 'center',
    color: '#575f69',
  },
  valueTextLarge: {
    width: 105,
    height: 27,
    fontFamily: 'JosefinSans-Regular',
    fontSize: 18,
    textAlign: 'center',
    // zIndex:99999,
    // backgroundColor:"blue",
    color: '#575f69',
  },
  valueTextSmall: {
    width: 90,
    height: 13,
    fontFamily: 'JosefinSans-SemiBold',
    fontSize: 9,
    fontWeight: '600',
    textAlign: 'center',
    color: '#575f69',
    textTransform: 'uppercase',
    alignSelf: 'center'
  }
})
export default MobileViewModules;