import React from 'react'
import { Dimensions} from 'react-native'
const actualWidth = Dimensions.get('screen').width;
const DragGames = (props) => {
  
    return (<>
        {props.iconKey == "planet" &&

                <img src={require("../../../../../src/assets/svg/Token-Planet.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "people" &&

                <img src={require("../../../../../src/assets/svg/Token-People.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "planetandpeople" &&

                <img src={require("../../../../../src/assets/svg/Token-PeoplePlanet.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "planetmoney" &&

                <img src={require("../../../../../src/assets/svg/Token-PlanetOverReturns.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "moneyplanet" &&

                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPlanet.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
         {props.iconKey == "positiveonly" &&

                <img src={require("../../../../../src/assets/svg/Token-PositiveImpact.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "carbonneutral" &&

                <img src={require("../../../../../src/assets/svg/Token-CarbonNeutral.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "screen" &&

                <img src={require("../../../../../src/assets/svg/Token-NoSins.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "all" &&

                <img src={require("../../../../../src/assets/svg/Token-All.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "peoplemoney" &&

                <img src={require("../../../../../src/assets/svg/Token-PeopleOverReturns.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "moneypeople" &&

                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPeople.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "bothmoney" &&

                <img src={require("../../../../../src/assets/svg/Token-BothOverReturns.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
        {props.iconKey == "moneyboth" &&

                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverBoth.svg")} style={{
                    width: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    height: actualWidth > 600 ? 114 : actualWidth > 400 ? 124 : 62,
                    objectFit: 'contain',
                }} />

        }
    </>
    )
}
export default DragGames;