import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

const Index = (props) => {
    const [scorepos, setscorepos] = useState(0);
    const [smallvalue, setsmallvalue] = useState('');
    const [thumbpos, setthumbpos] = useState(0);
    const [thumbcord, setthumbcord] = useState(0);
    const [coverwidth, setcoverwidth] = useState(0);
    useEffect(() => {
        let Lvalue = props.riskvalue < props.lossvalue ? props.lossvalue : props.riskvalue;
        let Svalue = props.riskvalue > props.lossvalue ? props.lossvalue : props.riskvalue;
        let Lvaluediff = props.score - Lvalue;
        let Svaluediff = props.score - Svalue;
        if (Lvaluediff < 0) {
            Lvaluediff = (Lvaluediff * (-1));
        }
        if (Svaluediff < 0) {
            Svaluediff = (Svaluediff * (-1));
        }
        if (Lvaluediff > Svaluediff) {
            setthumbpos('left');
            if (Lvalue - Svalue == 100) {
                if (Svaluediff < 20) {
                    setthumbcord((Svaluediff * 5.1) / 7);
                } else {
                    setthumbcord((Svaluediff * 5.1) / 3.5);
                }
            } else {
                if (Svaluediff < 10) {
                    setthumbcord((Svaluediff * 2.5) / 0);
                } else {
                    setthumbcord((Svaluediff * 4.9) / 14);
                }
            }
        } else {
            if (Lvaluediff == Svaluediff) {
                setthumbcord((Lvaluediff * 5.7) / 2.7);
            } else {
                if (Lvalue - Svalue == 100) {
                    if (Lvaluediff < 10) {
                        setthumbcord((Lvaluediff * 2.5) / 20)
                    } else {
                        setthumbcord((Lvaluediff * 5.3) / 3);
                    }
                } else {
                    if (Lvaluediff < 10) {
                        if (Lvaluediff < 5) {
                            setthumbcord((Lvaluediff * 2.5) / 0)
                        } else {
                            setthumbcord((Lvaluediff * 2.5) / 3)
                        }
                    }
                    else {
                        setthumbcord((Lvaluediff * 5) / 5);
                    }
                }
            }
            setthumbpos('right');
        }
    }, [])
    useEffect(() => {
        let Lvalue = props.riskvalue < props.lossvalue ? props.lossvalue : props.riskvalue;
        let value = props.riskvalue > props.lossvalue ? props.lossvalue : props.riskvalue;
        let widthpos = 0;
        if ((Lvalue - value) === 10) {
            widthpos = 90 / 2.5;
        } else if ((Lvalue - value) === 20) {
            widthpos = 165 / 2.1;
        } else if ((Lvalue - value) === 30) {
            widthpos = 225 / 2.2;
        } else if ((Lvalue - value) === 40) {
            widthpos = 293 / 2.3;
        } else if ((Lvalue - value) === 50) {
            widthpos = 353 / 2.3;
        }
        else if ((Lvalue - value) === 60) {
            widthpos = 417 / 2.4;
        }
        else if ((Lvalue - value) === 70) {
            widthpos = 485 / 2.4;
        }
        else if ((Lvalue - value) === 80) {
            widthpos = 548 / 2.4;
        }
        else if ((Lvalue - value) === 90) {
            widthpos = 615 / 2.5;
        }
        else if ((Lvalue - value) === 100) {
            widthpos = 670 / 2.5;
        }
        else {
            widthpos = ((Lvalue - value) > 89 ? ((((Lvalue - value) * 6) + 40) / 2.5) : ((Lvalue - value) >= 39 ? ((((Lvalue - value) * 6) + 45) / 2.3) : ((Lvalue - value) >= 20 ? ((((Lvalue - value) * 6) + 50) / 2.1) : ((Lvalue - value) >= 10 ? ((((Lvalue - value) * 6) + 35) / 2.1) : ((Lvalue - value) > 5 ? ((((Lvalue - value) * 6) + 25 / 2.1)) : ((((Lvalue - value) * 6) + 25 / 1)))))));
        }
        // let widthpos = ((Lvalue - value) > 89 ? ((Lvalue - value) === 100 ? 670 : (((Lvalue - value) * 6) + 40)) : ((Lvalue - value) >= 39 ? (((Lvalue - value) * 6) + 80) : (((Lvalue - value) * 6) + 70)));
        setcoverwidth(widthpos);
        if (props.riskvalue === value) {
            setsmallvalue('risk');
        }
        else {
            setsmallvalue('loss');
        }
        switch (true) {
            case 0 == value:
                setscorepos(0);
                break;
            case 10 == value:
                setscorepos((57 / 2.7));
                break;
            case 20 == value:
                setscorepos((120 / 2.9));
                break;
            case 30 == value:
                setscorepos((185 / 2.7));
                break;
            case 40 == value:
                setscorepos((250 / 2.7));
                break;
            case 50 == value:
                setscorepos((315 / 2.7));
                break;
            case 60 == value:
                setscorepos((375 / 2.7));
                break;
            case 70 == value:
                setscorepos((443 / 2.7));
                break;
            case 80 == value:
                setscorepos((505 / 2.7));
                break;
            case 90 == value:
                setscorepos((569 / 2.7));
                break;
            case 100 == value:
                setscorepos((635 / 2.7));
                break;
            case 0 <= value && value <= 5:
                setscorepos(20 / 2.7);
                break;
            case 5 < value && value < 10:
                setscorepos(40 / 2.7);
                break;
            case 10 < value && value <= 15:
                setscorepos(85 / 2.7);
                break;
            case 15 < value && value < 20:
                setscorepos(100 / 2.7);
                break;
            case 20 < value && value <= 25:
                setscorepos(155 / 2.7);
                break;
            case 25 < value && value < 30:
                setscorepos(185 / 2.7);
                break;
            case 30 < value && value <= 35:
                setscorepos(210 / 2.7);
                break;
            case 35 < value && value < 40:
                setscorepos(225 / 2.7);
                break;
            case 40 < value && value <= 45:
                setscorepos(280 / 2.7);
                break;
            case 45 < value && value < 50:
                setscorepos(310 / 2.7);
                break;
            case 50 < value && value <= 55:
                setscorepos(340 / 2.7);
                break;
            case 55 < value && value < 60:
                setscorepos(350 / 2.7);
                break;
            case 60 < value && value <= 65:
                setscorepos(410 / 2.7);
                break;
            case 65 < value && value < 70:
                setscorepos(430 / 2.7);
                break;
            case 70 < value && value <= 75:
                setscorepos(450 / 2.7);
                break;
            case 75 < value && value < 80:
                setscorepos(480 / 2.7);
                break;
            case 80 < value && value <= 85:
                setscorepos(540 / 2.7);
                break;
            case 85 < value && value < 90:
                setscorepos(570 / 2.7);
                break;
            case 90 < value && value <= 95:
                setscorepos(570 / 2.7);
                break;
            case 95 < value && value < 100:
                setscorepos(570 / 2.7);
                break;
        }

    }, [])
    return (
        <View style={[styles.mainView, { width: coverwidth, marginLeft: scorepos }]}>
            <View style={[styles.smallCircle, { backgroundColor: smallvalue === 'risk' ? '#6fd5da' : '#ee7998' }]}>
                <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontWeight: 'bold', color: '#ffffff' }}>{props.riskvalue > props.lossvalue ? props.lossvalue : props.riskvalue}</Text>
            </View>
            <View style={[styles.thumbMainView, thumbpos === 'right' ? { right: thumbcord } : { left: thumbcord }]}>
                <View style={styles.thumbCircleView}>
                    <Text style={{ textShadowColor: 'rgba(0, 0, 0, 0.5)', textShadowOffset: { width: 2, height: 2 }, textShadowRadius: 3, fontFamily: 'JosefinSans-Bold', fontSize: 40, fontWeight: 'bold', textAlign: 'center', color: '#ffffff' }}>{props.score}</Text>
                </View>
                <View style={styles.thumbTraingleView} />
            </View>
            <View style={[styles.smallCircle, { backgroundColor: smallvalue === 'risk' ? '#ee7998' : '#6fd5da' }]}>
                <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: 'JosefinSans-Bold', fontWeight: 'bold', color: '#ffffff' }}>{props.riskvalue > props.lossvalue ? props.riskvalue : props.lossvalue}</Text>
            </View>
        </View>
    );
};

export default Index;
const styles = StyleSheet.create({
    mainView: {
        height: 32,
        backgroundColor: 'rgba(87, 95, 105, 0.7)',
        borderRadius: 88,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
    },
    smallCircle: {
        height: 36,
        width: 36,
        borderRadius: 18,
        borderWidth: 4,
        borderColor: '#7f868d',
        // backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center'
    },
    thumbMainView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 120,
        position: 'absolute'
    },
    thumbCircleView: {
        height: 80, width: 80,
        borderRadius: 40,
        backgroundColor: '#00aaff',
        top: 7,
        justifyContent: 'center',
        alignItems: 'center'
    },
    thumbTraingleView: {
        width: 25,
        height: 25,
        borderRightWidth: 20,
        borderLeftWidth: 20,
        borderTopWidth: 30,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: 'rgba(0, 170, 255,0.5)',
    }
})