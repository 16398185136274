import React, { useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, Image } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../../Utils/responsive';
import { withRouter } from 'react-router-dom';
import Header from '../../../../../Components/HeaderSkin3';
import { aspectHeaderText, bodyTextHomepage, buttonText, bodyTextFuturePage, LOSSINTROBODY, lossLabels } from '../../../../../Utils/static-strings';
import { styles } from './Styles/index'
const deviceWidth = Dimensions.get('screen').width;
const backgroundColor = '#f5d3e4';

const Index = (props) => {

    const { setPrePage, moduleObject } = props;
    const bodyText = useRef(new Animated.Value(-250)).current;
    const triangleLG = useRef(new Animated.Value(-70)).current;
    const triangleSM = useRef(new Animated.Value(120)).current;
    const semiCircleM = useRef(new Animated.Value(300)).current;
    const circleGroup = useRef(new Animated.Value(-500)).current;
    const triangleGroup = useRef(new Animated.Value(-200)).current;
    const triangleXS = useRef(new Animated.Value(-200)).current;
    const animation = useRef(new Animated.Value(
        -Dimensions.get('screen').width
    )).current;

    useEffect(() => {
        Animated.parallel([
            Animated.timing(animation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false,
            }),
            Animated.timing(triangleLG, {
                toValue: 0,
                duration: 400,
                useNativeDriver: false,
            }),
            Animated.timing(triangleSM, {
                toValue: 200,
                duration: 400,
                useNativeDriver: false,
            }),
            Animated.timing(semiCircleM, {
                toValue: 395,
                duration: 400,
                useNativeDriver: false,
            }),
            Animated.timing(circleGroup, {
                toValue: 0,
                duration: 600,
                // delay: 100,
                useNativeDriver: false,
            }),
            Animated.timing(triangleGroup, {
                toValue: 0,
                duration: 600,
                // delay: 100,
                useNativeDriver: false,
            }),
            Animated.timing(triangleXS, {
                toValue: 452,
                duration: 600,
                // delay: 100,
                useNativeDriver: false,
            }),
            Animated.timing(bodyText, {
                toValue: 0,
                duration: 600,
                // delay: 100,
                useNativeDriver: false,
            }),
        ]).start();
    }, [])

    // const set1 = (props) => {
    //     return (
    //         <View style={{}}>
    //             <Text style={[styles.customText]}>
    //                 Okay, Now we're going to change things{`\n`}
    //                 around. Notice the difference?
    //             </Text>
    //             <TouchableOpacity onPress={() => {
    //                 props.setDisable(true);
    //                 props.fun()
    //             }} style={[styles.button, { marginLeft: undefined, alignSelf: 'center', marginTop: 22 }]}>
    //                 <Text style={[styles.buttonText]}>CONTINUE</Text>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }
    // const set2 = (props) => {
    //     return (
    //         <>

    //             <Text style={[styles.customText]}>
    //                 Great, Now what about when the odds{`\n`}
    //                 change?
    //             </Text>
    //             <TouchableOpacity onPress={() => {
    //                 props.setDisable(true);
    //                 props.fun()
    //             }} style={[styles.button, { marginLeft: undefined, alignSelf: 'center', marginTop: 22 }]}>
    //                 <Text style={[styles.buttonText]}>CONTINUE</Text>
    //             </TouchableOpacity>
    //         </>
    //     )
    // }
    // const set3 = (props) => {
    //     return (
    //         <>

    //             <Text style={[styles.customText]}>
    //                 Finally, We're going to change things{`\n`}
    //                 more.
    //             </Text>
    //             <TouchableOpacity onPress={() => {
    //                 props.setDisable(true);
    //                 props.fun()
    //             }} style={[styles.button, { marginLeft: undefined, alignSelf: 'center', marginTop: 22 }]}>
    //                 <Text style={[styles.buttonText]}>CONTINUE</Text>
    //             </TouchableOpacity>
    //         </>
    //     )
    // }

    const setMethod = () => {
        props.setDisable(true);
        props.fun();
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            backgroundColor,
            flexDirection: 'column'
        }} >
            {/* <Header currentRoute={"Understanding"} /> */}
            {props.currentSet === 0 ?
                <Animated.View style={{
                    width: deviceWidth > 600 ? '82%' : '90%',
                    height: '75%',
                    justifyContent: 'center',
                    // marginBottom: deviceWidth > 600 ? '13%' : '25%',
                    transform: [{ translateX: animation }]
                }}>
                    <Text style={{
                        fontFamily: 'Manrope-SemiBold',
                        fontSize: Dimensions.get('screen').width > 600 ? 60 : 40,
                        lineHeight: Dimensions.get('screen').width > 600 ? 68 : 48,
                        fontWeight: '600',
                        color: '#2f2f2f',
                        textTransform: 'uppercase',
                        marginBottom: hp("3%"),
                    }}>{lossLabels.loss} or {lossLabels.gain}</Text>
                    <Text style={{
                        width: Dimensions.get('screen').width > 1300 ? wp('60%') : Dimensions.get('screen').width > 700 ? wp('60%') : wp('89%'),
                        Height: hp('24.3%'),
                        fontSize: 16,
                        textAlign: 'Left',
                        color: '#2f2f2f',
                        marginBottom: hp("3%"),
                        fontFamily: 'Manrope-Regulars',
                        lineHeight: 24,
                        alignSelf: 'flex-start'
                    }}>
                        {LOSSINTROBODY}
                    </Text>
                    <View style={{ flexDirection: 'row', marginBottom: hp('3%'), opacity: 0.69, height: 18 }}>
                        <Image source={require('../../../../../assets/images/regular-clock.png')} style={{
                            height: 18,
                            width: 18,
                            resizeMode: 'contain',
                        }}></Image>
                        <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regulars', color: '#2f2f2f', paddingLeft: 10 }}>Typically 2-3 minutes</Text>
                    </View>
                    <TouchableOpacity style={{
                        width: 115,
                        height: 40,
                        backgroundColor: '#2f2f2f',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} onPress={() => props.fun()}>
                        <Text style={{
                            textAlign: 'center',
                            fontFamily: 'Manrope-SemiBold',
                            fontWeight: '600',
                            color: '#ffffff',
                            lineHeight: 24,
                            fontSize: 16
                        }}>
                            {buttonText}
                        </Text>
                    </TouchableOpacity>
                </Animated.View>
                :
                (<>
                    {setMethod()}
                </>)
                // (props.currentSet === 1 ? set1(props) : (props.currentSet === 2 ? set2(props) : (props.currentSet === 3 && set3(props))))
            }
            {props.currentSet === 0 && <View style={{ height: "30%", width: '100%' }} />}
            {props.currentSet === 0 ?
                <View style={styles.footer} >
                    <div className='graphics-container'>
                        <Animated.View style={{ position: 'absolute', bottom: 0, right: triangleLG }}>
                            <div className="triangle" style={{ position: 'absolute', bottom: 0, right: -90 }} ></div>
                        </Animated.View>
                        <Animated.View style={{ position: 'absolute', top: 50, zIndex: 99, right: circleGroup }}>
                            <View style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 99 }}>
                                <div className="halfCircle" style={{ bottom: 0, backgroundColor: '#e58ebb', right: 100, position: 'absolute', opacity: 0.63 }}></div>
                            </View>
                            <View style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                <div className='circle' style={{ bottom: 25, right: 130, position: 'absolute', opacity: 0.71 }}></div>
                            </View>
                            <View style={{ position: 'absolute', bottom: 50, right: 100 }}>
                                <div className='line' style={{ bottom: 97, right: 160, backgroundColor: '#e58ebb', position: 'absolute', }}></div>
                            </View>
                        </Animated.View>
                        <Animated.View style={{ position: 'absolute', bottom: 100, right: triangleSM, zIndex: 80 }}>
                            <div className="triangleSM" style={{ position: 'absolute', bottom: 0, right: 60, opacity: 0.91 }} ></div>
                        </Animated.View>
                        <Animated.View style={{ position: 'absolute', bottom: 0, right: triangleGroup }}>
                            <View style={{ position: 'absolute', bottom: 0, right: 240, zIndex: 99 }}>
                                <div className='line' style={{ bottom: 0, right: 160, width: 15, height: 75, backgroundColor: '#66b3e3', position: 'absolute', opacity: 0.83 }}></div>
                            </View>
                            <View style={{ position: 'absolute', bottom: 0, right: 250 }}>
                                <div className="triangleMM" style={{ position: 'absolute', bottom: 0, right: 60, opacity: 0.83, zIndex: -99 }} ></div>
                            </View>
                            <View style={{ position: 'absolute', bottom: -40, right: 385, }}>
                                <div className="triangleXS" style={{ position: 'absolute', bottom: 0, right: 60, opacity: 0.71, transform: 'rotate(180deg)' }} ></div>
                            </View>
                        </Animated.View>
                        <Animated.View style={{ position: 'absolute', bottom: -165, right: semiCircleM, zIndex: -99 }}>
                            <div className="halfCircle" style={{ bottom: 0, backgroundColor: '#0097a9', right: 100, position: 'absolute', opacity: 0.71, transform: 'rotate(-180deg)' }}></div>
                        </Animated.View>
                        <Animated.View style={{ position: 'absolute', bottom: -85, right: triangleXS, }}>
                            <div className="triangleXS" style={{ position: 'absolute', bottom: 0, right: 100, opacity: 0.71, transform: 'rotate(180deg)' }} ></div>
                        </Animated.View>
                    </div>
                </View >
                : null}
        </div >
    )
}

export default withRouter(Index);
