import React, { useEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { riskIntroPageTextSkin3, buttonText } from '../../../../Utils/static-strings';
import './introStyle.css';
const deviceWidth = Dimensions.get('screen').width;
const Index = (props) => {
    const animation = useRef(new Animated.Value(
        -Dimensions.get('screen').width
    )).current;
    const animation2 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;
    const animation3 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;
    const animation4 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;

    useEffect(() => {
        Animated.parallel([
            Animated.timing(animation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false,
            }),
            Animated.timing(animation2, {
                toValue: 0,
                duration: 1000,
                // delay: 500,
                useNativeDriver: false,
            }),
            Animated.timing(animation3, {
                toValue: 0,
                duration: 1000,
                delay: 300,
                useNativeDriver: false,
            }),
            Animated.timing(animation4, {
                toValue: 0,
                duration: 1000,
                delay: 150,
                useNativeDriver: false,
            }),
        ]).start();
    }, [])
    return (
        <div style={{
            height: '100%',// deviceWidth > 600 ? '85vh' : '88vh',
            width: '100%',
            // flex: 1,
            position: "absolute",
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',

        }}>
            <Animated.View style={{
                width: deviceWidth > 600 ? '82%' : '90%',
                height: '75%',
                justifyContent: 'center',
                // marginBottom: deviceWidth > 600 ? '13%' : '25%',
                transform: [{ translateX: animation }]
            }}>
                <Text style={{
                    fontFamily: 'Manrope-SemiBold',
                    fontSize: 60,
                    lineHeight: 68,
                    fontWeight: '600',
                    color: '#2f2f2f',
                    textTransform: 'uppercase',
                    marginBottom: hp("3%"),
                }}>Risk</Text>
                <Text style={styles.customText}>
                    {riskIntroPageTextSkin3}
                </Text>
                <View style={{ flexDirection: 'row', marginBottom: hp('3%'), opacity: 0.69, height: 18 }}>
                    <Image source={require('../../../../assets/images/regular-clock.png')} style={{
                        height: 18,
                        width: 18,
                        resizeMode: 'contain',
                    }}></Image>
                    <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regulars', color: '#2f2f2f', paddingLeft: 10 }}>Typically 2-3 minutes</Text>
                </View>
                <TouchableOpacity style={{
                    width: 115,
                    height: 40,
                    backgroundColor: '#2f2f2f',
                    justifyContent: 'center',
                    alignItems: 'center'
                }} onPress={() => props.Showing()}>
                    <Text style={{
                        textAlign: 'center',
                        fontFamily: 'Manrope-SemiBold',
                        fontWeight: '600',
                        color: '#ffffff',
                        lineHeight: 24,
                        fontSize: 16
                    }}>
                        {buttonText}
                    </Text>
                </TouchableOpacity>
            </Animated.View>

            <View style={{ height: "25%", width: "100%" }} />


            <Animated.View style={{
                position: 'absolute', bottom: 0, right: deviceWidth > 1024 ? 0 : '-14%'
            }}>
                <div className="mobileresponsive">
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            transform: [{ translateX: animation2 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 100,
                                height: 214,
                                width: 214,
                                backgroundColor: '#0097a9',
                                right: 180,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 240,
                                height: 33,
                                width: 33,
                                backgroundColor: '#7b76b6',
                                right: 220,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.5,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 260,
                                height: 41,
                                width: 41,
                                backgroundColor: '#66b3e3',
                                right: 200,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.6,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 30,
                                height: 146,
                                width: 146,
                                backgroundColor: '#7b76b6',
                                right: 330,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.6,
                            transform: [{ translateX: animation4 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 10,
                                height: 224,
                                width: 224,
                                backgroundColor: '#7ebd5f',
                                right: 350,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            transform: [{ translateX: animation2 }]
                        }}>
                        <Image source={require('../../../../assets/images/combined-shapeopenintro.png')}
                            style={{
                                position: 'absolute',
                                right: 500,
                                bottom: 35,
                                height: 135,
                                width: 178.8,
                                resizeMode: 'contain'
                            }}></Image>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.55,
                            transform: [{ translateX: animation4 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 5,
                                height: 116,
                                width: 116,
                                backgroundColor: '#66b3e3',
                                right: 550,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.8,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 0,
                                height: 146,
                                width: 146,
                                backgroundColor: '#0097a9',
                                right: 600,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            transform: [{ translateX: animation4 }]
                        }}>
                        <div className="rectangle" class="trianglesmall"
                            style={{
                                position: 'absolute',
                                // mixBlendMode: 'overlay',
                                bottom: 0,
                                right: 673
                            }} ></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            opacity: 0.78,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 45,
                                height: 31,
                                width: 31,
                                backgroundColor: '#7ebd5f',
                                right: 745,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            opacity: 0.78,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 20,
                                height: 31,
                                width: 31,
                                backgroundColor: '#007780',
                                right: 710,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0, right: 0,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 0,
                                height: 31,
                                width: 31,
                                backgroundColor: '#7ebd5f',
                                right: 820,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            opacity: 0.55,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 10,
                                height: 37,
                                width: 37,
                                backgroundColor: '#66b3e3',
                                right: 790,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 203,
                                height: 31,
                                width: 31,
                                backgroundColor: '#7ebd5f',
                                right: 500,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            transform: [{ translateX: animation2 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 170,
                                height: 31,
                                width: 31,
                                backgroundColor: '#007780',
                                right: 470,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            opacity: 0.55,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 210,
                                height: 63,
                                width: 63,
                                backgroundColor: '#66b3e3',
                                right: 340,
                            }}></div>
                    </Animated.View>
                    <Animated.View
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            transform: [{ translateX: animation3 }]
                        }}>
                        <div className="rectangle"
                            style={{
                                bottom: 35,
                                height: 52,
                                width: 52,
                                backgroundColor: '#007780',
                                right: 340,
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: [{ translateX: animation4 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 0,
                            height: 240,
                            width: 272,
                            backgroundColor: '#7ebd5f',
                            right: 50,
                            // mixBlendMode: 'overlay',
                            position: 'absolute'
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 0,
                            height: 31,
                            width: 31,
                            backgroundColor: '#7ebd5f',
                            right: 310,
                            // mixBlendMode: 'overlay',
                            position: 'absolute'
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.8,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 0,
                            height: 24,
                            width: 290,
                            backgroundColor: '#0097a9',
                            right: 333,
                            // mixBlendMode: 'overlay',
                            position: 'absolute'
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.5,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle" class="triangle" style={{
                            position: 'absolute',
                            // mixBlendMode: 'overlay',
                            bottom: 0,
                            right: -90
                        }} ></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.55,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 50,
                            height: 63,
                            width: 63,
                            backgroundColor: '#66b3e3',
                            right: 190,
                            // mixBlendMode: 'overlay',
                            position: 'absolute'
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.55,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 20,
                            height: 37,
                            width: 37,
                            backgroundColor: '#66b3e3',
                            right: 295,
                            // mixBlendMode: 'overlay',
                            position: 'absolute'
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle" style={{
                            bottom: 30,
                            height: 52,
                            width: 52,
                            backgroundColor: '#007780',
                            right: 110,
                            // mixBlendMode: 'overlay',
                            position: 'absolute',
                            opacity: 0.5
                        }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        transform: [{ translateX: animation4 }]
                    }}>
                        <Image source={require('../../../../assets/images/combined-shapeintro.png')} style={{
                            position: 'absolute',
                            right: 80,
                            bottom: 245,
                            height: 60,
                            width: 80,
                            resizeMode: 'contain'
                        }}></Image>
                    </Animated.View>
                </div>
            </Animated.View>

        </div>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',// deviceWidth > 600 ? '85vh' : '88vh',
        width: '100vw',
        // flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        width: Dimensions.get('screen').width > 1300 ? wp('60%') : Dimensions.get('screen').width > 700 ? wp('60%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: 16,
        textAlign: 'Left',
        color: '#2f2f2f',
        marginBottom: hp("3%"),
        fontFamily: 'Manrope-Regulars',
        lineHeight: 24,
        alignSelf: 'flex-start'
    },
})