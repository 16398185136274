import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
    return (
        <View style={styles.content}>
            <View style={styles.headingContainer}>
                <View style={styles.Line} />
                <Text style={styles.header}>{props.moduleObject.name}</Text>
                <View style={styles.Line2} />
            </View>
            <Text style={styles.customText}>
                Investors have power you know!
                {"\n"}{"\n"}
                The following screens will tell us whether you'd like your investment to support your preferences around issues such as climate change and equality.
            </Text>
            <TouchableOpacity onPress={props.pageOne} style={styles.button}>
                <Text style={styles.buttontext}>GET STARTED</Text>
            </TouchableOpacity>
        </View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        flex:1
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 750 ? 660 : Dimensions.get('screen').width > 400? 366 : 300,
        Height: hp('24.3%'),
        fontSize:Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400? 20 : 16,
        fontStyle: 'normal',
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.75)',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        // lineHeight: 26.5,
        letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400? 2 : 1.
    },
    button: {
        marginBottom:50, 
        backgroundColor: '#4bd5ad',
        width: 191,
        minHeight: 48,
        borderRadius:2,
        textAlignVertical: "center",
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: 20,
        paddingTop: 17,
        width: "100%",
        textAlign: "center",
        height: "100%",
        textAlignVertical: "center",
        alignSelf: "center",
        letterSpacing: 2,
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        // textShadowRadius: 3,
    },
    headingContainer: {
        width: 315,
        height: 70,
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: hp("4%")
    },
    Line: {
        width: 183,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    Line2: {
        width: 183,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    header: {
        position: "relative",
        width: "100%",
        height: "50%",
        alignSelf: 'center',
        // fontFamily: 'JosefinSans-Light',
        // fontSize: 40,
        // letterSpacing: 4.8,
        fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        color: '#575f69',
        textTransform: "uppercase",
        fontFamily: 'JosefinSans-Light',
        fontSize:Dimensions.get('screen').width > 400 ? 48:  32,
        letterSpacing: Dimensions.get('screen').width > 400 ? 4.8:  3.2,
    },
})