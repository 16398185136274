import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Image,
    Easing,
    Text,
    TouchableHighlight
} from 'react-native';
import { widthPercentageToDP } from '../../../../Utils/responsive';

const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {

    return (
        <View style={styles.container}>

            <Text style={styles.content}>Now, answer the following questions with <Text style={{ fontFamily: "JosefinSans-Bold" }}>True</Text> or <Text style={{ fontFamily: "JosefinSans-Bold" }}>False</Text>,{`\n`}
                and let us know how sure you are.{`\n`}{`\n`}If you don’t know, take a guess!</Text>

            <View style={styles.buttonContainer}>
                <TouchableHighlight style={styles.continueButton}
                    onPress={() => { props.navigation() }} >
                        
                    <Text style={styles.continueText}>OK, GOT IT!</Text>

                </TouchableHighlight>
            </View>

        </View>
    );
}
export default Index;

const styles = StyleSheet.create({

    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    content: {
        textAlign: 'center',
        fontFamily: 'JosefinSans-Thin',
        fontSize: actualWidth <= 320 ? 20 : 32,
        fontWeight: '300',
        width: actualWidth < 500 ? '95%' : actualWidth == 834 ? 644 : actualWidth,
        letterSpacing: actualWidth <= 320 ? 2 : 3.2,
    },

    button: {
        height: 'auto',
        width: 'auto',
        backgroundColor: '#575f69',
        borderRadius: 3,
        justifyContent: 'center',
        paddingHorizontal: 15,
        paddingTop: actualWidth == 414 || actualWidth == 834 ? 15 : 10,
        paddingBottom: actualWidth == 414 ? 15 : 10
    },

    buttonText: {
        fontFamily: 'JosefinSans-Regular',
        fontSize: 16,
        textAlign: 'center',
        letterSpacing: 1.5,
        color: '#ffffff',
    },

    buttonContainer: {
        top: 25
    },
    continueButton: {
        width: Dimensions.get('screen').width > 850 ? 194 : 134,
        height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'row',
        textTransform:"uppercase"
        // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
    },
    continueText:{
        fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'JosefinSans-Bold',
        lineHeight: 'normal',
        letterSpacing: 1.6,
        marginRight:widthPercentageToDP("0.5%")
    },
})