import React, { useRef, useState, useLayoutEffect } from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, Animated, Easing, Dimensions } from 'react-native'
import './index.css'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive'
import { WidthDimension } from '../FutureSecondSkin'
const deviceWidth = Dimensions.get('screen').width;
export const BackgroundColor = "#eeeeee"

const Index = ({ setPrePage, moduleObject }) => {
    const tBar = useRef(new Animated.Value(0)).current;
    const [display, setDisplay] = useState(false);
    useLayoutEffect(() => {
        Animated.timing(tBar, {
            toValue: 1,
            duration: 800,
            useNativeDriver: false,
            easing: Easing.linear
        }).start(() => setDisplay(true));
    }, [])

    const topBar = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%']
    });

    const topBar2 = tBar.interpolate({
        inputRange: [0, 1],
        outputRange: [deviceWidth > 1300 ? 124 : 102, 0]
    });
    return (
        <View style={styles.container}>
            <Animated.View style={[styles.borderTop, { transform: deviceWidth < 500 ? [{ translateX: 0 }] : [{ translateX: topBar2 }], width: topBar, }]} />
            {/* <Image source={require('../../assets/images/FutureModule/logoBeIQ.png')} style={styles.logo} /> */}
            <img src={require("../../assets/svg/futurelogo.svg")} style={{
                position: "relative",
                alignSelf: "flex-end",
                width: deviceWidth > 400 ? 54 : 40,
                objectFit: 'contain',
                height: deviceWidth > 400 ? 16 : 12,
                marginRight: 7,
            }} alt='logo' />
            {display && <View style={styles.content}>
                <View style={styles.headingContainer}>
                    <View style={styles.Line} />
                    <Text style={styles.header}>{moduleObject.name}</Text>
                    <View style={styles.Line2} />
                </View>
                <Text style={styles.customText}>
                    Imagine you're holding an investment which{"\n"}offers two exits.
                    {"\n"}{"\n"}
                    Both exits show a return, one in the near{"\n"}future one in the more distant future.
                    {"\n"}{"\n"}
                    Treat each scenario on its own merit and{"\n"}chose one exit.
                </Text>
                <TouchableOpacity onPress={() => setPrePage(false)} style={styles.customButton}>
                    <Text style={styles.label} >Get Started</Text>
                    {/* <Image source={require('../../assets/images/FutureModule/text2.png')} style={styles.button} /> */}
                </TouchableOpacity>
            </View>}
        </View>
    )
}

export default Index

const styles = StyleSheet.create({
    container: {
        height: "100vh",
        width: "100vw",
        backgroundColor: BackgroundColor,
        display: "flex",
        alignItems: "center",
        overflow: "hidden"
    },
    headingContainer: {
        // width: 534px;
        // height: 53px;
        width: 315,
        height: 'auto',
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: hp("4%"),
        overflow: "visible"
    },
    borderTop: {
        position: "relative",
        backgroundColor: "#fda435",
        // borderTopColor:"#fda435",
        // borderTopWidth:3,
        height: 3,
        alignSelf: 'center',
        width: "100%",
        marginBottom: "6px"
    },
    logo: {
        // overflow: 'hidden',
        width: "48px",
        height: "14px",
        resizeMode: "contain",
        alignSelf: "flex-end",
        marginRight: 7,
    },
    Line: {
        width: 183,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    Line2: {
        width: 183,
        // height: 1,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    header: {
        position: "relative",
        width: "100%",
        // height:"60",
        height: "auto",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize:Dimensions.get('screen').width > 400 ? 48:  32,
        letterSpacing: Dimensions.get('screen').width > 400 ? 4.8:  3.2,
        fontWeight: "300",
        // marginVertical:10,
        textAlignVertical: "center",
        textAlign: 'center',
        color: '#575f69',
        marginTop: 10,
        // marginBottom:4,
        textTransform: "uppercase"
    },
    content: {
        flex: 1,
        justifyContent: "center",
        alignItems: 'center'
    },
    heading: {
        position: "relative",
        width: wp("60%"),
        resizeMode: "contain",
        height: hp("10%"),
        marginBottom: hp("4%")
    },
    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 750 ? 660 : Dimensions.get('screen').width > 400? 366 : 300,
        // height: 212,
        fontSize:Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400? 20 : 16,
        fontWeight: 'normal',
        fontStyle: 'normal',
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.75)',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400? 2 : 1.6,

    },
    customButton: {
        position: "relative",
        width: 191,
        minHeight: 48,
        textAlignVertical: "center",
        // borderRadius: 2,
        backgroundColor: "#fda435",
    },
    label: {
        fontFamily: 'JosefinSans-Bold',
        fontSize: 20,
        paddingTop: 15,
        width: "100%",
        // width:"100%",
        textAlign: "center",
        height: "100%",
        // paddingHorizontal:"50%",
        textAlignVertical: "center",
        alignSelf: "center",
        letterSpacing: 2,
        color: '#ffffff',
        textTransform: "uppercase"

    }
})
