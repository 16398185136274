import React, { useLayoutEffect, useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import {moduleid, resultPage} from '../../Utils/static-strings'
import '../../App.css'

const Index = (props) => {
  const [risk, setrisk] = useState(null)
  const [score, setscore] = useState(null)
  useLayoutEffect(() => {
    if (props.riskCalculate) {
      setscore((props.riskCalculation.calcresults.find(module => module.moduleid === moduleid.brs()).score) * 100);
    }
    else {
      setscore((props.riskCalculation.calcresults.find(module => module.moduleid === moduleid.brs()).score) * 100);
    }

    switch (true) {
      case score >= 0 && score < 19:
        return setrisk(resultPage.veryLow())

      case score >= 19 && score < 31:
        return setrisk(resultPage.low())

      case score >= 31 && score < 42:
        return setrisk(resultPage.lowMedium())

      case (score >= 42 && score < 66):
        return setrisk(resultPage.medium())

      case score >= 66 && score < 82:
        return setrisk(resultPage.mediumHigh())

      case score >= 82 && score <= 100:
        return setrisk(resultPage.high())
    }
  })

  return (
    <View style={styles.container}>
      <View style={styles.rectangle}>
        <View style={styles.handle}>
          <View style={styles.handleLeft} />
          <View style={styles.handleRight} />
        </View>
        <View style={styles.cardMain}>
          {<Text style={styles.headerText}>
            Your investment style is
          </Text>}
          {/* <Text style={styles.Text1}>
            Based on your responses to the {'\n'}
                  Risk Profiling assessment,{'\n'}
                  your initial Risk Profile is:
                </Text> */}
          <Text style={styles.riskValue}>
            {risk} risk
            {/* The Risk Profiling Assessment is now complete. */}
          </Text>
          {<Text style={styles.Text1}>
            Please close this window {'\n'}
            to continue with your case
          </Text>}
          {/* <Text style={styles.cardBottomText}>
            This may change following {'\n'}
                further discussion with your {'\n'}
                advisor.
                </Text> */}
          {/* <TouchableOpacity style={{ alignSelf: 'flex-end', top: 5, right: 5 }}>
            <Text>Continue...</Text>
          </TouchableOpacity> */}
        </View>
        {/* <View style={styles.buttonGroup}>
              <View style={styles.button1}>
                <Text style={styles.buttonText}>{resultPage.button1()}</Text>
              </View>
              <View style={styles.button2}>
                <Text style={styles.buttonText1}>{resultPage.button2()}</Text>
              </View>
            </View> */}
      </View>
    </View>
  )
}
export default Index;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: "#ec0000"
  },
  rectangle: {
    position: "relative",
    top: "10%",
    width: "437px",
    height: "90%",
    // justifyContent:"center",
    alignItems: "center"
  },
  handle: {
    position: "absolute",
    // top:"2%",
    height: "100%",
    zIndex: -1,
    // zIndex:2,
    flexDirection: "row"
  },
  handleLeft: {
    width: "18px",
    height: "100%",
    backgroundColor: "#ff2b2c"
    // color:"#ff2b2c"
  },
  handleRight: {
    width: "18px",
    height: "100%",
    // color:"#d00301",
    backgroundColor: "#d00301"
  },
  cardMain: {
    width: "100%",
    height: "50%",
    top: "2%",
    zIndex: 2,
    backgroundColor: '#ffffff',
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  headerText: {
    fontFamily: "OpenSans-Light",
    fontSize: '1.7rem',
    textAlign: "center",
    color: "black",
    width: "100%"
  },
  Text1: {
    fontFamily: "OpenSans-Bold",
    fontSize: '1.4rem',
    textAlign: "center",
    color: "black",
    width: "100%",

  },
  riskValue: {
    fontFamily: "OpenSans-Bold",
    fontSize: '3.2rem',
    fontWeight: "bold",
    textAlign: "center",
    color: "black",
    width: "100%"
  },
  cardBottomText: {
    fontFamily: "OpenSans-Regular",
    fontSize: 20,
    textAlign: "center",
    color: "#ec0000",
    width: "100%"
  },
  buttonGroup: {
    top: "20%",
    zIndex: 2,
    width: "100%",
    height: "17%",
    justifyContent: 'center',
    // alignSelf:"center"
    alignItems: "center"
  },
  button1: {
    width: 291,
    height: 44,
    borderRadius: 22,
    backgroundColor: '#ffffff'
  },
  button2: {
    marginTop: 15,
    width: 291,
    height: 44,
    borderRadius: 22,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ffffff",
  },
  buttonText: {
    width: "100%",
    height: "100%",
    fontSize: 19,
    paddingTop: 7,
    textAlign: "center",
    fontFamily: "OpenSans-Regular",
    color: "#a81c1b"
  },
  buttonText1: {
    width: "100%",
    height: "100%",
    paddingTop: 7,
    fontSize: 19,
    textAlign: "center",
    fontFamily: "OpenSans-Regular",
    color: "#ffffff"
  }

})
