import { Dimensions, PixelRatio } from "react-native";
import { inIframe } from "./helper";

let screenWidth = inIframe() ? 1024 : Dimensions.get("window").width,
  screenHeight = inIframe() ? 768 : Dimensions.get("window").height;

const widthPercentageToDP = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  },
  heightPercentageToDP = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
  },
  listenOrientationChange = (e) => {
    Dimensions.addEventListener("change", (t) => {
      // screenWidth = t.window.width;
      // screenHeight = t.window.height;
      // e.setState({
      //   orientation: screenWidth < screenHeight ? "portrait" : "landscape",
      // });
    });
  },
  removeOrientationListener = () => {
    Dimensions.removeEventListener("change", () => {});
  };

export {
  widthPercentageToDP,
  heightPercentageToDP,
  listenOrientationChange,
  removeOrientationListener,
  screenWidth,
  screenHeight
};
