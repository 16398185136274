import React, { useLayoutEffect, useState } from 'react'
import RiskSkin1 from './Skin1/risk'
import RiskSkin2 from './Skin2/risk'
import RiskSkin3 from './Skin3/index'
import { tiger, zebra } from '../../../Utils/static-strings'
const Index = (props) => {
    const [currentSkin, setCurrentSkin] = useState(null);
    useLayoutEffect(() => {
        if (props.skin) {
            setCurrentSkin(props.skin)
        }
    }, [])

    const handleSkin = () => {
        switch (sessionStorage.getItem("skinName")) {
            case tiger:
                return <RiskSkin1 {...props} />
            case zebra:
                return <RiskSkin3 {...props} />
            default:
                return <RiskSkin2 {...props} />
        }
    }
    return (
        <React.Fragment>
            {currentSkin !== null && handleSkin()}
        </React.Fragment>
    )
}
export default Index

