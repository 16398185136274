import React, { useState } from 'react'
import FuturePrePage from './FuturePrePage/index'
import FuturePage from './FutureMainPage/index'

const backgroundColor = "#bbddf2"

const Index = (props) => {
    const [prePage, setPrePage] = useState(true);

    return (
        <React.Fragment>
            {
                prePage ? <FuturePrePage setPrePage={setPrePage} moduleObject={props.moduleObject} bgColor={backgroundColor} /> : <FuturePage {...props} />
            }
        </React.Fragment>
    )
}

export default Index
