import React, { useLayoutEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { KnowledgesecondPageText } from '../../../../Utils/static-strings'
import '../../../../App.css';
const Index = (props) => {
    const animation = useRef(new Animated.Value(0)).current;
    const [buttonColor, setButtonColor] = useState("#bb6fda")
    const Color = () => {
        setButtonColor("#800080");
        props.selectAnswerHandler(
            props.moduleObject.moduleid,
            props.moduleObject.questions[props.moduleIndex].qid,
            props.moduleObject.questions[props.moduleIndex].answers[0].respid,
        );
        props.refresh()
    }

    useLayoutEffect(() => {
        Animated.timing(animation, {
            toValue: 1,
            duration: 2000
        }).start();
    }, [])
    const nextPage = () => {
        Animated.timing(animation, {
            toValue: 0,
            duration: 2000
        }).start(() => props.Refresh());
    }
    return (
        <Animated.View style={[styles.content, { opacity: animation }]}>
            <Text style={styles.customText}>{KnowledgesecondPageText}</Text>
            <View style={styles.buttongroup}>
                <View>
                    <TouchableOpacity onPress={() => { Color() }} style={[styles.button, { borderColor: buttonColor, backgroundColor: buttonColor }]}>
                        <Text style={styles.buttontext}>{props.moduleObject.questions[props.moduleIndex].answers[0].text}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ margin: 5 }}></View>
                <View>
                    <TouchableOpacity onPress={() => { }} style={styles.button}>
                        <Text style={styles.buttontext}>{props.moduleObject.questions[props.moduleIndex].answers[5].text}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: 20,
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        lineHeight: 26.5
    },
    button: {
        width: 124,
        height: 124,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#bb6fda",
        backgroundColor: "#bb6fda",
        alignItems: "center",
        justifyContent: "center",
    },
    buttontext: {
        width: 105,
        height: 27,
        fontFamily: 'JosefinSans-Bold',
        fontSize: 20,
        textAlign: 'center',
        lineHeight: 26.5,
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#ffffff',
    },
    buttongroup: {

        flexDirection: 'row'
    },

})