import React from 'react';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
import '../../App.css';
const {
    View,
    StyleSheet,
    Text,
    Image,
    Dimensions
} = require('react-native');

export const TriangleCorner = (props) => {
    return (
        <>
            <View style={styles.rectangle}>
                <Image style={styles.opequotes} source={require('../../assets/images/combined-shape-open.png')}></Image>
                <Text style={styles.triangleCornerText}>{props.test}</Text>
                <Image style={styles.closequotes} source={require('../../assets/images/combined-shape-close.png')}></Image>
            </View>
            <View style={[styles.triangleCorner]} />
        </>
    );
};

const styles = StyleSheet.create({
    triangleCorner: {
        marginLeft: wp('5%'),
        marginBottom: hp('2%'),
        transform: [
            { rotate: '180deg' }
        ],

        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: Dimensions.get('screen').width > 1200 ? wp('1.5%') : Dimensions.get('screen').width > 767 ? wp('3%') : wp('5%'),
        borderRightWidth: Dimensions.get('screen').width > 1200 ? wp('1.5%') : Dimensions.get('screen').width > 767 ? wp('3%') : wp('5%'),
        borderBottomWidth: Dimensions.get('screen').width > 1200 ? wp('3%') : Dimensions.get('screen').width > 767 ? wp('6%') : wp('10%'),
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: "#7ebd5f",
    },
    rectangle: {
        // marginLeft: wp('2%'),
        width: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? 754 : wp('80%') : wp('95%'),
        backgroundColor: '#7ebd5f',
    },

    triangleCornerText: {
        padding: Dimensions.get('screen').width > 350 ? hp('5%') : hp('3%'),
        fontSize: Dimensions.get('screen').width > 1000 ? 24 : hp('3%'),
        fontFamily: 'Manrope-ExtraLight',
        color: '#ffffff',
        fontWeight: "300",
        fontStyle: 'normal',
        lineHeight: Dimensions.get('screen').width > 350 ? 32 : 28,
        textAlign: 'center',
    },
    opequotes: {
        resizeMode: 'contain',
        position: 'relative',
        top: hp('2%'),
        left: wp('2%'),
        height: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? hp('5%') : hp('4.5%') : hp('3.5%'),
        width: Dimensions.get('screen').width > 1200 ? wp('5%') : wp('8%')
    },
    closequotes: {
        alignSelf: 'flex-end',
        position: 'relative',
        resizeMode: 'contain',
        bottom: hp('2%'),
        right: wp('2%'),
        height: Dimensions.get('screen').width > 700 ? Dimensions.get('screen').width > 1200 ? hp('5%') : hp('4.5%') : hp('3.5%'),
        width: Dimensions.get('screen').width > 1200 ? wp('5%') : wp('8%')
    }
});
