import React, {useLayoutEffect, useEffect, useState, useRef, useContext} from 'react';
import {StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Image} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../App.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {ActivityContext} from '../../../Activities';

const Index = (props) => {
  const animation = useRef(new Animated.Value(0)).current;
  const resultContext = useContext(ActivityContext);
  const [buttonSelect, setButtonSelect] = useState(true)
  const [hide, setHide] = useState(true);
  const [data, setData] = useState([
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[0].text, isActive: false, id: 0},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[1].text, isActive: false, id: 1},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[2].text, isActive: false, id: 2},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[3].text, isActive: false, id: 3},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[4].text, isActive: false, id: 4},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[5].text + " ", isActive: false, id: 5},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[6].text, isActive: false, id: 6},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[7].text, isActive: false, id: 7},
    {name: props.moduleObject && props.moduleObject.questions[3]?.answers[8].text, isActive: false, id: 8},
  ]);

  const [reRender, setReRender] = useState('0');
  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  };

  useLayoutEffect(() => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 2000
    }).start(() => setHide(false));
    if (isSafari()) {
      setTimeout(() => {
        setReRender('3')
      }, 101)
    }
  }, [])


  const chage = (index) => {
    setButtonSelect(false);
    if (props.moduleObject && props.moduleObject.questions[3].multichoice) {
      let tmp = data;
      tmp[index].isActive = !tmp[index].isActive;
      setData(tmp => [...tmp])
    } else if(data){
      let tmp = data.map((item, _index) => {
        if (index === _index) {
          item.isActive = true;
          return item;
        } else {
          item.isActive = false;
          return item;
        }
      });
      setData(tmp)
    }
  }

  useEffect(() => {
    let select = data.find((Value) => Value.isActive == true);
    if (select == undefined) {
      setButtonSelect(true);
    }
  }, [data])

  const [selectedGroups, setSelectedGroups] = useState([]);

  const toggleGroup = (groupId) => {
    // If "None of these" is selected, deselect everything else.
    if (groupId === 8) {
      setSelectedGroups([8]);
    } else {
      setSelectedGroups(prevSelectedGroups => {
        if (prevSelectedGroups.includes(groupId)) {
          return prevSelectedGroups.filter(id => id !== groupId);
        } else {
          // If any other group is selected, ensure "None of these" is not selected.
          const filteredGroups = prevSelectedGroups.filter(id => id !== 8);
          return [...filteredGroups, groupId];
        }
      });
    }
    chage(groupId)
  };

  const isSelected = (groupId) => selectedGroups.includes(groupId);

  const flipAnim = useRef(new Animated.Value(0)).current; // For flip

  const nextPage = () => {
    let mm = data.filter(val => val.isActive == true);
    if (buttonSelect) {
      props.selectAnswerHandler(
          props.moduleObject.moduleid,
          props.moduleObject.questions[3].qid,
          props.moduleObject.questions[3].answers[8].respid,
          props.skippedQuestion ? props.skippedQuestion : undefined,
          true
      );
    } else {
      mm.forEach((val, i) => {
        props.skippedQuestion ?
            props.selectAnswerHandler(
                props.moduleObject.moduleid,
                props.moduleObject.questions[3].qid,
                props.moduleObject.questions[3].answers[val.id].respid,
                props.skippedQuestion ? props.skippedQuestion : undefined,
                mm.length - 1 == i && true
            )
            :
            props.selectAnswerHandler(
                props.moduleObject.moduleid,
                props.moduleObject.questions[3].qid,
                props.moduleObject.questions[3].answers[val.id].respid,
                props.skippedQuestion ? props.skippedQuestion : undefined,
                mm.length - 1 == i && true
            )
      })
    }
    Animated.timing(animation, {
      toValue: 0,
      duration: 2000
    }).start();
  }

  const flipInterpolate = flipAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'], // Flip by 360 degrees
  });

  const animatedStyle = {
    transform: [{rotateY: flipInterpolate}],
  };
  return (
      <div style={{height: '100%'}}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}>
          <div className="experience-circle-1" style={{
            width: '100%', display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column', backgroundColor: '#ffce0b'
          }}>
            <Animated.View style={[{}, animatedStyle, {
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }]}>
              <div className="button-container flip-element" style={{maxWidth: '600px', width: '100%'}}>
                <span style={{visibility: "hidden"}}>{reRender}</span>

                <svg width="100%" height="600" viewBox="0 0 800 800" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <circle id="path-1" cx="400" cy="400" r="400"></circle>
                  </defs>
                  <g id="experience-9segment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"/>
                    </mask>
                    <g id="Oval"></g>
                    <g className={`segment-group none ${isSelected(8) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(8)} id="Group-dont" mask="url(#mask-2)">
                      <g transform="translate(53.4993, 24.123)">
                        <path
                            d="M209.69261,0 L346.390807,375.576188 L0,176.015529 C45.3541299,97.2914002 117.636265,33.5057696 209.69261,0 Z"
                            className="wheel-segment" id="dont" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="dont-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="85.5232665" y="122.0642203" fill="#A6C3FF">I don’t seek</tspan>
                          <tspan x="109.541821" y="149.0642203" fill="#A6C3FF">advice</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(7) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(7)} id="Group-research" mask="url(#mask-2)">
                      <g transform="translate(-15.2253, 200)">
                        <path
                            d="M68.8151435,0 L414.947515,199.83921 L21.3218796,269.623494 C5.46227419,180.16425 19.8329917,84.8395756 68.8151435,0 Z"
                            className="wheel-segment" id="research" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="research-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="37.9638672" y="135.924987" fill="#A6C3FF">I conduct my</tspan>
                          <tspan x="34.6630859" y="162.924987" fill="#A6C3FF">own research</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(6) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(6)} id="Group-solicitor" mask="url(#mask-2)">
                      <g transform="translate(6.0769, 400.0541)">
                        <path
                            d="M393.608221,0 L136.929869,306.477799 C67.2768013,248.142341 17.0114187,165.881701 0,69.4051511 L393.608221,0 Z"
                            className="wheel-segment" id="solicitor" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="solicitor-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="77.7725111" y="151.378893" fill="#A6C3FF">Solicitor</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(5) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(5)} id="Group-accountant" mask="url(#mask-2)">
                      <g transform="translate(142.885, 400.2447)">
                        <path
                            d="M256.908731,0 L257.280722,399.764626 C166.426398,399.848801 75.0451573,369.143454 2.27373675e-13,306.17309 L256.908731,0 Z"
                            className="wheel-segment" id="accountant" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="accountant-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="92.8112959" y="291.627962" fill="#A6C3FF">Accountant</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(4) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(4)} id="Group-bank" mask="url(#mask-2)">
                      <g transform="translate(400, 400.32)">
                        <path
                            d="M0,0 L257.248581,305.997656 C187.704245,364.46268 97.9648453,399.68 0,399.68 L0,0 Z"
                            className="wheel-segment" id="bank" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="bank-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="85.4485187" y="291.210629" fill="#A6C3FF">Bank</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(3) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(3)} id="Group-websites" mask="url(#mask-2)">
                      <g transform="translate(400.2047, 400.2447)">
                        <path
                            d="M0,0 L393.756195,69.0529505 C378.062584,158.541662 331.955531,243.202713 256.910357,306.17309 L0,0 Z"
                            className="wheel-segment" id="websites" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="websites-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="222.261333" y="150.3223" fill="#A6C3FF">Investment</tspan>
                          <tspan x="234.795513" y="177.3223" fill="#A6C3FF">websites</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(2) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(2)} id="Group-family" mask="url(#mask-2)">
                      <g transform="translate(400.3151, 199.8521)">
                        <path
                            d="M346.020224,-5.68434189e-14 C391.520357,78.639942 410.619324,173.131019 393.607981,269.607138 L0,200.202987 L346.020224,-5.68434189e-14 Z"
                            className="wheel-segment" id="family" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="family-Value" stroke="none" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="283.115443" y="155.0880461" fill="#A6C3FF">Family</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(1) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(1)} id="Group-friends" mask="url(#mask-2)">
                      <g transform="translate(400.2772, 24.058)">
                        <path
                            d="M136.379014,0 C221.782701,30.9948248 297.150843,91.1025374 346.132952,175.942038 L0,375.781248 L136.379014,0 Z"
                            className="wheel-segment" id="friends" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="friends-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="169.40192" y="141.4864227" fill="#A6C3FF">Friends</tspan>
                        </text>
                      </g>
                    </g>
                    <g className={`segment-group ${isSelected(0) ? 'selected' : ''}`}
                       onClick={() => toggleGroup(0)} id="Group-adviser" mask="url(#mask-2)">
                      <g transform="translate(263, 0)">
                        <path
                            d="M0,24.2800533 C85.3464004,-6.87337286 181.718702,-9.27407704 273.775377,24.2318125 L137.075459,399.808 L0,24.2800533 Z"
                            className="wheel-segment" id="adviser" strokeOpacity="0.495641322"
                            stroke="#0066FF"
                            strokeWidth="2" fill="#a27000" fillRule="nonzero"></path>
                        <text className="wheel-segment-text" id="adviser-Value" stroke="none"
                              fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans" fontSize="20" fontWeight="500">
                          <tspan x="100.577148" y="91" fill="#A6C3FF">Adviser</tspan>
                        </text>
                      </g>
                    </g>
                    <g id="Center-9" mask="url(#mask-2)">
                      <g transform="translate(160, 160)">
                        <circle id="Oval" fillOpacity="0.202866348" fill="#000000" fillRule="evenodd"
                                cx="240"
                                cy="240" r="240"></circle>
                        <circle className="segment-center" id="Oval" fill="#ffce0b" fillRule="evenodd"
                                cx="240"
                                cy="240" r="222"></circle>
                        <text id="When-making-investme" fill="none"
                              fontFamily="OpenSans-Regular, Open Sans"
                              fontSize="29" fontWeight="normal">
                          <tspan x="67.7912598" y="190" fill="#000000b0">When making investment</tspan>
                          <tspan x="88.3054199" y="229" fill="#000000b0">decisions I would seek</tspan>
                          <tspan x="157.561279" y="268" fill="#000000b0">advice from:</tspan>
                        </text>
                        <text id="(select-all-that-app" fill="none"
                              fontFamily="OpenSans-SemiBold, Open Sans"
                              fontSize="16" fontWeight="500">
                          <tspan x="160.007812" y="317" fill="#000000b0">(select all that apply)</tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </svg>
                <div className="button experience"
                     style={{display: 'block', opacity: selectedGroups.length ? 1 : 0}}
                     onClick={() => nextPage()}>
                  Continue
                </div>
              </div>
            </Animated.View>
          </div>
        </div>
      </div>
  )
}
export default Index
const styles = StyleSheet.create({
  content: {
    height: '100vh',
    width:
        '100vw',
    flex:
        1,
    justifyContent:
        'center',
    alignItems:
        'center'
  }
  ,

  customText: {
    position: "relative",
    width:
        Dimensions.get('screen').width > 1300 ? wp('34%') : Dimensions.get('screen').width > 700 ? wp('34%') : wp('95%'),
    Height:
        hp('24.3%'),
    fontSize:
        Dimensions.get('screen').width > 1300 ? 24 : 22,
    fontStyle:
        'normal',
    textAlign:
        'center',
    color:
        '#575f69',
    marginBottom:
        hp("3%"),
    fontFamily:
        'JosefinSans-Light',
    lineHeight:
        26.5,
    letterSpacing: 2
  },
  customText2: {
    position: "relative",
    width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
    Height: hp('24.3%'),
    fontSize: Dimensions.get('screen').width > 1300 ? 16 : 14,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#575f69',
    marginBottom: hp("3%"),
    fontFamily: 'JosefinSans-Light',
  },
  button: {
    width: Dimensions.get('screen').width > 1300 ? 112 : 100,
    height: Dimensions.get('screen').width > 1300 ? 112 : 100,
    borderWidth: 3,
    borderRadius: Dimensions.get('screen').width > 1300 ? 60 : 55,
    borderStyle: "solid",
    borderColor: "#ff8d53",
    backgroundColor: "#ff8d53",
    alignItems: "center",
    justifyContent: "center",
  },
  buttontext: {
    // width: 85,
    // height: 50,
    fontFamily: 'JosefinSans-Bold',
    fontSize: Dimensions.get('screen').width > 320 ? 12 : 10,
    textAlign: 'center',
    letterSpacing: 1,
    color: '#ffffff',
  },
  buttongroup: {
    flexDirection: 'row'
  },
  lastButton: {
    backgroundColor: '#575f69',
    // width:'auto',
    // justifyContent: 'center',
    width: 'auto',
    minHeight: 35,
    textAlignVertical: "center",
    flexDirection: 'row'
  },
  lastButtonNewDesign: {
    backgroundColor: '#575f69',
    width: 273,
    justifyContent: 'center',
    height: 48,
    textAlignVertical: "center",
    flexDirection: 'row'
  },
  lastButtonNewDesignText: {
    color: '#ffffff',
    fontFamily: 'JosefinSans-Bold',
    fontSize: Dimensions.get('screen').width > 1300 ? 14 : 12,
    paddingTop: Dimensions.get('screen').width > 1300 ? 12 : 9,
    width: "90%",
    textAlign: "center",
    lineHeight: 16,
    fontWeight: 'bold',
    height: "100%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
  },
  lastButtonText: {
    color: '#ffffff',
    fontFamily: 'JosefinSans-Bold',
    fontSize: Dimensions.get('screen').width > 1300 ? 14 : 12,
    paddingTop: Dimensions.get('screen').width > 1300 ? 12 : 13,
    width: "100%",
    textAlign: "center",
    height: "100%",
    textAlignVertical: "center",
    alignSelf: "center",
    letterSpacing: 2,
  },
  arrow: {
    width: Dimensions.get('screen').width > 1300 ? 34 : 28,
    height: Dimensions.get('screen').width > 1300 ? 34 : 28,
    borderWidth: 3,
    borderRadius: Dimensions.get('screen').width > 1300 ? 18 : 15,
    borderStyle: "solid",
    borderColor: "#575f69",
    backgroundColor: "#575f69",
    alignItems: "center",
    justifyContent: "center",
  },
  continueButton: {
    // width: Dimensions.get('screen').width > 850 ? 196 : 178,
    height: 48, //Dimensions.get('screen').width > 1300 ? 48 : 28,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#575f69",
    backgroundColor: "#575f69",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'row',
    textTransform: "uppercase"
    // borderRadius: Dimensions.get('screen').width > 1300 ? 25 : 15,
  },
  customArrow: {
    alignSelf: 'center',
    width: Dimensions.get('screen').width > 850 ? 22 : 16,
    height: Dimensions.get('screen').width > 850 ? 22 : 16,
    resizeMode: "contain",
    marginRight: wp('1.4%'),
    marginLeft: wp('0.5%')

  },
  continueText: {
    fontSize: Dimensions.get('screen').width > 850 ? 20 : 12,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'JosefinSans-Bold',
    lineHeight: 'normal',
    letterSpacing: 1.6,
    marginRight: wp("0.5%"),
    marginLeft: wp('1.4%')


  },

})
