import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import FuturePage from '../../../../../Components/FutureThirdSkin/index'

const Index = (props) => {
    return <FuturePage {...props} />
}

export default Index

const styles = StyleSheet.create({})
