import React, { useState } from 'react'
import KnowledgePrePage from './KnowledgePrePage/index'
import KnowledgeMainPage from './KnowledgeMainPage/index'

const backgroundColor = "#bbddf2"

const Index = (props) => {
    const [prePage, setPrePage] = useState(true);

    return (
        <React.Fragment>
            {
                prePage ? <KnowledgePrePage setPrePage={setPrePage} moduleObject={props.moduleObject} bgColor={backgroundColor} /> : <KnowledgeMainPage {...props} />
            }
        </React.Fragment>
    )
}

export default Index
