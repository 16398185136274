import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import {
  StyleSheet,
  View,
  Animated,
  Dimensions,
  Image,
  Easing
} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import '../../../../App.css';
import Firstpage from './firstPage';
//import Secondpage from './secondPage';
import Secondpage from './thirdPage';
import Thirdpage from './fourthPage';
import Fourthpage from './fifthPage';

const deviceWidth = Dimensions.get('screen').width;
export default function Index(props) {

  const tBar = useRef(new Animated.Value(0)).current;
  const [display, setDisplay] = useState(false);
  const [pageSecond, setPageSecond] = useState(true);
  const [pageThird, setPageThird] = useState(true);
  const [pageFourth, setPageFourth] = useState(true);
  const [pageFifth, setPageFifth] = useState(true);
  const [skippedQuestion, setSkippedQuestion] = useState(false);
  useLayoutEffect(() => {
    Animated.timing(tBar, {
      toValue: 1,
      duration: 800,
      useNativeDriver: false,
      easing: Easing.linear
    }).start(() => setDisplay(true));
  }, [])

  const topBar = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%']
  });

  const topBar2 = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: [deviceWidth > 1300 ? -126 : -102, 0]
  });
  const Refresh = () => {
    setPageSecond(false)
  };
  const pageThree = () => {
    setPageThird(false)
  };
  const pageFour = () => {
    setPageFourth(false)
  };
  const pageFive = () => {
    setPageFifth(false)
  };
  return (
      <View style={[styles.container, {height: '100vh'}]}>
        {
            display &&
            (pageSecond ?
                <Firstpage Refresh={Refresh} {...props} /> :
                (pageThird ?
                    <Secondpage Refresh={pageThree} setSkippedQuestion={setSkippedQuestion}
                                skippedQuestion={skippedQuestion} {...props} />
                    : (pageFourth ?
                            <Thirdpage Refresh={pageFour} {...props} setSkippedQuestion={setSkippedQuestion}
                                       skippedQuestion={skippedQuestion}/>
                            :
                            <Fourthpage Refresh={pageFive} {...props} setSkippedQuestion={setSkippedQuestion}
                                        skippedQuestion={skippedQuestion}/>
                    )))
        }
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100vw",
    //flex: 1,
    backgroundColor: '#eeeeee',
    overflow: 'hidden'
  },
  topborder: {
    position: "relative",
    alignSelf: 'center',
    borderTopColor: "#FF8D53",
    borderTopWidth: 3,
    marginBottom: hp("1.2%")
  },
  Image: {
    position: "relative",
    alignSelf: "flex-end",
    marginLeft: '90vw',
    width: 48,
    resizeMode: "contain",
    marginRight: 8,
    height: 12
  },
});
