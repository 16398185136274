import React,{useLayoutEffect,useRef, useState} from 'react'
import { View ,StyleSheet,Text,Dimensions,TouchableOpacity,Animated} from 'react-native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;

let deviceWidth = Dimensions.get("window").width;
let deviceHeigth = Dimensions.get("window").height;

const isIpad =
navigator.userAgent.match(/Mac/) &&
navigator.maxTouchPoints &&
navigator.maxTouchPoints > 2;

const DragBalls = (props) => {
    return (<>
             {props.iconKey == "planet" &&
            <img src={require("../../../../../src/assets/svg/Token-Planet.svg")} style={{
                width: actualWidth >  400 ? 62 : 31,
                height: actualWidth >  400 ? 62 : 31,
                objectFit: 'contain',
            }} />
            }
            {props.iconKey == "people" &&
                <img src={require("../../../../../src/assets/svg/Token-People.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "planetandpeople" &&
                <img src={require("../../../../../src/assets/svg/Token-PeoplePlanet.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "planetmoney" &&
                <img src={require("../../../../../src/assets/svg/Token-PlanetOverReturns.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "moneyplanet" &&
                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPlanet.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
         {props.iconKey == "positiveonly" &&
                <img src={require("../../../../../src/assets/svg/Token-PositiveImpact.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "carbonneutral" &&
                <img src={require("../../../../../src/assets/svg/Token-CarbonNeutral.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "screen" &&
                <img src={require("../../../../../src/assets/svg/Token-NoSins.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "all" &&
                <img src={require("../../../../../src/assets/svg/Token-All.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "peoplemoney" &&
                <img src={require("../../../../../src/assets/svg/Token-PeopleOverReturns.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "moneypeople" &&
                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverPeople.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "bothmoney" &&
                <img src={require("../../../../../src/assets/svg/Token-BothOverReturns.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
        {props.iconKey == "moneyboth" &&
                <img src={require("../../../../../src/assets/svg/Token-ReturnsOverBoth.svg")} style={{
                    width: actualWidth >  400 ? 62 : 31,
                    height: actualWidth >  400 ? 62 : 31,
                    objectFit: 'contain',
                }} />
        }
    </>
    )
}


export const ConfirmationText = (props) => {
    const [height, setHeight] = useState(deviceHeigth);
    const [width, setWidth] = useState(deviceWidth);
    
    Dimensions.addEventListener("change", (e) => {
        deviceHeigth = e.window.height;
        deviceWidth = e.window.width;
        setHeight(deviceHeigth);
        setWidth(deviceWidth);
    });

    const animation = useRef(new Animated.Value(0)).current;
    const completefuntion=()=>{
        props.selectAnswerHandler(
        props.moduleObject.moduleid,
        props.moduleObject.questions[props.moduleIndex].qid,
        props.answerId,
        );
        props.resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid);
        props.history.push('/');
        }
        const resetfunction=()=>{
        props.pageOne()   
        props.pageTwo() 
        props.pageThree()  
        props.setCompeleteTask(false)
        props.setModuleIndex(0);
        props.resultContext.resetModuleResult(props.moduleObject.moduleid);
        }


    useLayoutEffect(() => {
        props.setDragOpacity(true);
        Animated.timing(animation, {
            toValue: 1,
            duration: 500
        }).start();
    }, [])

    return (
            <Animated.View style={[styles.container,{opacity:animation, height: isIpad && (width > height)? '64%' : actualWidth>1000? '60%':actualWidth>400?'70%':'60%'}]}>
                <Text style={styles.heading}>
                {props.metadata.resultlabel}
                </Text>
                <View style={styles.Line} />
                {props.metadata.confirmationtext.map((data,index)=>{
                    return(
                        <View key={index} style={{alignItems:'center'}}>
                         <Text style={styles.subHeading}>
                            {data.text}
                            </Text>
                            <View style={{marginBottom:actualWidth > 1000 ? actualHeight<900?hp("1%") : hp("3%"):actualWidth > 330?hp('3%'):hp("1.5%")}}>
                                <DragBalls iconKey={data.iconkey}/>
                         </View>
                        </View>
                    )
                })}
               { actualWidth > 550 && <View style={styles.Line} />}
            <TouchableOpacity style={styles.button} onPress={completefuntion}>
                <Text style={styles.buttontext}>
                {props.metadata.buttontext}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.goBack} onPress={resetfunction}>
                <Text style={styles.goBackText}>I've changed my mind (start again)</Text>
            </TouchableOpacity>
            </Animated.View>
                
    )
}

const styles = StyleSheet.create({
    container:{
        backgroundColor:'transparent',
        justifyContent:'space-evenly',
        alignItems:'center',
        width:'100%',
        marginBottom:actualWidth>700? isIpad ? 10 : 30 :hp('1%'),
        height:actualWidth>1000?'60%':actualWidth>400?'70%':'60%'
    },
    heading:{
        fontSize:  actualWidth > 600 ? 48 : actualWidth > 400 ? 32 : 16,
        fontWeight: 300,
        textAlign: 'center',
        color: '#575f69',
        marginBottom: actualWidth > 1000 ? actualHeight<900?hp("2%") : hp("3%"): actualWidth > 330?hp('2%'):hp("1%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: actualWidth > 1000 ?actualHeight<900? 3.2 : 4.8 : actualWidth > 400 ? 3.2 : 1.6
    },
    Line: {
        width: actualWidth > 1000 ? wp('35%') : actualWidth > 400 ? wp('45%') : wp('55%'),
        height: 1,
        backgroundColor: "#d4d4d4",
        marginBottom: actualWidth > 1000 ? actualHeight<900?hp("2%") : hp("3%"): actualWidth > 330?hp('2%'):hp("1%"),
    },
    subHeading:{
        fontSize: actualWidth > 400 ? 20 : 12,
        fontWeight:300,
        color: '#575f69',
        width:actualWidth>700?'100%':actualWidth>400?'95%':'95%',
        letterSpacing:actualWidth > 1000 ? 2 : actualWidth > 400 ? 2 : 1.2,
        fontFamily:'JosefinSans-Light',
        textAlign:'center',
        marginBottom: actualWidth > 1000 ? actualHeight<900?hp("1%") : hp("1%"): hp("1%"),
       },
       button: {
        backgroundColor: '#575f69',
        width: actualWidth > 1000 ? 296 : actualWidth > 400 ? 296 : 230,
        height: 48,
        marginBottom: actualWidth > 1000 ? actualHeight<900?hp("2%") : hp("3%"): hp("1%"),
        justifyContent: 'center',
        borderRadius:2,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        padding:5
    },
    goBack:{
        backgroundColor: 'transparent',
        width:actualWidth > 1000 ? 416 : actualWidth > 400 ? 320 : 210,
        height: 48,
        marginBottom: actualWidth > 1000 ? actualHeight<900?hp("4%") : hp("3%"): (actualWidth>400 && actualWidth < 500)?hp('0%') : hp("3%"),
        justifyContent: 'center'
    },
    goBackText:{
        color:"#575f69",
        fontFamily: 'JosefinSans-Bold',
        fontSize:actualWidth >1000 ? 16 : actualWidth > 400 ? 12 : 12,
        textAlign: "center",
        letterSpacing:actualWidth>1000?1.6: actualWidth > 400 ? 1.2 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        padding:5
    }
})