import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';
const IOSSlider = withStyles({
    root: {
        color: '#575f69',
        height: 3,
        padding: '15px 0',
    },
    thumb: {
        height: 32,
        width: 32,
        backgroundColor: '#63bbff',
        boxShadow: iOSBoxShadow,
        marginTop: -16,
        marginLeft: -16,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-40% + 12px)',
        top: 10,
        '& *': {
            background: 'transparent',
            color: '#ffffff',
        },
    },
    track: {
        height: 3,
    },
    rail: {
        height: 3,
        opacity: 1,
        backgroundColor: '#575f69',
    },
})(Slider);
export default IOSSlider;
