
export const addAnalytics = (error, errorConfig='')=>{

    console.log('%c Error log --->', 'color:green;font-size:14px', error, errorConfig);

    window.gtag("event", window['runConfig'].REACT_APP_ANALYTICS_ID, {
        event_category: "error",
        event_label: "error Message",
        value: {error, errorConfig},

    })
}