import React, {useRef, useState, useLayoutEffect, useEffect, useContext} from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Easing,
  Animated,
  StyleSheet,
  Dimensions,
  PixelRatio, TouchableOpacity
} from 'react-native';
import Rotate1 from './Rotate1';
import getSheet from './RotateStyle';
import IntroPage from './IntroPage';
import MiddlePage from './MiddlePage';
import {chunkify} from '../../../../Utils/chunkify';
import '../../../../../src/App.css';
import {withRouter} from 'react-router-dom';
import {ActivityContext} from '../../../Activities/index';
import {screenWidth as actualWidth, screenHeight as actualHeight} from '../../../../Utils/responsive';
import {inIframe} from '../../../../Utils/helper';
// const windowWidth = Dimensions.get('window').width;
// const actualWidth = Dimensions.get('screen').width;

const Loss = (props) => {
  const [gain, setGain] = useState(40);
  const [loss, setLoss] = useState(60);
  const [none, setNone] = useState('flex');
  const [primaryColorBlue, setprimaryColorBlue] = useState('#9f3469');
  const [primaryColorPurple, setprimaryColorPurple] = useState('#19b2bb');
  const [show, setShow] = useState(true);
  const [changeSize, setChangeSize] = useState(false)
  const [page, setPage] = useState(true);
  const [allValue, setAllValue] = useState(null);
  const [indexing, setIndexing] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const [historyVal, setHistoryVal] = useState(null);
  const resultContext = useContext(ActivityContext);
  const [dataHistory, setDataHistory] = useState([]);
  const animated1 = useRef(new Animated.Value(0)).current;
  const [middleIntro, setMiddleIntro] = useState(true);


  const [screenWidth, setScreenWidth] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight] = React.useState(actualHeight);
  const [valueLength, setValueLength] = React.useState(4);
  const [showMiddleIntro, setShowMiddleIntro] = React.useState(false);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth(t.window.width);
    setScreenHeight(t.window.height);

  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);


  useEffect(() => {
    animate();

    if (props.moduleObject.questions) {
      function calculateNumberOfSets(questions) {
        const uniqueSets = new Set();

        questions.forEach(question => {
          // Split the qid by '.' and extract the part that indicates the set
          const setId = question.qid.split('.')[3].substring(0, 2);
          uniqueSets.add(setId);
        });

        return uniqueSets.size;
      }
      const numberOfSets = calculateNumberOfSets(props.moduleObject.questions); // Replace 'yourArray' with your actual data variable
      setValueLength(numberOfSets)
      const chunkiElement = chunkify(props.moduleObject.questions, numberOfSets, true);

      setAllValue(chunkiElement);

      const totalLength = chunkiElement.map(elem => (
          {length: elem.length}
      ))
      setIndexing(totalLength);
    }

    setLoss(loss);
    setGain(gain);


  }, []);


  useLayoutEffect(() => {
    if (currentSet === valueLength) {
      resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid);
      props.history.push('/');
    }
    if (currentSet === 1) {
      //setShow(true)
      setShowMiddleIntro(true)
    }
    if (currentSet === 2) {
      //setShow(true)
      setShowMiddleIntro(true)
    }
    if (currentSet === 3) {
      //setShow(true)
      setShowMiddleIntro(true)
    }
  }, [currentSet]);

  const animate = () => {
    animated1.setValue(0);
    Animated.timing(animated1, {
      toValue: 35,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const spin = animated1.interpolate({
    inputRange: [5, 20],
    outputRange: ['360deg', '0deg'],
  });
  const transform = animated1.interpolate({
    inputRange: [0, 35],
    outputRange: [wp("120%"), wp('0%')],
  });

  const styles = getSheet(changeSize);

  // const fun = () => {
  //   if (!show) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  // };
  const Refresh = () => {
    setPage(false);
  };

  const Showing = () => {
    setShow(false);
    setChangeSize(true);
  };

  const prevQuestion = () => {
    if (dataHistory.length > 0) {
      const _dataHistory = JSON.parse(JSON.stringify(dataHistory));
      const prevHistory = JSON.parse(JSON.stringify(dataHistory));
      const lastIndex = _dataHistory.length - 1;
      setQuestionIndex(_dataHistory[lastIndex].questionIndex);
      setCurrentSet(_dataHistory[lastIndex].currentSet);
      prevHistory.pop();
      setDataHistory(prevHistory);
      if (_dataHistory[lastIndex].val === 'Accept') {
        if (_dataHistory[lastIndex].currentSet === 1 || _dataHistory[lastIndex].currentSet === 3) {
          let totalSkipLength = Number(_dataHistory[lastIndex].length) - Number(_dataHistory[lastIndex].questionIndex);
          props.setModuleIndex(prev => prev - totalSkipLength - 1);
        } else {
          props.setModuleIndex(prev => prev - 1);
        }
      } else {
        if (_dataHistory[lastIndex].currentSet === 0 || _dataHistory[lastIndex].currentSet === 2) {
          let totalSkipLength = Number(_dataHistory[lastIndex].length) - Number(_dataHistory[lastIndex].questionIndex);
          props.setModuleIndex(prev => prev - totalSkipLength - 1);
        } else {
          props.setModuleIndex(prev => prev - 1);
        }
      }
    }
  }

  // const prevQuestion = () => {
  //   if ((currentSet === 1 && questionIndex === 0) || (currentSet === 2 && questionIndex === 0) || (currentSet === 3 && questionIndex === 0)) {
  //     setCurrentSet(prev => prev - 1);
  //     if (historyVal !== 0) {
  //       setQuestionIndex(historyVal - 1);
  //     } else {
  //       setQuestionIndex(historyVal);
  //     }
  //     props.setModuleIndex(indexing[currentSet - 1].length - 1);
  //   }
  //   else {
  //     setQuestionIndex(prev => prev - 1);
  //   }
  // };

  const saveAnswer = (index) => {
    props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid);
  }

  const handleButtonClick = (val) => {
    if (val === "Accept") {
      if (Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 0) {
        if (questionIndex < indexing[currentSet].length - 1) {
          setHistoryVal(questionIndex);
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[0].respid);
          }
        }

        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[0].respid);
        setCurrentSet(prev => prev + 1);
        setQuestionIndex(0);
        setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])

      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(0);
          setQuestionIndex(prev => prev + 1);
          setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])
        } else {
          saveAnswer(0);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
          setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])
        }
      }
    } else {
      if (Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 1) {
        if (questionIndex < indexing[currentSet].length - 1) {
          setHistoryVal(questionIndex);
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid);
          }
        }
        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid);
        setCurrentSet(prev => prev + 1);
        setQuestionIndex(0);
        setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])
      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(1);
          setQuestionIndex(prev => prev + 1);
          setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])
        } else {
          saveAnswer(1);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
          setDataHistory(prev => [...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length - 1}])
        }
      }
    }
  }

  return (
      <View style={styles.body}>
        {show ? (
            <>
              {page ? <Animated.View style={{transform: [{translateX: transform}]}}>
                    <IntroPage Refresh={Refresh}/>
                  </Animated.View> :
                  <MiddlePage Showing={Showing}
                              primaryColorBlue={primaryColorBlue}
                              primaryColorPurple={primaryColorPurple}
                              spin={spin}
                              transform={transform}
                              show={show}
                              gain={80}
                              loss={10}
                              lossRatio={0.5}
                  />

              }
            </>
        ) : !showMiddleIntro ? (
                <>
                  <View style={styles1.content}>
                    <Text style={[styles1.textStyle, {
                      display: none,
                      width: inIframe() ? '95vw' : '92vw',
                      fontSize: inIframe() ? 22.5 : screenWidth <= 1125 ? wp('2.4%') : (screenWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
                    }]}>We’ll show you a number of scenarios with different returns and{`\n`}different chances of gaining.
                      We’ll ask if you’d accept or reject the{`\n`}investment</Text>
                    {currentSet !== valueLength && allValue && Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 1 && currentSet === 1 && !middleIntro && questionIndex === 0 &&
                        <Text style={[styles1.textStyle, {
                          width: inIframe() ? '95vw' : '92vw',
                          fontSize: inIframe() ? 22.5 : screenWidth <= 1125 ? wp('2.4%') : (screenWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
                        }]}>Now the chances of a gain, or a loss, are not the same.{`\n`}
                          There's a 17% chance of a loss and an 83% chance of a gain.</Text>}
                    <Animated.View style={[{transform: [{translateX: transform}, {rotate: spin}]}, {
                      position: 'relative',
                      alignSelf: "center",
                      height: screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : (screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : wp(`${2.7 * 12}%`)),
                      width: screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : (screenWidth <= 1125 ? wp(`${4.0 * 14}%`) : wp(`${2.7 * 12}%`))
                    }]}>
                      <Rotate1
                          gain={currentSet !== valueLength && parseInt(
                              allValue[currentSet][questionIndex].metadata.winamount.slice(1),
                          )}
                          loss={currentSet !== valueLength && parseInt(
                              allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
                          )}
                          lossRatio={
                              currentSet !== valueLength && allValue[currentSet][questionIndex].metadata.lossratio
                          }
                          primaryColorBlue={primaryColorBlue}
                          primaryColorPurple={primaryColorPurple}
                          spin={spin}
                          transform={transform}
                          show={show}/>

                    </Animated.View>
                    <View
                        style={{height: '10%', width: '70%', justifyContent: 'flex-end', position: 'absolute', bottom: 0,}}>
                      <Text style={[styles1.textStyle, {
                        width: inIframe() ? '95vw' : '92vw',
                        fontSize: inIframe() ? 22.5 : screenWidth <= 1125 ? wp('2.4%') : (screenWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
                      }]}>
                        {currentSet === 1 && !middleIntro && questionIndex === 0 ? `Would you risk losing £${currentSet !== valueLength && parseInt(allValue[currentSet][questionIndex].metadata.lossamount.slice(1))} for the chance to gain £${parseInt(allValue[currentSet][questionIndex].metadata.winamount.slice(1))} over the next 9 months?` : 'Would you accept or reject this?'}
                      </Text>
                      <View style={styles.rowBox}>
                        {props.moduleIndex > 0 ? (
                            <TouchableHighlight
                                style={styles.backButton}
                                onPress={() => {
                                  prevQuestion();
                                  animate();
                                }}>
                              <Text style={styles.backButtonText}>Back</Text>
                            </TouchableHighlight>
                        ) : null}
                        {(allValue !== null && currentSet !== valueLength) && allValue[currentSet][questionIndex].answers.map(
                            (answer, i) => {
                              return (

                                  <TouchableHighlight
                                      style={styles.button}
                                      key={answer.respid}
                                      onPress={() => {
                                        handleButtonClick(answer.text);
                                        animate();
                                        setNone('none')
                                      }}>
                                    <Text style={styles.buttonText}>{answer.text === 'Accept' ? 'Accept' : 'Reject'}</Text>
                                  </TouchableHighlight>

                              );
                            },
                        )}
                      </View>
                    </View>
                  </View>
                </>)
            : <Animated.View style={[stylesMiddleIntro.Container, {transform: [{translateX: transform}]}]}> <View
                style={[styles.Container, {
                  height: screenWidth > 1300 ? '100vh' : screenWidth > 760 ? '90vh' : '85vh',
                }]}>
              <View style={[stylesMiddleIntro.Container, {marginBottom: inIframe() ? 16.5 : hp('2%')}]}>
                <Text style={[stylesMiddleIntro.text, {
                  fontSize: inIframe() ? 18 : hp('2.5%'),
                  marginLeft: inIframe() ? 23.5 : wp('2%'),
                  marginBottom: inIframe() ? 16.5 : hp("2%")
                }]}>
                  {currentSet !== valueLength && allValue &&
                  Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 0 ? 'Okay, now we’re going to change things around' :
                      `Now let’s see how you feel when the chances of ${`\n`}gaining and losing are changed.${`\n`}${`\n`}In the following scenarios the chances of a gain are ${`\n`}higher than that of a loss.${`\n`}${`\n`}`}
                </Text>
                <TouchableOpacity style={[stylesMiddleIntro.button, {
                  marginLeft: inIframe() ? 23.5 : wp('2%'),
                }]} onPress={() => setShowMiddleIntro(false)}>
                  <Text style={stylesMiddleIntro.buttonText}>Continue</Text>
                </TouchableOpacity>
              </View>
            </View> </Animated.View>

        }
      </View>
  )
}

export default withRouter(Loss);

const styles1 = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // height:'70vh',
    width: '100vw',
  },
  textStyle: {
    color: 'white',
    letterSpacing: 0.5,
    fontFamily: 'OpenSans-Regular',
    fontWeight: 'normal',
    fontStyle: 'normal',
    position: 'relative',
    alignSelf: 'center',
    //marginVertical:'1%'
  },
  introButton: {
    minHeight: 48,
    width: 170,
    borderRadius: 50,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    paddingVertical: 15,
    color: '#a81c1b',
    position: "relative",
    marginTop: '2%',
    left: '2%',
    alignSelf: 'flex-start'
  },
  buttonText: {

    color: '#a81c1b',
    fontSize: 22,
    textAlign: 'center',

  },
})

const stylesMiddleIntro = StyleSheet.create({
  Container: {
    width: '100vw',
    // flex: 1,
    // justifyContent: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  button: {
    minHeight: 48,
    width: 170,
    borderRadius: 50,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    paddingVertical: 15,
    color: '#a81c1b',
    alignSelf: 'center',
    position: "relative",
    marginTop: '2%',
  },

  buttonText: {
    color: '#a81c1b',
    fontSize: '19px',
    textAlign: 'center',

  },
  text: {
    position: 'relative',
    width: 'auto',
    color: '#ffffff',
    fontWeight: '400',
    fontFamily: 'OpenSans-Regular',
    display: 'flex',
    alignItems: 'center'

  },
})
