import React, { useEffect, useLayoutEffect } from 'react';
import { StyleSheet, Text, View, Dimensions, Animated, Easing } from 'react-native';
import { withRouter } from 'react-router-dom';
import Header from '../../Components/HeaderSkin3';
import Circle from '../../views/IndexView';
import { ASPECTHEADERTEXT, BODYTEXTHOMEPAGE, buttonText, moduleid } from '../../Utils/static-strings';
import { styles } from './styles/index'
import { useState, useRef } from 'react';

const backgroundColor = '#91d5d3';

const Index = (props) => {
    const [check, setCheck] = useState(1)
    const [animate, setAnimate] = useState(false);
    const [descale, setDescale] = useState(true);
    const scaleValue = useState(new Animated.Value(1))[0];
    const descaleValue = useState(new Animated.Value(500))[0];
    const [moduleName, setModuleName] = useState(null);

    useLayoutEffect(() => {

        if (props.riskCalculation !== '') {
            setDescale(true)
        } else {
            setDescale(false)
        }
    }, [])

    const bgColor = [
        "#f5d3e4",
        "#c3e0b5",
        "#ffeec9",
        "rgb(187, 221, 242)",
        "rgb(204, 203, 227)"]

    const startAnimation = () => {
        setAnimate(true);
        Animated.timing(scaleValue, {
            toValue: 500,
            duration: 600
        }).start();
    }

    const startDescale = () => {
        setDescale(true);
        Animated.timing(descaleValue, {
            toValue: 1,
            duration: 900
        }).start(() => setDescale(false));
    }

    const handleBgColor = () => {
        switch (moduleName) {
            case moduleid.loss():
                return bgColor[0];
            case moduleid.experience():
                return bgColor[2];
            case moduleid.future():
                return bgColor[3];
            case moduleid.risk():
                return bgColor[1];
            case moduleid.knowledge():
                return bgColor[4];
            default: return '#eee'
        }
    }

    return (
        <div style={{
            display: "flex",
            height: '100%',
            width: '100%',
            position: "absolute",
            flexDirection: 'column',
            justifyContent: "space-between",
            backgroundColor
        }} >
            {/* <Header currentRoute={"Understanding"} /> */}
            {descale &&
                <Animated.View style={[styles.animatedCircleStyle,
                { backgroundColor: handleBgColor(), transform: [{ scale: descaleValue }] }]} />
            }
            {animate &&
                <Animated.View style={[styles.animatedCircleStyle,
                { backgroundColor: handleBgColor(), transform: [{ scale: scaleValue }] }]} />
            }
            <Circle {...props} {... { check }} {...{ setCheck }} {...{ startAnimation }} {...{ setModuleName }} {...{ startDescale }} />
        </div>
    )
}

export default withRouter(Index);
