import React, { useLayoutEffect, useState, useRef, useContext, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import { buttonText } from '../../../../Utils/static-strings'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import { ActivityContext } from '../../../Activities';
const Index = (props) => {
    const animation = useRef(new Animated.Value(0)).current;
    const resultContext = useContext(ActivityContext);
    const [buttonSelect, setButtonSelect] = useState(true);
    const [hide, setHide] = useState(true);
    const [data, setData] = useState([
        { name: props.moduleObject.questions[3].answers[0].text, isActive: false, id: 0 },
        { name: props.moduleObject.questions[3].answers[1].text, isActive: false, id: 1 },
        { name: props.moduleObject.questions[3].answers[2].text, isActive: false, id: 2 },
        { name: props.moduleObject.questions[3].answers[3].text, isActive: false, id: 3 },
        { name: props.moduleObject.questions[3].answers[4].text, isActive: false, id: 4 },
        { name: props.moduleObject.questions[3].answers[5].text, isActive: false, id: 5 },
        { name: props.moduleObject.questions[3].answers[6].text, isActive: false, id: 6 },
        { name: props.moduleObject.questions[3].answers[7].text, isActive: false, id: 7 },
        { name: props.moduleObject.questions[3].answers[8].text, isActive: false, id: 8 },
    ])

    useLayoutEffect(() => {
        let check;
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        if (props.moduleObject.questions[3].askif) {
            if (props.moduleObject.questions[3].askif.op == 'OR') {
                props.moduleObject.questions[3].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            check = props.moduleObject.questions[props.moduleIndex].askif.answers.find((cc) => cc.respid == data.respid);
                        }
                    })
                    if (check) { }
                    else {
                        props.selectAnswerHandler(
                            props.moduleObject.moduleid,
                            props.moduleObject.questions[3].qid,
                            props.moduleObject.questions[3].answers[0].respid,
                        );
                        props.setSkippedQuestion(true);
                        props.Refresh();
                    }

                })
            }
            if (props.moduleObject.questions[3].askif.op == 'AND') {
                props.moduleObject.questions[3].askif.answers.forEach((val) => {
                    moduleIdExep[0].responses.forEach((data) => {
                        if (val.qid == data.qid) {
                            if (val.respid == data.respid) {

                            }
                            else {
                                props.selectAnswerHandler(
                                    props.moduleObject.moduleid,
                                    props.moduleObject.questions[3].qid,
                                    props.moduleObject.questions[3].answers[0].respid,
                                );
                                props.Refresh();
                            }
                        }
                    })
                })
            }
        }
        Animated.timing(animation, {
            toValue: 1,
            duration: 1000
        }).start(() => setHide(false));
    }, [])

    const nextPage = () => {
        setHide(true);
        let cc = data.filter(val => val.isActive == true);
        if (buttonSelect) {
            props.selectAnswerHandler(
                props.moduleObject.moduleid,
                props.moduleObject.questions[3].qid,
                props.moduleObject.questions[3].answers[9].respid,
            );
        } else {
            cc.forEach((val) => {
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.moduleObject.questions[3].qid,
                    props.moduleObject.questions[3].answers[val.id].respid,
                );
            })
        }

        Animated.timing(animation, {
            toValue: 0,
            duration: 1000
        }).start(() => props.Refresh());
    }

    const chage = (index) => {
        setButtonSelect(false)
        if(props.moduleObject.questions[3].multichoice){
            let tmp = data;
            tmp[index].isActive = !tmp[index].isActive;
            setData(tmp => [...tmp])
        }else{
            let tmp = data.map((item, _index)=>{
                if(index === _index){
                    item.isActive = true;
                    return item;
                }else{
                    item.isActive = false;
                    return item;
                }
            });
            setData(tmp)
        }
    }
    useEffect(() => {
        let select = data.find((Value) => Value.isActive == true);
        if (select == undefined) {
            setButtonSelect(true);
        }
    }, [data])

    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            // flex: 1,
            // justifyContent: 'center',
            // alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Animated.View style={[styles.content, { opacity: animation }]}>
                <Text style={styles.customText}>
                    {props.moduleObject.questions[3].text.split('(')[0]}
                </Text>
                <Text style={styles.customText2}>
                    {props.moduleObject.questions[3].text.split('?')[1]}
                </Text>
                <View style={styles.buttongroup}>
                    {
                        data && data.map((el, index) => {
                            if (index < 3) {
                                return (
                                    <View key={index} style={{ margin: 4 }}>
                                        <View>
                                            <TouchableOpacity
                                                onPress={() => { chage(index) }}
                                                disabled={hide}
                                                style={[styles.button, { borderColor: el.isActive ? "#ea9550" : "#ffb91d", backgroundColor: el.isActive ? "#ea9550" : "#ffb91d" }]}>
                                                <Text style={styles.buttontext}>{el.name}</Text>
                                                {
                                                    el.isActive &&
                                                    <DoneIcon fontSize='large' htmlColor="#ffffff" style={{ position: 'absolute', height: 60, width: 60 }} />
                                                }
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            }
                        })
                    }
                </View>
                <View style={styles.buttongroup}>
                    {
                        data && data.map((el, index) => {
                            if (index >= 3 && index < 6) {
                                return (
                                    <View key={index} style={{ margin: 4 }}>
                                        <View>
                                            <TouchableOpacity
                                                onPress={() => { chage(index) }}
                                                disabled={hide}
                                                style={[styles.button, { borderColor: el.isActive ? "#ea9550" : "#ffb91d", backgroundColor: el.isActive ? "#ea9550" : "#ffb91d" }]}>
                                                <Text style={styles.buttontext}>{el.name}</Text>
                                                {
                                                    el.isActive &&
                                                    <DoneIcon fontSize='large' htmlColor="#ffffff" style={{ position: 'absolute', height: 60, width: 60 }} />
                                                }
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            }
                        })
                    }
                </View>
                <View style={styles.buttongroup}>
                    {
                        data && data.map((el, index) => {
                            if (index >= 6) {
                                return (
                                    <View key={index} style={{ margin: 4 }}>
                                        <View>
                                            <TouchableOpacity
                                                onPress={() => { chage(index) }}
                                                disabled={hide}
                                                style={[styles.button, { borderColor: el.isActive ? "#ea9550" : "#ffb91d", backgroundColor: el.isActive ? "#ea9550" : "#ffb91d" }]}>
                                                <Text style={styles.buttontext}>{el.name}</Text>
                                                {
                                                    el.isActive &&
                                                    <DoneIcon fontSize='large' htmlColor="#ffffff" style={{ position: 'absolute', height: 60, width: 60 }} />
                                                }
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            }
                        })
                    }
                </View>
                {/* <View style={{ margin: 5 }}></View> */}
                <View style={{ margin: hp('3%') }}>
                    {buttonSelect ?
                        <TouchableOpacity
                            onPress={() => { nextPage() }}
                            disabled={hide}
                            style={styles.lastButton}>
                            <Text style={styles.lastButtonText}> {props.moduleObject.questions[3].answers[9].text} </Text>
                            {/* <ArrowForwardIcon fontSize='default' htmlColor="#ffffff" style={{ paddingTop: 10 }} /> */}
                        </TouchableOpacity> :
                        <TouchableOpacity style={{
                            width: 115,
                            height: 40,
                            backgroundColor: '#2f2f2f',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} disabled={hide} onPress={() => { nextPage() }}>
                            <Text style={styles.lastButtonText}>
                                {buttonText}
                            </Text>
                        </TouchableOpacity>
                    }
                </View>

            </Animated.View >
        </div >
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('50%') : Dimensions.get('screen').width > 700 ? wp('70%') : wp('95%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1000 ? 24 : (Dimensions.get('screen').width < 350 ? 16 : 22),
        textAlign: 'center',
        color: '#2f2f2f',
        marginBottom: hp("3%"),
        fontFamily: 'Manrope-SemiBold',
        lineHeight: Dimensions.get('screen').width > 1000 ? 32 : (Dimensions.get('screen').width < 350 ? 24 : 26),
        fontWeight: "600"
    },
    customText2: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1000 ? 16 : (Dimensions.get('screen').width < 350 ? 12 : 16),
        textAlign: 'center',
        color: '#2f2f2f',
        marginBottom: hp("3%"),
        lineHeight: Dimensions.get('screen').width > 1000 ? 24 : (Dimensions.get('screen').width < 350 ? 16 : 24),
        fontWeight: "600",
        fontFamily: 'Manrope-Regulars',
    },
    button: {
        width: Dimensions.get('screen').width > 1000 ? 144 : (Dimensions.get('screen').width < 350 ? 88 : 114),
        height: Dimensions.get('screen').width > 1000 ? 144 : (Dimensions.get('screen').width < 350 ? 88 : 114),
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1000 ? 72 : (Dimensions.get('screen').width < 350 ? 44 : 57),
        borderStyle: "solid",
        borderColor: "#ffb91d",
        backgroundColor: "#ffb91d",
        alignItems: "center",
        justifyContent: "center",
    },
    buttontext: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: Dimensions.get('screen').width > 1000 ? 20 : (Dimensions.get('screen').width < 350 ? 11.3 : 12),
        textAlign: 'center',
        color: '#2f2f2f',
        fontWeight: '600',
        lineHeight: Dimensions.get('screen').width > 1000 ? 24 : (Dimensions.get('screen').width < 350 ? 15.8 : 20.5),
        padding: Dimensions.get('screen').width > 1000 ? 15 : (Dimensions.get('screen').width < 350 ? 15 : 15)
    },
    buttongroup: {
        flexDirection: 'row'
    },
    lastButton: {
        backgroundColor: '#2f2f2f',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: 40
    },
    lastButtonText: {
        color: '#ffffff',
        fontFamily: 'Manrope-SemiBold',
        fontSize: 16,
        textAlign: "center",
        fontWeight: '600',
        lineHeight: 24,
        marginHorizontal: 20
    },
    arrow: {
        width: Dimensions.get('screen').width > 1300 ? 34 : 28,
        height: Dimensions.get('screen').width > 1300 ? 34 : 28,
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1300 ? 18 : 15,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
    }

})