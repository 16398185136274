/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../Activities';
import Future from './future/FutureMain';
import Risk from './Risk/index';
import Loss from './Loss/index';
import Knowledge from './Knowledge/index';
import Experience from './Experience/index';
import ESG from './ESG/index';
import { moduleid } from '../../Utils/static-strings';
const Index = (props) => {
  const [moduleObject, setModuleObject] = useState(null);
  const [moduleIndex, setModuleIndex] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);

  const resultContext = useContext(ActivityContext);

  useLayoutEffect(() => {
    const moduleArray = props.modules.modules.filter(
      (module) => module.moduleid === props.match.url.split('/')[2],
    );
    resultContext.resetModuleResult(props.match.url.split('/')[2])
    if (moduleArray !== undefined) {
      setModuleObject(moduleArray[0]);
      setNumberOfQuestions(moduleArray[0].questions.length);
    }
  }, [props.match.url]);

  const selectAnswerHandler = (moduleId, questionId, answerId, isSkipValue, skipIndex, questionSkipArray, replyValue) => {
    if (isSkipValue) {
      resultContext.setAnswer(moduleId, questionId, answerId);
      if (skipIndex) {
        resultContext.setModuleCompletedAtTime(moduleId, isSkipValue);
      }
      props.setfinishAnimate(moduleId)
      props.history.push('/');
    }
    else if (moduleIndex < numberOfQuestions - 1) {
      if (moduleIndex === 0) {
        resultContext.setModuleStartedAtTime(moduleId);
      }
      setModuleIndex(moduleIndex => moduleIndex + 1);
      resultContext.setAnswer(moduleId, questionId, answerId);
    } else {
      if (moduleId == moduleid.experience()) {
        resultContext.setAnswer(moduleId, questionId, answerId);
        if (skipIndex) {
          resultContext.setModuleCompletedAtTime(moduleId);
          props.setfinishAnimate(moduleId)
          props.history.push('/');
        }
      } else {
        if (questionSkipArray !== undefined) {
          resultContext.setAnswer(moduleId, questionId, answerId);
          resultContext.setModuleCompletedAtTime(moduleId, undefined, true, questionSkipArray);
          props.setfinishAnimate(moduleId)
          props.history.push('/');
        } else {
          resultContext.setAnswer(moduleId, questionId, answerId);
          resultContext.setModuleCompletedAtTime(moduleId);
          props.setfinishAnimate(moduleId)
          props.history.push('/');
        }
      }
    }
  };
  const componentHandle = () => {
    const currentComponent = props.modules.modules.filter(
      (module) => module.moduleid === props.match.url.split('/')[2],
    )[0];
    switch (currentComponent.moduleid) {
      case moduleid.risk():
        return (
          <Risk
            selectAnswerHandler={selectAnswerHandler}
            moduleObject={moduleObject}
            moduleIndex={moduleIndex}
            setModuleIndex={setModuleIndex}
            result={resultContext.result}
            skin={props.skin}
            allmodule={props.modules.modules}
            resultContext={resultContext}
            history={props.history}
            setfinishAnimate={props.setfinishAnimate}
          />
        );
      case moduleid.loss():
        return (
          <Loss
            moduleObject={moduleObject}
            setModuleIndex={setModuleIndex}
            moduleIndex={moduleIndex}
            selectAnswerHandler={selectAnswerHandler}
            skin={props.skin}
            setfinishAnimate={props.setfinishAnimate}
          />
        );
      case moduleid.future():
        return (
          <Future
            moduleObject={moduleObject}
            moduleIndex={moduleIndex}
            setModuleIndex={setModuleIndex}
            selectAnswerHandler={selectAnswerHandler}
            skin={props.skin}
            setfinishAnimate={props.setfinishAnimate}

          />
        );
      case moduleid.knowledge():
        return (
          <Knowledge
            selectAnswerHandler={selectAnswerHandler}
            moduleObject={moduleObject}
            moduleIndex={moduleIndex}
            setModuleIndex={setModuleIndex}
            skin={props.skin}
            history={props.history}
            setfinishAnimate={props.setfinishAnimate}
          />
        );
      case moduleid.experience():
        return (
          <Experience
            selectAnswerHandler={selectAnswerHandler}
            moduleObject={moduleObject}
            moduleIndex={moduleIndex}
            setModuleIndex={setModuleIndex}
            skin={props.skin}
            setfinishAnimate={props.setfinishAnimate}

          />
        );
        case moduleid.esg():
          return (
            <ESG
              selectAnswerHandler={selectAnswerHandler}
              moduleObject={moduleObject}
              moduleIndex={moduleIndex}
              setModuleIndex={setModuleIndex}
              skin={props.skin}
              setfinishAnimate={props.setfinishAnimate}
              resultContext={resultContext}
              numberOfQuestions={numberOfQuestions}
              history={props.history}
            />
          );
      default:
        return null;
    }
  };

  return moduleObject !== null ? componentHandle() : null;
};

export default withRouter(Index);

