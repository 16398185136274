import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Animated, TouchableOpacity, Dimensions, Image } from 'react-native';
import { withRouter } from 'react-router-dom';
import Header from '../../../../../Components/HeaderSkin3';
import { buttonText, bodyTextFuturePage, THE, FUTURE } from '../../../../../Utils/static-strings';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../../Utils/responsive';
import { styles } from './Styles/index'

const deviceWidth = Dimensions.get('window').width;

const Index = (props) => {

    const mainAnimation = useState(new Animated.Value(0))[0];
    const LeftPostAnimation = useState(new Animated.Value(-200))[0];
    const RightPostAnimation = useState(new Animated.Value(-400))[0];
    const TriangleAnimation = useState(new Animated.Value(-500))[0];

    useEffect(() => {
        Animated.parallel([
            Animated.timing(mainAnimation, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }),
            Animated.timing(LeftPostAnimation, {
                toValue: deviceWidth > 1250 ? 0 : deviceWidth > 800 ? -35 : deviceWidth > 600 ? -110 : -90,
                duration: 1000,
                useNativeDriver: false
            }),
            Animated.timing(RightPostAnimation, {
                toValue: deviceWidth > 1250 ? 0 : deviceWidth > 800 ? -35 : deviceWidth > 600 ? -110 : -90,
                duration: 1000,
                useNativeDriver: false
            }),
            Animated.timing(TriangleAnimation, {
                toValue: deviceWidth > 1250 ? 0 : deviceWidth > 800 ? -35 : deviceWidth > 600 ? -110 : -90,
                duration: 1000,
                useNativeDriver: false
            })
        ])
            .start();
    }, [])

    const opacityInterpolate = mainAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
    })

    const leftTextAnimationInterpolate = mainAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: ["-50%", "0%"]
    })

    const { setPrePage, moduleObject } = props;

    return (
        <div style={{
            backgroundColor: "#bbddf2",
            height: "100%",
            width: "100%",
            display:"flex",
            justifyContent:"space-between",
            flexDirection:"column",
            position:"absolute"
            // flex : 1
        }} >
            <Header currentRoute={"Understanding"} />
            <Animated.View style={[styles.body, { transform: [{ translateX: leftTextAnimationInterpolate }], opacity: opacityInterpolate }]} >
                <View style={styles.bodyHeader} >
                    <Text style={styles.headerText} >{THE} {FUTURE}</Text>
                </View>
                <View style={styles.bodyTextContainer} >
                    <Text style={styles.bodyText} >{bodyTextFuturePage}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: hp('3%'), opacity: 0.69, height: 18, marginLeft: deviceWidth > 850 ? "8.5%" : "7%", }}>
                    <Image source={require('../../../../../assets/images/regular-clock.png')} style={{
                        height: 18,
                        width: 18,
                        resizeMode: 'contain',
                    }}></Image>
                    <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regulars', color: '#2f2f2f', paddingLeft: 10 }}>Typically 1 minute</Text>
                </View>
                <TouchableOpacity style={styles.button} onPress={() => setPrePage(false)} >
                    <Text style={styles.buttonText} >{buttonText}</Text>
                </TouchableOpacity>
            </Animated.View>
            <View style={{ height: "25%", width: "100%" }} />

            {
                deviceWidth > 250 && <>

                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        position: 'absolute',
                        right: LeftPostAnimation
                    }} >
                        <View style={customStyle.triangleViolet} >

                        </View>

                        <View style={customStyle.circleSmall} >

                        </View>

                        <View style={customStyle.blueRectangle} >

                        </View>
                    </Animated.View>

                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        position: 'absolute',
                        right: RightPostAnimation
                    }} >
                        <View style={customStyle.triangleBlueCenter} >

                        </View>

                        <View style={customStyle.triangleGreen} >

                        </View>

                        <View style={customStyle.circleLarge} >

                        </View>
                        <View style={customStyle.yellowRectangle} >

                        </View>
                    </Animated.View>

                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        position: 'absolute',
                        right: TriangleAnimation,
                        zIndex: 10
                    }} >
                        <View style={customStyle.triangleGreenLeft} >

                        </View>

                        <View style={customStyle.triangleBlueRight} >

                        </View>

                        <View style={customStyle.redRectangle} >

                        </View>

                        <View style={customStyle.greenRectangle} >

                        </View>
                    </Animated.View>

                </>
            }

        </div >
    )
}

export default withRouter(Index);


const customStyle = StyleSheet.create({

    triangleBlueRight: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1250 ? 260 / 2 : deviceWidth > 600 ? 210 / 2 : deviceWidth > 380 ? 93 : 62,
        borderBottomWidth: deviceWidth > 1250 ? 268 : deviceWidth > 600 ? 210 : deviceWidth > 380 ? 179 : 130,
        borderLeftWidth: deviceWidth > 1250 ? 260 / 2 : deviceWidth > 600 ? 210 / 2 : deviceWidth > 380 ? 93 : 62,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 10,
        right: deviceWidth > 600 ? -42 : deviceWidth > 380 ? -35 : 0,
        bottom: 0,
        opacity: 0.75
    },
    triangleGreen: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1250 ? 370 / 2 : deviceWidth > 600 ? 350 / 2 : deviceWidth > 380 ? 119 : 90,
        borderBottomWidth: deviceWidth > 1250 ? 390 : deviceWidth > 600 ? 360 : deviceWidth > 380 ? 247 : 178,
        borderLeftWidth: deviceWidth > 1250 ? 370 / 2 : deviceWidth > 600 ? 350 / 2 : deviceWidth > 380 ? 119 : 90,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#0097a9',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 9,
        right: deviceWidth > 600 ? 20 : deviceWidth > 380 ? 5 : 20,
        bottom: 0,
        opacity: 0.75
    },
    triangleBlueCenter: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1250 ? 186 / 2 : deviceWidth > 600 ? 160 / 2 : deviceWidth > 380 ? 133 / 2 : 48,
        borderBottomWidth: deviceWidth > 1250 ? 190 : deviceWidth > 600 ? 170 : deviceWidth > 380 ? 134 : 96,
        borderLeftWidth: deviceWidth > 1250 ? 186 / 2 : deviceWidth > 600 ? 160 / 2 : deviceWidth > 380 ? 133 / 2 : 48,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 6,
        right: deviceWidth > 600 ? 310 : deviceWidth > 380 ? 178 : 150,
        bottom: 0,
        opacity: 0.75
    },
    triangleViolet: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1250 ? 240 / 2 : deviceWidth > 600 ? 200 / 2 : deviceWidth > 380 ? 169 / 2 : 61,
        borderBottomWidth: deviceWidth > 1250 ? 250 : deviceWidth > 600 ? 210 : deviceWidth > 380 ? 168 : 122,
        borderLeftWidth: deviceWidth > 1250 ? 240 / 2 : deviceWidth > 600 ? 200 / 2 : deviceWidth > 380 ? 169 / 2 : 61,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#7b76b6',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 5,
        right: deviceWidth > 1250 ? 430 : deviceWidth > 600 ? 425 : deviceWidth > 380 ? 243 : 200,
        bottom: 0,
        opacity: 0.75
    },
    triangleGreenLeft: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 600 ? 120 / 2 : deviceWidth > 380 ? 94 / 2 : 34,
        borderBottomWidth: deviceWidth > 600 ? 120 : deviceWidth > 380 ? 93 : 68,
        borderLeftWidth: deviceWidth > 600 ? 120 / 2 : deviceWidth > 380 ? 94 / 2 : 34,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#0097a9',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 6,
        right: deviceWidth > 1250 ? 608 : deviceWidth > 600 ? 580 : deviceWidth > 380 ? 353 : 280,
        bottom: 0,
        opacity: 0.66
    },
    circleLarge: {
        height: deviceWidth > 600 ? 182 : deviceWidth > 380 ? 114 : 82,
        width: deviceWidth > 600 ? 182 : deviceWidth > 380 ? 114 : 82,
        borderRadius: deviceWidth > 600 ? 182 / 2 : deviceWidth > 380 ? 114 / 2 : 82 / 2,
        position: "absolute",
        alignSelf: "center",
        zIndex: 7,
        backgroundColor: "#ffb91d",
        right: deviceWidth > 1250 ? (20 + 390 / 2) : deviceWidth > 600 ? (10 + 390 / 2) : deviceWidth > 380 ? 130 : 113,
        bottom: deviceWidth > 1250 ? 73 : deviceWidth > 600 ? 60 : deviceWidth > 380 ? 61 : 46,
        // opacity: 0.75
    },
    circleSmall: {
        height: deviceWidth > 600 ? 98 : deviceWidth > 380 ? 62 : 44,
        width: deviceWidth > 600 ? 98 : deviceWidth > 380 ? 62 : 44,
        borderRadius: deviceWidth > 600 ? 98 / 2 : deviceWidth > 380 ? 31 : 44 / 2,
        position: "absolute",
        alignSelf: "center",
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        zIndex: 4,
        right: deviceWidth > 1250 ? 570 : deviceWidth > 600 ? 550 : deviceWidth > 380 ? 344 : 271,
        bottom: deviceWidth > 600 ? 73 : deviceWidth > 380 ? 68 : 47,
        // opacity: 0.75
    },
    yellowRectangle: {
        height: deviceWidth > 600 ? 8 : deviceWidth > 380 ? 5 : 4,
        width: deviceWidth > 600 ? 123 : deviceWidth > 380 ? 77 : 55,
        position: "absolute",
        alignSelf: "center",
        backgroundColor: "#ffb91d",
        position: "absolute",
        alignSelf: "center",
        zIndex: 8,
        right: deviceWidth > 1250 ? (20 + 390 / 2 + 182 / 2) : deviceWidth > 600 ? (390 / 2 + 182 / 2) : deviceWidth > 380 ? 185 : 150,
        bottom: deviceWidth > 1250 ? (73 + 182 - 8) : deviceWidth > 600 ? (60 + 182 - 8) : deviceWidth > 380 ? 170 : 123,
        // opacity: 0.75
    },
    redRectangle: {
        height: deviceWidth > 600 ? 8 : deviceWidth > 380 ? 5 : 4,
        width: deviceWidth > 600 ? 123 : deviceWidth > 380 ? 77 : 55,
        position: "absolute",
        alignSelf: "center",
        backgroundColor: "#db7d72",
        position: "absolute",
        alignSelf: "center",
        zIndex: 8,
        right: deviceWidth > 600 ? (390 / 2 + 182 / 2 + 52) : deviceWidth > 380 ? 213 : 169,
        bottom: deviceWidth > 1250 ? (73 + 182 - 50) : deviceWidth > 600 ? (60 + 182 - 55) : deviceWidth > 380 ? 147 : 107,
        opacity: 0.72
    },
    greenRectangle: {
        height: deviceWidth > 600 ? 5 : deviceWidth > 380 ? 3 : 2,
        width: deviceWidth > 600 ? 123 : deviceWidth > 380 ? 77 : 55,
        position: "absolute",
        alignSelf: "center",
        backgroundColor: "#7ebd5f",
        position: "absolute",
        alignSelf: "center",
        zIndex: 10,
        right: deviceWidth > 600 ? (390 / 2 + 176 / 2 - 55) : deviceWidth > 380 ? 140 : 120,
        bottom: deviceWidth > 1250 ? 174 : deviceWidth > 600 ? 162 : deviceWidth > 380 ? 128 : 95,
    },
    blueRectangle: {
        height: deviceWidth > 600 ? 15 : deviceWidth > 380 ? 9 : 7,
        width: deviceWidth > 600 ? 123 : deviceWidth > 380 ? 77 : 55,
        position: "absolute",
        alignSelf: "center",
        backgroundColor: "#66b3e3",
        position: "absolute",
        alignSelf: "center",
        zIndex: 6,
        right: deviceWidth > 1250 ? (430 + 46) : deviceWidth > 600 ? (430 + 30) : deviceWidth > 380 ? 285 : 233,
        bottom: deviceWidth > 1250 ? 240 : deviceWidth > 600 ? 200 : deviceWidth > 380 ? 162 : 117,
        opacity: 0.55
    },
})