import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity, Animated, ScrollView, Dimensions } from 'react-native'
import { heightPercentageToDP as hp } from '../../../Utils/responsive'
import { discriptorText, moduleid } from '../../../Utils/static-strings'
import '../../../App.css'
const actualWidth = Dimensions.get('screen').width;

const buttonGroupHeight = actualWidth > 760 ? 500 : 310;
const buttonGroupWidth = actualWidth > 760 ? 168 : 105;

const Index = (props) => {
    const animation = useRef(new Animated.Value(0)).current;
    const [show, setShow] = useState(true)
    const [desktop, setDesktop] = useState(actualWidth > 700 ? false : true)
    const [score, setscore] = useState(null)
    const [report, setreport] = useState(null)
    const [reportText, setReportText] = useState(null)
    const [currentset, setcurrentset] = useState(null)
    const [discriptor, setDiscriptor] = useState(null);

    useLayoutEffect(() => {
        if (props.riskCalculate) {
            setscore(props.riskCalculate);
        }
        else {
            setscore(props.riskCalculation);
        }
        setreport(props.report);

    }, [])

    useEffect(() => {
        if (props.riskCalculation !== undefined) {
            let brs = props.riskCalculation.calcresults.filter((item) => {
                if(item.moduleid === moduleid.brs()){
                    setDiscriptor(item.score);
                }
                // item.moduleid === moduleid.brs() ? setDiscriptor(item.score) : console.log("")
            })
        }
        setShow(false);
        upsideAnimation();
    }, [])

    const check = () => {
        setReportText(null);
        setcurrentset(null);
    }

    const upsideAnimation = () => {
        animation.setValue(hp("100%"));
        Animated.timing(animation, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false,
        }).start()
    }

    const ReportCard = (id = 'none') => {

        if (id !== 'none') {
            const reportArray = report.filter((item) => {
                if (item.hasOwnProperty('moduleids')) {
                    return item.moduleids[0] === id
                }
            })
            setReportText(reportArray);
        }
        return (
            <View>
                <Animated.View style={[styles.cardContainer, { transform: [{ translateY: animation }] }]} >
                    <View style={{ justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: 'auto', marginVertical: 20 }}>
                        <View style={{ width: '52%', height: '1px', backgroundColor: '#d4d4d4' }} />
                        <View style={styles.riskContainer1}>
                            {
                                (id !== "none" || reportText !== null) && <TouchableOpacity onPress={() => {
                                    check()
                                    upsideAnimation()
                                }}  >
                                    <Image style={styles.arrow1} source={require('../../../assets/images/ResultPage2/mask.png')}></Image>
                                </TouchableOpacity>
                            }
                            <Text style={styles.label3}>{reportText === null ? 'Your risk personality report' : reportText.length > 0 && reportText[0].text}</Text>
                        </View>
                        <View style={{ width: '52%', height: '1px', backgroundColor: '#d4d4d4' }} />
                    </View>
                    <View >
                        <Text style={styles.cardText}>
                            {
                                reportText === null ? report[4].text.replace(/\r\n/g, "\n\n") + report[5].text.replace(/\r\n/g, "\n\n") + report[6].text.replace(/\r\n/g, "\n\n") :
                                    reportText.length > 0 && reportText.map((items, index) => (
                                        items.moduleids[0] !== moduleid.loss() ? index > 1 && items.text.replace(/\r\n/g, "\n\n") : index > 0 && items.text.replace(/\r\n/g, "\n\n")

                                    ))
                            }
                        </Text>
                        <View style={{ width: '80%', height: '1px', backgroundColor: '#d4d4d4', marginBottom: 10, alignSelf: "center" }} />
                        <Text style={styles.cardText}>
                            Tap the results above to find out more
                            </Text>
                        <Text style={{ marginBottom: 20 }}></Text>
                    </View>
                </Animated.View>
            </View>

        )
    }

    return (
        <ScrollView>
            <View style={[styles.container, { height: show ? "100vh" : "auto", width: !show && "100vw" }]}>
                <Image source={require('../../../assets/images/ResultPage2/logoResult.png')} style={styles.logo} />
                <View style={styles.mainContainer}>
                    <View style={{ height: 100, width: '100%', justifyContent: 'space-evenly' }} >
                        {show ?

                            <View style={styles.headingContainer}>
                                <View style={styles.Line1} />
                                <Text style={styles.header}>YOU AND...</Text>
                                <View style={styles.Line2} />
                            </View>
                            :
                            <>
                                <View style={[styles.rectangle1, { marginTop: hp("3%") }]}>
                                    <Text style={styles.rectangleText1}>Risk</Text>
                                </View>
                                <View style={{ justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', width: actualWidth > 760 ? '310px' : '300px', height: 'auto', marginVertical: hp("3%") }}>
                                    <View style={{ width: '100%', height: '1px', backgroundColor: '#d4d4d4' }} />
                                    <Text style={styles.label2}>{discriptor !== null ?
                                        (discriptor >= discriptorText.level1.min && discriptor < discriptorText.level1.max ? discriptorText.level1.name :
                                            (discriptor >= discriptorText.level2.min && discriptor < discriptorText.level2.max ? discriptorText.level2.name :
                                                (discriptor >= discriptorText.level3.min && discriptor < discriptorText.level3.max ? discriptorText.level3.name :
                                                    (discriptor >= discriptorText.level4.min && discriptor < discriptorText.level4.max ? discriptorText.level4.name :
                                                        (discriptor >= discriptorText.level5.min && discriptor <= discriptorText.level5.max ? discriptorText.level5.name : 'Out Of Limit'))))) :
                                        'Null Text'}</Text>
                                    <View style={{ width: '100%', height: '1px', backgroundColor: '#d4d4d4' }} />
                                </View>
                            </>
                        }
                    </View>
                    <View style={[styles.rowStyling, { display: show ? 'flex' : 'none' }]}>
                        <View style={styles.firstItem}>
                            <Text style={styles.rectangleText}>Understanding</Text>
                        </View>
                        <View style={styles.rectangle}>
                            <Text style={styles.rectangleText}>Risk</Text>
                        </View>
                        <View style={styles.thirdItem}>
                            <Text style={styles.rectangleText}>Understanding</Text>
                        </View>
                    </View>
                    <View style={[styles.buttonGroup, { width: (show && desktop) ? buttonGroupWidth : buttonGroupHeight, height: (show && desktop) ? buttonGroupHeight : buttonGroupWidth, flexDirection: (show && desktop) ? 'column' : 'row', marginTop: hp("3%") }]}>
                        {score !== null && score.calcresults.filter((value) =>
                            value.moduleid == moduleid.risk() || value.moduleid == moduleid.loss() || value.moduleid == moduleid.future()
                        ).sort(function (a, b) {
                            var nameA = a.moduleid.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.moduleid.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            // names must be equal
                            return 0;
                        }).map((item, index) => (
                            <>
                                {<TouchableOpacity key={item.measure} style={index === 0 ? (currentset !== null ? (currentset === item.moduleid ? styles.oval1 : styles.disable1) : styles.oval1) : index === 1 ? (currentset !== null ? (currentset === item.moduleid ? styles.oval2 : styles.disable2) : styles.oval2) : (currentset !== null ? (currentset === item.moduleid ? styles.oval3 : styles.disable3) : styles.oval3)} onPress={() => {
                                    setcurrentset(item.moduleid)
                                    ReportCard(item.moduleid)
                                    upsideAnimation()
                                }}>
                                    <View key={index} >
                                        <Text style={styles.value}>{item.measure}</Text>
                                        <Text style={styles.label}>{report[report.findIndex((values, index) => {
                                            if (index > 0) {
                                                return values.moduleids[0] === item.moduleid
                                            }
                                        })].text}</Text>
                                    </View>
                                </TouchableOpacity>}
                            </>
                        )
                        )
                        }
                    </View>
                    <View style={{ display: !show ? 'flex' : 'none', marginTop: hp('2%'), marginBottom: hp('3%') }}>
                        <Text style={styles.middleText}>Tap a result to learn more</Text>
                    </View>
                    <View style={{ display: show ? 'flex' : 'none' }}>
                        <TouchableOpacity style={styles.bottomImage} onPress={() => {
                            upsideAnimation()
                            setShow(false)
                        }}>
                            <View style={styles.riskContainer}>
                                <Text style={styles.riskValue}> {score !== null && report[0].text}</Text>
                                <Image style={styles.arrow} source={require('../../../assets/images/ResultPage2/mask.png')}></Image>
                            </View>
                        </TouchableOpacity>
                    </View>
                    {!show && ReportCard()}
                    <TouchableOpacity style={styles.reportbutton}
                        onPress={() => { props.history.push('/') }}>
                        <Text style={styles.reportbuttonText1}
                        >Back</Text>
                        <Text style={styles.reportbuttonText2}
                        >To Main Menu</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    )
}

export default Index

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#eeeeee',
        // overflow:'scroll'
    },
    logo: {
        position: "relative",
        alignSelf: "flex-end",
        width: 38,
        resizeMode: "contain",
        marginRight: '15px',
        marginTop: 10,
        height: 14
    },
    mainContainer: {
        flex: 1,
        marginTop: "15px",
        // backgroundColor:"yellow",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    heading: {
        width: '100%',
        height: '100%',
        alignSelf: "center",
        resizeMode: 'contain',
    },
    rectangle: {
        width: 'auto',
        height: 'auto',
        borderRadius: 24,
        alignSelf: "center",
        backgroundColor: 'rgba(87, 95, 105, 0.1)'
    },
    rectangleText: {
        height: "100%",
        padding: 10,
        fontFamily: 'JosefinSans-Regular',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 1.6,
        textAlign: 'center',
        textAlignVertical: 'center',
        color: '#575f69',
        textTransform: 'uppercase'
    },
    rectangle1: {
        width: 'auto',
        height: 'auto',
        borderRadius: 24,
        alignSelf: "center",
        backgroundColor: 'rgba(87, 95, 105, 0.1)'
    },
    rectangleText1: {
        height: "100%",
        width: "100%",
        fontFamily: 'JosefinSans-Bold',
        fontSize: hp("1.8%"),
        fontWeight: 'bold',
        letterSpacing: 2,
        textAlign: 'center',
        textAlignVertical: 'center',
        color: '#575f69',
        // paddingVertical:12,
        paddingTop: 12,
        paddingBottom: 10,
        paddingHorizontal: 12,
        textTransform: 'uppercase'
    },
    rowStyling: {
        // flex:1,
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        // backgroundColor:"blue",
        flexDirection: "row"
    },
    firstItem: {
        position: "relative",
        width: 153,
        height: 48,
        opacity: 0,
        borderRadius: 24,
        // backgroundColor: 'green',
    },
    thirdItem: {
        position: "relative",
        width: 153,
        height: 48,
        borderRadius: 24,
        opacity: 0,
        // marginLeft:100,
        // alignSelf:"flex-end",
        // backgroundColor: 'green',
    },
    forthItem: {
        position: "relative",
        width: 153,
        height: 48,
        opacity: 0.35,
        // opacity: 0,
        borderRadius: 24,
    },
    buttonGroup: {
        borderRadius: 88,
        backgroundColor: 'rgba(87, 95, 105, 0.1)',
        // backgroundColor:"blue",
        alignItems: "center",
        justifyContent: "space-around"
    },
    oval1: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#6fd5da",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center"
    },
    oval2: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#79a2d9",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center"
    },
    oval3: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#fda435",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center"
    },
    disable1: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#6fd5da",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.35
    },
    disable2: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#79a2d9",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.35
    },
    disable3: {
        width: actualWidth > 760 ? 156 : 95,
        height: actualWidth > 760 ? 156 : 95,
        borderWidth: 3,
        borderRadius: 88,
        borderStyle: "solid",
        borderColor: "#fda435",
        backgroundColor: "#eeeeee",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.35
    },
    value: {
        width: 105,
        height: 27,
        fontFamily: 'JosefinSans-Regular',
        fontSize: 20,
        textAlign: 'center',
        // zIndex:99999,
        // backgroundColor:"blue",
        color: '#575f69',
    },
    label: {
        width: actualWidth > 760 ? 105 : 55,
        height: 13,
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: actualWidth > 760 ? 10.5 : 9,
        fontWeight: '600',
        textAlign: 'center',
        color: '#575f69',
        textTransform: 'uppercase',
        alignSelf: 'center'
    },
    bottomImage: {
        width: 246,
        height: 48,
        resizeMode: "contain"
    },
    arrow: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
        opacity: 0.75,
    },
    arrow1: {
        position: 'absolute',
        marginVertical: 10,
        left: 32,
        width: 16,
        height: 16,
        resizeMode: 'contain',
        opacity: 0.75,
        transform: [{ rotate: '-90deg' }]
    },
    cardContainer: {
        flex: 1,
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'center',
        width: actualWidth > 760 ? '68%' : 300,
        height: 'auto',
        marginBottom: "12px",
        borderRadius: 2,
        backgroundColor: '#ffffff',
        // backgroundColor:"blue",
        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 7,
        //elevation: 18,
    },
    riskContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 246,
        height: 48,
        justifyContent: 'space-evenly',
        borderRadius: 31.5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(87, 95, 105, 0.05)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        // elevation:15
    },
    riskContainer1: {
        flexDirection: 'row',
        //  alignItems:'center',
        width: '100%',
        height: 'auto',
        // justifyContent:'center',
        // elevation:15
    },
    cardText: {
        width: "80%",
        height: "auto",
        fontFamily: 'JosefinSans-Regular',
        fontSize: 17,
        lineHeight: 26.5,
        alignSelf: "center",
        // marginBottom:"8px",
        color: 'rgba(87, 95, 105, 0.75)',
    },
    heading2: {
        alignSelf: 'center',
        width: 413,
        height: 29,
        fontFamily: 'JosefinSans-Light',
        fontSize: 24,
        fontWeight: '300',
        letterSpacing: 2.4,
        textAlign: 'center',
        color: '#575f69',
    },
    label2: {
        alignSelf: 'center',
        width: '100%',
        height: 'auto',
        fontFamily: 'JosefinSans-Light',
        fontSize: 32,
        fontWeight: '300',
        letterSpacing: 3.2,
        textAlign: 'center',
        color: '#575f69',
        textTransform: 'uppercase',
        marginTop: 12,
        marginBottom: 8,
        textAlignVertical: 'center',
    },
    label3: {
        alignSelf: 'center',
        width: '100%',
        height: 'auto',
        fontFamily: 'JosefinSans-Light',
        fontSize: 20,
        fontWeight: '300',
        letterSpacing: 1.2,
        textAlign: 'center',
        textAlignVertical: 'center',
        marginVertical: 10,
        color: '#575f69',
    },
    riskContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 246,
        height: 48,
        justifyContent: 'space-evenly',
        borderRadius: 31.5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(87, 95, 105, 0.05)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        // elevation:15
    },
    riskValue: {
        width: 182,
        height: 24,
        fontFamily: 'JosefinSans-Light',
        fontSize: 20,
        fontWeight: '300',
        letterSpacing: 2,
        textAlign: 'center',
        color: '#575f69',
    },
    arrow: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
        opacity: 0.75,
    },
    headingContainer: {
        width: actualWidth < 600 ? "321px" : "534px",
        // height: 76,
        height: hp("10%"),
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor:'orange',
        alignSelf: "center",
        marginBottom: hp("2%")
    },

    Line1: {
        position: "relative",
        width:  actualWidth > 600 ? 292 :actualWidth > 370 ? 195:148,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',

    },

    Line2: {
        position: "relative",
        width:  actualWidth > 600 ? 292 :actualWidth > 370 ? 195:148,
        height: 1,
        // display: WidthDimension<600
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },
    middleText: {
        width: "100%",
        height: "13px",
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: "10px",
        fontWeight: '600',
        textAlign: 'center',
        color: '#575f69',
        textTransform: 'uppercase'
    },
    header: {
        position: "relative",
        width: "100%",
        //height:"10%",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize: actualWidth > 600 ? 48 : actualWidth > 370 ? 32 : 20,
        letterSpacing: actualWidth > 500 ? 2.8 : actualWidth > 330 ? 2.8 : 2,
        // fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        color: '#575f69',
        textTransform: "capitalize",
        top: actualWidth > 2599 ? hp('0.7%') : hp('1%')
    },
    reportbutton: {
        alignSelf: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        height: 53, width: 165, borderRadius: 25,
        elevation: 10,
        borderColor: 'rgba(87, 95, 105, 0.05)',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: "solid",
        shadowColor: "rgba(87, 95, 105, 0.15)",
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 25,
        marginBottom: 12
    },
    reportbuttonText1: {
        fontFamily: 'JosefinSans-Light',
        fontSize: 16,
        textAlign: 'center',
        fontWeight: '300',
        color: '#575f69',
        letterSpacing: 1.6,
        paddingBottom: 5
    },
    reportbuttonText2: {
        fontFamily: 'JosefinSans-SemiBold',
        fontSize: 10,
        textAlign: 'center',
        fontWeight: '600',
        color: '#575f69',
        letterSpacing: 1,
        textTransform: 'uppercase',
    }
})
