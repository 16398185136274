import React, { useLayoutEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';

const Index = (props) => {
    return (

        <View style={[styles.greenBox, { height: props.height, backgroundColor: props.color }]}></View>


    )
}
export default Index
const styles = StyleSheet.create({
    greenBox: {
        width: Dimensions.get('screen').width > 760 ? 160 : 100,
        borderRadius: 3,
        alignSelf: Dimensions.get('screen').width > 760 ? 'center' : 'flex-end',
    },

})