import React, { useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions,PixelRatio } from 'react-native';
import {  screenWidth as actualWidth , screenHeight as actualHeight, } from '../../../../Utils/responsive';
import '../../../../../src/App.css';
import { inIframe } from '../../../../Utils/helper';
// const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);

  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  return (
    <View style={[styles.Container,{
      height: screenWidth > 1300 ? '100vh' : screenWidth > 760 ? '90vh' : '85vh',}]}>
      <View style={{ marginBottom:inIframe() ? 16.5 : hp('2%') }}>
        <Text style={[styles.text,{
          fontSize:  inIframe() ? 18:hp('2.5%'),
          marginLeft:inIframe() ? 23.5: wp('2%'),
          marginBottom: inIframe() ? 16.5 : hp("2%")}]}>
          Investing is all about accepting a degree of uncertainty for the{`\n`}potential of a larger return.{`\n`}{`\n`}
          This exercise measures when the trade-off between{`\n`}gains and losses feels right and when it feels wrong.
       </Text>
        <TouchableOpacity style={[styles.button,{
    marginLeft:inIframe() ? 23.5: wp('2%'),}]} onPress={() => props.Refresh()}>
          <Text style={styles.buttonText}>Get Started</Text>
        </TouchableOpacity>
      </View>
    </View>

  )
}
export default Index;
const styles = StyleSheet.create({
  Container: {
    width: '100vw',
    // flex: 1,
    // justifyContent: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    minHeight: 48,
    width: 170,
    borderRadius: 50,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    paddingVertical: 15,
    color: '#a81c1b',
    alignSelf: 'center',
    position: "relative",
    marginTop: '2%',
  },

  buttonText: {
    color: '#a81c1b',
    fontSize: '19px',
    textAlign: 'center',

  },
  text: {
    position: 'relative',
    alignSelf: 'flex-start',
    width: 'auto',
    color: '#ffffff',
    fontWeight: '400',
    fontFamily: 'OpenSans-Regular',

  },
})
