import React, { useContext, useEffect } from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native'
import { routesSkin3, BRAND, NAME } from '../../Utils/static-strings'
import '../../App.css';
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../../Containers/Activities/index'

const actualWidth = Dimensions.get('window').width;
const unselectedColor = "#2f2f2f";
const selectedColor = '#db7d72';
const noData = 0.5;
const data = 1;
const whiteColor = "#ffffff";
const orangeColor = "#db7d72";

const Index = (props) => {

    const resultContext = useContext(ActivityContext);
    const { currentRoute, Brand, Name } = props;

    var totalModules = 5; //resultContext?.result?.modules?.length;
    var completedModules = resultContext?.riskCalculation?.modules?.length;
    var doneModules = completedModules === undefined ? 0 : completedModules;

    const handleRouteChange = (route) => {
    }

    useEffect(() => {
        totalModules = 5; //resultContext?.result?.modules?.length;
        completedModules = resultContext?.riskCalculation?.modules?.length;
        doneModules = completedModules === undefined ? 0 : completedModules;
    }, [resultContext, ActivityContext])

    return (
        <View style={[styles.header, { ...props.styles }]} >
            {/* <View style={[{ width: actualWidth > 550 ? "100%" : "98%", alignSelf: actualWidth > 550 ? undefined : "center" }]} >
                <View style={actualWidth > 550 ? styles.headerNameContainer : styles.headerNameContainer1}  >
                    <Text onPress={() => props.history.push('/')} style={styles.brandNameText} >{Brand ? Brand : BRAND}</Text>
                    <Text onPress={() => props.history.push('/')} style={[styles.brandNameText, { color: unselectedColor }]} >{Name ? Brand : NAME}</Text>
                </View>
            </View>
            <View style={styles.navigationPanels} >
                <View style={styles.progressBarContainer} >
                    <View style={[styles.progressDisplay, { width: `${(78 + (doneModules / totalModules) * 22)}%` }]} />
                </View>
                <View style={[styles.row, { ...props.row }]} >
                    {
                        actualWidth > 550 && routesSkin3.map(route => (
                            <View key={route} onPress={() => handleRouteChange(route)} >
                                <Text style={[styles.navigationRouteName, { color: (((doneModules / totalModules) === 1) && route === 'Recommendation') ? selectedColor : (route === currentRoute && ((doneModules / totalModules) !== 1)) ? selectedColor : unselectedColor, opacity: (route === 'Recommendation' && ((doneModules / totalModules) !== 1)) ? noData : data }]} >{route}</Text>
                            </View>
                        ))
                    }
                </View>
            </View> */}
        </View>
    )
}

export default withRouter(Index);

const styles = StyleSheet.create({
    // header: {
    //     height: actualWidth>420 ? "14%" : "11.5%",
    //     width: '100%',
    //     backgroundColor: 'transparent',
    //     justifyContent: "space-between",

    // },
    headerContainer: {
        width: "98%",
        alignSelf: "center",
    },
    headerNameContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "2%",
        marginTop: "2%",
        // alignSelf: "center"
    },
    headerNameContainer1: {
        display: "flex",
        flexDirection: "row",
        width: "90%",
        marginTop: "3%",
        alignSelf: "center"
    },
    brandNameText: {
        fontFamily: 'Manrope-Bold',
        fontSize: actualWidth > 550 ? 28 : 18,
        fontWeight: "600",
        color: orangeColor,
        lineHeight: 32,
        textTransform: "uppercase"
    },
    navigationPanels: {
        width: (actualWidth < 1300) ? (actualWidth < 900 ? (actualWidth < 600) ? "98%" : "95%" : "80%") : "58%",
        alignSelf: "center",
        height: "45%",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    progressBarContainer: {
        height: 4,
        width: "90%",
        backgroundColor: whiteColor
    },
    progressDisplay: {
        width: "75%",
        height: "100%",
        backgroundColor: orangeColor
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    navigationRouteName: {
        textAlign: "center",
        fontFamily: 'Manrope-SemiBold',
        fontSize: actualWidth < 1250 ? 14 : 16,
        fontWeight: "600",
        color: unselectedColor,
        lineHeight: 24
    },
})
