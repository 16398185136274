import React from 'react';
import { withRouter } from 'react-router-dom';
import { addAnalytics } from '../helper';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        error: null,
        info: null,
        location:null
      };
    }
    componentDidCatch(error, info) {
        const { location } = this.props
        const errorInfo = {
            hasError: true,
            error,
            info,
            location
        };
        
        addAnalytics(errorInfo);
        this.setState(errorInfo);
    }
    render() {
      return this.props.children;
    }
  }

export default withRouter(ErrorBoundary);