/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useLayoutEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import axios from 'axios';
import logo from '../../assets/loading-gif/DIA-loaderAnimation.gif'
import { ActivityContext } from '../Activities';
import { getReportForSession, saveResults } from '../../Utils/path-config';
import Riskcalculation from './riskCalculation'
import ResultSkinTwo from './ResultSkin2/index'
import Loadingskin2 from '../../Utils/reportLoader'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
import { tiger } from '../../Utils/static-strings'
import { addAnalytics } from '../../helper';

const Index = (props) => {
  const resultContext = useContext(ActivityContext);

  const [animating, setAnimating] = useState(true);
  const [report, setReport] = useState(null);
  const [skin, setSkin] = useState("First");
  const [riskCalculate, setRiskCalculate] = useState(null);

  useLayoutEffect(() => {
    if (props.skin) {
      setSkin(props.skin);
    }

    if (props.skin === tiger) {
      if (window.parent) {
        console.log('post has been triggered')
        window.parent.postMessage({
          message: "surveycomplete",
          userref: props.userref
        }, window['runConfig'].REACT_APP_RESEARCH_APP_URL);
      }
    }

    if (resultContext.userSession !== null && resultContext.userSession !== '') {
      const tempResult = resultContext.userSession;
      tempResult.modules = [];
      tempResult.modules = resultContext.userSession.modules;
      const data = JSON.stringify(tempResult);

      const config = {
        method: 'post',
        url: window['runConfig'].REACT_APP_BASE_URL + saveResults(),
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      const url = window['runConfig'].REACT_APP_BASE_URL + getReportForSession(props.userref, props.sessionref);

      axios(config)
        .then(function (response) {
          setRiskCalculate(response.data)
          const config = {
            method: 'get',
            url: url,
            headers: {
              'Content-Type': 'application/json',
            },
          };
          axios(config)
            .then(function (response) {
              setAnimating(false);
              setReport(response.data);

            })
            .catch(function (error) {
              setAnimating(false);
              addAnalytics(error, config);
            });
        })
        .catch(function (error) {
          addAnalytics(error, config);
        });
    }
    else {
      const config = {
        method: 'get',
        url: window['runConfig'].REACT_APP_BASE_URL + getReportForSession(props.userref, props.sessionref),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios(config)
        .then(function (response) {
          setAnimating(false);
          setReport(response.data);

        })
        .catch(function (error) {
          setAnimating(false);
          addAnalytics(error, config);
        });
    }
  }, [resultContext.result]);

  const handleSkin = () => {
    // if (resultContext.riskCalculation.modules.length === props.modules.modules.length) {
    //   if (window.parent) {
    //     if (window.parent.postMessage) {
    //       window.parent.postMessage({ message: "surveycomplete", userref: props.userref }, window['runConfig'].REACT_APP_RESEARCH_APP_URL);
    //     }
    //   }
    // }
    if (sessionStorage.getItem("skinName")?.toLowerCase() === tiger?.toLowerCase()) {
      return <Riskcalculation riskCalculation={resultContext.riskCalculation} report={report} riskCalculate={riskCalculate} />
    } else {
      return <ResultSkinTwo riskCalculation={resultContext.riskCalculation} report={report} riskCalculate={riskCalculate} {...props} />
    }
  }
  const loader = () => {
    if (sessionStorage.getItem("skinName")?.toLowerCase() === tiger?.toLowerCase()) {
      return <View style={{ height: '100vh', width: '100vw', backgroundColor: '#ec0000', justifyContent: 'center' }}><img style={{ objectFit: 'contain', alignSelf: 'center', height: hp('40%'), width: wp('40%'), borderRadius: (hp('40%') + wp('40%')) / 2 }} src={logo} alt="loading..." /></View>
    } else {
      return <Loadingskin2 />
    }
  }

  return (
    <View style={styles.container}>
      {animating ? (
        loader()
      ) : (
        (report && (resultContext.riskCalculation || riskCalculate)) && handleSkin())}
    </View>
  );
};

export default Index;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    width: "100vw",
    // backgroundColor: 'pink',
  },
  activityIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
});
