import React, { useLayoutEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Easing } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
    const introAnimation = useRef(new Animated.Value(actualWidth)).current;
    useLayoutEffect(() => {
        Animated.timing(introAnimation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: false,
            easing: Easing.linear
        }).start()
    }, [])

    const goBack=()=>{
        props.setInfopage(prev=>!prev)
        props.setModuleIndex(moduleIndex => moduleIndex - 1);
        props.resultContext.resetPreviousModuleResult(props.moduleObject.moduleid)
    } 

    return (
        <Animated.View style={[styles.content, { transform: [{ translateX: introAnimation }] }]}>
            <Text style={styles.heading}>
               {props.moduleObject.questions[props.moduleIndex-1].answers[props.metaindex].metadata.resultlabel}
            </Text>
            <Text style={styles.customText}>
            {props.moduleObject.questions[props.moduleIndex-1].answers[props.metaindex].metadata.confirmationtext}
            </Text>
            <View style={styles.Line} />
            <TouchableOpacity style={styles.button} onPress={()=>{
                props.pageTwo();
                props.pageThree();
            }}>
                <Text style={styles.buttontext}>
                {props.moduleObject.questions[props.moduleIndex-1].answers[props.metaindex].metadata.buttontext}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.goBack} onPress={()=>goBack()}>
                <Text style={styles.goBackText}>go back</Text>
            </TouchableOpacity>
        </Animated.View>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        flex:1
    },

    customText: {
        position: "relative",
        width: actualWidth > 1000 ? wp('75%') : actualWidth > 400 ? wp('85%') : wp('90%'),
        Height: hp('24.3%'),
        fontSize: actualWidth > 1000 ? 24 : actualWidth > 400 ? 20 : 12,
        fontWeight: 300,
        textAlign: 'center',
        color: 'rgba(87, 95, 105, 0.75)',
        marginBottom: hp("4%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: actualWidth > 1000 ? 2.4 : actualWidth > 400 ? 2 : 1.2
    },
    button: {
        backgroundColor: '#575f69',
        width: actualWidth > 1000 ? 296 : actualWidth > 400 ? 296 : 288,
        height: 48,
        marginBottom: hp("3%"),
        justifyContent: 'center',
        borderRadius:2,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        padding:5
    },
    Line: {
        width: actualWidth > 1000 ? wp('35%') : actualWidth > 400 ? wp('45%') : wp('55%'),
        height: 1,
        backgroundColor: "#d4d4d4",
        marginBottom: hp("4%"),
    },
    heading:{
        fontSize:  actualWidth > 1000 ? 48 : actualWidth > 400 ? 32 : 16,
        fontWeight: 300,
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
        letterSpacing: actualWidth > 1000 ? 4.8 : actualWidth > 400 ? 3.2 : 1.6
    },
    goBack:{
        backgroundColor: 'transparent',
        width: 118,
        height: 48,
        marginBottom: hp("3%"),
        justifyContent: 'center'
    },
    goBackText:{
        color:"#575f69",
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
    }
})