import React, { useRef, useLayoutEffect, useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Animated } from 'react-native';
import Slider from './Slider';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function CustomizedSlider(props) {
    const [chooseValue, setChooseValue] = useState(0);
    const [answerIndex, setAnswerIndex] = useState(1);

    //remove useeffect when be need this page
    // useEffect(() => {
    //     props.resultContext.setAnswer(
    //         props.module.moduleid,
    //         props.module.questions[2].qid,
    //         props.module.questions[2].answers[0].respid);
    //     props.resultContext.setModuleCompletedAtTime(props.module.moduleid);
    //     props.history.push('/');
    // }, [])


    const handleChange = (event, newValue) => {
        setAnswerIndex(newValue);
        setChooseValue(props.module.questions[2].answers[answerIndex - 1].text);
    };

    const handle = () => {
        props.resultContext.setAnswer(
            props.module.moduleid,
            props.module.questions[2].qid,
            props.module.questions[2].answers[answerIndex - 1].respid);
        props.resultContext.setModuleCompletedAtTime(props.module.moduleid);
        props.history.push('/');
    }

    return (
        <Animated.View style={[styles.content]}>
            <Text style={styles.customText}>
                Investments can go down as well as up and so investors should be ready to withstand a drop.
            </Text>
            <Text style={styles.customText}>
                How much could the value of your investments fall before you would begin to feel uncomfortable?
            </Text>
            <View style={styles.Line1} />
            <View style={styles.slider}>
                <Text style={{ color: "#575f69", textAlign: 'center', fontSize: 8, top: 10, width: 75, position: 'relative', textTransform: 'uppercase' }}>Any of it</Text>
                <View style={{ left: 6, bottom: 1 }}>
                    <ArrowBackIcon fontSize='large' htmlColor="#575f69" />
                </View>
                <View style={styles.smallL1} />
                <Slider aria-label="ios slider" min={1} max={5} onChange={handleChange} valueLabelFormat={chooseValue} valueLabelDisplay='on' />
                <View style={styles.smallL1} />
                <View style={{ right: 6, bottom: 1, zIndex: -99999 }}>
                    <ArrowForwardIcon fontSize='large' htmlColor="#575f69" />
                </View>
                <Text style={{ color: "#575f69", textAlign: 'center', fontSize: 8, top: 10, width: 70, position: 'relative', textTransform: 'uppercase' }}>All of it</Text>
            </View>
            <View style={{ margin: 5 }}></View>
            <View>
                <TouchableOpacity onPress={() => { handle() }} style={[styles.button]}>
                    <Image style={styles.arrow}
                        source={require('../../../assets/images/knowledgearrow.png')} ></Image>
                </TouchableOpacity>
            </View>
        </Animated.View>
    );
};
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    customText: {
        position: "relative",
        width: Dimensions.get('screen').width > 1300 ? wp('28%') : Dimensions.get('screen').width > 700 ? wp('28%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: Dimensions.get('screen').width > 1300 ? 24 : 20,
        fontWeight: 300,
        textAlign: 'center',
        color: '#575f69',
        marginBottom: hp("3%"),
        fontFamily: 'JosefinSans-Light',
        lineHeight: 26.5
    },
    slider: {
        flexDirection: 'row',
        width: Dimensions.get('screen').width > 1300 ? wp('26%') : Dimensions.get('screen').width > 700 ? wp('26%') : wp('87%')
    },
    button: {
        width: Dimensions.get('screen').width > 1300 ? 34 : 28,
        height: Dimensions.get('screen').width > 1300 ? 34 : 28,
        borderWidth: 3,
        borderRadius: Dimensions.get('screen').width > 1300 ? 18 : 15,
        borderStyle: "solid",
        borderColor: "#575f69",
        backgroundColor: "#575f69",
        alignItems: "center",
        justifyContent: "center",
    },
    arrow: {
        width: 15,
        height: 15,
        resizeMode: "contain",
    },
    Line1: {
        width: Dimensions.get('screen').width > 760 ? '24%' : '85%',
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
        marginBottom: hp("4%"),
    },
    smallL1: {
        height: 3,
        backgroundColor: "#575f69",
        width: 5,
        top: 15,
        zIndex: -99999
    },
})