import React, { useState, useEffect, useContext, useRef } from 'react';
import { StyleSheet, Text, View, Dimensions, Animated, TouchableOpacity, Pressable, Image } from 'react-native';
import CustomText from '../FuturePostTextSkin3.js';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../Utils/responsive';
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../../Containers/Activities/index';
import { chunkify } from '../../Utils/chunkify';
import '../FutureSecondSkin/index.css';
import Header from '../HeaderSkin3';
import { futureGamePageText, futureMonthChangeText } from '../../Utils/static-strings';

export const WidthDimension = Dimensions.get("window").width;
export const heightLeftHandle = hp("68%");
export const heightRightHandle = hp("46%");
export const widthLeftHandle = wp("20%");
export const widthRightHandle = wp("20%");
const deviceWidth = Dimensions.get('window').width;


const Index = ({ moduleObject, moduleIndex, setModuleIndex, selectAnswerHandler, question, history, setfinishAnimate }) => {
    const [allValue, setAllValue] = useState(null);
    const [indexing, setIndexing] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [currentSet, setCurrentSet] = useState(0);
    const [hide, setHide] = useState(false);
    const bodyTextAnimation = useState(new Animated.Value(0))[0];
    const leftHandleBottomAnimation = useState(new Animated.Value(0))[0];
    const rightHandleBottomAnimation = useState(new Animated.Value(0))[0];
    const resultContext = useContext(ActivityContext);
    const [leftPostPressed, setLeftPostPressed] = useState(false);
    const [leftPostClicked, setLeftPostClicked] = useState(false);
    const triangleBlueLeftAnimation = useState(new Animated.Value(0))[0];
    const triangleBlueRightAnimation = useState(new Animated.Value(0))[0];
    const triangleGreenAnimation = useState(new Animated.Value(0))[0];
    const triangleVioletAnimation = useState(new Animated.Value(0))[0];
    const [rightPostHeight, setRightPostHeight] = useState(deviceWidth > 600 ? 260 : deviceWidth > 380 ? 188 : 138);
    const [leftPostHeight, setLeftPostHeight] = useState(deviceWidth > 600 ? 200 : deviceWidth > 380 ? 155 : 116);
    const leftPostHeightAnimation = useState(new Animated.Value(deviceWidth > 600 ? 182 : deviceWidth > 380 ? 160 : 128))[0];
    const rightPostHeightAnimation = useState(new Animated.Value(deviceWidth > 600 ? 208 : deviceWidth > 380 ? 181 : 145))[0];
    const leftPostClickAnimation = useRef(new Animated.Value(0)).current; //useState(new Animated.Value(0))[0];
    const RightPostHoverAnimation = useRef(new Animated.Value(0)).current;
    const RightPostBlurAnimation = useRef(new Animated.Value(0)).current;
    const LeftPostHoverAnimation = useRef(new Animated.Value(0)).current;
    const LeftPostBlurAnimation = useRef(new Animated.Value(0)).current;
    const opacityLeft = useRef(new Animated.Value(0)).current;
    const opacityRight = useRef(new Animated.Value(0)).current;
    const [rightPostHover, setRightPostHover] = useState(false);
    const [leftPostHover, setLeftPostHover] = useState(false);
    const [rightPostClicked, setRightPostClicked] = useState(false);
    const [isLeftPostClicked, setIsLeftPostClicked] = useState(false);
    const [showMonthChangeHint, setShowMonthChangeHint] = useState(false);
    const monthChangeHintAnimation = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        if (moduleObject.questions) {
            const chunkiElement = chunkify(moduleObject.questions, 2, true);

            setAllValue(chunkiElement);

            const totalLength = chunkiElement.map(elem => (
                { length: elem.length }
            ))
            setIndexing(totalLength)
        }

        Animated.sequence([
            Animated.parallel([
                Animated.timing(triangleBlueLeftAnimation, {
                    toValue: 100,
                    duration: 1800,
                    useNativeDriver: false,
                }),
                Animated.timing(triangleBlueRightAnimation, {
                    toValue: 100,
                    duration: 1800,
                    useNativeDriver: false,
                }),
                Animated.timing(triangleGreenAnimation, {
                    toValue: 100,
                    duration: 1800,
                    useNativeDriver: false,
                }),
                Animated.timing(triangleVioletAnimation, {
                    toValue: 100,
                    duration: 1800,
                    useNativeDriver: false,
                }),
            ]),
            Animated.parallel([
                Animated.timing(opacityLeft, {
                    toValue: 1,
                    duration: 1800
                }),
                Animated.timing(opacityRight, {
                    toValue: 1,
                    duration: 1800
                }),
                Animated.timing(leftHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 395 : deviceWidth > 380 ? 290 : 290,
                    duration: 1800,
                    useNativeDriver: false,
                }),
                Animated.timing(bodyTextAnimation, {
                    toValue: 100,
                    duration: 1800,
                    useNativeDriver: false,
                }),
                Animated.timing(rightHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 325 : deviceWidth > 380 ? 230 : 230,
                    duration: 1800,
                    useNativeDriver: false
                })
            ])
        ]).start();

    }, [])

    const saveAnswer = (index) => {
        selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid)
    }

    const handleButtonClick = (val) => {
        if (val === "Left") {
            if (questionIndex < indexing[currentSet].length - 1) {
                saveAnswer(0)
                setQuestionIndex(prev => prev + 1)
            }
            else {
                saveAnswer(0)
                setQuestionIndex(0)
                setCurrentSet(prev => prev + 1)
            }
        }
        else {
            if (questionIndex < indexing[currentSet].length - 1) {
                for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
                    selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid)
                }
            }
            selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid)
            setCurrentSet(prev => prev + 1)
            setQuestionIndex(0)
        }
    }

    const BlueTriangleLeftInterpolate = triangleBlueLeftAnimation.interpolate({
        inputRange: [0, 85, 100],
        outputRange: [300, -40, 0]
    })
    const BlueTriangleRightInterpolate = triangleBlueRightAnimation.interpolate({
        inputRange: [0, 85, 100],
        outputRange: [100, -100, 0]
    })
    const GreenTriangleInterpolate = triangleGreenAnimation.interpolate({
        inputRange: [0, 85, 100],
        outputRange: [300, deviceWidth > 600 ? -40 : -20, deviceWidth > 600 ? 0 : deviceWidth > 380 ? 0 : 0]
    })
    const VioletTriangleInterpolate = triangleVioletAnimation.interpolate({
        inputRange: [0, 85, 100],
        outputRange: [300, -40, 0]
    })

    const opacityLeftPost = leftHandleBottomAnimation.interpolate({
        inputRange: [0, deviceWidth > 600 ? 395 : deviceWidth > 380 ? 290 : 290],
        outputRange: [0, 1]
    })

    const opacityRightPost = rightHandleBottomAnimation.interpolate({
        inputRange: [0, deviceWidth > 600 ? 325 : deviceWidth > 380 ? 109 : 79],
        outputRange: [0, 1]
    })

    const opacityBodyTextInterpolate = bodyTextAnimation.interpolate({
        inputRange: [0, 50, 100],
        outputRange: [0, 1, 1]
    })

    const positionBodyTextInterpolate = bodyTextAnimation.interpolate({
        inputRange: [0, 100],
        outputRange: [-200, deviceWidth > 600 ? 0 : -100]
    })


    const HandleLeftClick = () => {
        leftPostClickAnimation.setValue(0);
        setHide(true);
        handleButtonClick("Right");
        setIsLeftPostClicked(true);
        setShowMonthChangeHint(true);
        monthChangeHintAnimation.setValue(32);
        Animated.sequence([
            Animated.parallel([
                Animated.timing(leftHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 295 : deviceWidth > 380 ? 220 : 220,
                    duration: 800,
                    useNativeDriver: false,
                }),
                Animated.timing(rightHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 225 : deviceWidth > 380 ? 160 : 160,
                    duration: 800,
                    useNativeDriver: false
                }),
                Animated.timing(opacityLeft, {
                    toValue: 0,
                    duration: 800
                }),
                Animated.timing(opacityRight, {
                    toValue: 0,
                    duration: 800
                })
            ]),
            Animated.parallel([
                // Animated.timing(monthChangeHintAnimation, {
                //     toValue: 0,
                //     duration: 600,
                //     useNativeDriver: false,
                // }),
                Animated.timing(leftPostHeightAnimation, {
                    toValue: deviceWidth > 600 ? 156 : deviceWidth > 380 ? 144 : 115,
                    duration: 800,
                    useNativeDriver: false,
                    // delay: 3500
                })
            ])
            ,
            Animated.parallel([
                Animated.timing(leftHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 395 : deviceWidth > 380 ? 290 : 290,
                    duration: 800,
                    useNativeDriver: false,
                }),
                Animated.timing(rightHandleBottomAnimation, {
                    toValue: deviceWidth > 600 ? 325 : deviceWidth > 380 ? 230 : 230,
                    duration: 800,
                    useNativeDriver: false
                }),
                Animated.timing(opacityLeft, {
                    toValue: 1,
                    duration: 1000
                }),
                Animated.timing(opacityRight, {
                    toValue: 1,
                    duration: 1000
                })
            ]),

        ]).start(() => {
            monthChangeHintAnimation.setValue(0);
            Animated.timing(monthChangeHintAnimation, {
                toValue: 32,
                duration: 600,
                useNativeDriver: false,
            }).start(() => setShowMonthChangeHint(false));
            setLeftPostPressed(true);
            setLeftPostHeight(deviceWidth > 600 ? 150 : deviceWidth > 380 ? 145 : 106);
            setHide(false);
        })
    }


    const handleRightClick = () => {
        leftPostClickAnimation.setValue(0);
        setHide(true);
        handleButtonClick("Left");
        if (questionIndex === indexing[currentSet].length - 1) {
            // setIsLeftPostClicked(true);
            setShowMonthChangeHint(true);
            monthChangeHintAnimation.setValue(32);
            Animated.sequence([
                Animated.parallel([
                    Animated.timing(leftHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 295 : deviceWidth > 380 ? 220 : 220,
                        duration: 800,
                        useNativeDriver: false,
                    }),
                    Animated.timing(rightHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 225 : deviceWidth > 380 ? 160 : 160,
                        duration: 800,
                        useNativeDriver: false
                    }),
                    Animated.timing(opacityLeft, {
                        toValue: 0,
                        duration: 800
                    }),
                    Animated.timing(opacityRight, {
                        toValue: 0,
                        duration: 800
                    })
                ]),
                Animated.parallel([
                    // Animated.timing(monthChangeHintAnimation, {
                    //     toValue: 0,
                    //     duration: 600,
                    //     useNativeDriver: false,
                    // }),
                    // Animated.timing(leftPostHeightAnimation, {
                    //     toValue: deviceWidth > 600 ? 156 : deviceWidth > 380 ? 120 : 90,
                    //     duration: 700,
                    //     useNativeDriver: false,
                    //     // delay: 3500
                    // })
                ])
                ,
                Animated.parallel([
                    Animated.timing(leftHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 395 : deviceWidth > 380 ? 290 : 290,
                        duration: 800,
                        useNativeDriver: false,
                    }),
                    Animated.timing(rightHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 325 : deviceWidth > 380 ? 230 : 230,
                        duration: 800,
                        useNativeDriver: false
                    }),
                    Animated.timing(opacityRight, {
                        toValue: 1,
                        duration: 800
                    }),
                    Animated.timing(opacityLeft, {
                        toValue: 1,
                        duration: 1000
                    })
                ]),

            ])
                .start(() => {
                    monthChangeHintAnimation.setValue(0);
                    Animated.timing(monthChangeHintAnimation, {
                        toValue: 32,
                        duration: 600,
                        useNativeDriver: false,
                    }).start(() => setShowMonthChangeHint(false));
                    setLeftPostPressed(true);
                    setLeftPostHeight(deviceWidth > 600 ? 150 : deviceWidth > 380 ? 145 : 106);
                    setHide(false);
                })

        } else {
            Animated.sequence([
                Animated.parallel([
                    Animated.timing(leftHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 295 : deviceWidth > 380 ? 220 : 220,
                        duration: 800,
                        useNativeDriver: false,
                    }),
                    Animated.timing(opacityLeft, {
                        toValue: 0,
                        duration: 800
                    })
                ]),
                Animated.parallel([
                    // Animated.timing(monthChangeHintAnimation, {
                    //     toValue: 0,
                    //     duration: 600,
                    //     useNativeDriver: false,
                    // }),
                    // Animated.timing(leftPostHeightAnimation, {
                    //     toValue: deviceWidth > 600 ? 156 : deviceWidth > 380 ? 120 : 90,
                    //     duration: 700,
                    //     useNativeDriver: false,
                    //     // delay: 3500
                    // })
                ])
                ,
                Animated.parallel([
                    Animated.timing(leftHandleBottomAnimation, {
                        toValue: deviceWidth > 600 ? 395 : deviceWidth > 380 ? 290 : 290,
                        duration: 800,
                        useNativeDriver: false,
                    }),
                    Animated.timing(opacityLeft, {
                        toValue: 1,
                        duration: 1000
                    })
                ]),

            ]).start(() => {
                setRightPostClicked(false);
                setHide(false);
            })
            // setRightPostClicked(true);
            // Animated.timing(leftPostClickAnimation, {
            //     toValue: 100,
            //     duration: 1600,
            //     useNativeDriver: false,
            // }).start(() => {
            //     setRightPostClicked(false);
            //     setHide(false);
            // })
        }

    }

    useEffect(() => {
        if (currentSet === 2) {
            resultContext.setModuleCompletedAtTime(moduleObject.moduleid);
            setfinishAnimate(moduleObject.moduleid)
            history.push('/')
        }
    }, [currentSet])

    const onHoverRightPost = () => {
        setRightPostHover(true);
        RightPostHoverAnimation.setValue(0);
        Animated.timing(RightPostHoverAnimation, {
            toValue: 100,
            duration: 500,
            useNativeDriver: false,
        }).start(() => setHide(false))
        // setRightPostHeight(deviceWidth > 600 ? 270 :deviceWidth >380 ? 198 : 148);
        // Animated.timing(rightPostHeightAnimation, {
        //     toValue:deviceWidth > 600 ? 270 :deviceWidth >380 ? 198 : 148,
        //     duration: 150
        // }).start();
        return 1;
    }

    const onBlurRightPost = () => {
        setRightPostHover(false);
        RightPostBlurAnimation.setValue(0);
        Animated.timing(RightPostBlurAnimation, {
            toValue: 100,
            duration: 500,
            useNativeDriver: false,
        }).start(() => setHide(false))
        // setRightPostHeight(deviceWidth > 600 ? 260 :deviceWidth >380 ? 188 : 138);
        // Animated.timing(rightPostHeightAnimation, {
        //     toValue: deviceWidth > 600 ? 260 :deviceWidth >380 ? 188 : 138,
        //     duration: 150
        // }).start();
        return 1;
    }

    const onHoverLeftPost = () => {
        setLeftPostHover(true);
        LeftPostHoverAnimation.setValue(0);
        Animated.timing(LeftPostHoverAnimation, {
            toValue: 100,
            duration: 500
        }).start();
        if (!hide && (leftPostHeight === 200 || leftPostHeight === 150 || leftPostHeight === 155 || leftPostHeight === 116)) {
            setLeftPostHeight(leftPostHeight + 10);
            setLeftPostHover(true);
            // Animated.timing(leftPostHeightAnimation, {
            //     toValue: leftPostHeight + 10,
            //     duration: 150
            // }).start();
            return 1;
        }
    }

    const onBlurLeftPost = () => {
        setLeftPostHover(false);
        LeftPostBlurAnimation.setValue(0);
        Animated.timing(LeftPostBlurAnimation, {
            toValue: 100,
            duration: 500
        }).start();
        if (!hide && (leftPostHeight === 210 || leftPostHeight === 160 || leftPostHeight === 165 || leftPostHeight === 126)) {
            setLeftPostHeight(leftPostHeight - 10);
            // Animated.timing(leftPostHeightAnimation, {
            //     toValue: leftPostHeight - 10,
            //     duration: 150
            // }).start();
            return 1;
        }
    }

    const scaleLeftInterpolate = leftPostClickAnimation.interpolate({
        inputRange: [0, 50, 100],
        outputRange: [1, 1.1, 1]
    })

    const hoverScalingRight = RightPostHoverAnimation.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 1.1]
    })

    const blurScalingRight = RightPostBlurAnimation.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 1]
    })

    const hoverScalingLeft = LeftPostHoverAnimation.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 1.1]
    })

    const blurScalingLeft = LeftPostBlurAnimation.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 1]
    })


    return (
        <div style={{
            backgroundColor: "#bbddf2",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: 'absolute',
            justifyContent: "space-between"
        }} >
            <Header currentRoute={"Understanding"} />
            <View style={[styles.body]} >
                <Animated.View style={{ opacity: opacityBodyTextInterpolate, marginBottom: positionBodyTextInterpolate }} >
                    <Text style={styles.bodyText} >{futureGamePageText}</Text>
                </Animated.View>
            </View>
            <View style={[styles.footer]} >

                {/* {
                    showMonthChangeHint && <Animated.View style={{ width: 163, height: 38, justifyContent: "center", cursor: "pointer", alignItems: "center", position: "absolute", alignSelf: "center", zIndex: 65, bottom: deviceWidth > 600 ? (281 + 200) : deviceWidth > 380 ? (192 + 155) : (146 + 116), transform: [{ translateX: leftPostPressed ? deviceWidth > 600 ? -159 : deviceWidth > 380 ? -108 : -77 : deviceWidth > 600 ? -157 : deviceWidth > 380 ? -115 : -76 }, { translateY: monthChangeHintAnimation }], opacity: showMonthChangeHint ? 1 : 0 }}  >
                        <Text style={styles.monthChangeStyle}  >{futureMonthChangeText}</Text>
                        <Image source={require('../../assets/images/FutureModule/arrowDown.png')} style={{ height: deviceWidth > 600 ? 35 : deviceWidth > 400 ? 25 : 15, width: deviceWidth > 600 ? 30 : deviceWidth > 400 ? 24 : 14, alignSelf: 'center' }} />
                    </Animated.View>
                } */}

                <Animated.View style={{ height: leftPostHeightAnimation, width: leftPostHeightAnimation, backgroundColor: "#ffb91d", borderRadius: leftPostHeightAnimation, justifyContent: "center", cursor: "pointer", alignItems: "center", position: "absolute", alignSelf: "center", bottom: leftHandleBottomAnimation, zIndex: leftPostPressed ? 1 : 3, opacity: opacityLeft, transform: [{ translateX: leftPostPressed ? deviceWidth > 600 ? -157 : deviceWidth > 380 ? -105 : -90 : deviceWidth > 600 ? -157 : deviceWidth > 380 ? -105 : -90 }, { scale: !rightPostClicked ? leftPostHover ? hoverScalingLeft : blurScalingLeft : scaleLeftInterpolate }] }}  >

                </Animated.View>

                <Animated.View style={{ height: leftPostHeightAnimation, width: leftPostHeightAnimation, backgroundColor: "transparent", borderRadius: leftPostHeightAnimation, justifyContent: "center", cursor: "pointer", alignItems: "center", position: "absolute", alignSelf: "center", bottom: leftHandleBottomAnimation, zIndex: 55, opacity: opacityLeft, transform: [{ translateX: leftPostPressed ? deviceWidth > 600 ? -157 : deviceWidth > 380 ? -105 : -90 : deviceWidth > 600 ? -157 : deviceWidth > 380 ? -105 : -90 }, { scale: !rightPostClicked ? leftPostHover ? hoverScalingLeft : blurScalingLeft : scaleLeftInterpolate }], }}  >
                    {/* <Pressable style={({ pressed, hovered, focused }) => [{ height: "100%", width: "100%", zIndex: 55, justifyContent: "center", alignItems: "center", borderRadius: leftPostHeightAnimation, cursor: "pointer", transform: [{ scale: hovered ? onHoverLeftPost() : onBlurLeftPost() }], }]} onPress={HandleLeftClick} >
                        {
                            (allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[1].text} text="Second" leftPostPressed={leftPostClicked} />
                        }
                    </Pressable> */}
                    <div style={{ height: "100%", width: "100%", zIndex: 55, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: leftPostHeightAnimation, cursor: "pointer", transform: [{ scale: 1 }] }} onMouseEnter={() => onHoverLeftPost()} onMouseLeave={() => onBlurLeftPost()} onClick={() => {
                        if (!hide) {
                            HandleLeftClick();
                        }
                    }} >
                        {
                            (allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[1].text} text="Second" leftPostPressed={leftPostClicked} setIsLeftPostClicked={setIsLeftPostClicked} isLeftPostClicked={isLeftPostClicked} />
                        }
                    </div>
                </Animated.View>

                <Animated.View style={{ height: rightPostHeightAnimation, width: rightPostHeightAnimation, backgroundColor: "#ffb91d", borderRadius: rightPostHeightAnimation, justifyContent: "center", cursor: "pointer", alignItems: "center", position: "absolute", alignSelf: "center", bottom: rightHandleBottomAnimation, zIndex: 5, opacity: opacityRight, transform: [{ translateX: deviceWidth > 600 ? 130 : deviceWidth > 380 ? 95 : 80 }, { scale: rightPostHover ? hoverScalingRight : blurScalingRight }] }}  >
                </Animated.View>

                <Animated.View style={{ height: rightPostHeightAnimation, width: rightPostHeightAnimation, backgroundColor: "transparent", borderRadius: rightPostHeightAnimation, justifyContent: "center", cursor: "pointer", alignItems: "center", position: "absolute", alignSelf: "center", bottom: rightHandleBottomAnimation, zIndex: 55, opacity: opacityRight, transform: [{ translateX: deviceWidth > 600 ? 130 : deviceWidth > 380 ? 95 : 80 }, { scale: rightPostHover ? hoverScalingRight : blurScalingRight }] }} >

                    <div style={{ height: "100%", width: "100%", zIndex: 55, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: rightPostHeightAnimation, cursor: "pointer", transform: [{ scale: 1 }] }} onMouseEnter={() => onHoverRightPost()} onMouseLeave={() => onBlurRightPost()} onClick={() => {
                        if (!hide) {
                            handleRightClick();
                        }
                    }} >
                        {
                            (allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[0].text} text="First" />
                        }
                    </div>
                    {/* <Pressable style={({ pressed, hovered, focused }) => [{ height: "100%", width: "100%", zIndex: 55, justifyContent: "center", borderRadius: rightPostHeightAnimation, alignItems: "center", transform: [{ scale: hovered ? onHoverRightPost() : onBlurRightPost() }] }]} onPress={handleRightClick} >
                        {
                            (allValue !== null && currentSet !== 2) && <CustomText data={moduleObject.questions[moduleIndex].answers[0].text} text="First" />
                        }
                    </Pressable> */}
                </Animated.View>


                <Animated.View style={[styles.triangleBlueRight, { marginLeft: BlueTriangleRightInterpolate }]}  >

                </Animated.View>

                <Animated.View style={[styles.triangleGreen, { marginLeft: GreenTriangleInterpolate }]}  >

                </Animated.View>

                <Animated.View style={[styles.triangleViolet, { marginLeft: VioletTriangleInterpolate }]}  >

                </Animated.View>
                    
                <Animated.View style={[styles.triangleBlueLeft, { marginLeft: BlueTriangleLeftInterpolate }]}  >

                </Animated.View>

                

            </View >
        </div>
    )
}

export default withRouter(Index)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#bbddf2",
        height: "100vh",
        width: "100vw",
        justifyContent: "space-between"
    },
    body: {
        height: deviceWidth > 600 ? "22%" : "25%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    bodyText: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: 24,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: 32,
    },
    monthChangeStyle: {
        fontFamily: 'Manrope-Regulars',
        fontSize: deviceWidth > 400 ? 14 : 12,
        color: '#2f2f2f',
        lineHeight: 20,
        textAlign: 'center'
    },
    footer: {
        height: "75%",
        width: "100%",
    },
    triangleBlueRight: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 600 ? 120 : deviceWidth > 380 ? 100 : 80,
        borderBottomWidth: deviceWidth > 600 ? 240 : deviceWidth > 380 ? 200 : 160,
        borderLeftWidth: deviceWidth > 600 ? 120 : deviceWidth > 380 ? 100 : 80,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 6,
        // marginLeft: 614,
        bottom: 0,
        opacity: 0.50,
        alignSelf: 'center',
        transform: [{ translateX: deviceWidth > 600 ? 220 : deviceWidth > 380 ? 130 : 130 }]
    },
    //deviceWidth >600 ? 350/2 :deviceWidth >380 ? 119 : 90
    triangleGreen: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 600 ? 325 / 2 : deviceWidth > 380 ? 250 / 2 : 230 / 2,
        borderBottomWidth: deviceWidth > 600 ? 325 : deviceWidth > 380 ? 250 : 230,
        borderLeftWidth: deviceWidth > 600 ? 325 / 2 : deviceWidth > 380 ? 250 / 2 : 230 / 2,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#0097a9',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        bottom: 0,
        zIndex: 4,
        opacity: 0.50,
        transform: [{ translateX: (deviceWidth > 380 && deviceWidth < 600) ? 15 : 0 }]
    },
    triangleViolet: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 600 ? 120 : deviceWidth > 380 ? 100 : 80,
        borderBottomWidth: deviceWidth > 600 ? 240 : deviceWidth > 380 ? 200 : 160,
        borderLeftWidth: deviceWidth > 600 ? 120 : deviceWidth > 380 ? 100 : 80,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#7b76b6',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        // marginRight: deviceWidth > 600 ? 500 : deviceWidth > 380 ? 347 : 254,
        bottom: 0,
        opacity: 0.50,
        zIndex: 2,
        transform: [{ translateX: deviceWidth > 600 ? -180 : deviceWidth > 380 ? -100 : -100 }]
    },
    triangleBlueLeft: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 600?110 / 2:deviceWidth > 380?100 / 2:80 / 2,
        borderBottomWidth: deviceWidth > 600?110:deviceWidth > 380?100:80,
        borderLeftWidth: deviceWidth > 600?110 / 2:deviceWidth > 380?100/ 2:80 / 2,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 5,
        transform: [{ translateX:  deviceWidth > 600 ? -300 : deviceWidth > 380 ? -190 : -170 }],
        // marginRight: 624,
        bottom: 0,
        opacity: 0.50,
        zIndex: 4,
        //  marginRight: "800px",
    },
    amount: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: 56,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: 68,
    },
    time: {
        fontFamily: 'Manrope-Medium',
        fontSize: 28,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: 36,
    },
    amount2: {
        fontFamily: 'Manrope-SemiBold',
        fontSize: 43.1,
        fontWeight: "500",
        color: '#2f2f2f',
        lineHeight: 52.3,
    },
    time2: {
        fontFamily: 'Manrope-Medium',
        fontSize: 21.5,
        fontWeight: "600",
        color: '#2f2f2f',
        lineHeight: 27.7,
    }

})
