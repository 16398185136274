import styled from 'styled-components';
import nativeStyle from 'styled-components/native';

const evalString = (value) => {
  switch (value && value.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
}

export const Container = styled.section`
  display: flex;
  flex: 1;
  background-color: ${props => evalString(props.questionAnswer) ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.05)'};
  flex-direction: column;
`;

export const TopSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
`;

export const Text = styled.div`
  color:  ${props => evalString(props.questionAnswer) ? '#2f2f2f' : '#2f2f2f'};
  font-size: ${props => props.fontSize || 60}px;
  font-family: ${props => props.fontFamily || 'Manrope-SemiBold'};
  // font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => props.lineHeight || 68}px;
  letter-spacing: ${props => props.letterSpacing || 0}px;
  text-align: center;
  margin-top: ${props => props.marginTop || 0}px;
`

export const Question = styled.div`
  color: ${props => evalString(props.questionAnswer) ? '#2f2f2f' : '#2f2f2f'};
  font-size: 24px;
  font-family: Manrope-SemiBold;
  // font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const BottomSection = styled.div`
  display: flex;
  flex: 1;
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => props.justifyContent || ''};
  flex-direction: ${props => props.flexDirection || ''};
`;

export const Rectangle = nativeStyle.TouchableOpacity`
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: ${props => props.touched ? '0.5' : '1'};
  background-image: ${props => evalString(props.questionAnswer) ?
    `linear-gradient(to bottom, #d4d3e7, rgba(212, 211, 231, 0)), linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.16) 100%)` :
    `linear-gradient(to bottom, #c1c0d7, rgba(193, 192, 215, 0)), linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.16) 100%)`};
  }
`;

//linear-gradient(to bottom, #c1c0d7, rgba(193, 192, 215, 0)), linear-gradient(to left, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0.16) 0%);
// linear-gradient(to bottom, #d4d3e7, rgba(212, 211, 231, 0)), linear-gradient(to left, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0.16) 0%)
