export const chooseActivity = {
	title: () => {
		return 'Thinking about...';
	},
	instructionOne: () => {
		return 'Tap a sign above to start an activity.';
	},
	instructionTwo: () => {
		return 'Each activity takes about 3 minutes.';
	},
};

export const summaryPage = {
	allDoneTitle: () => {
		return 'All Done';
	},
	allDoneDescription: () => {
		return 'We now analyse your answers to determine your investment style and use this to recommend your investment';
	},
	getResultButton: () => {
		return 'Show me my investment style';
	},
};

export const resultPage = {
	title: () => {
		return 'You investment style is';
	},
	button1: () => {
		return 'I agree, that sounds like me';
	},
	button2: () => {
		return 'How did you work this out?';
	},
	veryLow: () => {
		return 'Very low';
	},
	low: () => {
		return 'Low';
	},
	lowMedium: () => {
		return 'Low Medium';
	},
	medium: () => {
		return 'Medium';
	},
	mediumHigh: () => {
		return 'Medium High';
	},
	high: () => {
		return 'High';
	},
};


export const moduleid = {
	risk: () => {
		return 'ATR';
	},
	loss: () => {
		return 'LA';
	},
	future: () => {
		return 'PB';
	},
	experience: () => {
		return 'EXP';
	},
	knowledge: () => {
		return 'OBJ';
	},
	atrquant: () => {
		return 'ATRQUANT'
	},
	esg: () => {
		return 'ESG'
	},
	brs: () => {
		return 'BRS'
	}
};

export const discriptorText = {
	level1: {
		min: 0,
		max: 0.28,
		name: 'Very cautious'
	},
	level2: {
		min: 0.28,
		max: 0.43,
		name: 'Cautious'
	},
	level3: {
		min: 0.43,
		max: 0.58,
		name: 'Balanced'
	},
	level4: {
		min: 0.58,
		max: 0.73,
		name: 'Risk tolerant'
	},
	level5: {
		min: 0.73,
		max: 1,
		name: 'Risk comfortable'
	},
}

export const lossLabels = {
	gain: 'Gain',
	loss: 'Loss'
}

export const tiger = 'santander';
export const zebra = 'mandg';
export const defaultSkin = 'default';
export const experiance = 'EXPERIENCE';
export const experianceIntroTextMobile = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quam felis, accumsan eget pulvinar eu, tincidunt a quam.`
export const experianceIntroText = `Have you invested before ? Would you take advice from your friends and family?
${"\n"}Our experience is one of the most powerful factors that influence our decsion making. Understanding what experience you have will help inform our advice to you.`
export const aspectHeaderText = 'Aspects of you…';
export const bodyTextHomepage = `There are a number of apects to our personality that come into play when matching the right kind of investment to the right level of risk.${"\n"}${"\n"}And, because we’re human, these different aspects don’t always make sense. But this is also what makes us human.${"\n"}${"\n"}Answer the questions or situations on the following screens to help us to understand the human part of you.`;
export const PIELABELS = {
	win: "Win",
	lose: "Lose",
}
export const riskIntroPageTextSkin3 = `The following ten statements aim to gauge your feeling towards risk.
${"\n"}There are no right or wrong answers, choose honestly and we’ll be able to pick the investment you’ll feel most comfortable with.`;
export const KNOWLEDGE = 'Knowledge';
export const THE = 'The';
export const FUTURE = 'Future';
export const RISK = 'Risk';
export const EXPERIENCE = 'Experience';
export const YOU = 'You';
export const SPINNERTEXT = 'A SPINNER IS SPUN';
export const IFLAND = 'If it lands on...';
export const WOULDPLAY = 'Would you play?'
export const ASPECTHEADERTEXT = `Let's consider risk…`;
export const BODYDESCRIPTION1 = `The next few screens will show us:`;
export const BODYDESCRIPTION2 = `What you currently understand. <br/>How you feel about risk.<br/>How you respond to some simple money choices.`;
export const BODYDESCRIPTION3 = `They're presented in the form of five interactive segments.<br/>Answer the questions honestly without overthinking.`
export const LOSSINTROBODY = 'Imagine that you are considering investing £1,000 where the likelihood of the gains and losses are determined by the random spin of a wheel';
export const buttonText = 'Continue';
export const routesSkin3 = ['About you', 'Work & Income', 'Expenses', 'Assets & Debts', 'Understanding', 'Recommendation'];
export const BRAND = "BRAND";
export const NAME = 'NAME';
export const bodyTextFuturePage = `Investing is about the future.${"\n"}${"\n"}Your choice of investment ‘today’ will impact the money you could receive ‘tomorrow’.${"\n"}${"\n"}This activity looks at how time affects your choices.`
export const knowledgeTextPrePage = `The difference between perception and reality reveals how well balanced your confidence is…${"\n"}${"\n"}Answer the following questions with True or False,
and let us know how sure you are.${"\n"}${"\n"}If you don’t know, take a guess!`
export const KnowledgesecondPageText = `Have you invested money before in ${"\n"}
financial products such as stocks,${"\n"}
 bonds, or mutual funds?`;
export const THANK = 'Thank you';
export const ENDTEXT = 'Your adviser will talk you through your detailed results later.';
export const DRAG_TEXT = '(drag the slider)';

export const futureGamePageText = "I’d rather have…";
export const futureMonthChangeText = `Now, let's move this further into the future`;
export const GAME_COMPLETED_MESSAGE = 'You’re done!';
export const GAME_COMPLETED_DESCRIPTION = 'Your adviser will be in touch shortly to discuss your results and how to best match your investments to your personality.';
export const NO_LINK_FOUND = 'This link couldn’t be found. Please check with your provider...';
export const CHOOSE_ONE = 'Choose one';
export const TARGET_INVESTMENT = 'Where do you want to target your investment?';
export const EXCLUDE_FROM_INVESTMENTS = 'Do you want to exclude controversial sectors?';
export const ESG_MODAL_HEADER = 'Food for thought…';
export const RISK_SKIN_2_HEADER= "LET'S UNDERSTAND YOU...";
