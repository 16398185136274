import React, { useLayoutEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
const Index = (props) => {
    const handle = (i) => {
        props.resultContext.setAnswer(
            props.module.moduleid,
            props.module.questions[0].qid,
            props.module.questions[0].answers[i].respid);
        props.pageOne(i)
    }
    return (
        <TouchableOpacity style={[styles.square, { borderColor: props.color, margin: 5 }]} onPress={() => { handle(props.id) }}>
            <View style={[styles.circle, { backgroundColor: props.color, alignSelf: 'flex-start', bottom: 5, right: 5 }]} />
            <View style={[styles.circle, { backgroundColor: props.color, alignSelf: 'flex-end', left: 5, bottom: Dimensions.get('screen').width > 1023 ? 30 : 25 }]} />
            <Text style={[styles.text, { color: props.color }]}>{props.text}</Text>
            <View style={[styles.circle, { backgroundColor: props.color, alignSelf: 'flex-start', top: Dimensions.get('screen').width > 1023 ? 5 : 0, right: 5 }]} />
            <View style={[styles.circle, { backgroundColor: props.color, alignSelf: 'flex-end', bottom: Dimensions.get('screen').width > 1023 ? 20 : 20, left: 5 }]} />
        </TouchableOpacity>

    )
}
export default Index
const styles = StyleSheet.create({

    square: {
        height: Dimensions.get('screen').width > 1023 ? 95 : 63,
        width: Dimensions.get('screen').width > 1023 ? 152 : 100,
        backgroundColor: 'transparent',
        borderWidth: Dimensions.get('screen').width > 1023 ? 12 : 8,
        borderRadius: 5
    },
    circle: {
        height: Dimensions.get('screen').width > 1023 ? 25 : 18,
        width: Dimensions.get('screen').width > 1023 ? 25 : 18,
        borderRadius: Dimensions.get('screen').width > 1023 ? 12.5 : 9,
    },
    text: {
        alignSelf: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        top: Dimensions.get('screen').width > 1023 ? 30 : 20,
        position: 'absolute',
        fontFamily: 'JosefinSans-SemiBold',
        fontWeight: '600',
        fontSize: Dimensions.get('screen').width > 1023 ? 20 : 10
    }
})