import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from 'react';
import {
    StyleSheet,
    View,
    Animated,
    Dimensions,
    Image,
    Easing
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../../Utils/responsive';
import '../../../../../App.css';
import Firstpage from './firstintropage';
import LastPage from './LastPage';
import TrueFalseIntro from './TrueFalseIntro';
import LastPageA from '../../../../../Components/LastPageB/index';
import { ActivityContext } from '../../../../Activities';
import { checkProgression } from '../../../../../Utils/path-config';
import axios from 'axios';
import Header from '../../../../../Components/HeaderSkin3';
import { addAnalytics } from '../../../../../helper';

const deviceWidth = Dimensions.get('window').width;
export default function Index(props) {
    const topLineAnimation = useRef(new Animated.Value(0)).current;
    const [display, setDisplay] = useState(false);
    const [showMainGamePage, setShowMainGamePage] = useState(false);
    const [showTrueFalsePage, setShowTrueFalsePage] = useState(true);
    const [SET1, setSET1] = useState('');
    const [SET2, setSET2] = useState('');
    const [SET3, setSET3] = useState('');
    const [SET4, setSET4] = useState('');
    const [SET5, setSET5] = useState('');
    const [SET, setSET] = useState([]);
    const [page, setPage] = useState('');
    const [questionSet, setQuestionSet] = useState(0);
    const [indexOfset, setIndexOfset] = useState(0);
    const [apiresponse, setApiresponse] = useState(null);
    const resultContext = useContext(ActivityContext);
    const [questionSkipArray, setQuestionSkipArray] = useState([]);
    const [failureQuestionSkipArray, setFailureQuestionSkipArray] = useState([]);

    useLayoutEffect(() => {
        Animated.timing(topLineAnimation, {
            toValue: 1,
            duration: 800,
            useNativeDriver: false,
            easing: Easing.linear
        }).start(() => setDisplay(true));
    }, [])


    useLayoutEffect(() => {
        setSET1(props.moduleObject.questions.filter((value) => value.metadata.set === props.moduleObject.moduleid + '01'));
        setSET2(props.moduleObject.questions.filter((value) => value.metadata.set === props.moduleObject.moduleid + '02'));
        setSET3(props.moduleObject.questions.filter((value) => value.metadata.set === props.moduleObject.moduleid + '03'));
        setSET4(props.moduleObject.questions.filter((value) => value.metadata.set === props.moduleObject.moduleid + '04'));
        setSET5(props.moduleObject.questions.filter((value) => value.metadata.set === props.moduleObject.moduleid + '05'));
    }, [])
    useEffect(() => {
        if (SET5 !== '') {
            SET.push(SET1);
            SET.push(SET2);
            SET.push(SET3);
            SET.push(SET4);
            SET.push(SET5);
        }
    }, [SET5])

    const topBar = topLineAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%']
    });

    const topBar2 = topLineAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [deviceWidth > 1300 ? -126 : -102, 0]
    });

    const lastPage = () => {
        setShowMainGamePage(false);
    }
    const lastPageA = (id) => {
        if (id == 0) {
            setPage('TRUE');
        } else if (id == 1) {
            setPage('FALSE');
        }
        setShowTrueFalsePage(false);
    }
    const Refresh = (take) => {
        let responses = [];
        setPage(take)
        let moduleIdExep = resultContext.result.modules.filter((mod) => mod.moduleid === props.moduleObject.moduleid);
        let firstPart = moduleIdExep[0].responses.filter((value) => {
            if (value.qid.endsWith("A")) { }
            else {
                return value;
            }
        })
        firstPart.filter((val, index) => {
            if (!questionSkipArray.includes(val.qid)) {
                responses.push(val);
                return val;
            } else {
                return;
            }
        })
        let finalArray = responses.filter((value) => {
            if (value.qid.endsWith("A")) { }
            else {
                return value;
            }
        })
        let dd = {
            "completedat": new Date().toISOString(),
            "moduleid": moduleIdExep[0].moduleid,
            "startedat": moduleIdExep[0].startedat,
            "responses": finalArray
        }
        let data = {
            'userref': resultContext.result.userref,
            'sessionref': resultContext.result.sessionref,
            'modules': [dd]
        }
        const config = {
            method: 'post',
            url: window['runConfig'].REACT_APP_BASE_URL + checkProgression(),
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };
        axios(config)
            .then(function (response) {
                setApiresponse(response.data);
                afterApiCall(response.data);
            })
            .catch(function (error) {
                alert('Ooops! Something went wrong. Please play this module again.');
                addAnalytics(error, config);
            });
    }

    const afterApiCall = (val) => {
        if (indexOfset == SET[questionSet].length) {
            setIndexOfset(0);
        }
        if (val == 'NEXT_QUESTION') {
            // setQuestionSet(0);
        } else if (val == 'SET_COMPLETE') {
            let currentIndexvalue = props.moduleIndex;
            if (SET[questionSet].length - 1 >= indexOfset) {
                SET[questionSet].forEach((val, i) => {
                    if (i > indexOfset) {
                        currentIndexvalue = currentIndexvalue + 1;
                        setIndexOfset(prev => prev + 1);
                        setQuestionSkipArray(prev => [...prev, props.moduleObject.questions[currentIndexvalue].qid]);
                        props.selectAnswerHandler(
                            props.moduleObject.moduleid,
                            props.moduleObject.questions[currentIndexvalue].qid,
                            props.moduleObject.questions[currentIndexvalue].answers[0].respid,
                        );
                    }
                });
                setIndexOfset(0);
                setQuestionSet(prev => prev + 1);
            }
            // setQuestionSet(prev => prev + 1);
        } else if (val == 'FAIL') {
            props.moduleObject.questions.forEach((count, index) => {
                if (index > props.moduleIndex) {
                    setQuestionSkipArray(prev => [...prev, props.moduleObject.questions[index].qid]);
                    props.selectAnswerHandler(
                        props.moduleObject.moduleid,
                        props.moduleObject.questions[index].qid,
                        props.moduleObject.questions[index].answers[0].respid,
                    );
                }
            })
        }
        setShowTrueFalsePage(true);
    }
    useEffect(() => {
        if (props.moduleIndex > props.moduleObject.questions.length - 1) {
            if (apiresponse == 'FAIL') {
                resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid, undefined, true, questionSkipArray);
                props.setfinishAnimate(props.moduleObject.moduleid)
                props.history.push('/');
            }
            if (apiresponse == 'SET_COMPLETE') {
                resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid, undefined, true, questionSkipArray);
                props.setfinishAnimate(props.moduleObject.moduleid)
                props.history.push('/');
            }
        }
    }, [props.moduleIndex])

    return (
        <div style={{
            height: '100%',// deviceWidth > 600 ? '85vh' : '88vh',
            width: '100%',
            display: "flex",
            backgroundColor: '#cccbe3',
            position:"absolute",
            overflow: 'hidden'
        }}>
            {/* <Header currentRoute={"Understanding"} styles={{ position: 'absolute', zIndex: 99, elevation: 5, }} /> */}
            {
                display && (showMainGamePage ? <TrueFalseIntro navigation={lastPage} /> :
                    (showTrueFalsePage ?
                        (<LastPage {...props} navigation={lastPageA} Questions={SET[questionSet]} questionSet={questionSet} indexOfset={indexOfset} setIndexOfset={setIndexOfset} page={page} />)
                        : <LastPageA {...props} indexOfset={indexOfset} setIndexOfset={setIndexOfset} questionSet={questionSet} Questions={SET[questionSet]} questionAnswer={page} refresh={Refresh} questionSkipArray={questionSkipArray} />))
            }
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100vw",
        // flex: 1,
        backgroundColor: '#cccbe3',
        overflow: 'hidden'
    },
    topborder: {
        position: "relative",
        alignSelf: 'center',
        borderTopColor: "#bb6fda",
        borderTopWidth: 3,
        // marginBottom: hp("1.2%")
    },
    Image: {
        position: 'absolute',
        alignSelf: "flex-end",
        marginLeft: '90vw',
        width: 48,
        zIndex: 99,
        resizeMode: "contain",
        marginRight: 8,
        height: 12,
        marginTop: hp("1.2%")
    },
});