import React, { createContext, useState, useEffect } from 'react';
import { View } from 'react-native';
import axios from 'axios';
import qs from 'qs';
import { getSurveyQuestions, getSkin, getLinkstub, consumeSuKey, consumeSessionKey, getSurveryQuestionsWithSessionKey } from './Utils/path-config';
import Activities from './Containers/Activities';
import LoaderSecond from './Utils/skin2Loader';
import { withRouter } from 'react-router-dom';
import logo from './assets/loading-gif/DIA-loaderAnimation.gif'
import { widthPercentageToDP as wp, heightPercentageToDP as hp, listenOrientationChange } from './Utils/responsive';
import { NO_LINK_FOUND, tiger, zebra, defaultSkin} from './Utils/static-strings';
import { addAnalytics } from './helper';

export const ReportContext = createContext();

const App = (props) => {
  const [modules, setModules] = useState(null);
  const [animating, setAnimating] = useState(false);
  const [skin, setSkin] = useState(null);
  const [showResultButon, setShowResultButton] = useState(true);
  const configureSkin = (skin) => {
    setSkin(skin || defaultSkin)
  }

  let user_ref_variable = null; //new URLSearchParams(props.location.search).get("userref");
  let linkstubValue = null;
  let suKey = null;
  let linkstubObject = null;
  let sessionKey = null;

  const path = props.location.pathname.split('/');
  if (path.length <= 1) {
    alert(NO_LINK_FOUND);
    addAnalytics(NO_LINK_FOUND);
  } else if (path[1] === "sukeys" && path.length >= 2) {
    suKey = path[2];
  } else if (path[1] === "sessions" && path.length >= 2) {
    sessionKey = path[2];
  }
  else {
    if (path[1] !== '') {
      const UrlQueryStrings = props.location.search.replace("?", "");
      const queryValues = qs.parse(UrlQueryStrings);
      if (Object.keys(queryValues).length > 0) {
        linkstubObject = {
          create: '1', ...queryValues
        }
      }
      linkstubValue = path[1];
    }
  }


  const [linkstub, setlinkstub] = useState(linkstubValue)
  const [userref, setUserRef] = useState(user_ref_variable);
  const [sessionref, setSessionRef] = useState(null);



  useEffect(() => {
    if (suKey !== null) {

      let config = {
        method: 'get',
        url: window['runConfig'].REACT_APP_BASE_URL + consumeSuKey(suKey),

      };
      axios(config)
        .then((response) => {
          setUserRef(response.data.userref);
          setSessionRef(response.data.sessionref);
          sessionStorage.setItem("userref", userref);
          sessionStorage.setItem("sessionref", sessionref);
        }).catch((error) => {
          alert("This link has now expired. Please ask your provider to send another.")
          addAnalytics(error, config);
          // throw new Error( error );

        });
    }
    else if (linkstub !== null) {
      let config = {
        method: 'POST',
        url: window['runConfig'].REACT_APP_BASE_URL + getLinkstub(linkstub),
        data: linkstubObject,

      };
      axios(config)
        .then((response) => {
          setAnimating(true);
          sessionStorage.setItem("skinName", response?.data?.profilerskin);
          configureSkin(response?.data?.profilerskin);
          setUserRef(response.data.userref);
          setSessionRef(response.data.sessionref);
		  const showResultPage = typeof response.data === 'object' ? response.data.hidereport : true;
          setShowResultButton(showResultPage);

          sessionStorage.setItem("userref", response.data.userref);
          sessionStorage.setItem("sessionref", response.data.sessionref);
          let config = {
            method: 'get',
            url: window['runConfig'].REACT_APP_BASE_URL + getSurveyQuestions(response.data.userref),

          };
          axios(config)
            .then((response) => {
              /*if(sessionStorage.getItem('skinName') === tiger){
                response.data.modules.push({
                  "moduleid": "EXP",
                  "name": "Experience",
                  "measure": "Experience",
                  "moduledesc": "Experience questions",
                  "tags": [
                    "ui.risk"
                  ],
                  "questions": [
                    {
                      "qid": "EXP.1.1.01",
                      "text": "Have you invested money before in financial products such as stocks, bonds, or mutual funds?",
                      "answers": [
                        {
                          "respid": "R01",
                          "text": "Yes"
                        },
                        {
                          "respid": "R02",
                          "text": "No"
                        }
                      ]
                    },
                    {
                      "qid": "EXP.1.1.02",
                      "text": "When you invest in financial products, do you:",
                      "answers": [
                        {
                          "respid": "R01",
                          "text": "Trade in/out of your investments"
                        },
                        {
                          "respid": "R02",
                          "text": "Buy and hold for the longer term"
                        },
                        {
                          "respid": "R03",
                          "text": "I do not invest regularly"
                        }
                      ],
                      "askif": {
                        "op": "OR",
                        "answers": [
                          {
                            "qid": "EXP.1.1.01",
                            "respid": "R01"
                          }
                        ]
                      }
                    },
                    {
                      "qid": "EXP.1.1.03",
                      "text": "How many years have you been trading or investing in financial products on a regular basis?",
                      "answers": [
                        {
                          "respid": "R01",
                          "text": "0-1 year"
                        },
                        {
                          "respid": "R02",
                          "text": "1-3 years"
                        },
                        {
                          "respid": "R03",
                          "text": "3-5 years"
                        },
                        {
                          "respid": "R04",
                          "text": "5-10 years"
                        },
                        {
                          "respid": "R05",
                          "text": "Over 10 years"
                        }
                      ],
                      "askif": {
                        "op": "OR",
                        "answers": [
                          {
                            "qid": "EXP.1.1.01",
                            "respid": "R01"
                          }
                        ]
                      }
                    },
                    {
                      "qid": "EXP.1.1.04",
                      "text": "Which of the following do you have direct experience in investing in over the past 5 years? (select all that apply)",
                      "multichoice": true,
                      "answers": [
                        {
                          "respid": "R01",
                          "text": "Mutual Funds / Unit Trusts"
                        },
                        {
                          "respid": "R02",
                          "text": "Equities / Shares"
                        },
                        {
                          "respid": "R03",
                          "text": "Exchange Traded Funds"
                        },
                        {
                          "respid": "R04",
                          "text": "Investment Trusts"
                        },
                        {
                          "respid": "R05",
                          "text": "Fixed Interest Bonds"
                        },
                        {
                          "respid": "R06",
                          "text": "Structured Products"
                        },
                        {
                          "respid": "R07",
                          "text": "Futures / Options"
                        },
                        {
                          "respid": "R08",
                          "text": "Venture Capital Trusts"
                        },
                        {
                          "respid": "R09",
                          "text": "Hedge Funds, Private Equity Funds"
                        },
                        {
                          "respid": "R10",
                          "text": "None of these"
                        }
                      ],
                      "askif": {
                        "op": "OR",
                        "answers": [
                          {
                            "qid": "EXP.1.1.01",
                            "respid": "R01"
                          }
                        ]
                      }
                    },
                    {
                      "qid": "EXP.1.1.05",
                      "text": "When making investment decisions, where would you seek advice (select all that apply)?",
                      "multichoice": true,
                      "answers": [
                        {
                          "respid": "R01",
                          "text": "Adviser"
                        },
                        {
                          "respid": "R02",
                          "text": "Friends"
                        },
                        {
                          "respid": "R03",
                          "text": "Family"
                        },
                        {
                          "respid": "R04",
                          "text": "Investment websites"
                        },
                        {
                          "respid": "R05",
                          "text": "Bank"
                        },
                        {
                          "respid": "R06",
                          "text": "Accountant"
                        },
                        {
                          "respid": "R07",
                          "text": "Solicitor"
                        },
                        {
                          "respid": "R08",
                          "text": "I conduct my own research"
                        },
                        {
                          "respid": "R09",
                          "text": "I don't or have never sought advice"
                        }
                      ]
                    }
                  ]
                });
              }*/
              setModules(response.data);
              setAnimating(false);
            })
            .catch((error) => {
              setModules(error);
              setAnimating(false);
              addAnalytics(error, config);

              // throw new Error( error );

            });
        })
        .catch((error) => {
          configureSkin(error);
          setAnimating(true)
          setTimeout(function () { alert("This link couldn't be found. unrecognised URL..."); }, 2000);
          addAnalytics(error, config);

          // throw new Error( error );

        })
    }
    else if (sessionKey !== null) {
      let config = {
        method: 'get',
        url: window['runConfig'].REACT_APP_BASE_URL + consumeSessionKey(sessionKey),
      };
      axios(config)
        .then((response) => {
          setSessionRef(response.data.sessionref);
          setUserRef(response.data.userref);
          sessionStorage.setItem("userref", response.data.userref);
          sessionStorage.setItem("sessionref", response.data.sessionref);
        }).catch((error) => {
          alert("This link has now expired. Please ask your provider to send another.")
          addAnalytics(error, config);

          // throw new Error( error );
        });
    }
    else if (linkstub === null && suKey === null && sessionKey === null) {
      alert(NO_LINK_FOUND)
      addAnalytics(NO_LINK_FOUND);

    }
  }, []);

  useEffect(() => {
    if (suKey !== null) {
      if (userref !== null) {
        let config = {
          method: 'get',
          url: window['runConfig'].REACT_APP_BASE_URL + getSkin(userref),

        };
        axios(config)
          .then((response) => {
            setAnimating(true);
            sessionStorage.setItem("skinName", response?.data?.profilerskin);
            let config = {
              method: 'get',
              url: window['runConfig'].REACT_APP_BASE_URL + getSurveyQuestions(userref),
            };
            axios(config)
              .then((response) => {
                setModules(response.data);
                setAnimating(false);
              })
              .catch((error) => {
                setModules(error);
                setAnimating(false);
                addAnalytics(error, config);
                // throw new Error( error );

              });
            const orgRef = response.data.orgref ? response.data.orgref : response.data;
            const showResultPage = typeof response.data === 'object' ? response.data.hidereport : true;
            configureSkin(sessionStorage.getItem("skinName"));
            setShowResultButton(showResultPage);
          })
          .catch((error) => {
            configureSkin(error);
            setAnimating(true);
            addAnalytics(error, config);
            // throw new Error( error );

          })
      }
    }

    if (sessionKey !== null) {
      if (userref !== null) {
        let config = {
          method: 'get',
          url: window['runConfig'].REACT_APP_BASE_URL + getSkin(userref),

        };
        axios(config)
          .then((response) => {
            setAnimating(true);
            sessionStorage.setItem("skinName", response?.data?.profilerskin);
            let baseUrl = window['runConfig'].REACT_APP_BASE_URL + getSurveyQuestions(userref);
            if (sessionref) {
              baseUrl = window['runConfig'].REACT_APP_BASE_URL + getSurveryQuestionsWithSessionKey(userref, sessionref);
            }

            let config = {
              method: 'get',
              url: baseUrl,

            };

            axios(config)
              .then((response) => {
                setModules(response.data);
                setAnimating(false);
              })
              .catch((error) => {
                setModules(error);
                setAnimating(false);
                addAnalytics(error, config);
                // throw new Error( error );

              });
            const orgRef = response.data.orgref ? response.data.orgref : response.data;
            const showResultPage = typeof response.data === 'object' ? response.data.hidereport : true;
            configureSkin(sessionStorage.getItem("skinName"));
            setShowResultButton(showResultPage);
          })
          .catch((error) => {
            configureSkin(error);
            setAnimating(true);
            addAnalytics(error, config);
            // throw new Error( error );

          })
      }
    }

  }, [userref])


  const loaderGif = () => {
    if (skin !== null) {
      if (sessionStorage.getItem("skinName")?.toLowerCase() === tiger?.toLowerCase()) {
        return <View style={{ height: '100vh', width: '100vw', backgroundColor: '#ec0000', justifyContent: 'center' }}><img style={{ objectFit: 'contain', alignSelf: 'center', height: hp('40%'), width: wp('40%'), borderRadius: (hp('40%') + wp('40%')) / 2 }} src={logo} alt="loading..." /></View>
      } else {
        return <LoaderSecond />
      }
    }
  }

  return (
    <>
      {
        (animating || modules === null) ? (
          loaderGif()
        ) : (
          <>
            {
              modules ?
                <ReportContext.Provider value={{ showResultButon }} >
                  <Activities modules={modules} userref={userref} skin={skin} sessionref={sessionref} />
                </ReportContext.Provider> :
                null
            }
          </>
        )
      }
    </>
  );
};

export default withRouter(App);

