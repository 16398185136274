import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Animated, TouchableOpacity, Dimensions, Image } from 'react-native';
import { withRouter } from 'react-router-dom';
import Header from '../../../../../Components/HeaderSkin3';
import { buttonText, knowledgeTextPrePage } from '../../../../../Utils/static-strings';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../../Utils/responsive';
import { styles } from './Styles/index';


const backgroundColor = '#cccbe3';
const deviceWidth = Dimensions.get('window').width;

const Index = (props) => {

    const mainAnimation = useState(new Animated.Value(0))[0];
    const GraphicsAnimation = useState(new Animated.Value(-500))[0];
    const TriangleAnimation = useState(new Animated.Value(-100))[0];
    const RectangeAnimation = useState(new Animated.Value(-200))[0];
    const CircleAnimation = useState(new Animated.Value(-300))[0];

    useEffect(() => {
        Animated.parallel([
            Animated.timing(mainAnimation, {
                toValue: 1,
                duration: 1200,
                useNativeDriver: false
            }),
            Animated.timing(TriangleAnimation, {
                toValue: deviceWidth < 1350 ? deviceWidth < 1250 ? deviceWidth < 1000 ? deviceWidth < 800 ? deviceWidth < 380 ? -30 : -70 : -70 : -48 : -48 : 0,
                duration: 1200,
                useNativeDriver: false
            }),
            Animated.timing(GraphicsAnimation, {
                toValue: deviceWidth < 1350 ? deviceWidth < 1250 ? deviceWidth < 1000 ? deviceWidth < 800 ? deviceWidth < 380 ? -30 : -70 : -70 : -48 : -48 : 0,
                duration: 1200,
                useNativeDriver: false
            }),
            Animated.timing(RectangeAnimation, {
                toValue: deviceWidth < 1350 ? deviceWidth < 1250 ? deviceWidth < 1000 ? deviceWidth < 800 ? deviceWidth < 380 ? -30 : -70 : -70 : -48 : -48 : 0,
                duration: 1200,
                useNativeDriver: false
            }),
            Animated.timing(CircleAnimation, {
                toValue: deviceWidth < 1350 ? deviceWidth < 1250 ? deviceWidth < 1000 ? deviceWidth < 800 ? deviceWidth < 380 ? -30 : -70 : -70 : -48 : -48 : 0,
                duration: 1200,
                useNativeDriver: false
            }),
        ])
            .start();
    }, [])

    //deviceWidth < 650 ? 232 : 355

    const opacityInterpolate = mainAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
    })

    const leftTextAnimationInterpolate = mainAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: ["-50%", "0%"]
    })

    const { setPrePage, moduleObject } = props;

    return (
        <div style={{
            height: '100%',// deviceWidth > 600 ? '85vh' : '88vh',
            width: '100%',
            display: "flex",
            justifyContent: "space-between",
            backgroundColor,
            position:'absolute'
        }} >
            <Header currentRoute={"Understanding"} />
            <Animated.View style={[styles.body, { transform: [{ translateX: leftTextAnimationInterpolate }], opacity: opacityInterpolate }]} >
                <View style={styles.bodyHeader} >
                    <Text style={styles.headerText} >{moduleObject.name}</Text>
                </View>
                <View style={styles.bodyTextContainer} >
                    <Text style={styles.bodyText} >{knowledgeTextPrePage}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: hp('3%'), opacity: 0.69, height: 18, marginLeft: "7%" }}>
                    <Image source={require('../../../../../assets/images/regular-clock.png')} style={{
                        height: 18,
                        width: 18,
                        resizeMode: 'contain',
                    }}></Image>
                    <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regulars', color: '#2f2f2f', paddingLeft: 10 }}>Typically 2-5 minutes</Text>
                </View>
                <TouchableOpacity style={styles.button} onPress={() => setPrePage(false)} >
                    <Text style={styles.buttonText} >{buttonText}</Text>
                </TouchableOpacity>
            </Animated.View>

            <View style={{ height: "25%", width: "100%" }} >

            </View>

            {
                deviceWidth > 250 && <>


                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        position: 'absolute',
                        right: TriangleAnimation
                    }} >
                        <View style={[customStyle.ovalViolet,]}  >

                        </View>

                        <View style={[customStyle.triangleBlueLeft,]}  >

                        </View>

                        <View style={[customStyle.triangleBlueRightHalf,]}  >

                        </View>

                        <View style={[customStyle.blueTriangleLarge,]}  >

                        </View>
                    </Animated.View>

                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        right: CircleAnimation,
                        position: 'absolute',
                        zIndex: 4
                    }} >

                        <View style={[customStyle.triangleViolet,]}  >

                        </View>

                        <View style={[customStyle.violetRectangle,]}  >

                        </View>

                        <View style={[customStyle.pinkRectangle]}  >

                        </View>

                        <View style={[customStyle.violetCircleHalf,]}  >

                        </View>
                    </Animated.View>

                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        right: RectangeAnimation,
                        position: 'absolute',
                        zIndex: 2
                    }} >
                        <View style={[customStyle.violetRectangle, { bottom: deviceWidth < 650 ? deviceWidth < 380 ? 30 : 52 : 63, right: deviceWidth < 650 ? deviceWidth < 380 ? 80 : 125 : 240, width: deviceWidth < 650 ? deviceWidth < 380 ? 202 : 332 : 541 }]}  >

                        </View>

                        <View style={[customStyle.orangeRectangle,]}  >

                        </View>

                    </Animated.View>


                    <Animated.View style={{
                        height: "15%",
                        width: "100%",
                        bottom: 0,
                        right: RectangeAnimation,
                        position: 'absolute',
                    }} >
                        <Animated.View style={[customStyle.pinkRectangle, { zIndex: 6, right: deviceWidth < 650 ? deviceWidth < 380 ? 110 : 232 : 355 }]}  >

                        </Animated.View>

                    </Animated.View>


                </>
            }

            {/* </Animated.View > */}
        </div >
    )
}

export default withRouter(Index);


const customStyle = StyleSheet.create({
    orangeRectangle: {
        height: deviceWidth < 650 ? deviceWidth < 380 ? 42 : 72 : 96,
        width: deviceWidth < 650 ? deviceWidth < 380 ? 91 : 149 : 243,
        backgroundColor: "rgba(219, 125, 114, 0.77)",
        position: 'absolute',
        bottom: 0,
        zIndex: 2,
        right: deviceWidth < 650 ? deviceWidth < 380 ? 128 : 200 : 420,
    },
    violetRectangle: {
        height: deviceWidth < 650 ? deviceWidth < 380 ? 12 : 20 : 33,
        width: deviceWidth < 650 ? deviceWidth < 380 ? 150 : 280 : 433,
        position: 'absolute',
        backgroundColor: "#7b76b6",
        bottom: deviceWidth < 650 ? deviceWidth < 380 ? 100 : 159 : 250,
        alignSelf: "center",
        right: 0,
        zIndex: 8
    },
    pinkRectangle: {
        height: deviceWidth < 650 ? deviceWidth < 380 ? 144 : 237 : 387,
        width: deviceWidth < 650 ? deviceWidth < 380 ? 3 : 4 : 7,
        position: 'absolute',
        backgroundColor: "#e58ebb",
        bottom: 10,
        alignSelf: "center",
        right: deviceWidth < 650 ? deviceWidth < 380 ? 100 : 220 : 330,
        zIndex: 9
    },
    violetCircleHalf: {
        height: deviceWidth < 650 ? deviceWidth < 380 ? 128 : 216 : 320,
        width: deviceWidth < 650 ? deviceWidth < 380 ? 128 : 216 : 320,
        borderRadius: deviceWidth < 650 ? deviceWidth < 380 ? 128 / 2 : 216 / 2 : 320 / 2,
        backgroundColor: "#7b76b6",
        opacity: 0.74,
        zIndex: 6,
        right: deviceWidth < 650 ? deviceWidth < 380 ? 120 : 180 : 420,
        alignSelf: "center",
        position: 'absolute',
        bottom: deviceWidth < 650 ? deviceWidth < 380 ? -68 : -108 : -168
    },

    ovalViolet: {
        width: 340,
        height: 340,
        borderRadius: 340 / 2,
        backgroundColor: "#7b76b6",
        opacity: 0.74,
        // transform: [{ scaleX: 2 },{rotate:"-180deg"}],
        position: "absolute",
        alignSelf: "center",
        bottom: 0,
        zIndex: 2,
        right: deviceWidth < 650 ? -500 : -260,
    },
    blueTriangleLarge: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1350 ? 485 / 2 : deviceWidth < 650 ? deviceWidth < 380 ? 172 / 2 : 310 / 2 : 454 / 2,
        borderBottomWidth: deviceWidth > 1350 ? 485 : deviceWidth < 650 ? deviceWidth < 380 ? 172 : 310 : 454,
        borderLeftWidth: deviceWidth > 1350 ? 485 / 2 : deviceWidth < 650 ? deviceWidth < 380 ? 172 / 2 : 310 / 2 : 454 / 2,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        bottom: 0,
        zIndex: 4,
        opacity: 0.75,
        right: deviceWidth < 650 ? deviceWidth < 380 ? -35 : -25 : 0
    },

    triangleBlueLeft: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth > 1350 ? 450 / 2 : deviceWidth < 650 ? deviceWidth < 380 ? 148 / 2 : 265 / 2 : 410 / 2,
        borderBottomWidth: deviceWidth > 1350 ? 450 : deviceWidth < 650 ? deviceWidth < 380 ? 150 : 280 : 410,
        borderLeftWidth: deviceWidth > 1350 ? 450 / 2 : deviceWidth < 650 ? deviceWidth < 380 ? 148 / 2 : 265 / 2 : 410 / 2,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        zIndex: 3,
        right: deviceWidth < 650 ? deviceWidth < 380 ? 120 / 2 : 220 / 2 : 380 / 2,
        bottom: 0,
        opacity: 0.75
    },
    triangleBlueRightHalf: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: deviceWidth < 650 ? 210 / 2 : 410 / 2,
        borderBottomWidth: deviceWidth < 650 ? 210 : 410,
        borderLeftWidth: deviceWidth < 650 ? 210 / 2 : 410 / 2,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: '#66b3e3',
        borderLeftColor: 'transparent',
        position: "absolute",
        alignSelf: "center",
        bottom: 0,
        zIndex: 4,
        opacity: 0.75,
        right: deviceWidth < 650 ? -250 / 2 : -420 / 2
    },
    triangleViolet: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderRightWidth: deviceWidth < 650 ? deviceWidth < 380 ? 160 : 280 : 458,
        borderTopWidth: deviceWidth < 650 ? deviceWidth < 380 ? 160 : 280 : 356,
        borderRightColor: "transparent",
        borderTopColor: "#7b76b6",
        position: "absolute",
        alignSelf: "center",
        bottom: 0,
        opacity: 0.75,
        zIndex: 7,
        transform: [{ rotate: "180deg" }],
        right: deviceWidth < 650 ? 0 : 130
    },
})