import React, { useState, useEffect, useContext } from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Animated,
  TouchableOpacity,
  Easing,
  PixelRatio
} from 'react-native';
import CustomCard from '../customCard/index';
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../../Containers/Activities/index'
import { chunkify } from '../../Utils/chunkify'
import '../../App.css'
import {screenWidth as actualWidth , screenHeight as actualHeight} from '../../Utils/responsive';
import BottomText from '../../Containers/ChooseActivity/future/BottomText/index'
import BackButton from '../BackButton/index'
import { inIframe } from '../../Utils/helper';

export const THREAD_COLOR = 'rgba(255, 255, 255, 0.5)';
export const CARD_BACKGROUND_COLOR = '#ec0000';
export const CARD_BACKGROUND_COLOR2 = '#d00301';
export const dotColor = '#495049';
// export const screenWidth = Dimensions.get('window').width;

const Index = (props) => {
  
  const [allValue, setAllValue] = useState(null);
  const [indexing, setIndexing] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(0)
  const firstCardAnimation = useState(new Animated.Value(0))[0];
  const secondCardAnimation = useState(new Animated.Value(0))[0];
  const secondCardOpacity = useState(new Animated.Value(1))[0];
  const [cardWidth, setCardWidth] = useState("-11px")
  const resultContext = useContext(ActivityContext);
  const [dataHistory, setDataHistory] = useState([]);
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);
   
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  const { moduleObject, moduleIndex, setModuleIndex, selectAnswerHandler, question, history }  = props;

  const saveAnswer = (index) => {
    selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid)
  }

  const handleButtonClick = (val) => {
    if (val === "Left") {
      if (questionIndex < indexing[currentSet].length - 1) {
        saveAnswer(0)
        setQuestionIndex(prev => prev + 1);
        setDataHistory(prev=>[...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length-1}])
      }
      else {
        saveAnswer(0)
        setQuestionIndex(0)
        setCurrentSet(prev => prev + 1);
        setDataHistory(prev=>[...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length-1}])
      }
    }
    else {
      if (questionIndex < indexing[currentSet].length - 1) {
        for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
          selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid)
        }
      }
      selectAnswerHandler(moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid)
      setCurrentSet(prev => prev + 1)
      setQuestionIndex(0);
      setDataHistory(prev=>[...prev, {val, currentSet, questionIndex, length: indexing[currentSet].length-1}])
    }
  }

  useEffect(() => {
    if (moduleObject.questions) {
      const chunkiElement = chunkify(moduleObject.questions, 2, true);

      setAllValue(chunkiElement);

      const totalLength = chunkiElement.map(elem => (
        { length: elem.length }
      ))
      setIndexing(totalLength)
    }

    Animated.parallel([
      Animated.timing(firstCardAnimation, {
        toValue: Dimensions.get('screen').height,
        duration: 400,
        useNativeDriver: false,
        easing: Easing.linear,
      }),
      Animated.timing(secondCardAnimation, {
        toValue: Dimensions.get('screen').height - 30,
        duration: 400,
        useNativeDriver: false,
        delay: 400,
        easing: Easing.linear,
      }),
    ]).start();
  }, []);

  useEffect(() => {
    if (question !== null) {
      if (currentSet === 1 && questionIndex === 0) {
        setCurrentSet(0)
        setQuestionIndex(indexing[0].length - 1)
        setModuleIndex(indexing[0].length - 1)
        upsideAnimation();
      }
      else {
        setQuestionIndex(prev => prev - 1)
        upsideAnimation();
      }
    }
  }, [question]);

  const upsideAnimation = () => {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(secondCardAnimation, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(secondCardOpacity, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
      ]),
      Animated.parallel([
        Animated.timing(secondCardAnimation, {
          toValue: Dimensions.get('screen').height,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.linear,
        }),
        Animated.timing(secondCardOpacity, {
          toValue: 1,
          duration: 400,
          useNativeDriver: false,
        }),
      ]),
    ]).start();
  };
  const upsideAnimationTwo = () => {
    setCardWidth("20px")
    Animated.sequence([
      Animated.parallel([
        Animated.timing(secondCardAnimation, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(secondCardOpacity, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
      ]),
      Animated.parallel([
        Animated.timing(secondCardAnimation, {
          toValue: Dimensions.get('screen').height,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.linear,
        }),
        Animated.timing(secondCardOpacity, {
          toValue: 1,
          duration: 400,
          useNativeDriver: false,
        }),
      ]),
    ]).start();
  };

  const firstCardHeight = firstCardAnimation.interpolate({
    inputRange: [0, Dimensions.get('screen').height],
    outputRange: ["0%", "100%"]
  })

  const secondCardHeight = secondCardAnimation.interpolate({
    inputRange: [0, Dimensions.get('screen').height],
    outputRange: ["0%", "95%"]
  })

  const prevQuestion = ()=>{
    if(dataHistory.length>0){
      const _dataHistory = JSON.parse(JSON.stringify(dataHistory));
      const prevHistory = JSON.parse(JSON.stringify(dataHistory));
      const lastIndex = _dataHistory.length-1;
      setQuestionIndex(_dataHistory[lastIndex].questionIndex);
      setCurrentSet(_dataHistory[lastIndex].currentSet);
      prevHistory.pop()
      setDataHistory(prevHistory);
      if(_dataHistory[lastIndex].val === 'Left'){
        upsideAnimation();
        setModuleIndex(prev=>prev-1);

      }else{
        upsideAnimationTwo()
        let totalSkipLength = Number(_dataHistory[lastIndex].length) - Number(_dataHistory[lastIndex].questionIndex);
        setModuleIndex(prev=>prev-totalSkipLength-1);
      }
    }
  }

  useEffect(() => {
    if (currentSet === 2) {
      resultContext.setModuleCompletedAtTime(moduleObject.moduleid);
      history.push('/')
    }
  }, [currentSet])

  return (
   <>
    <View style={[styles.cardsContainer,{    width:inIframe() ? '472px': screenWidth > 1500 ? '516px' : screenWidth > 600 ? "472px" : screenWidth > 405 ? "391px" : "303px",
}]}>
      <Animated.View style={[styles.firstcard, { height: firstCardHeight,    width:inIframe() ? 310: screenWidth > 1500 ? '338px' : screenWidth > 600 ? "310px" : screenWidth > 405 ? "227px" : "176px",
 }]}>
        <View style={styles.firstThread} />
        <TouchableOpacity
          onPress={() => {
            handleButtonClick("Left");
            upsideAnimation();
          }} style={{ height: "24%", width: "100%" }} >
          {(allValue !== null && currentSet !== 2) && <CustomCard
            style={styles.customStyle}
            data={allValue[currentSet][questionIndex].answers[0].text}
            innerStyle={[styles.innerStyleFirst,{
              fontSize: inIframe() ? 21.5:hp('2.6%'),}]}
            valueStyle={[styles.valueStyleFirst,{    fontSize:inIframe() ? 41: hp("5%"),
          }]}
            thread="first"
          />}
        </TouchableOpacity>
        <div className={screenWidth > 600 ? "firstCardShadow" : screenWidth > 405 ? "firstCardShadow1" : "firstCardShadow2"} />
      </Animated.View>
      <Animated.View
        style={[
          styles.secondcard,
          {
            width: inIframe() ? currentSet == 1 ? '245px' : '185px' :  screenWidth > 1500 ? currentSet == 1 ? '203' : '263px' : screenWidth > 600 ? currentSet == 1 ? "185px" : "245px" : screenWidth > 405 ? currentSet == 1 ? "113px" : "173px" : currentSet == 1 ? "74px" : "134px",
            height: secondCardHeight,
            left: cardWidth,
            opacity: 1,
          },
        ]}>
        <View style={styles.secondThread} />
        <TouchableOpacity
          onPress={() => {
            handleButtonClick("Right");
            upsideAnimationTwo();
          }} style={{ height: currentSet == 1 ? '18%' : "22%", width: "100%" }}>
          {
            (allValue !== null && currentSet !== 2) && <CustomCard
              style={styles.customStyle2}
              data={allValue[currentSet][questionIndex].answers[1].text}
              innerStyle={[styles.innerStyleSecond, { fontSize: currentSet == 1 ? inIframe() ? 13 :  hp('1.6%') : inIframe() ? 19.5:  hp('2.4%') }]}
              valueStyle={[styles.valueStyleSecond, { fontSize: currentSet == 1 ? inIframe() ? 24.5:  hp("3%") :  inIframe() ? 33.5: hp('4.1%') }]}
              thread="second"
            />
          }
        </TouchableOpacity>
        <div className={screenWidth > 600 ? "secondCardShadow" : screenWidth > 405 ? "secondCardShadow1" : "secondCardShadow2"} />
      </Animated.View>
    </View>
    <BottomText {...props} />
    {moduleIndex > 0 && (
      <BackButton prevQuestion={prevQuestion} />
    )}
   </>

  );
};

export default withRouter(Index);

const styles = StyleSheet.create({
  cardsContainer: {
    height: "65%",
    alignSelf: "center",
    flexDirection: "row"
  },
  firstcard: {
    position: 'relative',
    zIndex: 3,
    // backgroundColor:"blue"
  },
  firstThread: {
    position: 'relative',
    borderLeftColor: THREAD_COLOR,
    borderRightColor: THREAD_COLOR,
    borderWidth: 2,
    borderStyle: 'solid',
    height: "70%",
    zIndex: 5,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    width: "85%",
    alignSelf: "center"
  },
  secondcard: {
    position: 'relative',


  },
  secondThread: {
    position: 'relative',
    borderLeftColor: THREAD_COLOR,
    borderRightColor: THREAD_COLOR,
    borderWidth: 2,
    borderStyle: 'solid',
    height: "70%",
    zIndex: 5,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    width: "85%",
    alignSelf: "center"
  },
  customStyle: {
    position: 'relative',
    marginTop: -10,
    backgroundColor: CARD_BACKGROUND_COLOR,
    width: '100%',
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  innerStyleFirst: {
    position: 'relative',
    width: "100%",
    textAlign: "center",
    color: '#ffffff',
    fontFamily: "OpenSans-Bold",
    // fontSize:  "18px",
    fontWeight: 'bold'
  },
  valueStyleFirst: {
    fontFamily: "OpenSans-Bold",
    color: "#ffffff",
    fontWeight: 'bold'
  },
  customStyle2: {
    position: 'relative',
    marginTop: -10,
    backgroundColor: CARD_BACKGROUND_COLOR2,
    height: "100%",
    width: '100%',
    justifyContent: "center",
    alignItems: "center"
  },
  valueStyleSecond: {
    fontFamily: "OpenSans-Bold",
    // fontSize: hp("4.1%"),
    color: "#ffffff",
    fontWeight: 'bold'
  },
  innerStyleSecond: {
    position: 'relative',
    width: "100%",
    textAlign: "center",
    color: '#ffffff',
    fontFamily: "OpenSans-Bold",
    // fontSize: hp('2.4%'),
    fontWeight: 'bold'

  },
});
