import React, {useCallback, useMemo} from 'react';
import { View, Image, Dimensions, PixelRatio } from 'react-native';
import { useEffect, useState } from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import '../../../../../src/App.css';
import getSheet from './RotateStyle';
import { screenWidth as actualWidth , screenHeight as actualHeight  } from '../../../../Utils/responsive';
import { inIframe } from '../../../../Utils/helper';
// const actualWidth =inIframe() ? 1024 : Dimensions.get('screen').width;
// const actualHeight = inIframe() ? 768 : Dimensions.get('screen').height;


const Rotate = (props) => {
  const [degree, setDegree] = useState('0deg')
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);


  const wp =  useCallback((e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }, [screenWidth])


  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);

  });

  const height = useMemo(()=>{
    return screenWidth <= 1125 ? wp('17%') : (screenWidth <= 1125 ? wp('15.0%') : wp("9.5%"))
  }, [screenWidth, wp]);

  const width = useMemo(()=>{
    return screenWidth <= 1125 ? wp('7%') : (screenWidth <= 1125 ? wp('6.5%') : wp("5.6144%"))
  }, [screenWidth, wp]);


  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);


  const {
    gain,
    loss,
    primaryColorBlue,
    primaryColorPurple,
    secondaryColorBlue,
    secondaryColorPurple,
    lossRatio,
  } = props;

  const styles = getSheet(
    secondaryColorBlue,
    secondaryColorPurple,
  );

  useEffect(() => {

    if (lossRatio !== null) {
      const deg = lossRatio * 100 * 3.6
      setDegree(`${deg}deg`)
    }

  }, [lossRatio])

  useEffect(() => {
    if (lossRatio !== null) {
      const deg = (lossRatio * 100) * 3.6
      if (lossRatio === 0.5) {
        setDegree('0deg')
      } else {
        setDegree(`${deg}deg`)
      }
    }

  }, [])
  const CustomLabel = () => {

    return (
      <>
        <View style={{
          width: actualWidth <= 1125 ? (screenWidth <= 1125 ? wp('28%') : wp("14.56%")+25) : (screenWidth <= 1125 ? wp('24%') : wp("14.56%")),
          height: actualWidth <= 1125 ? (screenWidth <= 1125 ?  wp('28%') : wp("14.56%")+25) : (screenWidth <= 1125 ? wp('24%') : wp("14.56%")),
          borderRadius: actualWidth <= 1125 ? (screenWidth <= 1125 ?  wp('28%') : wp("14.56%")) : (screenWidth <= 1125 ? wp('24%') : wp("14.56%")) / 2,
          borderColor: 'black',
          opacity: 0.3,
          borderWidth: actualWidth <= 1125 ? (screenWidth <= 1125 ?  wp('2.4%') : wp('2.4%')-10) : (screenWidth <= 1125 ? wp('2.1') : wp("1.27%")),
          borderStyle: 'solid',
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 99,
          transform: [{
            translateX: actualWidth <= 1125 ? (screenWidth <= 1125 ?  -wp('28%') / 2 : -12-wp("14.56%") / 2) : (screenWidth <= 1125 ? -wp('24%') / 2 : -wp("14.56%") / 2)
          },
          {
            translateY: actualWidth <= 1125 ? (screenWidth <= 1125 ?  -wp('28%') / 2 : -12-wp("14.56%") / 2) : (screenWidth <= 1125 ? -wp('24%') / 2 : -wp("14.56%") / 2)
          }]
        }} />
      </>
    );
  };

  return (
    <>

      {CustomLabel()}
      <VictoryPie
        colorScale={[primaryColorBlue, primaryColorPurple]}
        width={actualWidth <= 1125 ? wp(`${4.0 * 14.7}%`) : (screenWidth <= 1125 ? wp(`${2.8 * 14}%`) : wp(`${2.7 * 14.69}%`))}
        height={actualWidth <= 1125 ? wp(`${4.0 * 14.7}%`) : (screenWidth <= 1125 ? wp(`${2.8 * 14}%`) : wp(`${2.7 * 14.69}%`))}

        data={[
          { x: 3, y: lossRatio * 100 * 3.6 },
          { x: 1, y: 360 - (lossRatio * 100 * 3.6) },
        ]}

        labels={[() =>
          () => ['Lose', `£${loss}`, `(${loss/10}%)`],
        () => ['Gain', `£${gain}`, `(${gain/10}%)`]]}

        labelPosition={"centroid"}

        labelComponent={
          <VictoryLabel
            style={[
              {
                //   width: 46,
                //   height: 27,
                fontFamily: 'OpenSans-Light',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                alignSelf: 'center',
                fill: 'white',
                fontSize: actualWidth <= 1125 ? wp('3.0%') : (screenWidth <= 1125 ? wp('2.0%') : wp('1.9%')) //23
              },
              {
                fontSize: actualWidth <= 1125 ? wp('3.1%') : (screenWidth <= 1125 ? wp('2.2%') : wp('2.3%')), //23
                fill: 'white',
                //   width: 46,
                //   height: 27,
                fontFamily: 'OpenSans-Bold',
                fontWeight: 'bolder',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.39,
                letterSpacing: 0.5,
                padding: 30,
                margin: 50,
              },
              {
                fontSize: actualWidth <= 1125 ? wp('3.1%') : (screenWidth <= 1125 ? wp('2.2%') : wp('1.9%')), //23
                fill: 'white',
                //   width: 46,
                //   height: 27,
                fontFamily: 'OpenSans-Light',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.39,
                letterSpacing: 0.5,
                padding: 30,
                margin: 50,
              },
            ]} />
        }

        innerRadius={actualWidth <= 1125 ? wp('12.3%') : (screenWidth <= 1125 ? wp('6.98%') : wp("7.41%"))}//59
        labelRadius={actualWidth <= 1125 ? wp('16.0%') : (screenWidth <= 1125 ? wp('9.0%') : wp("10.5%"))} />

      {/* </Animated.View> */}
    </>
  );
};

export default Rotate;
