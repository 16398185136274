import styled from 'styled-components';
import nativeStyle from 'styled-components/native';
import { Dimensions } from 'react-native';

const evalString = (value) => {
  switch (value && value.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
}

export const Container = styled.section`
  display: flex;
  flex: 1;
  background-color: ${props => evalString(props.questionAnswer) ? '#eeeeee' : '#575f69'};
  flex-direction: column;
`;

export const TopSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
`;

export const Text = styled.div`
  color:  ${props => evalString(props.questionAnswer) ? '#575f69' : '#ffffff'};
  font-size: ${props => props.fontSize || 24}px;
  font-family: JosefinSans-Bold;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  letter-spacing: ${props => props.letterSpacing || 2.34}px;
  text-align: center;
  margin-top: ${props => props.marginTop || 0}px;
`

export const Question = styled.div`
  color: ${props => evalString(props.questionAnswer) ? '#575f69' : '#ffffff'};
  font-size: ${Dimensions.get('screen').width <= 320 ? '20px' : '24px'};
  font-family: JosefinSans-Light;
  letter-spacing: 2.4px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: ${Dimensions.get('screen').width <= 320 ? '20px' : '15px'}
`;

export const BottomSection = styled.div`
  display: flex;
  flex: 1;
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => props.justifyContent || ''};
  flex-direction: ${props => props.flexDirection || ''};
`;

export const Rectangle = nativeStyle.TouchableOpacity`
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: ${props => props.touched ? '0.5' : '1'};
  background-image: ${props => evalString(props.questionAnswer) ?
    `linear-gradient(to bottom, #eeeeee, rgba(238, 238, 238, 0)), linear-gradient(to right, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.1) 100%)` :
    `linear-gradient(to bottom, #575f69, rgba(87, 95, 105, 0)), linear-gradient(to right, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.1) 100%)`};
  }
`;