import React, { useRef, useState, useLayoutEffect, useEffect, useContext } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  Easing,
  Animated,
  Dimensions
} from 'react-native';
import Rotate from './Rotate';
import Donut from '../../../../Components/CustomDonut'
import CircularPie from '../../../../Components/CircularPie';
import getSheet from './RotateStyle';
import { chunkify } from '../../../../Utils/chunkify';
import { withRouter } from 'react-router-dom';
import { ActivityContext } from '../../../Activities/index';
import LossPrePage from './LossPrePage/index';
import HeaderSkin3 from '../../../../Components/HeaderSkin3/index';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
const actualHeight = Dimensions.get('screen').height;

const Loss = (props) => {
  const [gain, setGain] = useState(40);
  const [loss, setLoss] = useState(60);
  const [primaryColorBlue, setprimaryColorBlue] = useState('#bb6ef0');
  const [primaryColorPurple, setprimaryColorPurple] = useState('#ee7998');
  const [show, setShow] = useState(true);
  const [allValue, setAllValue] = useState(null);
  const [indexing, setIndexing] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const resultContext = useContext(ActivityContext);
  const animated1 = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(0)).current;
  const opacity2 = useRef(new Animated.Value(0)).current;
  const bottomViewY = useRef(new Animated.Value(0)).current;
  const tBar = useRef(new Animated.Value(0)).current;
  const yesButton = useRef(new Animated.Value(0)).current;
  const noButton = useRef(new Animated.Value(0)).current;
  const scaleButton = useRef(new Animated.Value(1)).current;
  const pinch = useRef(new Animated.Value(1)).current;

  const [display, setDisplay] = useState(false);
  const [disable, setDisable] = useState(true);

  useLayoutEffect(() => {
    Animated.timing(tBar, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
      easing: Easing.linear
    }).start(() => setDisplay(true));
  }, [])

  useEffect(() => {
    animate();

    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 1,
        duration: 2500,
        useNativeDriver: false,
        easing: Easing.in()
      }),
      Animated.timing(opacity2, {
        toValue: 1,
        duration: 900,
        useNativeDriver: false,
        easing: Easing.in
      }),
      Animated.timing(bottomViewY, {
        toValue: 35,
        duration: 900,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
    ]).start();
    if (props.moduleObject.questions) {
      const chunkiElement = chunkify(props.moduleObject.questions, 4, true);

      setAllValue(chunkiElement);

      function calculateNumberOfSets(questions) {
        const uniqueSets = new Set();

        questions.forEach(question => {
          // Split the qid by '.' and extract the part that indicates the set
          const setId = question.qid.split('.')[3].substring(0, 2);
          uniqueSets.add(setId);
        });

        return uniqueSets.size;
      }

      const numberOfSets = calculateNumberOfSets(props.moduleObject.questions); // Replace 'yourArray' with your actual data variable
      setValueLength(numberOfSets)

      const totalLength = chunkiElement.map(elem => (
        { length: elem.length }
      ));
      setIndexing(totalLength);
    }

    setLoss(loss);
    setGain(gain);


  }, []);

  useLayoutEffect(() => {
    animate();
  }, [show])

  useLayoutEffect(() => {
    if (currentSet === 4) {
      resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid);
      props.setfinishAnimate(props.moduleObject.moduleid);
      props.history.push('/');
    }
    if (currentSet === 1) {
      setShow(true);
    }
    if (currentSet === 2) {
      setShow(true);

    }
    if (currentSet === 3) {
      setShow(true);
    }
  }, [currentSet]);

  const animate = () => {
    animated1.setValue(0);
    setDisable(true);
    Animated.timing(animated1, {
      toValue: 35,
      duration: 900,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      setDisable(false);
    });
  };

  const yesAnimated = () => {
    yesButton.setValue(0);
    scaleButton.setValue(1);
    Animated.parallel([
      Animated.timing(scaleButton, {
        toValue: 1.6,
        duration: 200,
      }),
      Animated.timing(yesButton, {
        toValue: 1,
        duration: 700,
        easing: Easing.linear,
      }),

    ]).start(() => setDisable(false));
  }

  const noAnimated = () => {
    noButton.setValue(0);
    scaleButton.setValue(1);
    Animated.parallel([
      Animated.timing(scaleButton, {
        toValue: 1.6,
        duration: 200,
      }),
      Animated.timing(noButton, {
        toValue: 1,
        duration: 700,
        easing: Easing.linear,
      }),

    ]).start(() => setDisable(false));
  }


  const vanishButtons = () => {
    setTimeout(() => {
      Animated.sequence([
        Animated.timing(pinch, {
          toValue: 0,
          duration: 500,
          // delay: 1000
        }),
        Animated.timing(pinch, {
          toValue: 1,
          duration: 500,
          delay: 1600
        })
      ]).start()
    }, 600)
  }

  const yesInterpol = yesButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: ['0deg', '45deg', '0deg']
  })

  const noInterpol = noButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: ['0deg', '-45deg', "0deg"]
  });

  const yesScalingInterpolate = yesButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: [1, 1.6, 1]
  })
  const noScalingInterpolate = noButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: [1, 1.6, 1]
  })

  // const animate2 = () => {
  //   animated1.setValue(35);
  //   Animated.timing(animated1, {
  //     toValue: 60,
  //     duration: 1000,
  //     easing: Easing.linear,
  //     useNativeDriver: true,
  //   }).start(() => animate());
  // };

  const [valueLength, setValueLength] = React.useState(4);


  const spin = animated1.interpolate({
    inputRange: [5, 20],
    outputRange: ['360deg', '0deg'],
  });
  const transform = animated1.interpolate({
    inputRange: [0, 35],
    outputRange: [wp("120%"), wp('0%')],
  });

  const allOpacity = opacity.interpolate({
    inputRange: [0, 0.8, 1],
    outputRange: [0, 0, 1]
  });

  const opacityT2 = opacity2.interpolate({
    inputRange: [0, 0.8, 1],
    outputRange: [0, 0, 1]
  });

  const bottomViewTranslationY = bottomViewY.interpolate({
    inputRange: [0, 35],
    outputRange: [wp("120%"), wp('0%')],
  });

  const topBar = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%']
  });

  const styles = getSheet(show);

  const fun = () => {
    setShow(false);
  };

  // const prevQuestion = () => {
  //   if ((currentSet === 1 && questionIndex === 0) || (currentSet === 2 && questionIndex === 0) || (currentSet === 3 && questionIndex === 0)) {
  //     setCurrentSet(prev => prev - 1);
  //     setQuestionIndex(indexing[currentSet - 1].length - 1);
  //     props.setModuleIndex(indexing[currentSet - 1].length - 1);
  //   }
  //   else {
  //     setQuestionIndex(prev => prev - 1);
  //   }
  // };

  function closestNumber(n, m) {
    let q = parseInt(n / m);
    let n1 = m * q;
    let n2 = (n * m) > 0 ? (m * (q + 1)) : (m * (q - 1));
    if (Math.abs(n - n1) < Math.abs(n - n2))
      return n1;
    return n2;
  }

  const saveAnswer = (index) => {
    props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid);
  }

  const handleButtonClick = (val) => {
    if (val === "Accept") {
      yesAnimated();
      if (currentSet < 4 && questionIndex === 0) {
        //animate2();
      }
      if (currentSet === 1 || currentSet === 3) {

        if (questionIndex < indexing[currentSet].length - 1) {
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[0].respid);
          }
        }
        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[0].respid);
        setCurrentSet(prev => prev + 1);
        setDisable(true);
        setQuestionIndex(0);
        setTimeout(() => {
          yesAnimated()
        }, 100)
      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(0);
          setQuestionIndex(prev => prev + 1);
        }
        else {
          saveAnswer(0);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
          setDisable(true);
        }
      }
    }
    else {
      noAnimated();
      if (currentSet < 4 && questionIndex === 0) {
        //animate2();
      }
      if (currentSet === 0 || currentSet === 2) {
        if (questionIndex < indexing[currentSet].length - 1) {
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid);
          }
        }
        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid);
        setCurrentSet(prev => prev + 1);
        setQuestionIndex(0);
        setTimeout(() => {
          noAnimated();
        }, 100)
      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(1);
          setQuestionIndex(prev => prev + 1);
        }
        else {
          saveAnswer(1);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
        }
      }
    }
  }

  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));
  useEffect(() => {
    const onChange = ({window}) => {
      setScreenDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);

  return (
    <div style={{
      // display: "flex",
      backgroundColor: "#f5d3e4",
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
      // flexDirection:"column"
    }}>
      <HeaderSkin3 currentRoute={"Understanding"} />
      {show ? (
        <>
          {display &&

            <LossPrePage fun={fun} setDisable={setDisable} currentSet={currentSet} moduleObject={props.moduleObject} />
          }
        </>
      ) : (
          <View style={{ flex: 1, justifyContent: 'center', height:'100%', gap:15}}>
            <View>
              <Animated.View style={styles.headingContainer, { opacity: allOpacity, marginTop: '5%' }}>
                <Text style={styles.headerSkin3}>A spinner is spun.</Text>
                <Text style={styles.headerSkin3}>If it lands on...</Text>
              </Animated.View>
            </View>
            <CircularPie
              activeColor="#007780"
              passiveColor="#e58ebb"
              baseColor="white"
              width={50}
              circularPieForSkin3={true}
              done={
                currentSet !== 4 &&
                (allValue[currentSet][questionIndex].metadata.lossratio < 0.5 ?
                  closestNumber(((1 - allValue[currentSet][questionIndex].metadata.lossratio) * 100), 5) :
                  closestNumber((allValue[currentSet][questionIndex].metadata.lossratio * 100), 5))
              }
              ratio={allValue && allValue[currentSet] && allValue[currentSet][questionIndex] && allValue[currentSet][questionIndex].metadata.lossratio}
              isBlueOverDark={currentSet !== valueLength && allValue && allValue[currentSet][questionIndex].metadata.lossratio !== 0.5}
              setDisable={setDisable}
              setTriangle={currentSet === 2 || currentSet === 3}
              gain={currentSet !== 4 && parseInt(
                allValue[currentSet][questionIndex].metadata.winamount.slice(1),
              )}
              loss={currentSet !== 4 && parseInt(
                allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
              )}
              radius={screenDimensions.height < 700 || screenDimensions.width < 600 ? 100 : 120}
              bigRadius={120}
              smallRadius={100}
              duration={600}
              showPercentage={false}
              showAmount={true}
              hideBracket={true}
            />
            <Animated.View style={[styles.bottomView, { flexDirection: 'column' }]}>
              <Text style={styles.headerSkin3}>
                Would you play?
            </Text>
              <View style={styles.button5Container}>
                {(allValue !== null && currentSet !== 4) &&
                  allValue[currentSet][questionIndex].answers.map(
                    (answer, i) => {
                      return (
                        <TouchableOpacity
                          disabled={disable}
                          // style={styles.button}
                          key={i}
                          onPress={() => {
                            handleButtonClick(answer.text);
                            setDisable(true);
                            vanishButtons();
                            // animate();
                            // yesAnimated();
                            // if (answer.text === 'Accept') {
                            //   yesAnimated();
                            // }
                            // else {
                            //   noAnimated();
                            // }
                          }}
                          style={[answer.text === 'Accept' ? { marginTop: 10, marginRight: '5vh', } : { marginTop: 10 }]}>
                          <Animated.View
                            style={[styles.button5, answer.text === 'Accept' ? {
                              transform: [
                                { scale: yesScalingInterpolate },
                                { rotate: yesInterpol }
                              ],

                            } : {
                                transform: [
                                  { scale: noScalingInterpolate },
                                  { rotate: noInterpol }
                                ],
                              },
                            ]
                            }
                          >
                            <Text style={styles.button5text}>{answer.text === 'Accept' ? 'Yes' : 'No'}</Text>
                          </Animated.View>
                        </TouchableOpacity>
                      );
                    },
                  )}
              </View>
            </Animated.View>
          </View>
        )
      }
    </div>
  );
}

export default withRouter(Loss);
