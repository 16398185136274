import React, { useRef, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated, Image } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../App.css';
import { experiance, experianceIntroText, buttonText, experianceIntroTextMobile } from '../../../../Utils/static-strings'
import './introPage.css';
import { Opacity } from '@material-ui/icons';
const deviceWidth = Dimensions.get('screen').width;
const Index = (props) => {

    const animation = useRef(new Animated.Value(
        -Dimensions.get('screen').width
    )).current;
    const animation2 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;
    const animation3 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;
    const animation4 = useRef(new Animated.Value(
        deviceWidth > 600 ? Dimensions.get('screen').width : Dimensions.get('screen').width + 800
    )).current;

    useEffect(() => {
        Animated.parallel([
            Animated.timing(animation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false,
            }),
            Animated.timing(animation2, {
                toValue: 0,
                duration: 1000,
                // delay: 500,
                useNativeDriver: false,
            }),
            Animated.timing(animation3, {
                toValue: 0,
                duration: 1000,
                delay: 400,
                useNativeDriver: false,
            }),
            Animated.timing(animation4, {
                toValue: 0,
                duration: 1000,
                delay: 150,
                useNativeDriver: false,
            }),
        ]).start();
    }, [])

    return (
        <div style={{
            height: '100%',// deviceWidth > 600 ? '85vh' : '88vh',
            width: '100%',
            // flex: 1,
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',

        }}>
            <Animated.View style={{
                width: deviceWidth > 600 ? '82%' : '90%',
                height: '75%',
                justifyContent: 'center',
                // marginBottom: deviceWidth > 600 ? '13%' : deviceWidth < 374 ? '25%' : '35%',
                transform: [{ translateX: animation }]
            }}>
                <Text style={{
                    fontFamily: 'Manrope-SemiBold',
                    fontSize: deviceWidth > 768 ? 60 : deviceWidth > 320 ? 48 : 40, lineHeight: deviceWidth > 768 ? 68 : deviceWidth > 320 ? 52 : 48,
                    fontWeight: '600', color: '#2f2f2f',
                    textTransform: 'uppercase',
                    marginBottom: hp("4%"),
                }}>
                    {experiance}</Text>
                <Text style={styles.customText}>
                    {experianceIntroText}
                    {/* {deviceWidth < 600 ? experianceIntroTextMobile : experianceIntroText} */}
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quam felis, accumsan eget pulvinar eu, tincidunt a quam.
                    {"\n"}{"\n"}
                    Duis felis nulla, consectetur a eros eget, suscipit tempus velit. Sed nec purus sit amet odio dignissim tristique. Fusce eu semper tellus, sit amet euismod magna. */}
                </Text>
                <View style={{ flexDirection: 'row', marginBottom: hp('3%'), opacity: 0.69, height: 18 }}>
                    <Image source={require('../../../../assets/images/regular-clock.png')} style={{
                        height: 18,
                        width: 18,
                        resizeMode: 'contain',
                    }}></Image>
                    <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regulars', color: '#2f2f2f', paddingLeft: 10 }}>Typically 2 minutes</Text>
                </View>
                <TouchableOpacity style={{ width: 115, height: 40, backgroundColor: '#2f2f2f', justifyContent: 'center', alignItems: 'center' }} onPress={() => { props.setDisplay(false) }}>
                    <Text style={{ textAlign: 'center', fontFamily: 'Manrope-SemiBold', fontWeight: '600', color: '#ffffff', lineHeight: 24, fontSize: 16 }}>
                        {buttonText}
                    </Text>
                </TouchableOpacity>
            </Animated.View>
            <View style={{ height: "25%", width: "100%" }} />

            <Animated.View style={{
                position: 'absolute', bottom: 0, right: deviceWidth > 1023 ? deviceWidth == 1024 ? '-16%' : 0 : deviceWidth == 768 ? '-9%' : '-19%'
            }}>
                <div className="mobileresponsive">
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 50,
                                right: 160,
                                height: 233,
                                width: 233,
                                borderRadius: 115,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="Halfcircle"
                            style={{
                                bottom: -30,
                                right: 90,
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.88,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 90,
                                right: 0,
                                height: 93,
                                width: 93,
                                borderRadius: 45,
                                backgroundColor: '#db7d72',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 10,
                                right: 20,
                                height: 143,
                                width: 143,
                                borderRadius: 75,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.77,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 20,
                                right: 20,
                                height: 271,
                                width: 5,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0,
                        transform: [{ translateX: animation4 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 220,
                                right: 300,
                                height: 106,
                                width: 5,
                                backgroundColor: '#7ebd5f',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: -30, right: 0, opacity: 0.76,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="triangle1"
                            style={{
                                bottom: 0,
                                right: 200,
                                position: 'absolute',
                                // mixBlendMode: 'overlay',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.88,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 150,
                                right: 450,
                                height: 93,
                                width: 93,
                                borderRadius: 45,
                                backgroundColor: '#db7d72',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: -40,
                                right: 680,
                                height: 155,
                                width: 155,
                                borderRadius: 75,
                                backgroundColor: '#db7d72',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 60,
                                right: 470,
                                height: 271,
                                width: 5,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: -50,
                                right: 460,
                                height: 270,
                                width: 270,
                                borderRadius: 135,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 90,
                                right: 290,
                                height: 5,
                                width: 406,
                                backgroundColor: '#db7d72',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: -20,
                                right: 420,
                                height: 192,
                                width: 10,
                                backgroundColor: '#7ebd5f',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0, opacity: 0.74,
                        transform: [{ translateX: animation3 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 20,
                                right: 350,
                                height: 93,
                                width: 93,
                                borderRadius: 45,
                                backgroundColor: '#ffb91d',
                            }}></div>
                    </Animated.View>
                    <Animated.View style={{
                        position: 'absolute',
                        bottom: 0, right: 0,
                        transform: [{ translateX: animation2 }]
                    }}>
                        <div className="rectangle"
                            style={{
                                bottom: 125,
                                right: 725,
                                height: 22,
                                width: 22,
                                backgroundColor: '#7ebd5f',
                            }}></div>
                    </Animated.View>
                </div>
            </Animated.View>
        </div>
    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',// deviceWidth > 600 ? '85vh' : '88vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    customText: {
        width: Dimensions.get('screen').width > 1300 ? Dimensions.get('screen').width > 1400 ? wp('56%') : wp('60%') : Dimensions.get('screen').width > 700 ? wp('60%') : wp('89%'),
        Height: hp('24.3%'),
        fontSize: 16,
        textAlign: 'Left',
        color: '#2f2f2f',
        marginBottom: hp("3%"),
        fontFamily: 'Manrope-Regulars',
        lineHeight: 24,
        alignSelf: 'flex-start'
    },
})