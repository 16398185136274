import { StyleSheet, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import '../../../../../src/App.css';
const windowWidth = Dimensions.get('window').width;
const actualWidth = Dimensions.get('window').width;

const getSheet = (size) => {
    return StyleSheet.create({
        body: {
            flex: 1,
            backgroundColor: "#8c9e0a",
            height: '100vh',
            width: '100vw',
            overflow: 'hidden'
        },

        textStyle: {
            color: 'white',
            fontSize: hp('2.6%'),
            letterSpacing: 0.5,
            fontFamily: 'OpenSans-Light',
            fontWeight: 'normal',
            fontStyle: 'normal',
            lineHeight: hp('2.6%'),
            padding: 10,
            position: 'relative',
            top: Dimensions.get('screen').height > 800 ? hp('3.5%') : hp('2%'),
            left: wp('3%')

        },
        introText: {
            color: 'white',
            fontSize: hp('2.6%'),
            letterSpacing: 0.5,
            fontFamily: 'OpenSans-Light',
            fontWeight: 'normal',
            fontStyle: 'normal',
            lineHeight: hp('2.6%'),
            padding: 10,
            position: 'relative',

        },
        buttonView: {
            flexDirection: 'row',
            position: 'relative',
            top: wp('30%')
        },
        introButton: {
            height: hp('6%'),
            width: wp('30%'),
            borderRadius: wp('25%'),
            backgroundColor: '#ffffff',
            textAlign: 'center',
            paddingVertical: hp('1.4%'),
            color: '#a81c1b',
            position: "relative",
            top: Dimensions.get('screen').height > 800 ? hp('3%') : hp('2.5%'),
            left: Dimensions.get('screen').width > 600 ? wp('2%') : wp('2.7%')
        },
        introView: {
            position: 'relative',

        },
        nextTextStyle: {
            color: 'white',
            fontFamily: 'OpenSans-Regular',
            fontSize: actualWidth <= 1125 ? wp('2.4%') : (windowWidth <= 1125 ? wp('2.4%') : wp('1.9%')),
            fontWeight: '300',
            fontStyle: 'normal',
            width: wp('98%'),
            alignSelf: 'center',
            position: 'relative',
            //marginBottom:'1%',
            left: '1%'
        },

        bottomView: {
            // flex: (1/2)/2,
            position: "absolute",
            // justifyContent: 'flex-end',
            bottom: 0,
            left: 0
        },
        button: {
            height: 48,
            width: 120,
            borderRadius: 50,
            backgroundColor: '#ffffff',
            textAlign: 'center',
            paddingVertical: 9,
            color: '#a81c1b',
            position: "relative",
            marginHorizontal: '1%',
            marginTop: 20,
            bottom: 0,
            left: 0
        },
        buttonText: {

            color: '#a81c1b',
            fontSize: 20,
            textAlign: 'center',
            fontFamily: 'OpenSans-Regular',

        },
        backButtonText: {

            color: '#ffffff',
            fontSize: 20,
            textAlign: 'center',
            fontFamily: 'OpenSans-Regular',

        },
        backButton: {
            height: 48,
            width: 120,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#ffffff',
            borderRadius: 50,
            paddingVertical: 9,
            position: "relative",
            marginHorizontal: '1%',
            marginTop: 20,
            bottom: 0,
            left: 0
        },
        rowBox: {
            flexDirection: "row",
            justifyContent: 'flex-start',
            height: 100,
            width: wp('100%'),
        }

    })
}
export default getSheet;