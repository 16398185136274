
import React,{useEffect,useState,useLayoutEffect,useRef} from 'react'
import { View ,StyleSheet,Text,Dimensions,TouchableOpacity,Animated,Easing} from 'react-native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { ESG_MODAL_HEADER } from '../../../../Utils/static-strings';
const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;

let deviceWidth = Dimensions.get("window").width;
let deviceHeigth = Dimensions.get("window").height;

const isIpad =
navigator.userAgent.match(/Mac/) &&
navigator.maxTouchPoints &&
navigator.maxTouchPoints > 2;

export const Modal = (props) => {
    const [height, setHeight] = useState(deviceHeigth);
    const [width, setWidth] = useState(deviceWidth);
    
    Dimensions.addEventListener("change", (e) => {
        deviceHeigth = e.window.height;
        deviceWidth = e.window.width;
        setHeight(deviceHeigth);
        setWidth(deviceWidth);
    });


    const introAnimation = useRef(new Animated.Value(-200)).current;
    useLayoutEffect(() => {
        setTimeout(()=>{
            props.setDragOpacity(true);
            },100)
        Animated.timing(introAnimation, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
            easing: Easing.linear
        }).start()
    }, [])
const UpwordAnimation=(next)=>{
    Animated.timing(introAnimation, {
        toValue: -200,
        duration: 200,
        useNativeDriver: false,
        easing: Easing.linear
    }).start(()=>{
        if(next){
            if(props.metadata.confirmationtext){
                  props.setModal(false);
                  props.setCompeleteTask(true);
            }else{
                props.setDragOpacity(false);
                props.setModal(false);
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.moduleObject.questions[props.moduleIndex].qid,
                    props.answerId,
                );
            }
        }else{
        props.setDragOpacity(false);
        props.removeAnswer();
        props.setModal(false);
        }
    })
}
    return (
            <Animated.View style={[styles.modalView,{ top: isIpad && (width > height) ? 10 : actualHeight>1000? actualHeight>1300?hp('14%') :hp('5%'): 5 , transform: [{ translateY: introAnimation }] }]}>
            { actualWidth>400?  
               <>
              <img src={require("../../../../../src/assets/svg/ic-lightbulb-outline.svg")} style={{
                              height:actualWidth>400?80:24,
                              width:actualWidth>400?80: 24,
                              objectFit: 'contain',
                            margin:hp('2%')
               }}/>
               <Text style={styles.headingText}>{ESG_MODAL_HEADER}</Text>
               </>
               :
               <View style={{flexDirection:'row',justifyContent:'flex-start',alignItems:'baseline',width:'90%',marginTop:hp('2%')}}>
               <img src={require("../../../../../src/assets/svg/ic-lightbulb-outline.svg")} style={{
                               height:actualWidth>400?80:24,
                               width:actualWidth>400?80: 24,
                               objectFit: 'contain',
                               marginRight:10,
                               paddingTop:5
                }}/>  
                <Text style={styles.headingText}>{ESG_MODAL_HEADER}</Text>
                </View>
            }
               <Text style={styles.subHeading}>{props.metadata.foodforthought}</Text>
               <TouchableOpacity style={styles.button} onPress={()=>UpwordAnimation(true)}>
                <Text style={styles.buttontext}>{props.metadata.foodforthoughtcontinue}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={()=>UpwordAnimation(false)}>
                    <Text style={styles.buttontext}>{props.metadata.foodforthoughtcancel}</Text>
                </TouchableOpacity>
            </Animated.View>
    )
}
const styles = StyleSheet.create({
    modalView:{
        top:actualHeight>1000? actualHeight>1300?hp('14%') :hp('5%'): 5,
        position:'absolute',
        backgroundColor:'#ffffff',
        elevation:4,
        borderRadius:actualWidth>700?9.7:8,
        justifyContent:'center',
        alignItems:'center',
        width:actualWidth>700? 640:actualWidth>400?366:304
    },
    headingText:{
        fontSize:actualWidth > 700 ? 32 : actualWidth > 400 ? 32 : 20,
        fontWeight:300,
        color: '#575f69',
        letterSpacing:actualWidth > 700 ? 3.2 : actualWidth > 400 ? 3.2 : 2,
        fontFamily:'JosefinSans-Light',
        textAlign:'center',
        marginBottom:actualWidth>400 ?hp("3%"):hp('2%'),
       },
    subHeading:{
        width:'90%',
        fontSize:actualWidth > 700 ? 20 : actualWidth > 400 ? 20 : 14,
        color: '#575f69',
        lineHeight:actualWidth > 700 ? 26.5 : actualWidth > 400 ? 26.5 : 21,
        fontFamily:'JosefinSans-Regular',
        textAlign:'justify',
        marginBottom: actualWidth>400 ?hp("3%"):hp('2%'),
       },
    button: {
        backgroundColor: '#575f69',
        width: actualWidth > 700 ? 318 : actualWidth > 400 ? 318 : 272,
        height: 48,
        marginBottom:actualWidth>400 ?hp("3%"):hp('2%'),
        justifyContent: 'center',
        borderRadius:2,
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.15)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowRadius: 2,
    },
    buttontext: {
        color: '#ffffff',
        fontFamily: 'JosefinSans-Bold',
        fontSize: actualWidth > 400 ? 16 : 12,
        textAlign: "center",
        letterSpacing: actualWidth > 400 ? 1.6 : 1.2,
        textTransform: 'uppercase',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: 0, height: 1 }, 
        padding:5
    },
})