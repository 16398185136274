import React, {useRef, useState, useLayoutEffect, useEffect, useContext} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Easing,
  Animated,
  Dimensions,
  StyleSheet
} from 'react-native';
import CircularPie from '../../../../Components/CircularPie';
import getSheet from './RotateStyle';
import {chunkify} from '../../../../Utils/chunkify';
import {withRouter} from 'react-router-dom';
import {ActivityContext} from '../../../Activities/index';
import IntroPage from './IntroPage';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from '../../../../Utils/responsive';
import {IFLAND, SPINNERTEXT} from '../../../../Utils/static-strings';
import LossSecondPage from './LossSecondPage';
import {Image} from 'react-native';

const deviceWidth = Dimensions.get('screen').width;


const Loss = (props) => {
  const [gain, setGain] = useState(40);
  const [loss, setLoss] = useState(60);
  const [show, setShow] = useState(true);
  const [allValue, setAllValue] = useState(null);
  const [indexing, setIndexing] = useState(null);
  const [totalLossAmount, setTotalLossAmount] = useState(10);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const resultContext = useContext(ActivityContext);
  const [showSecondPage, setShowSecondPage] = useState(false);
  const animated1 = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(0)).current;
  const opacity2 = useRef(new Animated.Value(0)).current;
  const bottomViewY = useRef(new Animated.Value(0)).current;
  const tBar = useRef(new Animated.Value(0)).current;
  const yesButton = useRef(new Animated.Value(0)).current;
  const noButton = useRef(new Animated.Value(0)).current;
  const scaleButton = useRef(new Animated.Value(1)).current;
  const pinch = useRef(new Animated.Value(1)).current;
  const [dataHistory, setDataHistory] = useState([]);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [height, setHeight] = useState(Dimensions.get('window').height);

  const [display, setDisplay] = useState(false);
  const [disable, setDisable] = useState(true);
  const [valueLength, setValueLength] = React.useState(4);


  useLayoutEffect(() => {
    Animated.timing(tBar, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
      easing: Easing.linear
    }).start(() => setDisplay(true));
  }, [])

  useEffect(() => {
    animate();

    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 1,
        duration: 2500,
        useNativeDriver: false,
        easing: Easing.in()
      }),
      Animated.timing(opacity2, {
        toValue: 1,
        duration: 900,
        useNativeDriver: false,
        easing: Easing.in
      }),
      Animated.timing(bottomViewY, {
        toValue: 35,
        duration: 900,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
    ]).start();
    if (props.moduleObject.questions) {
      function calculateNumberOfSets(questions) {
        const uniqueSets = new Set();

        questions.forEach(question => {
          // Split the qid by '.' and extract the part that indicates the set
          const setId = question.qid.split('.')[3].substring(0, 2);
          uniqueSets.add(setId);
        });

        return uniqueSets.size;
      }
      const numberOfSets = calculateNumberOfSets(props.moduleObject.questions); // Replace 'yourArray' with your actual data variable
      setValueLength(numberOfSets)
      const chunkiElement = chunkify(props.moduleObject.questions, numberOfSets, true);

      setAllValue(chunkiElement);

      const totalLength = chunkiElement.map(elem => (
          {length: elem.length}
      ));
      setIndexing(totalLength);
    }

    setLoss(loss);
    setGain(gain);
    setWidth(Dimensions.get('window').width);
    if (allValue && allValue[currentSet] && allValue[currentSet][questionIndex]) {
      setTotalLossAmount(parseInt(
          allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
      ) + 10)
    } else {
      setTotalLossAmount(10)
    }

  }, []);

  useLayoutEffect(() => {
    animate();
    setWidth(Dimensions.get('window').width);
  }, [show])

  useLayoutEffect(() => {
    if (currentSet === valueLength) {
      resultContext.setModuleCompletedAtTime(props.moduleObject.moduleid);
      props.history.push('/');
    }
    if (currentSet === 1) {
      setShow(true)
    }
    if (currentSet === 2) {
      setShow(true)
    }
    if (currentSet === 3) {
      setShow(true)
    }
  }, [currentSet]);

  const animate = () => {
    animated1.setValue(0);
    setDisable(true);
    Animated.timing(animated1, {
      toValue: 35,
      duration: 900,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      setTimeout(() => {setDisable(false)},4000);
    });
  };

  const yesAnimated = () => {
    yesButton.setValue(0);
    scaleButton.setValue(1);
    Animated.parallel([
      Animated.timing(scaleButton, {
        toValue: 1.6,
        duration: 200,
      }),
      Animated.timing(yesButton, {
        toValue: 1,
        duration: 700,
        easing: Easing.linear,
      }),

    ]).start(() => {
      setTimeout(() => {setDisable(false)},4000)
    });
  }

  const noAnimated = () => {
    noButton.setValue(0);
    scaleButton.setValue(1);
    Animated.parallel([
      Animated.timing(scaleButton, {
        toValue: 1.6,
        duration: 200,
      }),
      Animated.timing(noButton, {
        toValue: 1,
        duration: 700,
        easing: Easing.linear,
      }),

    ]).start(() => {
      setTimeout(() => {setDisable(false)},4000)
    });
  }


  const vanishButtons = () => {
    setTimeout(() => {
      Animated.sequence([
        Animated.timing(pinch, {
          toValue: 0,
          duration: 500,
          // delay: 1000
        }),
        Animated.timing(pinch, {
          toValue: 1,
          duration: 500,
          delay: 1600
        })
      ]).start()
    }, 600)
  }

  const yesInterpol = yesButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: ['0deg', '45deg', '0deg']
  })

  const noInterpol = noButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: ['0deg', '-45deg', "0deg"]
  });

  const yesScalingInterpolate = yesButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: [1, 1.6, 1]
  })
  const noScalingInterpolate = noButton.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: [1, 1.6, 1]
  })

  const allOpacity = opacity.interpolate({
    inputRange: [0, 0.8, 1],
    outputRange: [0, 0, 1]
  });

  const opacityT2 = opacity2.interpolate({
    inputRange: [0, 0.8, 1],
    outputRange: [0, 0, 1]
  });

  const topBar = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%']
  });

  const styles = getSheet(show);

  const fun = () => {
    setShow(false);
  };

  const showDescriptionPage = () => setShowSecondPage(prev => !prev);

  function closestNumber(n, m) {
    let q = parseInt(n / m);
    let n1 = m * q;
    let n2 = (n * m) > 0 ? (m * (q + 1)) : (m * (q - 1));
    if (Math.abs(n - n1) < Math.abs(n - n2))
      return n1;
    return n2;
  }

  const saveAnswer = (index) => {
    props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][questionIndex].qid, allValue[currentSet][questionIndex].answers[index].respid);
  }

  const handleButtonClick = (val) => {
    if (val === "Accept") {
      if (allValue && allValue[currentSet] && allValue[currentSet][questionIndex]) {
        setTotalLossAmount(parseInt(
            allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
        ) + 10)
      } else {
        setTotalLossAmount(10)
      }
      if (currentSet < 2 && questionIndex === 0) {
        //animate2();
      }
      if (Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 0) {
        if (questionIndex < indexing[currentSet].length - 1) {
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[0].respid);
          }
        }
        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[0].respid);
        setCurrentSet(prev => prev + 1);
        setDisable(true);
        setQuestionIndex(0);
      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(0);
          setQuestionIndex(prev => prev + 1);
        } else {
          saveAnswer(0);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
          setDisable(true);
        }
      }
    } else {
      if (allValue && allValue[currentSet] && allValue[currentSet][questionIndex]) {
        setTotalLossAmount(parseInt(
            allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
        ) + 10)
      } else {
        setTotalLossAmount(10)
      }
      if (currentSet < 2 && questionIndex === 0) {
        //animate2();
      }
      if (Number(allValue[currentSet][questionIndex].qid.slice(-4, -2)) % 2 === 1) {
        if (questionIndex < indexing[currentSet].length - 1) {
          for (let i = questionIndex; i < indexing[currentSet].length - 1; i++) {
            props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][i].qid, allValue[currentSet][i].answers[1].respid);
          }
        }
        props.selectAnswerHandler(props.moduleObject.moduleid, allValue[currentSet][indexing[currentSet].length - 1].qid, allValue[currentSet][indexing[currentSet].length - 1].answers[1].respid);
        setCurrentSet(prev => prev + 1);
        setQuestionIndex(0);
      } else {
        if (questionIndex < indexing[currentSet].length - 1) {
          saveAnswer(1);
          setQuestionIndex(prev => prev + 1);
        } else {
          saveAnswer(0);
          setQuestionIndex(0);
          setCurrentSet(prev => prev + 1);
        }
      }
    }
  }

  const findResearchValue = (array) => {
    const researchElement = array.find(element => element.startsWith('research.'));
    return researchElement ? researchElement.split('.')[1] : 'Not Found';
  };
  const testingGroup = findResearchValue(props.moduleObject.tags)
  const headerStep2 =
      testingGroup === 'A' ? 'We’ll show you two different scenarios with varying amounts and/or chances of gaining or losing.'
          : testingGroup === 'B' ? 'We’ll show you two different scenarios with varying amounts and/or chances of gaining or losing.'
              : 'We’ll show you two different scenarios with varying amounts and/or chances of gaining or losing.'

  const headerStep3 =
      testingGroup === 'A' ? 'We’ll show you two different scenarios with varying amounts and/or chances of gaining.\n\n' +
          '                \n' +
          '                We’ll ask if you’d play or skip.'
          : allValue && allValue[1][0].metadata.lossratio === 0.5 ? ''
              : `What if…

  There’s a 17% chance of a loss and an 83% chance of a gain.`

  const [footerStep2, setFooterStep2] = useState('');
  const [footerStep3, setFooterStep3] = useState('');
  const [footerStep4, setFooterStep4] = useState('');

  const [screenDimensions, setScreenDimensions] = useState(Dimensions.get('window'));

  // Handle dimension changes
  useEffect(() => {
    const onChange = ({window}) => {
      setScreenDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);

  const dynamicStyles = StyleSheet.create({
    customText: {
      letterSpacing: screenDimensions.height <= 479 ? 1.6 : screenDimensions.width <= 767 ? 2 : screenDimensions.width <= 991 ? 2.4 : 3.2,
      fontSize: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 16 : 24) : screenDimensions.width <= 767 ? 20 : screenDimensions.width <= 991 ? 24 : 32,
      lineHeight: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 21 : 29) : screenDimensions.width <= 767 ? 24 : screenDimensions.width <= 991 ? 29 : 38,
      marginTop: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      marginBottom: screenDimensions.height <= 600 ? (screenDimensions.width <= 767 ? 8 : 16) : screenDimensions.width <= 991 ? 24 : 24,
      // Other styles that do not depend on dimensions can remain static
      position: "relative",
      height: 'auto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      color: 'rgba(87, 95, 105, 0.75)',
      width: '100%',
      maxWidth: 680,
      fontFamily: 'JosefinSans-Light',
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const newFooterStep2 =
          testingGroup === 'A' ? 'Would you play?'
              : testingGroup === 'B' ? 'Would you risk losing £10 (1%)\n  for the chance to gain £80\n (8%) over the next 6 months?\n\n'
                  : `Would you risk losing ${allValue && Number(allValue[currentSet][questionIndex].metadata.lossamount.slice(1)) * 0.1}%\n for the chance to gain ${allValue && Number(allValue[currentSet][questionIndex]?.metadata?.winamount?.slice(1)) * 0.1}% over the next 6 months?\n\n`;

      setFooterStep2(newFooterStep2);
    }, 900); // 500ms delay

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, [testingGroup, allValue, currentSet, questionIndex]);


  useEffect(() => {
    const timer3 = setTimeout(() => {
      const newFooterStep3 =
          testingGroup === 'A' ? 'Would you play?'
              : testingGroup === 'B' ? 'Would you accept or reject?'
                  : 'Would you accept or reject?';

      setFooterStep3(newFooterStep3);
    }, 900); // 500ms delay for footerStep3

    return () => clearTimeout(timer3); // Cleanup timeout on component unmount
  }, [testingGroup]); // Dependencies for useEffect related to footerStep3

  useEffect(() => {
    const timer4 = setTimeout(() => {
      const newFooterStep4 =
          testingGroup === 'A' ? 'Would you play?'
              : testingGroup === 'B' ? 'Would you accept or reject?'
                  : `Would you risk losing ${allValue && Number(allValue[currentSet][questionIndex].metadata.lossamount.slice(1)) * 0.1}% for the chance to gain ${allValue && Number(allValue[currentSet][questionIndex]?.metadata?.winamount?.slice(1)) * 0.1}% over the next 6 months?\n\n`;

      setFooterStep4(newFooterStep4);
    }, 900); // 500ms delay for footerStep4

    return () => clearTimeout(timer4); // Cleanup timeout on component unmount
  }, [testingGroup, allValue, currentSet, questionIndex]);
  return (
      <View style={styles.body}>
        <Animated.View style={[styles.topborder, {width: topBar}]}></Animated.View>
        {/* <Text style={styles.Image}>Be-IQ</Text> */}
        <Image
            source={require("../../../../assets/svg/losslogo.svg")}
            style={{
              position: "relative",
              alignSelf: "flex-end",
              width: deviceWidth > 400 ? 54 : 40,
              height: deviceWidth > 400 ? 16 : 12,
              marginRight: 7
            }}
        />
        {show ? (
            <>
              {display &&

                  <IntroPage fun={fun} showDescriptionPage={showDescriptionPage} setDisable={setDisable}
                             currentSet={currentSet} moduleObject={props.moduleObject}
                             isVariantC={allValue && allValue[1][0].metadata.lossratio !== 0.5}/>
              }
            </>
        ) : (
            <>
              {
                showSecondPage ?
                    <LossSecondPage testingGroup={testingGroup} showDescriptionPage={showDescriptionPage}/> :
                    <View style={{flex: 1, justifyContent: 'center', overflow: 'hidden', margin: '16px 0'}}>
                      <View style={styles.topView}>
                        {

                          currentSet === 0 && questionIndex === 0 && !disable ?
                              <View style={outerStyle.content}>
                                <Text style={dynamicStyles.customText}>
                                  {headerStep2}
                                </Text>
                              </View>
                              : currentSet === 1 && questionIndex === 0 ? <View style={outerStyle.content}>
                                    <Text style={dynamicStyles.customText}>
                                      {currentSet === 1 && questionIndex === 0 ? headerStep3 : headerStep2}
                                    </Text>
                                  </View> :
                                  testingGroup === 'A' && <>
                                    <Animated.View style={[styles.headingContainer, {opacity: allOpacity}]}>
                                      <View style={styles.Line1}/>
                                      <Text style={styles.header}>{SPINNERTEXT}</Text>
                                      <View style={styles.Line2}/>
                                    </Animated.View>
                                    <Animated.View style={[styles.headingTxtPos, {opacity: opacityT2}]}>
                                      <Text style={styles.headingTxt}>{IFLAND}</Text>
                                    </Animated.View>
                                  </>

                        }
                      </View>
                      <CircularPie
                          activeColor="#79a2d9"
                          passiveColor="#4B73A8"
                          baseColor="white"
                          width={70}
                          done={
                              currentSet !== valueLength &&
                              (allValue && allValue[currentSet][questionIndex].metadata.lossratio < 0.5 ?
                                  closestNumber(((1 - allValue[currentSet][questionIndex].metadata.lossratio) * 100), 3) :
                                  closestNumber((allValue[currentSet][questionIndex].metadata.lossratio * 100), 5))
                          }
                          setDisable={setDisable}
                          gain={currentSet !== valueLength && parseInt(
                              allValue && allValue[currentSet][questionIndex].metadata.winamount.slice(1),
                          )}
                          showAnimation={true}
                          loss={currentSet !== valueLength && parseInt(
                              allValue && allValue[currentSet][questionIndex].metadata.lossamount.slice(1),
                          )}
                          setTriangle={currentSet === 2 || currentSet === 3}
                          radius={screenDimensions.height <= 700 || screenDimensions.width <= 600 ? 110 : 155}
                          bigRadius={155}
                          smallRadius={110}
                          duration={600}
                          hideIndicator={testingGroup !== 'A'}
                          showPercentage={true}
                          showAmount={false}
                          hideBracket={true}
                          isGain={testingGroup !== 'A'}
                          isBlueOverDark={currentSet !== valueLength && allValue && allValue[currentSet][questionIndex].metadata.lossratio !== 0.5}
                          ratio={allValue && allValue[currentSet] && allValue[currentSet][questionIndex] && allValue[currentSet][questionIndex].metadata.lossratio}
                          useBiggerFont={!(width < 992 || height < 1080)}
                      />
                      <Animated.View style={[styles.bottomView, {transform: [{scale: pinch}]}]}>
                        <Text style={dynamicStyles.customText}>
                          {currentSet === 0 ? footerStep2 : currentSet === 1 && questionIndex === 0 ? footerStep4 : footerStep3}
                        </Text>
                        <View style={styles.rowBox}>
                          {(allValue !== null && currentSet !== valueLength) &&

                              allValue[currentSet][questionIndex].answers.map(
                                  (answer, i) => {
                                    return (
                                        <TouchableOpacity
                                            disabled={disable}
                                            // style={styles.button}
                                            key={i}
                                            onPress={() => {
                                              handleButtonClick(answer.text);
                                              setDisable(true);
                                              vanishButtons();
                                              // animate();
                                              // yesAnimated();
                                              if (answer.text === 'Accept') {
                                                yesAnimated();
                                              } else {
                                                noAnimated();
                                              }
                                            }}>
                                          <Animated.View
                                              style={[styles.button, answer.text === 'Accept' ? {
                                                transform: [
                                                  {scale: yesScalingInterpolate},
                                                  {rotate: yesInterpol}
                                                ],
                                              } : {
                                                transform: [
                                                  {scale: noScalingInterpolate},
                                                  {rotate: noInterpol}
                                                ],
                                              }]
                                              }
                                          >
                                            <Text
                                                style={styles.buttonText}>{answer.text === 'Accept' ? testingGroup === 'A' ? 'YES' : 'YES' : testingGroup === 'A' ? 'NO' : 'NO'}</Text>
                                          </Animated.View>
                                        </TouchableOpacity>
                                    );
                                  },
                              )}
                        </View>
                      </Animated.View>
                    </View>
              }

            </>
        )
        }
      </View>
  );
}

export default withRouter(Loss);

const outerStyle = StyleSheet.create({
  content: {
    width: '100vw',
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  customText: {
    position: "relative",
    // width: actualWidth > 500 ? wp('100%') : actualWidth < 320 ? wp('90%') : wp('95%'),
    height: 'auto',
    // lineHeight: 22,
    // fontSize: 20,
    fontWeight: 'normal',
    fontStyle: 'normal',
    // letterSpacing: 0.5,
    textAlign: 'center',
    color: 'rgba(87, 95, 105, 0.75)',
    // fontFamily: "JosefinSans-Regular",
    top: 0,
    width: '100%', maxWidth: 680,
    // height: 212,
    fontSize: Dimensions.get('screen').width > 750 ? 28 : Dimensions.get('screen').width > 400 ? 20 : 16,
    fontFamily: 'JosefinSans-Light',
    letterSpacing: Dimensions.get('screen').width > 750 ? 2.8 : Dimensions.get('screen').width > 400 ? 2 : 1.6,
    padding: '17px'
  },
})
