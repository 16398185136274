import React, { useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    Dimensions,
    Animated,
    TouchableOpacity,
    Easing,
} from 'react-native';
import '../../../../App.css'
import CustomCard from './CustomCard';
import { withRouter } from 'react-router-dom';


export const THREAD_COLOR = '#e8e8e8';
export const CARD_BACKGROUND_COLOR = '#bb6fda';
export const CARD_BACKGROUND_COLOR2 = '#d00301';
export const dotColor = '#495049';
export const customDimesion = Dimensions.get('window').width;
const actualWidth = Dimensions.get('screen').width;

const Index = (props) => {

    const firstCardAnimation = useRef(new Animated.Value(-Dimensions.get('window').height / 2)).current;

    useEffect(() => {
        if (props.flow === 'dow') {
            firstCardAnimation.setValue(-Dimensions.get('window').height / 2);
            Animated.timing(firstCardAnimation, {
                toValue: 1,
                duration: 400,
                useNativeDriver: false,
                easing: Easing.linear,
            }).start(() => {
                setTimeout(() => props.setRestrict(false), 200)
            });
        }
        if (props.flow === 'up') {
            firstCardAnimation.setValue(1);
            Animated.timing(firstCardAnimation, {
                toValue: -Dimensions.get('window').height / 2,
                duration: 400,
                useNativeDriver: false,
                easing: Easing.linear,
            }).start(() => {
                props.selectAnswerHandler(
                    props.moduleObject.moduleid,
                    props.Questions[props.indexOfset].qid,
                    props.Questions[props.indexOfset].answers[props.answerId].respid,
                );
                props.setIndexOfset(prev => prev + 1);
                props.navigation(props.answerId);
                setTimeout(() => props.setRestrict(false), 200)

            });
        }
    }, []);



    return (
        <View style={styles.cardsContainer}>
            <Animated.View style={[styles.firstcard, { transform: [{ translateY: firstCardAnimation }] }]}>
                <View style={styles.firstThread} />
                <TouchableOpacity style={{ height: "65%", width: "100%" }} disabled={true}>
                    {<CustomCard
                        style={styles.customStyle}
                        data={props.Questions[props.indexOfset].text}//allValue[currentSet][questionIndex].answers[0].text}
                        innerStyle={styles.innerStyleFirst}
                        valueStyle={styles.valueStyleFirst}
                        thread="first"
                    />}
                </TouchableOpacity>
                <div className={customDimesion > 600 ? "firstCardShadow" : customDimesion > 405 ? "firstCardShadow1" : "firstCardShadow2"} />
            </Animated.View>
        </View>
    );
}

export default withRouter(Index);

const styles = StyleSheet.create({
    cardsContainer: {
        height: "50%",
        width: '65%', //customDimesion > 1500 ? '516px' : customDimesion > 600 ? "472px" : customDimesion > 405 ? "391px" : "303px",
        alignSelf: "center",
        // flexDirection: "row",
        elevation: 10,
        position: 'absolute',
        top: -20
    },
    firstcard: {
        position: 'relative',
        zIndex: 3,
        width: '100%', //customDimesion > 1500 ? '302px' : customDimesion > 600 ? "274px" : customDimesion > 405 ? "227px" : "176px",
        // backgroundColor:"blue",
        height: '90%'
    },

    firstThread: {
        position: 'relative',
        borderLeftColor: THREAD_COLOR,
        borderRightColor: THREAD_COLOR,
        borderWidth: 2,
        borderStyle: 'solid',
        height: "40%",
        zIndex: 5,
        borderBottomColor: 'transparent',
        borderTopColor: 'transparent',
        width: "85%",
        alignSelf: "center"
    },

    customStyle: {
        position: 'relative',
        marginTop: -10,
        backgroundColor: CARD_BACKGROUND_COLOR,
        width: '100%',
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 40,
        padding: 15
    },
    innerStyleFirst: {
        position: 'relative',
        width: "100%",
        textAlign: "center",
        color: '#ffffff',
        fontFamily: "JosefinSans-Regular",
        // fontSize:  "18px",
        fontSize: actualWidth <= 320 ? 16 : 23,
        letterSpacing: actualWidth <=320 ? 1.6 : 'normal'
        // fontWeight: 'bold',
        // letterSpacing:3.2
    },
});