import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../Utils/responsive';
import '../../../App.css';
import RectBlade from './RecBlade'
const actualWidth = Dimensions.get('screen').width;
const Index = (props) => {
    useEffect(() => {
        props.resultContext.setModuleStartedAtTime(props.module.moduleid);
    }, [])
    return (
        <View style={[styles.content]}>
            <View style={[styles.headingContainer, { marginBottom: Dimensions.get('screen').width > 1023 ? 25 : 12 }]}>
                <View style={styles.Line1} />
                <Text style={styles.header}>{props.module.questions[0].text}</Text>
                <View style={styles.Line2} />
            </View>
            <View style={{ justifyContent: 'center', flexDirection: Dimensions.get('screen').width > 1023 ? 'row' : 'column' }}>
                <RectBlade color="#6fd5da" text={props.module.questions[0].answers[0].text} {...props} id={0} />
                <RectBlade color="#fda435" text={props.module.questions[0].answers[1].text} {...props} id={1} />
                <RectBlade color="#ee7998" text={props.module.questions[0].answers[2].text} {...props} id={2} />
                <RectBlade color="#bb6fda" text={props.module.questions[0].answers[3].text} {...props} id={3} />
                <RectBlade color="#7483ed" text={props.module.questions[0].answers[4].text} {...props} id={4} />
            </View>
        </View>

    )
}
export default Index
const styles = StyleSheet.create({
    content: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headingContainer: {
        width: actualWidth < 1023 ? "321px" : "674px",
        // height: 76,
        height: 'auto',
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor:'orange',
        // alignSelf:"center",
        marginBottom: hp("2%")
    },

    Line1: {
        position: "relative",
        width: actualWidth < 1023 ? 250 : 500,
        height: 1,
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',

    },

    Line2: {
        position: "relative",
        width: actualWidth < 1023 ? 250 : 500,
        height: 1,
        // display: WidthDimension<600
        backgroundColor: "#d4d4d4",
        alignSelf: 'center',
    },

    header: {
        position: "relative",
        width: "100%",
        height: "auto",
        alignSelf: 'center',
        fontFamily: 'JosefinSans-Light',
        fontSize: Dimensions.get('screen').width > 1023 ? 32 : 22,
        letterSpacing: Dimensions.get('screen').width > 1023 ? 2.8 : 2,
        fontWeight: '300',
        textAlignVertical: "center",
        textAlign: 'center',
        marginTop: 6,
        color: '#575f69',
        textTransform: 'uppercase'
        // top:actualWidth>2599 ?hp('0.7%') : hp('1%')
    },
})