import React, {useState, useEffect} from 'react';
import {Text, View, Animated, Dimensions, StyleSheet, TouchableOpacity} from 'react-native';
import {heightPercentageToDP as hp} from '../../Utils/responsive';

const actualWidth = Dimensions.get('screen').width;
const actualHeight = Dimensions.get('screen').height;


const FrontPageTabBar = (props) => {
  const {
    rightArrow,
    rightArrowClicked,
    leftArrow,
    leftArrowClicked,
    setLeftArrowVisibility,
    setRightArrowVisibility,
    setCurrentSelectedTab,
    defaultScreen,
    valueUI,
    understandingUI
  } = props;
  const [selectedTab, setSelectedTab] = useState(1);
  const [scrollViewPos, setScrollViewPos] = useState(new Animated.ValueXY(0, 0));
  const [centerPos, setCenterPos] = useState(0);
  const [originalPos, setOriginalPos] = useState(0);
  useEffect(() => {

    if (defaultScreen == 2) {
      setTab("understanding")
      setSelectedTab(2);
      setCurrentSelectedTab(2);
    }
    if (defaultScreen == 3) {
      setTab("values")
      setSelectedTab(3);
      setCurrentSelectedTab(3);
    }
  }, [])

  useEffect(() => {
    if (selectedTab === 1) {
      setLeftArrowVisibility(false);
      if (understandingUI.length === 0) {
        setRightArrowVisibility(valueUI.length !== 0);
      } else {
        setRightArrowVisibility(true);
      }
    } else if (selectedTab === 3) {
      setRightArrowVisibility(false);
      setLeftArrowVisibility(true);
    } else {
      if (valueUI.length === 0) {
        setRightArrowVisibility(false);
      } else {
        setRightArrowVisibility(true);
      }
      setLeftArrowVisibility(true);
    }
    setCurrentSelectedTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (rightArrow === true) {
      if (selectedTab === 1) {
        if (understandingUI.length > 0) {
          setTab("understanding");
          rightArrowClicked(false);
        }
        if (understandingUI.length === 0 && valueUI.length > 0) {
          setTab("values");
          rightArrowClicked(false);
        }
      } else if (selectedTab === 2) {
        setTab("values");
        rightArrowClicked(false);
      } else {
        setTab("values");
        rightArrowClicked(false);
      }
    }
  }, [rightArrow]);

  useEffect(() => {
    if (leftArrow === true) {
      if (selectedTab === 3) {
        setTab("understanding");
        leftArrowClicked(false);
        if (understandingUI.length === 0 && valueUI.length > 0) {
          setTab("risk");
          leftArrowClicked(false);
        }
      } else {
        setTab("risk");
        leftArrowClicked(false);
      }
    }
  }, [leftArrow]);

  const calculatePosFirst = () => {
    if (selectedTab === 2) {
      setSelectedTab(1);
      // return scrollViewPos.x._value + centerPos;
      return -originalPos;
    } else if (selectedTab === 3) {
      setSelectedTab(1);
      return -originalPos
      // return -(scrollViewPos.x._value + 400);

    } else {
      setSelectedTab(1);
      return scrollViewPos.x._value;
    }
  }

  const calculatePosMiddle = () => {
    if (selectedTab === 1) {
      setSelectedTab(2);
      return -(centerPos - 25);
    } else if (selectedTab === 3) {
      setSelectedTab(2);
      return (scrollViewPos.x._value + centerPos);
    } else {
      setSelectedTab(2);
      return (scrollViewPos.x._value);
    }
  }

  const calculateLastTabPos = () => {
    if (selectedTab === 1) {
      if (defaultScreen === 3) {
        setSelectedTab(3);
        return -(333);
      } else {
        setSelectedTab(3);
        return -((centerPos * 2) - 25);
      }
    } else if (selectedTab === 2) {
      setSelectedTab(3)
      return -((centerPos * 2) - 30)
    } else {
      setSelectedTab(3);
      return scrollViewPos.x._value;
    }
  }

  const setTab = (tab) => {
    switch (tab) {
      case "risk":
        Animated.spring(scrollViewPos, {
          toValue: {x: calculatePosFirst(), y: 0}
        }).start();
        break;
      case "understanding":
        Animated.spring(scrollViewPos, {
          toValue: {x: calculatePosMiddle(), y: 0}
        }).start();

        break;

      case "values":
        Animated.spring(scrollViewPos, {
          toValue: {x:(understandingUI.length > 0 ? calculateLastTabPos() : calculateLastTabPos() + 105), y: 0}
        }).start();
        break;
    }
  }

  return (
      <>
        <View>
          <Animated.View style={scrollViewPos.getLayout()}>
            <View
                style={[styles.rowStyling, {left: defaultScreen == 2 ? '-2%' : actualWidth > 550 ? (selectedTab == 1 ? (actualWidth > 1024 ? (understandingUI.length == 0 ? '18%' : '43%') : '43%') : (actualWidth > 1024 ? '40%' : '41%')) : selectedTab == 1 ? "45%" : "42%",},]}>
              <TouchableOpacity
                  style={[styles.buttonContainer, {position: centerPos}, {opacity: selectedTab === 1 ? 1.0 : 0.3}, {backgroundColor: selectedTab === 1 ? "rgba(87, 95, 105, 0.1)" : null}]}
                  onPress={() => {
                    setTab("risk")
                  }}>
                <Text style={styles.rectangleText}>RISK</Text>
              </TouchableOpacity>
              {understandingUI.length == 0 ? null
                  :
                  <TouchableOpacity style={[styles.buttonContainer, {opacity: selectedTab === 2 ? 1.0 : 0.3}, {
                    backgroundColor: selectedTab === 2 ? "rgba(87, 95, 105, 0.1)" : null,
                    marginLeft: '5%'
                  }]} onPress={() => {
                    setTab("understanding")
                  }}>
                    <Text style={styles.rectangleText}>UNDERSTANDING</Text>
                  </TouchableOpacity>
              }
              {valueUI.length == 0 ?
                  <TouchableOpacity
                      style={[styles.buttonContainer, {opacity: valueUI.length == 0 ? 0 : selectedTab === 3 ? 1.0 : 0.3}, {
                        backgroundColor: selectedTab === 3 ? "rgba(87, 95, 105, 0.1)" : null,
                        marginLeft: '5%'
                      }]}>
                    <Text style={[styles.rectangleText, {opacity: 0}]}>VALUES</Text>
                  </TouchableOpacity>
                  :
                  understandingUI.length === 0 ?
                      <TouchableOpacity style={[styles.buttonContainer, {opacity: selectedTab === 3 ? 1.0 : 0.3}, {
                        backgroundColor: selectedTab === 3 ? "rgba(87, 95, 105, 0.1)" : null,
                        marginLeft: selectedTab === 3 ? '-10%' : '-10%',
                      }]} onPress={() => {
                        setTab("values")
                      }}>
                        <Text style={styles.rectangleText}>VALUES</Text>
                      </TouchableOpacity>
                      :
                      <TouchableOpacity style={[styles.buttonContainer, {opacity: selectedTab === 3 ? 1.0 : 0.3}, {
                        backgroundColor: selectedTab === 3 ? "rgba(87, 95, 105, 0.1)" : null,
                        marginLeft: '5%'
                      }]} onPress={() => {
                        setTab("values")
                      }}>
                        <Text style={styles.rectangleText}>VALUES</Text>
                      </TouchableOpacity>
              }
            </View>
          </Animated.View>
          <Text style={styles.centerPos} onLayout={event => {
            setCenterPos(event.nativeEvent.layout.x)
          }} hidden={true}>Center</Text>
        </View>
      </>
  );
}

export default FrontPageTabBar;
const styles = StyleSheet.create({


  // rectangle: {
  //   width: 'auto',
  //   height: 'auto',
  //   borderRadius: 24,
  //   alignSelf: "center",
  //   backgroundColor: 'rgba(87, 95, 105, 0.1)',
  //   position: 'relative',
  //   top: actualHeight > 812 ? 0 : hp('2%'),
  //   margin: 5
  // },
  rectangleText: {
    padding: 12,
    height: "100%",
    fontFamily: 'JosefinSans-Regular',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    textAlign: 'center',
    textAlignVertical: 'center',
    color: '#575f69',
  },
  rowStyling: {
    width: 400,
    justifyContent: 'space-evenly',
    alignItems: "center",
    flexDirection: "row",
    position: 'relative',
    // backgroundColor:"blue"
    // left: '40%',
  },

  buttonContainer: {
    borderRadius: 24,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    margin: 5
  },
  centerPos: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'transparent'
  }
});
