import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, Animated } from 'react-native';
import '../../../../App.css'

const Index = (props) => {
  const BottomTextOpacity = useState(new Animated.Value(0))[0];
  const BottomTextAnimation = useState(new Animated.Value(100))[0];

  useEffect(() => {
    Animated.parallel([
      Animated.timing(BottomTextOpacity, {
        toValue: 1,
        duration: 1200,
        delay: 800,
        useNativeDriver: false,
      }),
      Animated.timing(BottomTextAnimation, {
        toValue: 0,
        duration: 1200,
        delay: 800,
        useNativeDriver: false,
      }),
    ]).start();
  }, []);

  return (
    <Animated.View
      style={[
        styles.customBottomView,
        { opacity: BottomTextOpacity, transform: [{ translateY: BottomTextAnimation }] },
      ]}>
      <Text style={styles.textBottom}>Tap which one-off payment</Text>
      <Text style={styles.textBottom}>you'd rather have.</Text>
    </Animated.View>
  );
};

export default Index;

const styles = StyleSheet.create({
  customBottomView: {
    position: 'relative',
    width: "100%",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center"
  },
  textBottom: {
    fontFamily: "OpenSans-Regular",
    width: "100%",
    fontSize: "22px",
    color: '#ffffff',
  },
});
