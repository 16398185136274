import React, { useState } from 'react';
import { View } from 'react-native';

const height = 85 //107.5; and 120 in 500
const width = 37.5 //37.5;
const Arrow = () => {
    const [degree, setDegree] = useState('0deg');

    return (
        <>
            <View style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: [{ translateX: -width / 2 }, { translateY: -height + 13 }],
                zIndex: 99,
                width: width,
                height: height
            }}>

                {/* <Image
                    style={{
                        width: width,
                        height: height,
                        resizeMode: 'stretch',
                        transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
                    }}
                    source={require('../../assets/images/arrow.png')} /> */}
                <View style={{
                    transform: [{ translateY: height / 2 - 13 }, { rotate: degree }, { translateY: -height / 2 + 13 }],
                }} >
                    <View style={{
                        position: 'absolute',
                        width: 2, height: 25,
                        backgroundColor: 'black', transform: [{ rotate: '30deg' }],
                        left: 10,
                        top: -21
                    }} />
                    <View style={{
                        position: 'absolute',
                        width: 2, height: 25,
                        backgroundColor: 'black', transform: [{ rotate: '-30deg' }],
                        left: 25,
                        top: -21
                    }} />
                    <View style={{
                        width: 3, height: height,
                        left: 17,
                        bottom: 21,
                        backgroundColor: 'black',
                    }} />
                    <View style={{
                        backgroundColor: '#eee',
                        height: 15, width: 15, borderRadius: 15 / 2,
                        borderColor: 'black', borderWidth: 5,
                        left: 11.4, bottom: 22,
                    }} />
                </View>
            </View>
        </>
    );
};

export default Arrow;