import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Cards from '../../../../Components/CardDesign/index';
// import BottomText from '../BottomText/index';
// import BackButton from '../../../../Components/BackButton/index';
import GetStarted from '../../../../Components/FuturePrePage/index';
import { listenOrientationChange } from '../../../../Utils/responsive';

const Index = (props) => {
  const [question, setQuestion] = useState(null);
  const [getStarted, setGetStarted] = useState(true);
  // const prevQuestion = () => {
  //   props.setModuleIndex((prev) => prev - 1);
  //   setQuestion(props.moduleIndex);
  // };

  const handleStart = () => {
    setGetStarted(false);
  };

  useEffect(()=>{
    listenOrientationChange();
  }, [])

  return (
    <React.Fragment>
      {getStarted ? (
        <GetStarted handleStart={handleStart} />
      ) : (
          <View style={styles.main}>
            <Cards {...props} question={question} />
            {/* <BottomText {...props} />
            {props.moduleIndex > 0 && (
              <BackButton prevQuestion={prevQuestion} />
            )} */}
          </View>
        )}
    </React.Fragment>
  );
};

export default Index;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    height: "100vh",
    width: "100vw",
    backgroundColor: '#9f3469',
    // flexDirection: 'row',
  },
});
