import React, { useEffect, useState, useRef } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Animated,
  TouchableOpacity,
  Dimensions,
  PixelRatio
} from 'react-native';
import {  screenWidth as actualWidth , screenHeight as actualHeight, listenOrientationChange } from '../../../../Utils/responsive'
import { TriangleCorner, Button } from '../../../../Components/ButtonOption/index';
import Introduction from './Introductionpage'
import Middlepage from './Middlepage'
import '../../../../App.css'
import { inIframe } from '../../../../Utils/helper';
export default function Index({
  moduleIndex,
  selectAnswerHandler,
  moduleObject,
  setModuleIndex,
}) {

  const animation = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation1 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation2 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation3 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation4 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation5 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const animation6 = useRef(new Animated.Value(Dimensions.get('screen').width + 100)).current;
  const [page, setpage] = useState(true);
  const [show, setShow] = useState(true);
  const [screenWidth, setScreenWidth ] = React.useState(actualWidth);
  const [screenHeight, setScreenHeight ] = React.useState(actualHeight);

  const wp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    return PixelRatio.roundToNearestPixel((screenWidth * t) / 100);
  }

  const hp = (e) => {
    const t = "number" == typeof e ? e : parseFloat(e);
    const newHeight = PixelRatio.roundToNearestPixel((screenHeight * t) / 100);
    return newHeight;
  };

  Dimensions.addEventListener("change", (t) => {
    setScreenWidth( t.window.width);
    setScreenHeight( t.window.height);
   
  });

  useEffect(() => {
    setScreenWidth(Dimensions.get("window").width);
    setScreenHeight(Dimensions.get("window").height);
  }, []);

  useEffect(() => {
    animation.setValue(Dimensions.get('screen').width + 100);
    animation1.setValue(Dimensions.get('screen').width + 100);
    animation2.setValue(Dimensions.get('screen').width + 100);
    animation3.setValue(Dimensions.get('screen').width + 100);
    animation4.setValue(Dimensions.get('screen').width + 100);
    animation5.setValue(Dimensions.get('screen').width + 100);
    animation6.setValue(Dimensions.get('screen').width + 100);
    Animated.parallel([
      Animated.timing(animation, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: false,
      }),
      Animated.timing(animation1, {
        toValue: 0,
        duration: 1000,
        delay: 500,
        useNativeDriver: false,
      }),
      Animated.timing(animation2, {
        toValue: 0,
        duration: 1000,
        delay: 600,
        useNativeDriver: false,
      }),
      Animated.timing(animation3, {
        toValue: 0,
        duration: 1000,
        delay: 700,
        useNativeDriver: false,
      }),
      Animated.timing(animation4, {
        toValue: 0,
        duration: 1000,
        delay: 800,
        useNativeDriver: false,
      }),
      Animated.timing(animation5, {
        toValue: 0,
        duration: 1000,
        delay: 900,
        useNativeDriver: false,
      }),
      Animated.timing(animation6, {
        toValue: 0,
        duration: 1000,
        delay: 1000,
        useNativeDriver: false,
      }),
    ]).start();
  });
  const Refresh = () => {
    setpage(false)
  };
  const Showing = () => {
    setShow(false)
  }
  const backButton = () => {
    setModuleIndex(moduleIndex - 1);
  };


  useEffect(()=>{
    listenOrientationChange();
  }, [])

  return (
    <View style={[styles.container]}>
      {show ? (
        <Animated.View style={{ transform: [{ translateX: animation }] }}>
          {page ? <Introduction Refresh={Refresh} /> : <Middlepage Showing={Showing} />}
        </Animated.View>
      ) : (
          <View style={[{ flex: 1, justifyContent: 'center', }]}>
            <View>
              <Animated.View style={{ transform: [{ translateX: animation }] }}>
                <TriangleCorner test={moduleObject.questions[moduleIndex].text} />
              </Animated.View>
            </View>
            <View>
              <Animated.View style={{ transform: [{ translateX: animation1 }] }}>
                <Button
                  selectAnswerHandler={selectAnswerHandler}
                  moduleIndex={moduleIndex}
                  moduleObject={moduleObject}
                  i={0}
                />
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation2 }] }}>
                <Button
                  selectAnswerHandler={selectAnswerHandler}
                  moduleIndex={moduleIndex}
                  moduleObject={moduleObject}
                  i={1}
                />
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation3 }] }}>
                <Button
                  selectAnswerHandler={selectAnswerHandler}
                  moduleIndex={moduleIndex}
                  moduleObject={moduleObject}
                  i={2}
                />
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation4 }] }}>
                <Button
                  selectAnswerHandler={selectAnswerHandler}
                  moduleIndex={moduleIndex}
                  moduleObject={moduleObject}
                  i={3}
                />
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation5 }] }}>
                <Button
                  selectAnswerHandler={selectAnswerHandler}
                  moduleIndex={moduleIndex}
                  moduleObject={moduleObject}
                  i={4}
                />
              </Animated.View>
              {moduleIndex > 0 ? (
                <Animated.View style={{ transform: [{ translateX: animation6 }] }}>
                  <TouchableOpacity
                    style={[styles.backButton,{
                      marginTop: inIframe() ? 8: hp('1%'),
                      marginLeft: inIframe() ? 23.5: wp('2%'),}]}
                    onPress={() => backButton()}>
                    <Text style={styles.backButtonText}>Go back</Text>
                  </TouchableOpacity>
                </Animated.View>
              ) : null}
            </View>
          </View>
        )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    backgroundColor: '#19b2bb',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backButton: {
    alignSelf: 'flex-start',
    width: 'auto',
    borderRadius: '22px',
    backgroundColor: 'transparent',
    borderWidth: '1px',
    borderColor: '#ffffff',
    borderStyle: 'solid',
  },
  backButtonText: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '19px',
    fontFamily: 'OpenSans-Regular'
  },
});
