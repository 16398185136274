import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Animated,
  TouchableOpacity,
  Dimensions,
  Image,
  Easing
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from '../../../../Utils/responsive';
import { TriangleCorner } from '../../../../Components/ButtonOption/Index2';
import Intro from './Intro';
import '../../../../App.css';
import Quant from '../../AtrQuants/index'
import { moduleid } from '../../../../Utils/static-strings'
const deviceWidth = Dimensions.get('screen').width;
export default function Index({
  moduleIndex,
  selectAnswerHandler,
  moduleObject,
  history,
  allmodule,
  resultContext
}) {
  const actualWidth = Dimensions.get('screen').width;
  const introAnimation = useRef(new Animated.Value(actualWidth)).current;
  const animation = useRef(new Animated.Value(0)).current;
  const animation1 = useRef(new Animated.Value(0)).current;
  const animation2 = useRef(new Animated.Value(0)).current;
  const animation3 = useRef(new Animated.Value(0)).current;
  const animation4 = useRef(new Animated.Value(0)).current;
  const animation5 = useRef(new Animated.Value(0)).current;
  const tBar = useRef(new Animated.Value(0)).current;
  const [show, setShow] = useState(true);
  const [display, setDisplay] = useState(false);
  const [atrQuant, setatrQuant] = useState(true);
  const [atrQuantModule, setAtrQuantModule] = useState(null);
  useLayoutEffect(() => {
    const moduleArray = allmodule.filter(
      (module) => module.moduleid === moduleid.atrquant(),
    );
    setAtrQuantModule(moduleArray[0]);
    Animated.timing(tBar, {
      toValue: 1,
      duration: 800,
      useNativeDriver: false,
      easing: Easing.linear
    }).start(() => {
      setDisplay(true)
      Animated.timing(introAnimation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.linear
      }).start()
    });
  }, [])

  useEffect(() => {
    animation.setValue(Dimensions.get('screen').width);
    animation1.setValue(Dimensions.get('screen').width);
    animation2.setValue(Dimensions.get('screen').width);
    animation3.setValue(Dimensions.get('screen').width);
    animation4.setValue(Dimensions.get('screen').width);
    animation5.setValue(Dimensions.get('screen').width);
    Animated.parallel([
      Animated.timing(animation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
      }),
      Animated.timing(animation1, {
        toValue: 0,
        duration: 600,
        delay: 500,
        useNativeDriver: false,
      }),
      Animated.timing(animation2, {
        toValue: 0,
        duration: 600,
        delay: 600,
        useNativeDriver: false,
      }),
      Animated.timing(animation3, {
        toValue: 0,
        duration: 600,
        delay: 700,
        useNativeDriver: false,
      }),
      Animated.timing(animation4, {
        toValue: 0,
        duration: 600,
        delay: 800,
        useNativeDriver: false,
      }),
      Animated.timing(animation5, {
        toValue: 0,
        duration: 600,
        delay: 900,
        useNativeDriver: false,
      }),
    ]).start();
  });

  const topBar = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%']
  });

  const topBar2 = tBar.interpolate({
    inputRange: [0, 1],
    outputRange: [deviceWidth > 1300 ? -126 : -102, 0]
  });

  const setAnswer = (i) => {
    if (moduleIndex < 9) {
      selectAnswerHandler(
        moduleObject.moduleid,
        moduleObject.questions[moduleIndex].qid,
        moduleObject.questions[moduleIndex].answers[i].respid,
      );
    } else {
      resultContext.setAnswer(moduleObject.moduleid,
        moduleObject.questions[moduleIndex].qid,
        moduleObject.questions[moduleIndex].answers[i].respid);
      resultContext.setModuleCompletedAtTime(moduleObject.moduleid);
      if (atrQuantModule == undefined || atrQuantModule == null) {
        history.push('/');
      } else {
        setatrQuant(false)
      }
    }
  };
  const Showing = () => {
    setShow(false);
  }
  return (
    <>{atrQuant ? (<>
      <View style={[styles.container1]}>
        <Animated.View style={[styles.topborder, { transform: deviceWidth < 500 ? [{ translateX: 0 }] : [{ translateX: topBar2 }], width: topBar, }]}></Animated.View>
        {/* <Image style={styles.Image}
          source={require('../../../../assets/images/risklogo.png')} ></Image> */}
        <img src={require("../../../../assets/svg/risklogo.svg")} style={{
          position: "relative",
          alignSelf: "flex-end",
          width: deviceWidth > 400 ? 54 : 40,
          objectFit: 'contain',
          height: deviceWidth > 400 ? 16 : 12,
          marginRight: 7
        }} />
      </View>
      <View style={[styles.container]}>
        {show ? (
          <>
            {display && <Animated.View style={{ transform: [{ translateX: introAnimation }] }}>
              <Intro Showing={Showing} moduleObject={moduleObject} />
            </Animated.View>}
          </>

        ) : (
          <View style={[{ flex: 1, justifyContent: 'center' }, actualWidth > 760 ? { marginBottom: hp('2%') } : null]}>
            <View  >
              <Animated.View style={{ transform: [{ translateX: animation }] }}>
                <TriangleCorner test={moduleObject.questions[moduleIndex].text} />
              </Animated.View>
            </View>
            <View>
              <Animated.View style={{ transform: [{ translateX: animation1 }] }}>
                <TouchableOpacity style={styles.button1} onPress={() => setAnswer(0)}>
                  <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[0].text}</Text>
                </TouchableOpacity>
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation2 }] }}>
                <TouchableOpacity style={styles.button2} onPress={() => setAnswer(1)}>
                  <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[1].text}</Text>
                </TouchableOpacity>
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation3 }] }}>
                <TouchableOpacity style={styles.button3} onPress={() => setAnswer(2)}>
                  <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[2].text}</Text>
                </TouchableOpacity>
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation4 }] }}>
                <TouchableOpacity style={styles.button4} onPress={() => setAnswer(3)}>
                  <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[3].text}</Text>
                </TouchableOpacity>
              </Animated.View>
              <Animated.View style={{ transform: [{ translateX: animation5 }] }}>
                <TouchableOpacity style={styles.button5} onPress={() => setAnswer(4)}>
                  <Text style={styles.button5text}>{moduleObject.questions[moduleIndex].answers[4].text}</Text>
                </TouchableOpacity>
              </Animated.View>
            </View>
          </View>
        )}
      </View></>) :
      <Quant
        resultContext={resultContext}
        history={history}
        module={atrQuantModule}
      />
    }
    </>
  );
}

const styles = StyleSheet.create({
  container1: {
    width: "100vw",
    //flex: 1,
    backgroundColor: '#eeeeee',
    overflow: 'hidden'
  },
  container: {
    width: "100vw",
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    overflow: 'hidden'
  },
  topborder: {
    position: "relative",
    alignSelf: 'center',
    borderTopColor: "#6fd5da",
    borderTopWidth: 3,
    marginBottom: hp("1.2%")
  },
  Image: {
    position: "relative",
    alignSelf: "flex-end",
    marginLeft: '90vw',
    width: 48,
    resizeMode: "contain",
    marginRight: 8,
    height: 12
  },
  button1: {
    // marginTop:10,
    left: wp('2%'),
    backgroundColor: '#6fd5da',
    width: 'auto',
    alignSelf: 'flex-start',
    borderRadius: 2
  },
  button2: {
    marginTop: 5,
    left: wp('2%'),
    backgroundColor: '#6fd5da',
    width: 'auto',
    alignSelf: 'flex-start',
    borderRadius: 2
  },
  button3: {
    marginTop: 5,
    left: wp('2%'),
    backgroundColor: '#6fd5da',
    width: 'auto',
    alignSelf: 'flex-start',
    borderRadius: 2
  },
  button4: {
    marginTop: 5,
    left: wp('2%'),
    backgroundColor: '#6fd5da',
    width: 'auto',
    alignSelf: 'flex-start',
    borderRadius: 2
  },
  button5: {
    marginTop: 5,
    left: wp('2%'),
    backgroundColor: '#6fd5da',
    width: 'auto',
    alignSelf: 'flex-start',
    borderRadius: 2
  },
  button5text: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingTop: 12,
    paddingBottom: 9,
    color: '#ffffff',
    fontFamily: 'JosefinSans-Bold',
    letterSpacing: 2,
    fontSize: 20,
    textTransform: 'uppercase'
  }
});